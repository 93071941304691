<template>
   <div class="flex justify-center mt-10 p-4">
    <div class="shadow rounded-md w-full lg:w-5/6">
      <div class="p-4 bg-warm-gray-200 flex justify-between items-center dark:bg-warm-gray-700 dark:text-warm-gray-200">
        <div class="bg-warm-gray-100 w-full py-1 px-4 text-warm-gray-600 dark:bg-warm-gray-300 rounded-md flex items-center justify-start">
          <SearchIcon class="h-4 w-4 mr-2" />
          <input type="text" class="bg-warm-gray-100 dark:bg-warm-gray-300 p-1 w-full focus:outline-none" v-model="searchEmail" placeholder="Search email address">
        </div>
      </div>
      <table class="w-full">
          <thead>
              <tr class="hidden lg:table-row text-warm-gray-700 border-b border-warm-gray-300 dark:text-warm-gray-100">
                  <th class="lg:w-3/10 py-2 text-left pl-2">Email</th>
                  <th class="lg:w-2/10 py-2">Reason</th>
                  <th class="w-2/10 py-2">Disable Date</th>
                  <th class="lg:w-1/10 py-2"></th>
              </tr>
          </thead>
          <template v-if="!isUserRetrieving">
            <tbody class=" overflow-visible text-center">
                <!-- for mobile -->
                <tr v-for="user,i in filteredUsers" :key="i" class="lg:hidden">
                  <td>
                    <div class="border text-warm-gray-700 dark:text-warm-gray-300 dark:border-warm-gray-600 rounded-md m-2 p-2 text-left">
                      <p> <b>Email:</b> {{ user.data.email }}</p>
                      <p> <b>Disable date:</b> {{ moment.unix(user.data.date/1000).format('LLLL') }}</p>
                      <p> <b>Reason:</b> {{ user.data.reason }}</p>

                      <div class=" border-t dark:border-warm-gray-700 flex justify-end items-center mt-2 pt-2">
                          <button class="border dark:border-warm-gray-700 rounded px-2 py-1" @click="enableAccount(user)">Enable</button>
                      </div>

                    </div>
                  </td>
                </tr>
                <!-- for pc -->
                <tr v-for="user,i in filteredUsers" :key="i" class=" hidden lg:table-row border-b hover:text-warm-gray-600 text-warm-gray-600 lg:text-white hover:bg-warm-gray-100 dark:text-warm-gray-900 dark:border-warm-gray-700 dark:hover:bg-warm-gray-800 dark:hover:text-warm-gray-200">
                    <td class="text-warm-gray-700 dark:text-warm-gray-200 py-1 text-left pl-2">
                      <div class="flex items-center w-full justify-start">
                        {{ user.data.email }}
                      </div>
                    </td>
                    <td class="text-warm-gray-700 dark:text-warm-gray-200 py-1 hidden lg:table-cell px-5">
                        {{ user.data.reason }}
                    </td>
                    <td class="text-warm-gray-700 dark:text-warm-gray-200 py-1 hidden lg:table-cell">{{ moment.unix(user.data.date/1000).format('LLLL') }}</td>
                    <td class="px-3 py-1">
                      <button class="border border-warm-gray-400 rounded-md px-1 py-0.5 text-warm-gray-700 dark:text-warm-gray-400" @click="enableAccount(user)">Enable</button>
                    </td>
                </tr>


            </tbody>
          </template>
          <template v-else>
            <tbody class="animate-pulse">
              <tr v-for="i in 3" :key="i">
                <td><div class="h-4 bg-warm-gray-200 rounded m-2"></div></td>
                <td><div class="h-4 bg-warm-gray-200 rounded m-2"></div></td>
                <td><div class="h-4 bg-warm-gray-200 rounded m-2"></div></td>
                <td><div class="h-4 bg-warm-gray-200 rounded m-2"></div></td>
              </tr>
            </tbody>
          </template>
      </table>
    </div>
  </div>

  <ProcessingModal v-if="isProcessing" />

</template>
<script setup>
  import moment from "moment";
</script>
<script>
import { doc, deleteDoc } from "firebase/firestore";
import { db } from '@/firebase/config.js';

import {disableUser} from '@/firebase/controllers/userController';
import swal from 'sweetalert2';

import ProcessingModal from '@/components/ProcessingModal';

import {
  MagnifyingGlassIcon as SearchIcon
} from "@heroicons/vue/24/outline";

export default {
  props: {
    users: Array,
    isUserRetrieving: Boolean,
    getUser: {
        type: Function
      }
  },
  data(){
    return{
      isProcessing: false,
      searchEmail: '',
    }
  },
  computed: {
    filteredUsers(){
      return this.users.filter(u => u.data.email.toLowerCase().includes(this.searchEmail));
    }
  },
  components: {
    SearchIcon,
    ProcessingModal
  },
  methods: {
    enableAccount(user){

      swal.fire({
        title: 'Enable account?',
        text: `Are you sure you want to enable user ${user.data.email}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#db912a',
        cancelButtonColor: '#4a4848',
        confirmButtonText: 'Yes, enable'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isProcessing = true;
          const res = await disableUser(user.data.uid, false);
          if(res.success){
            
            await deleteDoc(doc(db, "disabledUsers", user.docId));

            this.getUser();
            swal.fire(
                'Success!',
                `${user.email} has been enabled.`,
                'success'
            );
          }
          this.isProcessing = false;
        }
      });

    },
  },
}
</script>