<template>


    <main v-if="!isInitializing"  class="h-full w-full text-sm pb-10">

      <!-- search bar -->
      <div class="my-4 mx-2">

        <div class="relative rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg class="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>

          </div>
          <input v-model="searchString" type="text" name="search-key" id="search-key" class="text-sm block w-full rounded-md border-gray-300 pl-9 pr-12 py-4 focus:border-yellow-500 focus:ring-yellow-500" placeholder="Search users by email, firstname, or lastname" aria-describedby="search-key">
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span class="text-gray-500 text-xs" id="search-key">{{ users.length }} Results</span>
          </div>
        </div>


      </div>


      <div v-if="!isSearching" class="overflow-auto w-full h-full">

        <div class="h-full w-full">
          <router-link :to="`/v2/user/${user.id}`" tag="button" v-for="user, i in users" :key="i" class="border border-warm-gray-300 p-4 m-2 flex justify-start items-center hover:bg-warm-gray-50">
  
            <!-- photo -->
            <div>
              <img v-if="user.data.photo" class="object-cover h-10 w-10 rounded-full mr-2" :src="user.data.photo" alt="" />
              <div v-else class="bg-gray-100 rounded-full overflow-hidden h-10 w-10 mr-2">
                <svg
                  class="w-full h-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                  ></path>
                </svg>
              </div>
    
            </div>
            <!-- info -->
            <div class=" text-warm-gray-500 ml-2">
              
              <p class="text-warm-gray-600 font-semibold">{{ user.data.firstname }} {{ user.data.lastname }}</p>
              <p>{{ user.data.email }}</p>
              <p>{{ user.data.date_created ? formatDate(user.data.date_created) : '---' }}</p>
              <div class=" mt-2">
                  <!-- CAAP badge -->
                  <div v-if="user.data.reviewers && user.data.reviewers.caap != 'inactive'" 
                    :class="[
                      (user.data.reviewers.caap == 'paid') ? 'bg-yellow-100 text-yellow-800' : '',
                      (user.data.reviewers.caap == 'ready') ? 'bg-yellow-100 text-yellow-800' : '',
                      (user.data.reviewers.caap == 'active') ? 'bg-purple-100 text-purple-800' : '',
                      (user.data.reviewers.caap == 'expired') ? 'bg-red-100 text-red-800' : '',
                      'grow-0 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium mr-2'
                    ]">
                      {{ (user.data.reviewers.caap == 'paid') ? 'CAAP - Waiting Approval' : '' }}
                      {{ (user.data.reviewers.caap == 'ready') ? 'CAAP - Ready' : '' }}
                      {{ (user.data.reviewers.caap == 'active' ||  user.data.reviewers.caap == 'expired') ? 'CAAP' : '' }}
                  </div>
    
                  <!-- CAAP LITE badge -->
                  <div v-if="user.data.reviewers && user.data.reviewers.caap_lite && user.data.reviewers.caap_lite != 'inactive' && user.data.reviewers.caap_lite != ''" 
                    :class="[
                      (user.data.reviewers.caap_lite == 'paid') ? 'bg-yellow-100 text-yellow-800' : '',
                      (user.data.reviewers.caap_lite == 'active') ? 'bg-purple-100 text-purple-800' : '',
                      'grow-0 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium mr-2'
                    ]">
                      {{ (user.data.reviewers.caap_lite == 'paid') ? 'CAAP Lite - Waiting Approval' : '' }}
                      {{ (user.data.reviewers.caap_lite == 'active') ? 'CAAP Lite' : '' }}
                  </div>
    
                  <!-- CAAP PLUS badge -->
                  <div v-if="user.data.reviewers && user.data.reviewers.caap_plus && user.data.reviewers.caap_plus != 'inactive' && user.data.reviewers.caap_plus != ''" 
                    :class="[
                      (user.data.reviewers.caap_plus == 'paid') ? 'bg-yellow-100 text-yellow-800' : '',
                      (user.data.reviewers.caap_plus == 'active') ? 'bg-purple-100 text-purple-800' : '',
                      'grow-0 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium mr-2'
                    ]">
                      {{ (user.data.reviewers.caap_plus == 'paid') ? 'CAAP Plus - Waiting Approval' : '' }}
                      {{ (user.data.reviewers.caap_plus == 'active') ? 'CAAP Plus' : '' }}
                  </div>
    
                  <!-- MTP badge -->
                  <div v-if="user.data.reviewers && user.data.reviewers.mtp && user.data.reviewers.mtp != 'inactive' && user.data.reviewers.mtp != ''" 
                    :class="[
                      (user.data.reviewers.mtp == 'paid') ? 'bg-yellow-100 text-yellow-800' : '',
                      (user.data.reviewers.mtp == 'active') ? 'bg-purple-100 text-purple-800' : '',
                      'grow-0 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium mr-2'
                    ]">
                      {{ (user.data.reviewers.mtp == 'paid') ? 'MTP - Waiting Approval' : '' }}
                      {{ (user.data.reviewers.mtp == 'active') ? 'MTP' : '' }}
                  </div>
    
                  <div v-if="user.data.endorser" class="text-xs grow-0 inline-flex items-center rounded-full px-2.5 py-0.5 font-normal bg-gray-200 text-gray-500">
                    {{ user.data.endorser }}
                  </div>
              </div>
              
            </div>
  
          </router-link>

          <div class="w-full flex justify-center mt-5">
            <button v-if="isSearchMode" @click=" handleSearch() " class="border border-warm-gray-400 text-warm-gray-400 py-2 px-4 rounded-md disabled:opacity-25" :disabled="isLastPage.firstnameSearch && isLastPage.lastnameSearch && isLastPage.emailSearch">Load more...</button>
            <button v-else @click=" loadUsers()" class="border border-warm-gray-400 text-warm-gray-400 py-2 px-4 rounded-md disabled:opacity-25" :disabled="isLastPage.noSearch">Load more...</button>
          </div>

          <br><br><br><br><br><br>

        </div>

      </div>

      <div v-else class="flex justify-center items-center w-full mt-12 italic text-warm-gray-400">
        Searching user...
      </div>



    </main>

    <!-- Waiting Screen -->
    <main v-else class="overflow-auto h-full w-full flex justify-center items-center">
        <Logo class="w-24 animate-pulse text-warm-gray-300" />
    </main>

  

</template>

<script setup>

  import { ref, onMounted, watch } from "vue";
  import Logo from "@/_for_dash/icons/Logo";

  import { db } from "@/firebase/config";
  import { collection, query, orderBy, limit, startAfter, getDocs, where } from "firebase/firestore";

  const isInitializing = ref(false);

  const lastDocs = ref({
    firstnameSearch: null,
    lastnameSearch: null,
    emailSearch: null,
    noSearch: null
  })
  const isLastPage = ref({
    firstnameSearch: false,
    lastnameSearch: false,
    emailSearch: false,
    noSearch: false
  })
  const pageSize = ref(10);
  const users = ref([]);


  const timer = ref(null);
  const searchString = ref('');
  const isSearching = ref(false);
  const isSearchMode = ref(false);
  
  // Fetch users
  const fetchUsers = async (lastDoc=null, searchType=null,) => {

    const usersRef = collection(db,'users');

    let q;


    switch (searchType) {
      case 'firstname':

          q = query(usersRef, 
                  where('firstname', '>=', searchString.value),
                  where('firstname', '<=', searchString.value + '\uf8ff'),
                  limit(pageSize.value)
                  );
          if (lastDoc) {
            q = query(usersRef, 
                  where('firstname', '>=', searchString.value),
                  where('firstname', '<=', searchString.value + '\uf8ff'),
                  startAfter(lastDoc), 
                  limit(pageSize.value)
                  );
          }
        
        break;

      case 'lastname':

          q = query(usersRef, 
                  where('lastname', '>=', searchString.value),
                  where('lastname', '<=', searchString.value + '\uf8ff'),
                  limit(pageSize.value)
                  );
          if (lastDoc) {
            q = query(usersRef, 
                  where('lastname', '>=', searchString.value),
                  where('lastname', '<=', searchString.value + '\uf8ff'),
                  startAfter(lastDoc), 
                  limit(pageSize.value)
                  );
          }

          break;
      case 'email':

          q = query(usersRef, 
                  where('email', '>=', searchString.value),
                  where('email', '<=', searchString.value + '\uf8ff'),
                  limit(pageSize.value)
                  );
          if (lastDoc) {
            q = query(usersRef, 
                  where('email', '>=', searchString.value),
                  where('email', '<=', searchString.value + '\uf8ff'),
                  startAfter(lastDoc), 
                  limit(pageSize.value)
                  );
          }

          break;

      default:

          q = query(usersRef, 
                  where('firstname', '!=', ''),
                  orderBy('firstname', 'asc'),
                  limit(pageSize.value)
                  );
          if (lastDoc) {
            q = query(usersRef, 
                  where('firstname', '!=', ''),
                  orderBy('firstname', 'asc'),
                  startAfter(lastDoc), 
                  limit(pageSize.value)
                  );
          }

        break;
  
    }
      

    const querySnapshot = await getDocs(q);

    const toRetUsers = [];
    querySnapshot.forEach(doc => {
        toRetUsers.push({
          id: doc.id,
          data: doc.data()
        })
    });

    const isLastPage = querySnapshot.size < pageSize.value;

    return { users: toRetUsers, lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1], isLastPage };

  }
  

  const handleSearch = async () => {

    let [firstnameSearchResult, lastnameSearchResult, emailSearchResult] = await Promise.all([
        fetchUsers( isLastPage.value.firstnameSearch ? null : lastDocs.value.firstnameSearch ,'firstname'),
        fetchUsers(isLastPage.value.lastnameSearch ? null : lastDocs.value.lastnameSearch ,'lastname'),
        fetchUsers(isLastPage.value.emailSearch ? null : lastDocs.value.emailSearch ,'email'),
    ]);

    // merge results from fistname
    let mergedUsers = [...firstnameSearchResult.users, ...lastnameSearchResult.users, ...emailSearchResult.users]
    // make sure no duplicate
    let uniqueIds = new Set();
    // Filter the array to keep only unique objects based on their id
    let uniqueUsers = mergedUsers.filter(user => {
      // Check if the id is already in the Set
      if (uniqueIds.has(user.id)) {
        // If the id is already present, it's a duplicate, so exclude it
        return false;
      } else {
        // If the id is not present, add it to the Set and include the object
        uniqueIds.add(user.id);
        return true;
      }
    });

    users.value.push(...uniqueUsers);

    // record lastDocs
    lastDocs.value.firstnameSearch = firstnameSearchResult.lastDoc;
    lastDocs.value.lastnameSearch = lastnameSearchResult.lastDoc;
    lastDocs.value.emailSearch = emailSearchResult.lastDoc;

    // record isLastPage
    isLastPage.value.firstnameSearch = firstnameSearchResult.isLastPage;
    isLastPage.value.lastnameSearch = lastnameSearchResult.isLastPage;
    isLastPage.value.emailSearch = emailSearchResult.isLastPage;
  
  }

  
  const loadUsers = async () => {
    let resUsers = await fetchUsers(lastDocs.value.noSearch);
    users.value.push(...resUsers.users);
    lastDocs.value.noSearch = resUsers.lastDoc;
    isLastPage.value.noSearch = resUsers.isLastPage;
  }


  watch(searchString, async (newVal, oldVal)=> {

      users.value = [];
      isSearching.value = true;
      isSearchMode.value = true;

      isLastPage.value.firstnameSearch = false;
      isLastPage.value.lastnameSearch = false;
      isLastPage.value.emailSearch = false;

      lastDocs.value.firstnameSearch = null;
      lastDocs.value.lastnameSearch = null;
      lastDocs.value.emailSearch = null;

      // Clear the previous timer
      clearTimeout(timer.value);

      // check if blank
      if (newVal.trim() == '') {
        lastDocs.value.noSearch = null;
        isSearchMode.value = false;
        await loadUsers();
        isSearching.value = false;
        return;
      }

      // Set a new timer for 300 milliseconds
      timer.value = setTimeout(async () => {
        await handleSearch();
        isSearching.value = false;
      }, 500); // Adjust debounce delay as needed

  })

  onMounted(async()=> {
    isInitializing.value = true;

    await loadUsers()

    isInitializing.value = false;
  })

  //########## helper functions

  const formatDate = (timestampInSeconds) => {

    const timestampInMilliseconds = parseInt(timestampInSeconds);
    const date = new Date(timestampInMilliseconds);

    // Define an array to map month numbers to month names
    const months = [
    "Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.",
    "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
    ];

    // Get the month, day, and year from the date object
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    // Construct the formatted date string
    return `${month} ${day}, ${year}`;

  }  


</script>
<style>
</style>