// const axios = require('axios');
import axios from "axios";

async function subscribe(subscriber, method, rev){
  return new Promise(async (resolve, reject) => {
  
  

  console.log("subscriber", subscriber)

  const data = {
    api_key: process.env.VUE_APP_CONVERTKIT_API_KEY,
    email: subscriber.email,
    first_name: subscriber.firstname,
    // last_name: subscriber.lastname
  };

  // console.log({data})
  console.log("method: ", method)

  if(method == 'caap_discounted'){
    method = 'caap_discounted'
  }else if(rev == 'caap'){
    method = 'purchased'
  }else if(rev == 'caap_lite'){
    method = 'lite'
  }else if(rev == 'caap_plus'){
    method = 'plus'
  }else if(rev == 'mtp'){
    method = 'mtp'
  }

  // @TODO: Update the linkedin ID
  const form_ids = {
    mtp: process.env.VUE_APP_CONVERTKIT_MTP_FORM_ID,
    lite: process.env.VUE_APP_CONVERTKIT_CAAP_LITE_FORM_ID,
    plus: process.env.VUE_APP_CONVERTKIT_CAAP_PLUS_FORM_ID,
    recapture: process.env.VUE_APP_CONVERTKIT_CAAP_RECAPTURE_FORM_ID,
    purchased: process.env.VUE_APP_CONVERTKIT_CAAP_PURCHASED_FORM_ID,
    caap_discounted: process.env.VUE_APP_CONVERTKIT_CAAP_DISCOUNTED_FORM_ID
  }
  
  // const caap_form_id = process.env.VUE_APP_CONVERTKIT_CAAP_PURCHASED_FORM_ID

  // try {
    // await axios.post(
    //   `https://api.convertkit.com/v3/forms/${form_ids[method]}/subscribe`,
    //   // `https://api.convertkit.com/v3/forms/${caap_form_id}/subscribe`,
    //   data,
    // );
    // return {
    //   statusCode: 200,
    //   body: 'Email Subscribed',
    // };

    let CONVERTKIT_URL = `https://api.convertkit.com/v3/forms/${form_ids[method]}/subscribe`;

    axios.post(CONVERTKIT_URL, data)
    .then((response) => {
      console.log(response);
      if(response.status == 200){
        resolve(true)
      }
    }, (error) => {
      console.error(error)
      reject(error)
    });

  })

  // } catch (err) {
  //   console.log("An Error Occured")
  //   console.log(err)
  //   return {
  //     statusCode: err.response,
  //     body: JSON.stringify({ msg: err.message }),
  //   };
  // }
}

export {
  subscribe
}