// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";
import { getDatabase, serverTimestamp } from "firebase/database";


// use this code when developing using the firebase emulators
// import { connectAuthEmulator } from "@firebase/auth";
// import { connectFirestoreEmulator } from "@firebase/firestore";
import { connectFunctionsEmulator } from "@firebase/functions";
// import { connectStorageEmulator } from "firebase/storage";
export const isLocalDB = false;


const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    databaseURL: process.env.VUE_APP_FIREBASE_RTDB_URL
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp);
export const storage = getStorage(firebaseApp);
export const fcmMessaging = getMessaging(firebaseApp);
export const rtdb = getDatabase(firebaseApp);
export const timestamp = serverTimestamp();


// use this code when developing using the firebase emulators
if (isLocalDB && window.location.hostname.includes("localhost")) {
    // connectAuthEmulator(auth, "http://localhost:9099");
    // connectFirestoreEmulator(db, 'localhost', 8085);
    connectFunctionsEmulator(functions, "localhost", 5001);
    // connectStorageEmulator(storage, "localhost", 9199);
}