import { httpsCallable } from "firebase/functions";
import { functions } from '@/firebase/config.js';
import { collection, getDocs, doc, getDoc, query, where } from "firebase/firestore";
import { db } from '@/firebase/config.js';
import { reviewersColName } from '@/firebase/vars';

export const create = async(form) => {
    const createReviewer = httpsCallable(functions, 'createReviewer');
    return await createReviewer({ form })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}


export const getAll = async(form) => {
    const getReviewers = httpsCallable(functions, 'getReviewers');
    return await getReviewers()
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const viewReviewer = async(revId) => {
    const docSnap = await getDoc(doc(db, reviewersColName, revId));
    if (docSnap.exists()) {
        return { isError: false, data: docSnap.data() }
    } else {
        return { isError: true, data: null }
    }
}

export const deleteReviewer = async(revId) => {
    const deleteReviewer = httpsCallable(functions, 'deleteReviewer');
    return await deleteReviewer({ revId })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const update = async(form) => {
    const updateReviewer = httpsCallable(functions, 'updateReviewer');
    return await updateReviewer({ form })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}