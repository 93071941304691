export const plusSurveyRoutes = [{
        path: '/plus-survey',
        name: 'plusSurvey',
        component: () =>
            import ('../views/PlusSurveyLanding.vue')
    },
    {
        path: '/plus-survey/:subjectId',
        name: 'plusSurveySubjectDetails',
        component: () =>
            import ('../views/PlusSurveySubjectDetails.vue')
    },
    {
        path: '/plus-survey/:subjectId/user/:userId',
        name: 'plusSurveyUserDetails',
        component: () =>
            import ('../views/PlusSurveyUserDetails.vue')
    },
]