<template>
  <div class="flex-row border-b dark:border-warm-gray-700">
    <div class="flex justify-start items-center mb-8">
      <h2 class="text-xl font-extrabold text-warm-gray-700 dark:text-warm-gray-100 tracking-wider">Reviewers Data</h2>
      <template v-if="reviewers.data">
        <select class="border-b border-warm-gray-500 border-0 focus:outline-none focus:ring-0 text-warm-gray-800 dark:text-warm-gray-200 bg-transparent py-1 ml-2" v-model="curRevId">
          <option v-for="reviewer in reviewers.data.reviewers" :value="reviewer.id" class="text-warm-gray-800">{{ reviewer.data.name }}</option>
        </select>        
      </template>
    </div>

    
    <ul v-if="!retrieving && this.$route.query.r && reviewerSet" class="flex justify-start">
      <li v-for="(tab,i) in tabs" :key="i" :class="[view == tab.viewId && 'border-b-4 border-yellow-600']">
        <router-link :to="tab.to+'?r='+curRevId">
          <button class="py-2 px-4 focus:outline-none">
            {{tab.name}}
          </button>
        </router-link>
      </li>
    </ul>

  </div>
  <router-view v-if="!retrieving && this.$route.query.r && reviewerSet"/>
  <div v-if="retrieving" class="flex justify-center items-center mt-10">
    <SpinningIcon class="h-6 w-6 text-yellow-600" />
  </div>
</template>

<script>
import { getAll } from '../modules/reviewer/firebase';
import SpinningIcon from '@/components/SpinningIcon';

 // test for git stash
export default {
  data() {
    return {
      isMounted: false,
      retrieving: false,
      reviewers: [],
      reviewerSet: true,
      curRevId: null,
      view:'',
      activeTab: 'questionnaires',
      tabs: [
        {
          viewId: 'questionnaires',
          name: 'Questionnaires',
          to: '/data/questionnaires',
        },
        {
          viewId: 'courses',
          name: 'Courses',
          to: '/data/courses',
        },
        {
          viewId: 'subjects',
          name: 'Subjects',
          to: '/data/subjects',
        },
        {
          viewId: 'subtopics',
          name: 'Subtopics',
          to: '/data/subtopics',
        },
        {
          viewId: 'tags',
          name: 'Tags',
          to: '/data/tags',
        },
        {
          viewId: 'explanations',
          name: 'Explanations',
          to: '/data/explanations',
        },
      ],
    }
  },
  components:{
    SpinningIcon,
  },
  updated() {
    const curPath = this.$route.path;
    this.view = curPath.split('/')[2];
    if (this.isMounted) {
      this.setReviewerId();
    }
  },
  watch:{
    curRevId(newVal, oldVal){
      this.$router.push('/data/questionnaires?r='+newVal);
    }
  },
  async mounted() {
    
    this.retrieving = true;

    const curPath = this.$route.path;
    this.view = curPath.split('/')[2];
    this.reviewers = await getAll();

    // check if there is a reviewer added already
    if(this.reviewers.data.reviewers.length > 0){
      // this.setReviewerId();
      this.curRevId = this.reviewers.data.reviewers[0].id;
    }else{
      this.reviewerSet = false;
      alert('Please add reviewer first!');
      this.$router.push('/reviewers');
      return;
    }

    this.retrieving = false;
    this.isMounted = true;
  },
  methods: {
    setReviewerId(){

      if (!this.$route.query.r) {
        this.$router.push(this.$route.path+'?r='+this.reviewers.data.reviewers[0].id);
        return;
      }

      // check if the reviewer ID has been passed already in the url
      if(this.curRevId){
        // if already passed check the ID if valid
        let isFound = this.reviewers.data.reviewers.find(r => r.id == this.curRevId)
        if(!isFound){
          alert('Reviewer ID not found!');
          this.reviewerSet = false;
          return;
        }
      }else{
        this.curRevId = this.reviewers.data.reviewers[0].id;
        // this.$router.push(this.$route.path+'?r='+this.reviewers.data.reviewers[0].id);
      }

    }
  },
}
</script>