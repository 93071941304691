<template>
  <div>
    
  </div>
</template>
<script>
import { logOut } from '@/firebase/controllers/userController';
import { setPresence } from '@/firebase/controllers/presenceController'
export default {
 async mounted() {
    await setPresence('offline');
    await logOut();
    this.$router.push('Login');
 }, 
}
</script>