<template>
    <!-- Header -->
    <header v-if="!isInitializing" class="h-header overflow-none">
        <div class="p-4 border-b border-gray-300">
            <div class="relative rounded-md shadow-sm">
                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <!-- <span class="text-gray-500 sm:text-sm">$</span> -->
                    <svg class="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>

                </div>
                <input v-model="searchKey" type="text" name="search-key" id="search-key"
                    class="text-xs block w-full rounded-md border-gray-300 pl-9 pr-12 focus:border-yellow-500 focus:ring-yellow-500"
                    placeholder="Search Keyword" aria-describedby="search-key">
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <span class="text-gray-500 text-xs" id="search-key">{{ filteredQuestions.length }} Results</span>
                </div>
            </div>
        </div>
    </header>

    <div v-if="!isInitializing" class="h-full overflow-y-auto scrollbar-hide mt-6 px-4 space-y-2" aria-label="Questionnaires">
        
        <div v-for="questionnaire in filteredQuestions" :key="questionnaire.id" class="border border-gray-300 text-xs sm:text-sm flex flex-col px-4 py-3">
            {{ questionnaire }}
        </div>

    </div>

    <!-- Waiting Screen -->
    <main v-if="isInitializing" class="overflow-auto h-full w-full flex justify-center items-center">
      <Logo class="w-24 animate-pulse text-warm-gray-300" />
    </main>



    <!-- <header v-if="!isInitializing">
            <div class="h-header border-b border-gray-300 ">
                <ul class="flex w-full h-header justify-between">
                    <li 
                        v-for="view, i in VIEWS"
                        @click="activeView = view"
                        :key="view" 
                        :class="[
                            (activeView == view) ? 'border-b-4 border-gray-600' : '',
                            
                            'w-full flex items-center justify-center'
                        ]">
                        <a href="#"   
                        class="cursor-pointer px-4 py-1 text-xs sm:text-sm font-base leading-4 text-gray-700 h-full w-full flex items-center justify-center"
                        :class="(i == 0) ? '' : 'border-l border-gray-300 border-dashed'"
                        >
                        <span>
                            {{ view }}
                        </span>
                        </a>
                    </li>
                </ul>
            </div>
        </header> -->

    <!-- <div v-if="!isInitializing && activeView == VIEWS[0]" class="h-full overflow-none px-4 py-2">
            Email
        </div>
        <div v-else-if="!isInitializing && activeView == VIEWS[1]" class="h-full overflow-none px-4 py-2">
            Date View
        </div>
        <div v-else-if="!isInitializing && activeView == VIEWS[2]" class="h-full overflow-none px-4 py-2">
            Endorser View
        </div> -->
</template>
<script setup>
import { ref, computed } from "vue"
import { auth, db, functions } from '@/firebase/config.js'
import { getDocs,collection, orderBy, query, doc, updateDoc, setDoc, deleteDoc } from "firebase/firestore";
import Logo from "@/_for_dash/icons/Logo";

const VIEWS = [
    'Email',
    'Date',
    'Endorser',
]
const activeView = ref(VIEWS[0])

const questionnaires = ref([])


const allQuestions = computed(() => {
    var _questions = [];
    questionnaires.value.forEach( questionnaire => {
        console.log(questionnaire.id)
        let l = questionnaire.questions.length
        questionnaire.questions.forEach( (question, index) => {
            let questionObject = {
                questionnaireId: questionnaire.id,
                question: question,
                tag: `${index+1} of ${l}`
            }
            _questions.push(questionObject)
        })
    })
    return _questions;
})

const searchKey = ref('')
const filteredQuestions = computed(() => {
    console.log(allQuestions.value)
  let filteredResults = allQuestions.value.filter( e => e.question.question.includes(searchKey.value));
  return filteredResults;
})

const isInitializing = ref(false)

const fetchAllQuestionnaires = async () => {
  return new Promise(async (resolve, reject) => {
    getDocs( query(collection(db, "questionnaires")))
      .then((querySnapshot) => { 
        if(querySnapshot.size > 0){
          var _questionnaires = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data()
            data.id = doc.id
            _questionnaires.push(data)
          });
          resolve(_questionnaires)
        }else{
          console.log("Query returned no results!")
        }
      })
      .catch((error) => {
        console.error("Error retreiving questionaires list from DB");
        throw `Error getting user document: ${error}`;
      });
  })
}

const initialize = async () => {
  isInitializing.value = true
  questionnaires.value = await fetchAllQuestionnaires()
  isInitializing.value = false
}
initialize()

</script>