<template>
  <div class="container mx-auto p-4">

    <div class="flex justify-between">
      <!-- Context Menu -->
      <div class="flex mb-4">
        <button :disabled="isWaiting" @click="createNewCoupon"
          :class="['bg-blue-500 text-white p-2 rounded mr-2', (isWaiting) ? disabledClasses : '']">Create Coupon</button>
        <button v-if="selectedCoupons.length" :disabled="isWaiting"
          :class="['bg-red-500 text-white p-2 rounded mr-2', (isWaiting) ? disabledClasses : '']"
          @click="deleteSelectedCoupons">Delete</button>
        <!-- <button class="bg-green-500 text-white p-2 rounded" @click="assignSelected">Assign</button> -->
      </div>
      <!-- <div class="flex space-x-2">
        <input v-model="uiCouponCode" type="text" name="uiCouponCode" id="uiCouponCode" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
        <button :disabled="isWaiting" @click="handleVerifyCouponClick()"
          :class="['bg-blue-500 text-white p-2 rounded mr-2', (isWaiting) ? disabledClasses : '']">Check Coupon</button>
        <button v-if="isUiCouponCodeValid" :disabled="isWaiting" @click="handleUseUiCouponClick(uiCoupon)"
          :class="['bg-green-500 text-white p-2 rounded mr-2', (isWaiting) ? disabledClasses : '']">Use Coupon</button>
      </div> -->
      <div>
        <form>
          <div class="flex space-x-4">
            <input v-model="uiCouponCode" type="text" id="discount-code" name="discount-code" class="text-black block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
            <button @click="handleVerifyCouponClick()" :disabled="isWaiting" class="disabled:opacity-25 disabled:cursor-default rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">Verify</button>
            <button v-if="isUiCouponCodeValid" @click="handleUseUiCouponClick(uiCoupon)" :disabled="isWaiting" class="disabled:opacity-25 disabled:cursor-default w-full rounded-md bg-blue-600 px-2 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-blue-50">Apply Discount</button>
          </div>
        </form>
      </div>
    </div>
    <table class="min-w-full bg-white mt-4">
      <thead class="bg-gray-800 text-white">
        <tr class="text-left">
          <th class="w-3 py-2 px-4">
            <input :disabled="isWaiting || coupons.length <= 0" type="checkbox" @change="toggleAll"
              :checked="isAllSelected" :class="(isWaiting) ? disabledClasses : ''" />
          </th>
          <th class="py-2 px-4">Code</th>
          <th class="py-2 px-4">Status</th>
          <td class="py-2 px-4">Created On</td>
          <td class="py-2 px-4">Expiry Date</td>
          <td class="py-2 px-4">Discount</td>
          <td class="py-2 px-4">Assigned To</td>
          <td class="py-2 px-4">Used By</td>
          <td class="py-2 px-4">QR</td>
          <th class="py-2 px-4"></th>
          <th class="py-2 px-4"></th>
        </tr>
      </thead>
      <tbody :class="[(isWaiting) ? 'text-gray-400' : 'text-black', 'min-w-full bg-white mt-4']">
        <tr v-for="(coupon, index) in coupons" :key="coupon.id" class="text-left">
          <td class="py-2 px-4 w-3">
            <input v-if="coupon.data.status != COUPON_STATUS.USED" :disabled="isWaiting" type="checkbox"
              v-model="selectedCoupons" :value="index" :class="(isWaiting) ? disabledClasses : ''" />
          </td>
          <td class="py-2 px-4 uppercase">{{ coupon.data.code }}</td>
          <td class="py-2 px-4">{{ coupon.data.status }}</td>
          <td class="py-2 px-4">{{ convertTimestampToDate(coupon.data.createdAt) }}</td>
          <td :class="[(checkCouponIfExpired(coupon)) ? 'text-red-500 font-semibold' : '', 'py-2 px-4']">
            {{ convertTimestampToDate(coupon.data.expiryDate) }}
          </td>
          <td class="py-2 px-4">
            <div>
              <select :disabled="isWaiting" v-model="coupon.data.discountAmount" @change="handleDiscountChange(index, $event)" class="py-1 pl-2 pr-0 w-20">
                <!-- Default option -->
                <option v-for="discount in DISCOUNT_OPTIONS" :key="discount" :value="discount">
                  {{ discount }}
                </option>
              </select>
            </div>
          </td>
          <td class="py-2 px-4">
            <div class="flex space-x-1">
              <select :disabled="isWaiting" v-model="coupon.data.assignedToId" @change="handleEndorserChange(index, $event)" class="py-1 pl-2 pr-0">
                <!-- Default option -->
                <option disabled value="">Please select one</option>
                <!-- Iteratiing through each option -->
                <option v-for="endorser in endorsers" :key="endorser.id" :value="endorser.id">
                  {{ endorser.data.code }}
                </option>
              </select>
              <button v-if="coupon.data.status == COUPON_STATUS.ASSIGNED" :disabled="isWaiting" class="text-red-500 disabled:opacity-25" @click="handleUnassignCouponClick(index)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>


              </button>
            </div>
          </td>
          <td class="py-2 px-4">{{ coupon.data.usedByEmail }}</td>
          <td class="py-2 px-4">
            <div>
              <button :disabled="isWaiting" class="text-blue-500 disabled:opacity-25" @click="toggle(index)">{{ (visibility[index])? 'Hide':'View' }} QR</button>
            </div>
            <div v-if="visibility[index]" class="p-3">
              <qrcode-vue :value="`https://app.skilltech.ph/coupon?code=${coupon.data.code}`" :size="400" level="H" />
            </div>
          </td>
          <td class="py-2 px-4">
            <!-- <button v-if="coupon.data.status != COUPON_STATUS.USED && coupon.data.status != COUPON_STATUS.DEACTIVATED" -->
            
            <button v-if="coupon.data.status == COUPON_STATUS.USED"
              :disabled="isWaiting" class="text-blue-500 disabled:opacity-25"
              @click="handleReleaseCouponClick(index)">Release</button>
            <button v-else
              :disabled="isWaiting" class="text-blue-500 disabled:opacity-25"
              @click="handleUseCouponClick(index)">Use</button>
          </td>
          <td class="py-2 px-4">
            <button v-if="coupon.data.status == COUPON_STATUS.ACTIVE" :disabled="isWaiting"
              class="text-blue-500 disabled:opacity-25" @click="handleDeactivateCouponClick(index)">Deactivate</button>
            <button v-else-if="coupon.data.status == COUPON_STATUS.ASSIGNED" :disabled="isWaiting"
              class="text-blue-500 disabled:opacity-25" @click="handleDeactivateCouponClick(index)">Deactivate</button>
            <button v-else-if="coupon.data.status == COUPON_STATUS.DEACTIVATED" :disabled="isWaiting"
              class="text-blue-500 disabled:opacity-25" @click="handleActivateCouponClick(index)">Activate</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-4">
      <span>Total: {{ coupons.length }} Coupons</span>
    </div>
  </div>
  <!-- <div class="absolute bottom-0 text-white mb-2 w-full">
    <div class="container mx-auto">
      <ul>
        <transition-group name="fade" tag="ul">
          <li v-for="(log, index) in couponLogs" :key="`log-${log.id}`" class="list-item border border-white">
            <p>{{ log.data.details }} Date: {{ convertTimestampToDate(log.data.timestamp) }}. ID: {{ log.data.couponId }}
            </p>
            <p>Before: {{ log.data.beforeState }} -- After: {{ log.data.afterState }}</p>
          </li>
        </transition-group>
      </ul>
    </div>
  </div> -->
</template>


<script setup>
import { ref, computed } from 'vue'
import { v4 } from 'uuid'
import { auth } from '@/firebase/config.js'
// import { createCoupon, getCoupon, getAllCoupons, deleteCoupon, updateCoupon } from '@/helpers/couponService'
import QrcodeVue from 'qrcode.vue'
import {
  createCoupon,
  listAllCoupons,
  deleteCoupon,
  listCouponLogs,
  // coupon functions
  activateCoupon,
  deactivateCoupon,
  assignCoupon,
  unassignCoupon,
  useCoupon,
  releaseCoupon,
  checkCouponIfExpired,
  checkCouponCodeIfExists,
  checkCouponValidity,
  convertTimestampToDate,
  updateCoupon
} from '@/v3/CouponService'

import {
  listAllEndorserCodes
} from '@/v3/EndorserService'
import { Timestamp } from "firebase/firestore"
import { readCoupon } from '../v3/CouponService'

const disabledClasses = `disabled:opacity-20 disabled:pointer-events-none disabled:cursor-not-allowed`
const COUPON_STATUS = {
  ACTIVE: "active",
  ASSIGNED: "assigned",
  USED: "used",
  DEACTIVATED: "deactivated"
};

const value = 'https://example.com';
const size = 100;

/**
 * add discount options here. Ideally, we can have a range 
 * wherein the admin can specifically decide the discount.
 */
const DISCOUNT_OPTIONS = [ 200, 1000 ] 

/**
 * The app's endorser codes. These are fetched from the DB.
 * Admin can select this from a dropdown.
 */
const endorsers = ref([])
const selectedEndorserCode = ref(null)

const uiCouponCode = ref('')
const uiCoupon = ref(null)
const isUiCouponCodeValid= ref(false)

const couponLogs = ref([])
const coupons = ref([])
const isWaiting = ref(false)
const selectedCoupons = ref([])
const isAllSelected = computed(() => selectedCoupons.value.length === coupons.value.length);
const toggleAll = () => { selectedCoupons.value = (isAllSelected.value) ? [] : coupons.value.map((_, index) => index) };


const visibility = ref(coupons.value.map(() => false));

const toggle = (index) => {
  visibility.value[index] = !visibility.value[index];
};

const handleVerifyCouponClick = async () => {
  isWaiting.value = true
  try {
    uiCoupon.value = await checkCouponCodeIfExists(uiCouponCode.value)
    await checkCouponValidity(uiCoupon.value)
    isUiCouponCodeValid.value = true
  } catch (error) {
    alert(error)
  } finally {
    couponLogs.value = await listCouponLogs(5)
    isWaiting.value = false
  }
}

const handleUseUiCouponClick = async (coupon) => {
  isWaiting.value = true
  let user = {
    id: 'user-id',
    email: 'user@gmail.com',
  }
  try {
    await useCoupon(coupon, user)
    coupons.value = []
    coupons.value = await listAllCoupons()
    couponLogs.value = await listCouponLogs(5)
  } catch (error) {
    alert(error)
  } finally {
    uiCouponCode.value = ''
    uiCoupon.value = null
    isUiCouponCodeValid.value = false

    isWaiting.value = false
  }
}

const deleteSelectedCoupons = async () => {
  isWaiting.value = true
  let successfulAttempts = []

  for (let index = 0; index < selectedCoupons.value.length; index++) {
    const storedIndex = selectedCoupons.value[index];
    const coupon = coupons.value[storedIndex];
    try {

      // let result = await deleteCoupon(index == 1 ? 'errorcode' : couponId) // for debugging 
      let result = await deleteCoupon(coupon)
      console.log('result delete', result)

      /**
       * If delete was successful, we will pop the coupon out of the coupons array
       * to update the UI. This is ideal so we don't have to query all of 
       * the coupons again from the Database (especially on large collections).
       * If there are any unsuccessful deletes, we should not remove them from the 
       * UI, therefore we must track sucessful delete attempts.
       */
      if (result) successfulAttempts.push(storedIndex)

    } catch (error) {
      alert(error)
    }
  }

  /**
   * Update the UI. If some of the items failed to delete, 
   * make sure to not remove them from the UI.
   */
  successfulAttempts.sort((a, b) => b - a) // Sort the indexes in descending order to avoid index shifting issues
  successfulAttempts.forEach((index) => { coupons.value.splice(index, 1) }) // Remove the items from the coupons array
  selectedCoupons.value = successfulAttempts = [] // cleanup by emptying arrays

  couponLogs.value = await listCouponLogs(5)

  isWaiting.value = false // unfreeze ui

}

const handleDiscountChange = async (index, event) => {
  isWaiting.value = true

  let couponId = coupons.value[index].id;
  let data = {
    discountAmount: event.target.value
  }
  try {
    const response = await updateCoupon(couponId, data)
    coupons.value = await listAllCoupons()
  } catch (error) {
    console.error(error) // Logs the error message if any
  } finally {
    isWaiting.value = false
  }
}

const handleEndorserChange = async (index, event) => {
  try {
    isWaiting.value = true
    let coupon = coupons.value[index]
  
    let filteredEndorser = endorsers.value.filter(endorser => endorser.id == event.target.value)
    
    let endorser = { id: null, endorserCode: null }
    if(filteredEndorser.length > 0){
      endorser.id = filteredEndorser[0].id
      endorser.endorserCode = filteredEndorser[0].data.code
      endorser.email = ''
    }

    let response = await assignCoupon(coupon, endorser)
    coupons.value[index] = response
    couponLogs.value = await listCouponLogs(5)
  } catch (error) {
    alert(error)
  } finally {
    isWaiting.value = false
  }
}

// const handleAssignCouponClick = async (index, endorser_code) => {
//   try {
//     isWaiting.value = true
//     let coupon = coupons.value[index]
    
//     let endorser = null;

//     if(endorser_code == 'MM-111098'){
//       endorser = {
//         id: 'se3ikwXm9DfqekV4NCRD0T2hrVx2',
//         email: 'muyueladivina@gmail.com',
//         endorserCode: endorser_code,
//       }
//     }else if(endorser_code == "KL-100799") {
//       endorser = {
//         id: 'y7DfNyrcGCXrdHkk5bQokxyYhgW2',
//         email: 'kentoliverllobit@gmail.com',
//         endorserCode: endorser_code,
//       }
//     }

//     let response = await assignCoupon(coupon, endorser)
//     coupons.value[index] = response
//     couponLogs.value = await listCouponLogs(5)
//   } catch (error) {
//     alert(error)
//   } finally {
//     isWaiting.value = false
//   }
// }

const handleUnassignCouponClick = async (index) => {
  try {
    isWaiting.value = true
    let coupon = coupons.value[index]
    let response = await unassignCoupon(coupon)
    coupons.value[index] = response
    couponLogs.value = await listCouponLogs(5)
  } catch (error) {
    alert(error)
  } finally {
    isWaiting.value = false
  }
}

const handleUseCouponClick = async (index) => {
  isWaiting.value = true
  let coupon = coupons.value[index]
  let user = {
    id: 'user-id',
    email: 'user@gmail.com',
  }
  try {
    let response = await useCoupon(coupon, user)
    coupons.value[index] = response
    couponLogs.value = await listCouponLogs(5)
  } catch (error) {
    alert(error)
  } finally {
    
    isWaiting.value = false
  }
}

const handleReleaseCouponClick = async (index) => {
  isWaiting.value = true
  let coupon = coupons.value[index]
  try {
    let response = await releaseCoupon(coupon)
    coupons.value[index] = response
    couponLogs.value = await listCouponLogs(5)
  } catch (error) {
    alert(error)
  } finally {
    
    isWaiting.value = false
  }
}

const handleActivateCouponClick = async (index) => {
  isWaiting.value = true
  let coupon = coupons.value[index]
  try {
    let response = await activateCoupon(coupon)
    coupons.value[index] = response
    couponLogs.value = await listCouponLogs(5)
  } catch (error) {
    alert(error)
  } finally {
    isWaiting.value = false
  }
}

const handleDeactivateCouponClick = async (index) => {
  isWaiting.value = true
  let coupon = coupons.value[index]
  try {
    let response = await deactivateCoupon(coupon)
    coupons.value[index] = response
    couponLogs.value = await listCouponLogs(5)
  } catch (error) {
    alert(error)
  } finally {
    isWaiting.value = false
  }
}

const createNewCoupon = async () => {

  isWaiting.value = true

  // Your assign logic here
  let couponData = {
    // expiryDate: generateCouponExpiryInMinutes(1), // expires in 2 minutes by default
    expiryDate: generateCouponExpiry(31), // expires in 30 days by default
    // discountAmount: 25, // in percentage
    // discountAmount: 200, // in php
    discountAmount: DISCOUNT_OPTIONS[0], // 200 by default, as per initial options
  }

  try {
    const response = await createCoupon(couponData)
    coupons.value.push(response)

    couponLogs.value = await listCouponLogs(5)
  } catch (error) {
    alert(error)
  } finally {
    isWaiting.value = false
  }
};

const initialize = async () => {
  isWaiting.value = true
  console.log('🎫 Initializing Coupons page...')

  try {
    /**
     * Fetch all Endorser data and prepare it for User display.
     */
    endorsers.value = await listAllEndorserCodes()
    coupons.value = await listAllCoupons()
    couponLogs.value = await listCouponLogs(5)
  } catch (error) {
    alert(error)
  } finally {
    isWaiting.value = false
    console.log('🎫 Coupons page has been initialized.')
  }
}
initialize();

/**
 * Helper functions
 */
const generateCouponExpiry = (durationInDays) => {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + durationInDays)
  const expiryTimestamp = Timestamp.fromDate(currentDate)
  return expiryTimestamp
}

const generateCouponExpiryInMinutes = (durationInMinutes) => {
  const currentDate = new Date()
  currentDate.setMinutes(currentDate.getMinutes() + durationInMinutes)
  const expiryTimestamp = Timestamp.fromDate(currentDate); // Convert to Firestore Timestamp
  return expiryTimestamp;
};

</script>

<style scoped>@layer components {
  main {
    @apply h-screen overflow-auto
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s, background-color 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    background-color: black;
  }
}</style>