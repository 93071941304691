<template>
  <div class="lg:p-10 mt-10 lg:mt-0">

    <div class="mb-5 flex lg:justify-end px-2">
      <router-link to="/scheduler/announcements/create" class="w-full lg:w-auto text-center bg-yellow-600 text-white font-semibold p-2 rounded-md focus: outline-none hover:bg-yellow-700 focus:bg-yellow-700">
        Create New Announcement
      </router-link>
    </div>

    <table class="w-full">
          <thead>
            <tr class="hidden lg:table-row text-warm-gray-700 border-b border-warm-gray-700 dark:border-warm-gray-300 dark:text-warm-gray-100">
              <th class="py-2 text-left pl-2">Title</th>
              <th class="py-2 w-2/10">Type</th>
              <th class="py-2">Announce At</th>
              <th class="py-2 hidden lg:table-cell">Expires At</th>
              <th class="py-2 hidden lg:table-cell">Seen by</th>
              <th class="py-2 hidden md:table-cell">Status</th>
            </tr>
          </thead>
          <tbody>

            <!-- For mobile -->
            <tr v-for="announcement in announcements" colspan="5" class=" lg:hidden dark:text-warm-gray-300">
              <td>
                <div @click="viewAnnouncement(announcement)" class="border text-warm-gray-700 dark:text-warm-gray-100 dark:border-warm-gray-600 rounded-md m-2 p-2 text-left">
                  <div class="flex justify-start items-center border-b dark:border-warm-gray-700 font-bold py-2 mb-2">
                    {{ announcement.data.title }}
                  </div>
                  <div class="flex justify-start items-center">
                    <span class="mr-2 dark:text-warm-gray-200 font-semibold">type:</span>
                    <span class=" rounded-full  px-2 text-xs uppercase text-white" :class="announcement.data.type == 'post' ? 'bg-red-600': 'bg-yellow-700'">
                      {{ announcement.data.type }}
                    </span>
                  </div>
                  <div class="flex justify-start items-center">
                    <span class="mr-2 dark:text-warm-gray-200 font-semibold">Announce at:</span>
                    {{ convertDate(announcement.data.announce_at.seconds) }}
                  </div>
                  <div class="flex justify-start items-center">
                    <span class="mr-2 dark:text-warm-gray-200 font-semibold">Expires at:</span>
                    {{ convertDate(announcement.data.expires_at.seconds) }}
                  </div>
                  <div  class="flex justify-start items-center">
                    <span class="mr-2 dark:text-warm-gray-200 font-semibold">Seen by:</span>
                    {{ announcement.data.seen_by.length }}
                  </div>
                  <div class="flex justify-start items-center">
                    <span class="mr-2 dark:text-warm-gray-200 font-semibold">Status:</span>
                    {{ announcement.data.status }}
                  </div>

                  <!-- <div class=" border-t dark:border-warm-gray-700 flex justify-between items-center mt-2 pt-2">
                    <div class="flex justify-end items-center gap-2">
                      <button class="border dark:border-warm-gray-700 rounded px-2 py-1">View</button>
                    </div>
                  </div> -->

                </div>
              </td>
            </tr>

            <!-- for PC -->
            <tr  @click="viewAnnouncement(announcement)" v-for="announcement in announcements" class="hidden lg:table-row border-b border-warm-gray-300 dark:border-warm-gray-700 cursor-pointer hover:bg-warm-gray-100 dark:hover:bg-warm-gray-800 ">
              <td class="p-2 text-left font-semibold">{{ announcement.data.title }}</td>
              <td class="p-2 text-center">
                <span class=" rounded-full  px-2 py-0.5 text-xs uppercase text-white" :class="announcement.data.type == 'post' ? 'bg-red-600': 'bg-yellow-700'">
                  {{ announcement.data.type }}
                </span>
              </td>
              <td class="p-2 text-center">{{ convertDate(announcement.data.announce_at.seconds) }}</td>
              <td class="p-2 text-center">{{ convertDate(announcement.data.expires_at.seconds) }}</td>
              <td class="p-2 text-center">{{ announcement.data.seen_by.length }}</td>
              <td class="p-2 text-center">{{ announcement.data.status }}</td>
            </tr>

          </tbody>
      </table>
      
      <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" @close="setIsOpen(false)" class="relative z-10">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div class="fixed inset-0 overflow-y-auto">
            <div
              class="flex min-h-full items-center justify-center p-4 text-center"
            >
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel
                  class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                > 
                 
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900"
                  >
                    <small class="text-gray-700 font-semibold">Title:</small><br/>
                    <span class="text-warm-gray-800">{{ viewInfo.title }}</span>
                  </DialogTitle>
                  
                  <div class="mt-2">
                    <small class="text-gray-700 font-semibold">Message:</small><br/>
                    <p class="text-sm text-gray-500">
                      {{ viewInfo.message }}
                    </p>
                    <p class="text-sm text-gray-500 mt-2"> 
                      <small class="text-gray-700 font-semibold">Type: </small>
                      {{ viewInfo.type }}
                    </p>
                    <p class="text-sm text-gray-500"> 
                      <small class="text-gray-700 font-semibold">Status: </small>
                      {{ viewInfo.status }}
                    </p>
                    <p class="text-sm text-gray-500"> 
                      <small class="text-gray-700 font-semibold">Announce at: </small>
                      {{ convertDate(viewInfo.announce_at) }}
                    </p>
                    <p class="text-sm text-gray-500"> 
                      <small class="text-gray-700 font-semibold">Expires at: </small>
                      {{ convertDate(viewInfo.expires_at) }}
                    </p>
                  </div>

                  <div class="mt-4">
                    <button
                      type="button"
                      class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      @click="setIsOpen(false)"
                    >
                      Close
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>


  </div>
</template>
<script>
import moment from 'moment'
import { getAllAnnouncements } from '../../../firebase'

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'



export default {
  data(){
    return {
      isRetrievin: false,
      announcements: [],
      isOpen: false,
      viewInfo: {
        title: '',
        message: '',
        type: '',
        status: '',
        announce_at: '',
        expires_at: '',
        seen_by: []
      },
    }
  },
  mounted() {
    this.retrieveAnnouncements();
  },
  components:{
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  },
  methods: {
    async retrieveAnnouncements(){
      this.announcements = await getAllAnnouncements();
      console.log(this.announcements)
    },
    convertDate(time) {
     return moment.unix(time).format('llll')
    },
    viewAnnouncement(announcement){

      this.viewInfo.title = announcement.data.title;
      this.viewInfo.message = announcement.data.message;
      this.viewInfo.type = announcement.data.type;
      this.viewInfo.status = announcement.data.status;
      this.viewInfo.announce_at = announcement.data.announce_at;
      this.viewInfo.expires_at = announcement.data.expires_at;

      this.setIsOpen(true);
    },
    setIsOpen(value) {
      this.isOpen = value
    }
  },
}
</script>
<style lang="">
  
</style>