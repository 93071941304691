<template>
  <div>
    <p>
      {{message}}
    </p>

    <!-- <button @click="copyText(0)">Copy Text</button>

    
    <pre v-for="col,i in data" :id="'col-'+i" class="border-b-2 p-2 mt-4">
      {{ col }}
    </pre> -->
  </div>
</template>
<script>
import {
  populate,
  retrieve
} from '@/firebase/dummyData';
export default {
  data() {
    return {
      message: 'waiting for result...',
      data: []
    }
  },
  async mounted() {
    if (window.location.hostname.includes("localhost")) {
      if (this.$route.params.action == 'populate') {
        let res = await populate(); 
        this.message = res.isError ? 'Error!' : 'Successfully imported the dummy data to firestore.'; 
      }
      if (this.$route.params.action == 'retrieve') {
        let res = await retrieve(); 
        this.message = res.isError ? 'Error!' : 'Successfully exported the data from firestore.'; 
        this.data = res.data;
        console.log(this.data);
      }
    } 
  },
  methods: {
    copyText(id) {
      // // Get the text field
      // var copyText = document.getElementById("col-"+id);

      // // Select the text field
      // copyText.select();
      // copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      // navigator.clipboard.writeText(this.data[0]);
      
      // Alert the copied text
      // alert("Copied the text");
    }
  },
}
</script>