import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import Login from '../views/Login.vue';
import Logout from '../views/Logout.vue';
import Home from '../views/Home.vue';
import Data from '../views/Data.vue';
import Authentication from '../views/Authentication.vue';
import Dash from '../views/Dash.vue';
import FCM from '../views/FCM.vue';

import Root from '@/views/Root.vue';
import Exports from '@/views/Exports.vue';
import Mtp from '@/modules/mtp/Mtp.vue';

import Questionnaires from '../components/RevData/Questionnaires';
import Subjects from '../components/RevData/Subjects';
import Subtopics from '../components/RevData/Subtopics';
import Tags from '../components/RevData/Tags';
import Explanations from '../components/RevData/Explanations';
import Courses from '../components/RevData/Courses';
import CourseCreate from '../components/RevData/CourseCreate';
import CourseView from '../components/RevData/CourseView';
import CourseUpdate from '../components/RevData/CourseUpdate.vue';
import SubjectView from '../components/RevData/SubjectView';
import SubjectAddUpdate from '../components/RevData/SubjectAddUpdate';

import { reviewerRoutes } from '../modules/reviewer/router/reviewerRoutes';
import { schedulerRoutes } from '../modules/scheduler/router/schedulerRoutes';
import { v3UsersRoutes } from '../v3/modules/users/router/v3UsersRoutes';

import { softBlockRoutes } from '../modules/soft_block/router/softBlockRoutes';
import { scorecardRoutes } from '../modules/scorecard/router/scorecardRoutes';
import { rewardRoutes } from '../modules/reward/router/rewardRoutes';
import { plusSurveyRoutes } from '../modules/plus_survey/router/plusSurveyRoutes';

// for just dummy data when running the emulator
import DummyData from '../views/DummyData.vue';

// Added for testing on October 18, 2023
import Nest from '../views/Nest.vue';
import Coupons from '../views/Coupons.vue';
import ValidateCoupon from '../views/ValidateCoupon.vue';

/**
 * V2 Components
 */

import V2ParentView from '@/v2/views/V2ParentView.vue';
import V2Dash from '@/v2/modules/dash/DashView.vue';
import V2Users from '@/v2/modules/users/UsersView.vue';
import V2User from '@/v2/modules/users/UserView.vue';
import V2Materials from '@/v2/modules/materials/MaterialsView.vue';
import V2Profile from '@/v2/modules/profile/ProfileView.vue';
import V2League from '@/v2/modules/league/LeagueView.vue';

// Added for testing on December 1, 2023
import Dome from '../views/Dome.vue';

// Added for testing on Jan 31, 2024
import Finance from '../views/Finance.vue';

// Added for testing on Jul 6, 2024
import { monitorRoutes } from '../modules/monitor/router/monitorRoutes';


const routes = [
    ...monitorRoutes,
    ...reviewerRoutes,
    ...schedulerRoutes,
    ...v3UsersRoutes,
    ...softBlockRoutes,
    ...scorecardRoutes,
    ...rewardRoutes,
    ...plusSurveyRoutes,
    {
        path: '/:action/dummy-data',
        component: DummyData
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/dash',
        name: 'Dash',
        component: Dash,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/nest',
        name: 'Nest',
        component: Nest,
        meta: {
            requiresAuth: true
        }
    },
    /**
     * An experiment page by John. Please ignore
     */
    {
        path: '/dome',
        name: 'Dome',
        component: Dome,
        meta: {
            requiresAuth: true
        }
    },
    /**
     * An experiment page by John for Finance. Please ignore
     */
    {
        path: '/finance',
        name: 'Finance',
        component: Finance,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/coupons',
        name: 'Coupons',
        component: Coupons,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/coupon',
        name: 'Coupon',
        component: ValidateCoupon,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/exports',
        name: 'Exports',
        component: Exports,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/root',
        name: 'Root',
        component: Root,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/mtp',
        name: 'MTP',
        component: Mtp,
        meta: {
            requiresAuth: true
        }
    },
    // {
    //     path: '/users',
    //     name: 'Users',
    //     component: Users,
    //     meta: {
    //         requiresAuth: true
    //     }
    // },

    /**
     * V2 Routes
     */
    {
        path: '/v2',
        component: V2ParentView,
        meta: { requiresAuth: true },
        redirect: { path: '/v2/dash' },
        children: [{
                path: 'dash',
                component: V2Dash,
                meta: { requiresAuth: true }
            },
            {
                path: 'users',
                component: V2Users,
                meta: { requiresAuth: true }
            },
            {
                path: 'user/:id',
                component: V2User,
                meta: { requiresAuth: true }
            },
            {
                path: 'materials',
                component: V2Materials,
                meta: { requiresAuth: true }
            },
            {
                path: 'league',
                component: V2League,
                meta: { requiresAuth: true }
            },
            {
                path: 'profile',
                component: V2Profile,
                meta: { requiresAuth: true }
            },
        ],
    },

    // reviewer data routes
    {
        path: '/data',
        component: Data,
        children: [{
                path: 'questionnaires',
                component: Questionnaires
            },
            {
                path: 'courses',
                component: Courses
            },
            {
                path: 'courses/create',
                component: CourseCreate
            },
            {
                path: 'courses/:course',
                component: CourseView,
                props: true,
            },
            {
                path: 'courses/:course/update',
                component: CourseUpdate,
                props: true,
            },
            {
                path: 'subjects',
                component: Subjects
            },
            {
                path: 'subjects/view/:subject',
                component: SubjectView,
                props: true,
            },
            {
                path: 'subjects/:actionType/:subject?',
                component: SubjectAddUpdate,
                props: true,
            },
            {
                path: 'subtopics',
                component: Subtopics
            },
            {
                path: 'tags',
                component: Tags
            },
            {
                path: 'explanations',
                component: Explanations
            },
        ],
        meta: {
            requiresAuth: true
        }
    },

    // authentication routes
    {
        path: '/authentication',
        name: 'Authentication',
        component: Authentication,
        meta: {
            requiresAuth: true
        }
    },


    {
        path: '/fcm',
        name: 'FCM',
        component: FCM,
        mete: {
            requiresAuth: true
        }
    },

    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () =>
            import ( /* webpackChunkName: "404" */ '../views/404.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// router.beforeResolve
router.beforeResolve(async(to, from, next) => {

    // required auth route...
    if (to.matched.some(record => record.meta.requiresAuth) && !store.state.user.credentials) {
        next({ name: 'Login' });
        return;
    }

    // login route...
    // check if user already login, reroute to home page
    if (to.name === 'Login' && store.state.user.credentials) {
        next({ name: 'Home' });
        return;
    }

    // log out route...
    // if (to.name === 'Logout') {
    //     console.log('logging out...');
    //     await logOut();
    //     // console.log('logged out, setting user to null...')
    //     // await store.dispatch('setUser', null);
    //     console.log('rerouting to login...')
    //     next({ name: 'Login' });
    //     return;
    // }
    next();
})

export default router