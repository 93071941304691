<template>

  <div class=" flex justify-center mb-2 text-warm-gray-600">
    <div class="w-10/12 lg:w-7/12">
      <!-- above buttons -->
      <div class="flex justify-between mt-10">
        <div class="flex justify-start items-center">
          <p class="flex justify-center text-warm-gray-500">Showing all courses</p>
        </div>
        <div class="flex justify-end items-center">
          <router-link type="button" :to="`/data/courses/create?r=${curRevId}`" class="font-semibold text-yellow-700 py-2 px-4 rounded-md hover:bg-yellow-50 focus:outline-none focus:bg-yellow-50 dark:hover:bg-warm-gray-800 dark:focus:bg-warm-gray-800">Add new course</router-link>
        </div>
      </div>

      <!-- body field -->
      <div class="mt-4 grid gap-8 lg:grid-cols-2">
        
        <div v-for="course in courses" class="border rounded shadow p-4 text-warm-gray-700 dark:border-warm-gray-600 dark:text-warm-gray-300">
          <div class="flex justify-start items-center pb-2 border-b font-medium border-warm-gray-700">
            <AcademicCapIcon class="h-6 w-6 mr-2"/>
            {{ course.name }}
          </div>
          <div class=" text-warm-gray-500 mt-2 ml-2 text-sm">
            {{ course.subjects.length }} subjects under this courses
          </div>
          <div class=" bg-warm-gray-100 p-2 mt-2 text-sm dark:bg-warm-gray-800">
            {{ course.description }}
          </div>
          <div class="mt-2 flex justify-end items-center">
            <router-link type="button" :to="`/data/courses/${course.id}?r=${curRevId}`" class="text-yellow-700 underline py-2 px-4 hover:bg-yellow-50 focus:bg-yellow-50 dark:hover:bg-warm-gray-800 dark:focus:bg-warm-gray-800">view</router-link>
            <button @click="deleteCourse(course.id)" class="text-warm-gray-500 underline py-2 px-4 hover:bg-warm-gray-100 focus:bg-warm-gray-100 dark:hover:bg-warm-gray-800 dark:focus:bg-warm-gray-800">delete</button>
          </div>
        </div>

      </div>
    </div>
    <ProcessingModal v-if="isProcessing" />
  </div>
</template>
<script>
import {
  AcademicCapIcon as AcademicCapIcon
} from '@heroicons/vue/24/solid'
import {getCourses, deleteCourse} from '@/firebase/controllers/reviewerController';
import swal from 'sweetalert2';
import ProcessingModal from '@/components/ProcessingModal';

export default {
  data() {
    return {
      isProcessing: false,
      curRevId: '',
      course: 'amt',
      courses: []
    }
  },
  components:{
    AcademicCapIcon,
    ProcessingModal
  },
  async mounted() {
    if (this.$route.query.r) {
      this.curRevId = this.$route.query.r;
      this.getCourses()
    }
  },
  methods: {
    async getCourses(){
      let {data} = await getCourses(this.curRevId);
      this.courses = data;
    },
    async deleteCourse(id){

      const isConfirmed = await swal.fire({
        title: `Delete course?`,
        text: `Are you sure you want to delete this course? All subjects under this course will be deleted as well.`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#db912a',
        cancelButtonColor: '#4a4848',
        confirmButtonText: 'Yes, delete'
      }).then(result => result.isConfirmed);
      if(!isConfirmed){return false;}

      this.isProcessing = true;
      let res = await deleteCourse(this.curRevId, id);

      // check response has error
      if(!res.data.isError){
        // if none show success alert
        this.getCourses();
        swal.fire(
            'Success!',
            `Course has been deleted.`,
            'success'
        );
      }else{
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Something went wrong! ${res.data.error}`,
        })
      }

      this.isProcessing = false;

    }
  },
}
</script>