<template>
  <div class="min-h-screen bg-warm-gray-700 flex flex-col justify-center py-12 sm:px-6 lg:px-8 px-4">

    <form @submit.prevent class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="border-b border-gray-300 pb-2 mb-6 flex items-center text-gray-800">
        <logo />
        <h3 class="ml-4 font-black text-xl tracking-wide">Admin Login</h3>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
          Email
        </label>
        <input v-model="loginForm.email" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Enter Email" required="">
      </div>
      <div class="mb-6">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
          Password
        </label>
        <input v-model="loginForm.password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="Enter password" required="">
      </div>

      <!-- Error Display -->
      <div v-if="error.hasError" class="rounded-md bg-yellow-50 p-4 mb-6">
        <div class="flex">
          <div class="flex-shrink-0">
            <ExclamationIcon
              class="h-5 w-5 text-yellow-600"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3 text-yellow-800">
            <p class="font-bold">{{error.title}}</p>
            <p class="text-sm">{{error.message}}.</p>
          </div>
          <div class="ml-auto pl-3">
            <div class="-mx-1.5 -my-1.5">
              <button
                @click="error.hasError = false"
                type="button"
                class="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yelow-50 focus:ring-yelow-600 ">
                <span class="sr-only">Dismiss</span>
                <XIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-between">
        <button @click="login" :class="[isBusy ? 'bg-yellow-800 cursor-wait': 'bg-yellow-700', 'flex items-center  hover:bg-yellow-800 text-white font-bold py-2 rounded focus:outline-none focus:shadow-outline px-11']" type="submit">
          <svg
            v-if="isBusy"
            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Log in
        </button>
        <a class="inline-block align-baseline font-bold text-sm text-yellow-700 hover:text-yellow-800" href="#">
          Forgot Password?
        </a>
      </div>

      <template v-if="$route.query.sso">
        <!-- continue with label -->
        <div class="relative mt-10">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-warm-gray-300" />
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="px-2 bg-white text-warm-gray-700">
              Continue with
            </span>
          </div>
        </div>

        <!-- Providers -->
        <div class="mt-6 flex space-x-4 justify-between w-full">
          <!-- Google -->
          <button
            @click="ssoLogin('google')"
            type="button"
            :disabled="isWaiting"
            class="btn-primary"
          >
            <GoogleIcon class="w-5 h-5"/>
            <span class="ml-2">Google</span>
          </button>
          <!-- Yahoo -->
          <button
            @click="ssoLogin('yahoo')"
            type="button"
            :disabled="isWaiting"
            class="btn-primary"
          >
            <YahooIcon class="w-5 h-5"/>
            <span class="ml-2">Yahoo</span>
          </button>
        </div>        
      </template>


    </form>
    <p class="text-center text-gray-200 text-xs">
      &copy;2021 skilltech.ph. All rights reserved.
    </p>


  </div>
</template>
<script>
import logo from '@/components/Logo';

// import { ExclamationIcon, XIcon } from "@heroicons/vue/solid";

/**
 * UI dependencies
 */
import {
  ExclamationTriangleIcon as ExclamationIcon,
  XMarkIcon as XIcon
} from "@heroicons/vue/24/solid";

import {logIn, getUserOtherInfo} from '@/firebase/controllers/userController';
import store from '@/store';
import * as validators from '@/helpers/validators';

import { auth } from '../firebase/config';
import { signInWithPopup, GoogleAuthProvider, OAuthProvider  } from "firebase/auth";

const googleProvider = new GoogleAuthProvider();
const yahooProvider = new OAuthProvider('yahoo.com');

import YahooIcon from "@/components/icons/YahooIcon"
import GoogleIcon from "@/components/icons/GoogleIcon"
import SpinnerIcon from "@/components/icons/SpinnerIcon"

export default {
    data() {
      return {
        isBusy: false,
        sso:{
          isWaiting: false,
          error: null,
        },
        error: {
          hasError: false,
          title: "",
          message: "",
          errors: {},
        },
        loginForm:{
            email: "",
            password: ""
        }
      }
    },
    components:{
      ExclamationIcon,
      XIcon,
      logo,
      GoogleIcon,
      YahooIcon,
      SpinnerIcon
    },
    mounted() {
      if(this.$route.query.unauthorized){
            this.error.hasError = true;
            this.error.title = 'Unauthorized';
            this.error.message = 'You are not authorized to login to this page.';
        }
    },
    methods: {
      async login(){
           
            this.isBusy = true;
            this.error.hasError = false;

            // validate data
            this.error.errors = validators.validate(this.loginForm, [
                ['email', ['required','email']],
                ['password', ['required']],
            ]);
            if(validators.hasError){ 
              this.isBusy = false;
              this.error.hasError = true;
              this.error.message = "Email and password fields are required.";
              return;
            }

            // proceed
            let res = await logIn(this.loginForm.email, this.loginForm.password);
            if(!res.error){

                // get and save other user info to store..
                // could not wait for the onAuthStateChanged function..
                let userOtherInfo = await getUserOtherInfo(res.data.uid);
                await store.dispatch('setUser',{credentials: res.data, otherInfo:userOtherInfo});
                this.$router.push(this.redirect ? this.redirect: '/');

            }else{
                if(res.data == 'unauthorized'){
                  window.location.replace("/login?unauthorized=true");
                }else{
                  this.error.hasError = true;
                  this.error.title = res.data.title;
                  this.error.message = res.data.message;
                }
            }

            this.isBusy = false;
        },

      async ssoLogin(provider){

        this.sso.isWaiting = true;
        this.sso.error = null;

        // evaluate provider
        if (provider == 'google') {
          // continue using Google
          await signInWithPopup(auth, googleProvider)
            .then((result) => {
              this.$router.go();
            }).catch((err) => {
              console.log(err);
              if (err.code != 'auth/popup-closed-by-user' && err.code != 'dsf') { 
                console.log('Login Error!')
              }
            });

        }else{
          // continue using Yahoo
          await signInWithPopup(auth, yahooProvider)
            .then((result) => {
              this.$router.go();
            })
            .catch((err) => {
              console.log(err);
              if (err.code != 'auth/popup-closed-by-user' && err.code != 'sd') { 
                console.log('Login Error!')
              }
            });
        }
        this.sso.isWaiting = false;
      }
    },
}
</script>

<style lang="postcss" scoped>
@layer components {
  .btn-primary {
    @apply w-full inline-flex justify-center py-2 px-4 border border-warm-gray-300 rounded-md shadow-sm text-sm font-medium text-warm-gray-500 bg-warm-gray-50 hover:bg-warm-gray-100;
  }
  .btn-dismiss {
    @apply inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600;
  }
}
</style>