import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from '../config';
/**
 * sort the users via skillpoints for the leaderboard
 */
export const sortLeaderboard = (users) => {
    let sorted_users = users.sort(function(a, b) {
        if (a.skillpoints === b.skillpoints) { return a.date_started - b.date_started } // if they have equal points, give priority to people who signed up earlier
        else { return b.skillpoints - a.skillpoints; } // sort via skillpoints
    });
    sorted_users.forEach((user, i) => { user.rank = i + 1; });
    return sorted_users
}

/**
 * NOTE: Connects To Firebase
 * fetch the leaderboard
 */
export const fetchLeaderboard = () => {
    return getDocs(query(collection(db, "leaderboard"), orderBy("date_created", "asc")))
        .then((querySnapshot) => {
            var leaderboard = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                leaderboard.push(data)
            });
            // console.log({leaderboard})
            return leaderboard;
        })
        .catch((error) => {
            console.error("Error getting Leaderboard data: ", error);
            throw "Error getting Leaderboard data"
        })
}