import { httpsCallable } from "firebase/functions";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { functions, storage } from '@/firebase/config.js';


export const getSubtopics = async(revId) => {
    // return await (await getDocs(collection(db, reviewersColName, 'caap', 'subtopics'))).docs;
    const getAllSubtopics = httpsCallable(functions, 'getAllSubtopics');
    return await getAllSubtopics({ revId })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

/**
 * get all list of questionnaires
 * @returns {*}
 */
export const getQuestionnaires = async(revId) => {
    const getQuestionnaires = httpsCallable(functions, 'getQuestionnaires');
    return await getQuestionnaires({ revId })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

/**
 * add new questionnaire or subtopic to questionnaires
 * @param {*} subtopic 
 * @returns 
 */
export const addQuestionnaire = async(revId, subtopic) => {
    const addQuestionnaire = httpsCallable(functions, 'addQuestionnaire');
    return await addQuestionnaire({ revId, subtopicId: subtopic.id, subtopicName: subtopic.name })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

/**
 * Add new question for a subtopic
 * @param {*} questionForm 
 * @returns 
 */
export const addQuestion = async(revId, tags, questionForm, imageFile) => {

    let storageRefPath = `images/questions/${revId}/${questionForm.subtopic}/${questionForm.form.id}`;
    if (questionForm.form.hasImage && imageFile) {
        const uploadRes = await uploadImage(storageRefPath, imageFile);
        if (uploadRes.isError) { return uploadRes; }
        questionForm.form.imageUrl = uploadRes.url;
        questionForm.form.storageRefPath = storageRefPath;
    }

    const addQuestion = httpsCallable(functions, 'addQuestion');
    return await addQuestion({...questionForm, revId, tags })
        .then((result) => {
            if (result.data.isError) deleteImage(storageRefPath);
            return result;
        })
        .catch((err) => {
            deleteImage(storageRefPath);
            return err;
        });
}

/**
 * Update the question
 * @param {*} questionForm 
 * @returns 
 */
export const updateQuestion = async(revId, tags, questionForm, imageFile) => {


    const updateQuestion = httpsCallable(functions, 'updateQuestion');
    return await updateQuestion({...questionForm, revId, tags })
        .then(async(result) => {

            // check if there is no error, then upload or delete image if needed
            // and update the question information again!!!! (inefficient: need to refactor this code!)
            if (!result.data.isError) {
                let storageRefPath = `images/questions/${revId}/${questionForm.subtopic}/${questionForm.form.id}`;
                // upload the image if has image and image file
                if (questionForm.form.hasImage && imageFile) {
                    const uploadRes = await uploadImage(storageRefPath, imageFile);
                    if (uploadRes.isError) { return uploadRes; }
                    questionForm.form.imageUrl = uploadRes.url;
                    questionForm.form.storageRefPath = storageRefPath;
                    // this shoud work because it already work at first call
                    await updateQuestion({...questionForm, revId, tags });
                }

                // remove the image if has image set to false but storage ref path has value value
                if (!questionForm.form.hasImage && questionForm.form.storageRefPath && questionForm.form.storageRefPath != '') {
                    // delete the image
                    deleteImage(storageRefPath);
                    questionForm.form.storageRefPath = '';
                    await updateQuestion({...questionForm, revId, tags });
                }
            }

            return result;
        })
        .catch((err) => {
            return err;
        });
}

/**
 * Delete/remove subtopic from the questionnaire
 * @param {string} subtopic 
 * @returns 
 */
export const deleteSubtopicFromQuestionnaire = async(revId, subtopic) => {

    const deleteSubtopicFromQuestionnaire = httpsCallable(functions, 'deleteSubtopicFromQuestionnaire');
    // delete all images under this subtopic

    return await deleteSubtopicFromQuestionnaire({...subtopic, revId })
        .then((result) => {
            return result
        })
        .catch((err) => {
            return err;
        })
}

/**
 * Delete/remove question from subtopic list of questions
 * @param {number} qid 
 * @returns 
 */
export const deleteQuestion = async(revId, data, storageRefPath) => {
    deleteImage(storageRefPath);
    const delQuestion = httpsCallable(functions, 'deleteQuestion');
    return await delQuestion({...data, revId })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log('Function deleteQuestion Error:', err)
            return err;
        })
}

/**
 * upload image
 * Hard to implement using firebase cloud function
 * so for now, just implementing it here... ^____^
 * @param {*} questionForm 
 * @param {file} imageFile 
 * @returns 
 */
const uploadImage = async(storageRefPath, imageFile) => {

    if (imageFile) {

        const storageRef = ref(storage, storageRefPath);

        return await uploadBytes(storageRef, imageFile).then(async() => {
            return await getDownloadURL(storageRef).then((url) => {
                console.log('image url', url);
                return { isError: false, url: url };
            }).catch((e) => {
                console.log('unable to get the URL: ', e);
                return { isError: true, error: 'Upload error.' };
            });
        }).catch((e) => {
            console.log('Upload err: ', e);
            return { isError: true, error: 'Upload error.' };
        });
    }
    return { isError: true, error: 'file not found' };

}

const deleteImage = (storageRefPath) => {
    if (storageRefPath) {
        // Create a reference to the file to delete
        const toDelRef = ref(storage, storageRefPath);
        // Delete the file
        deleteObject(toDelRef);
    }
}