export let hasError = false;

/**
 * will received the data from the caller
 */
export const validate = (data,toValidate) => {
    // set an empty array first for the list of errors
    let toRet = {};
    hasError = false;

    // loop to thru the item need to validate
    toValidate.forEach(arr => {

        // initialize needed variables
        let fn = arr[0]
        let condResult = null;

        // loop thru conditions for each item
        arr[1].forEach(condition => {

            // check first if the previous condition has no error
            // if no, then continue checking or validating base on the array of condition 
            if (!condResult) {
                if (condition == 'required' && typeof data[fn] !== 'undefined') condResult = isNotEmpty(fn,data[fn]);
                if (condition == 'int' && typeof data[fn] !== 'undefined') condResult = isNumber(fn,data[fn]);
                if (condition == 'phone' && typeof data[fn] !== 'undefined') condResult = isValidPhone(data[fn]); 
                if (condition == 'email' && typeof data[fn] !== 'undefined') condResult = isValidEmail(data[fn]); 
                if (condition == 'password' && typeof data[fn] !== 'undefined') condResult = isValidPassword(data[fn]); 
                if (condition == 'confirm' && typeof data[fn] !== 'undefined') condResult = isConfirm(fn, data[fn],data[`confirm${fn.charAt(0).toUpperCase() + fn.slice(1)}`]); 
            }

        });

        if(condResult) {toRet[fn] = condResult; hasError = true;}
    });

    return toRet;
}

/**
 * required
 */
const isNotEmpty = (fieldName, val) => {
    let formattedName = formatFieldName(fieldName);
    return val ? null : `${formattedName} field is required.`;
}

/**
 * int
 */
const isNumber = (fieldName, val) => {
    let formattedName = formatFieldName(fieldName);
    return Number.isInteger(val) ? null : `${formattedName} should be an integer.`;
}

/**
 * phone
 */
const isValidPhone = (val) => {
    let re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
    return val.match(re) ? null : 'Invalid phone number format.';
}

/**
 * email
 */
const isValidEmail = (val) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(val) ? null : 'Invalid email address format.';
}

/**
 * password
 */
const isValidPassword = (password) => {
    var strength = 0;
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (password.match(/[_$@#&!]+/)) {
      strength += 1;
    }
  
    if (password.length < 6) {
        return "minimum number of characters is 6.";
    }else if(strength < 2){
        return "Password must include at least 1 number, capital letter or special characters.";
    }else{
        return null
    }

}

/**
 * confirm
 */
const isConfirm = (fn, val1, val2) => {
    return val1 == val2 ? null : `The ${fn} confirmation doesn't match.`;
}

/**
 * format the field name
 * Split the field name if it is in camel case
 */
const formatFieldName = (fieldName) => {
    let formattedName = '';
    fieldName.split(/(?=[A-Z])/).forEach(i => formattedName += `${i.toLowerCase()} `);
    return formattedName.charAt(0).toUpperCase() + formattedName.slice(1)
}