<template>
  <div class=" flex justify-center mb-2 text-warm-gray-600 dark:text-warm-gray-200">
    <div class="w-10/12 lg:w-7/12">

      <div  class="flex justify-between items-center mt-14">
        <span>Showing all subjects</span>
        <router-link :to="'/data/subjects/add?r='+curRevId" type="button" class="py-2 px-4 text-yellow-700 font-semibold hover:text-yellow-800 focus:text-yellow-800 focus:outline-none dark:hover:text-yellow-600 dark:focus:text-yellow-600">Add new subject</router-link>
      </div>

       <!-- body field -->
      <div v-if="!isRetrieving" class="mt-4 grid gap-4">
        <!-- loop subjects -->
        <div v-for="(subject,i) in subjects" :key="i" class="border rounded shadow p-4 text-warm-gray-700 dark:text-warm-gray-200 dark:border-warm-gray-700">
          <div class="flex justify-start items-center pb-2 border-b font-medium dark:border-warm-gray-700">
            <LightningBoltIcon class="h-6 w-6 mr-2"/>
            {{subject.data.name}}
          </div>
          <div class=" text-warm-gray-500 mt-2 text-sm xl:flex justify-between dark:text-warm-gray-400">
            <div class="mb-2 xl:mb-0">
              <span class="dark:border-warm-gray-400" >Under {{subject.data.course.length}} {{ subject.data.course.length > 1 ? 'courses':'course' }} | </span>
              <span class="" >Has {{subject.data.exam.subtopics.length}} {{ subject.data.exam.subtopics.length > 1 ? 'subtopics':'subtopic' }}</span>
            </div>
            <div class="flex xl:justify-end items-center">
              <span class="text-xs text-white flex justify-center items-center bg-warm-gray-400 py-1 px-2 rounded mr-1 dark:bg-warm-gray-600"> <PaperClipIcon class="h-4 w-4 mr-1" />{{subject.data.exam.limits.passer}} | {{subject.data.exam.points.passer}}</span>
              <span class="text-xs text-white flex justify-center items-center bg-warm-gray-400 py-1 px-2 rounded mr-1 dark:bg-warm-gray-600"> <LightningBoltIcon class="h-4 w-4 mr-1" />{{subject.data.exam.limits.speedrunner}} | {{subject.data.exam.points.speedrunner}}</span>
              <span class="text-xs text-white flex justify-center items-center bg-warm-gray-400 py-1 px-2 rounded mr-1 dark:bg-warm-gray-600"> <FireIcon class="h-4 w-4 mr-1" />{{subject.data.exam.limits.master}} | {{subject.data.exam.points.master}}</span>
              <span class="text-xs text-white flex justify-center items-center bg-warm-gray-400 py-1 px-2 rounded dark:bg-warm-gray-600"> <ClockIcon class="h-4 w-4 mr-1" />{{subject.data.exam.limits.time}}</span>
            </div>
          </div>
          <div class=" bg-warm-gray-100 p-2 mt-2 text-sm dark:bg-warm-gray-800">
            {{subject.data.description}}
          </div>
          <div class="mt-2 flex justify-end items-center text-sm">
            <router-link type="button" :to="`/data/subjects/view/${subject.id}?r=${curRevId}`" class="text-yellow-700 underline py-2 px-2 hover:bg-yellow-50 focus:bg-yellow-50 dark:hover:bg-warm-gray-800 dark:focus:bg-warm-gray-800">view</router-link>
            <button @click="deleteSubject(subject.id, subject.data.name)" class="text-warm-gray-500 underline py-2 px-2 hover:bg-warm-gray-100 focus:bg-warm-gray-100  dark:hover:bg-warm-gray-800 dark:focus:bg-warm-gray-800">delete</button>
          </div>
        </div>
      </div>
      <div v-else class="mt-4 grid gap-4 animate-pulse">
        <div v-for="i in 5" :key="i" class="border rounded shadow p-4 bg-warm-gray-300 h-40 dark:bg-warm-gray-700 dark:border-warm-gray-700">
        </div>
      </div>

      <ProcessingModal v-if="isProcessing" />

    </div>
  </div>
</template>
<script>
import swal from 'sweetalert2';
import {getSubjects, deleteSubject} from '@/firebase/controllers/reviewerController';
// import {LightningBoltIcon,PaperClipIcon,FireIcon,ClockIcon} from '@heroicons/vue/outline';
import ProcessingModal from '@/components/ProcessingModal';


import {
  BoltIcon as LightningBoltIcon,
  PaperClipIcon as PaperClipIcon,
  FireIcon as FireIcon,
  ClockIcon as ClockIcon
} from "@heroicons/vue/24/outline";

export default {
  data() {
    return {
      curRevId: '',
      isProcessing: false,
      isRetrieving: false,
      isSortByName: true,
      subjects: [],
    }
  },
  components:{
    LightningBoltIcon,
    PaperClipIcon,
    FireIcon,
    ClockIcon,
    ProcessingModal
  },
  mounted() {
    if (this.$route.query.r) {
      this.curRevId = this.$route.query.r;
      this.getSubjects();
    }
  },
  methods: {
    async getSubjects(){
      this.isRetrieving = true;
      const res = await getSubjects(this.curRevId);
      this.subjects = !this.isSortByName ? res.subjects : res.subjects.sort((a,b) => (a.data.name > b.data.name) ? 1 : ((b.data.name > a.data.name) ? -1 : 0));
      this.isRetrieving = false;
    },
    async deleteSubject(subjectId, subjectName){

      const isConfirmed = await swal.fire({
        title: `Delete ${subjectName}?`,
        text: `Are you sure you want to delete this subject?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#db912a',
        cancelButtonColor: '#4a4848',
        confirmButtonText: 'Yes, delete'
      }).then(result => result.isConfirmed);
      if(!isConfirmed){return false;}

      this.isProcessing = true;
      let res = await deleteSubject(this.curRevId,subjectId);
      if (!res.data.isError) {
        this.getSubjects();
        swal.fire(
            'Success!',
            `Subject ${subjectName} has been deleted.`,
            'success'
        );
      }else{
        swal.fire({
          title: 'Error!',
          text: 'Unable to delete subject',
          icon: 'error'
        });
      }
      this.isProcessing = false;
    },
  },
}
</script>