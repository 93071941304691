<template>
  <div class="block">
      <button @click="subscribe()" class=" border bg-gray-500 p-2 rounded">Subscribe</button>
      <br/>
      <br/>
      <span class="text-green-700">{{currentToken}}</span>
      <br>
      <br>
      <button @click="sendNotification()" class=" border bg-green-500 p-2 rounded">Send test notification</button>
  </div>
</template>
<script>
import {fcmMessaging, functions} from '@/firebase/config.js';
import { getToken, onMessage } from "firebase/messaging";
import { httpsCallable } from '@firebase/functions';

export default {
  data() {
    return {
      currentToken: ''
    }
  },
  mounted() {
    onMessage(fcmMessaging, (payload) => {
      console.log('message on client', payload);
      alert('notification receive!');
    })
  },
  methods: {
    subscribe(){
      console.log('Subscribing...');
      getToken(fcmMessaging, { vapidKey: 'BIL1wpBUTaHAPL5oVutvwbK9UV1Pokgf779BkjOosD0-qpCz8misB5gBPiMYFnh3Sh2YOWZzN_Z3awHdCb4U3qI' }).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          console.log('Current Token:', currentToken);
          this.currentToken = currentToken;
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
    },
    async sendNotification(){

      const registrationTokens = [
        'eOrtZR92Df4T7kGfjTQism:APA91bGg3lQHPBd0if2gzgPylZFCrF8l8otEfLTiA_JYkHiAW5kWEEwLzxsp7wTiL0H3ny877UU5nLpAEn3YSJuzpJ7aTCS71d60wzC2dcaufPhLQRQpPkUauWUICELJCmHK9DtDUDln',
        'dnt2XELSsZCuGnTGncUMdn:APA91bHS8MGta6dGxfKU0z_s8dIEEEn73QfAhyERCeWnh7adckX1y-bps9k-cbK5vuqEbsIakF33n8B77NWOWWbc854qbPmHYa_R4mmKkLRoLxG3XyvzXmvCGJY_Zb83VeSk6LVfvR6S',
        'edfpeV4lzGh_LG2QW9Rmua:APA91bHunKhCRolWwU3Cix9KwiPwIEVdYnQWYUS_EqOtcPSeA1DTqTnV44PxzUlDLcdhms4FJ5kkSgKfXdgHxrE4vCs8iWxNozYAptGo-D5v6-wdbJEx0kA2il1WOcrASIJFI9v_xsN-'
    ];


      const data = {
        payload: {
          notification: {
            title: 'Howdeeyyy Skilltech!',
            body: 'I hope this notification will now work!',
            icon: 'https://pbs.twimg.com/profile_images/1281559186808217601/OMLlN_aF_400x400.jpg'
          },
         },
        tokens: registrationTokens,
    };
	
      
      const sendNotificationUsingTokens = httpsCallable(functions, 'sendNotificationUsingTokens');
      await sendNotificationUsingTokens(data)
        .then((result) => {
            console.log('Send notification success:', result);
        })
        .catch((err) => {
            console.log('Send notification error:', err)
        });

    }
  },
}
</script>
