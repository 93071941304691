<template>

  <TransitionRoot appear :show="true" as="template">
    <Dialog as="div">
      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
         
          <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40" />

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            >
            <div
              class="inline-block max-w-md p-4 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
              >

              <div class="m-2 flex justify-center items-center">
                <SpinningIcon class="h-6 w-6 mr-2 text-yellow-600" />
                <p class="text-sm text-gray-500">
                 {{ message ? message  : 'Please wait. Processing your request...' }}  
                </p>
              </div>

            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

</template>

<script>
import SpinningIcon from '@/components/SpinningIcon';
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
} from '@headlessui/vue'

export default {
  props: {
    message: {
      type: String,
      required: false,
    },
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    SpinningIcon,
  },

}
</script>
