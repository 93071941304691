<template>
    <div class=" py-10 flex flex-col items-center w-full space-y-10">

        <div v-for="explanation in explanations" class=" rounded-lg bg-warm-gray-300 dark:bg-warm-gray-600 w-3/4 p-4">

            <div class=" flex justify-between">
                <div class="">
                    <p>Subtopic ID: <span class=" text-warm-gray-500 dark:text-warm-gray-400">{{ explanation.data.questionId.split('_')[0] }}</span></p>
                    <p>Question #: <span class="text-warm-gray-500 dark:text-warm-gray-400">{{ explanation.data.questionId.split('_')[1] }}</span></p>
                </div>
                
                <div class="flex justify-start text-warm-gray-600 dark:text-warm-gray-300">
                    <div class="flex justify-start mr-2">
                        <HandThumbDownIcon class=" w-5 h-5 mr-0.5"/>
                        <span>{{ explanation.data.downvotes }}</span>
                    </div>
                    <div class="flex justify-start">
                        <HandThumbUpIcon class=" w-5 h-5 mr-0.5"/>
                        <span>{{ explanation.data.upvotes }}</span>
                    </div>
                </div>
            </div>

            <div class="mt-4 rounded border border-warm-gray-500 px-2 py-4 bg-warm-gray-400 dark:bg-warm-gray-700 text-warm-gray-700 dark:text-warm-gray-300">
                <template v-if="explanation.data.textHtml">
                    <p v-html="explanation.data.textHtml"></p>
                </template>
                <template v-else>
                    <p v-for="parag,i in formatExplanationForViewing(explanation.data.text)" :key="`answer-explanation-${i}`">
                        {{ parag }}
                    </p>
                </template>
            </div>

            <div class=" flex justify-between mt-5">
                <div class="flex justify-start">
                    <button @click="openQuestion(explanation.data.questionId.split('_')[0], explanation.data.questionId.split('_')[1])" class="border rounded border-warm-gray-600 text-warm-gray-600 dark:border-warm-gray-400 dark:text-warm-gray-300 p-2 flex justify-center items-center">
                        <DocumentTextIcon class="w-4 h-4"/>
                        <span>View Question</span>
                    </button>
                </div>
                
                <div class="flex justify-end">
                    <button @click="delExplanation(explanation.id)" class="border rounded border-red-600 text-red-500 p-2 mr-2 flex justify-center items-center">
                        <TrashIcon class="w-4 h-4"/>
                        <span>Remove</span>
                    </button>
                    <button @click="openUpdateExplanationModal(explanation)" class="border rounded border-yellow-700 text-yellow-600 p-2 flex justify-center items-center">
                        <PencilSquareIcon class="w-4 h-4"/>
                        <span>Update</span>
                    </button>
                </div>

            </div>

        </div>

        <div v-if="!isLastPage" class=" w-full flex justify-center items-center">
            <button @click="getAllExplanation(true)" class="rounded border border-warm-gray-700 text-warm-gray-700 dark:border-warm-gray-400 dark:text-warm-gray-400 py-2 px-4 flex justify-center items-center disabled:opacity-40" :disabled="isRetrieving">
                <SpinningIcon v-if="isRetrieving" class="w-4 h-4 mr-1"/>
                Load more..
            </button>
        </div>

        <!-- View Question Modal -->
        <TransitionRoot as="template" id="test1" :show="isQuestionModalOpen">
            <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="setIsQuestionModalOpen(false)">
                <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 dark:bg-warm-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
                </TransitionChild>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="inline-block align-bottom dark:bg-warm-gray-800  bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                    <!-- Close Button -->
                    <div class="block absolute top-0 right-0 pt-4 pr-4">
                        <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="setIsQuestionModalOpen(false)">
                        <span class="sr-only">Close</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    
                    
                    <div v-if="openedQuestion" class="relative px-1 md:px-2 max-w-4xl mx-auto py-4 mt-3">

                        <h2 class="mb-1 tracking-wider text-warm-gray-700 dark:text-warm-gray-100">Question</h2>
                        
                        <div class=" rounded border border-dashed border-warm-gray-700 dark:border-warm-gray-500 py-4 px-2">
                            <p class=" text-warm-gray-500 dark:text-warm-gray-400">{{ openedQuestion.question }}</p>
                        </div>
                        
                        <h2 class="mt-10 mb-2 tracking-wider text-warm-gray-700 dark:text-warm-gray-100">Choices:</h2>
                        <ul>
                            <li class=" p-2 rounded mb-2" :class="choice.id == openedQuestion.answer ? 'bg-gray-300 text-warm-gray-600 border-warm-gray-400 dark:bg-gray-800 dark:text-warm-gray-300 dark:border-warm-gray-700 border' : 'bg-warm-gray-300 text-warm-gray-500 dark:bg-warm-gray-700 dark:text-warm-gray-400'" v-for="choice, i in openedQuestion.choices" :key="i">{{ choice.choice }}</li>
                        </ul>
                        
                    </div>
                    </div>
                </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- Update Explanation Modal -->
        <TransitionRoot as="template" id="test1" :show="isUpdateExplanationModalOpen">
            <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="setIsUpdateExplanationModalOpen(false)">
                <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 dark:bg-warm-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
                </TransitionChild>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="inline-block align-bottom dark:bg-warm-gray-700  bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                        <h2 class=" text-semibold text-xl text-warm-gray-700 dark:text-warm-gray-200">Update Explanation</h2>
                        
                        <!-- Close Button -->
                        <div class="block absolute top-0 right-0 pt-4 pr-4">
                            <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="setIsUpdateExplanationModalOpen(false)">
                            <span class="sr-only">Close</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        
                        
                        <div class="relative max-w-4xl mx-auto py-4">

                             <!-- WYSIWYG Editor -->
                            <div class="bg-white overflow-auto mt-2 rounded">
                                <div id="my-toolbar" class="bg-warm-gray-50">
                                    <span class="ql-formats">
                                    <button class="ql-bold"></button>
                                    <button class="ql-italic"></button>
                                    <button class="ql-underline"></button>
                                    <button class="ql-strike"></button>
                                    <select class="ql-align"></select>
                                    <button class="ql-image"></button>
                                    </span>
                                </div>
                                <div class=" h-56 text-warm-gray-900">
                                    <QuillEditor v-model:content="updateExplanationForm.data.textHtml" :modules="modules" content="html" content-type="html" theme="snow" toolbar="#my-toolbar" class="focus:outline-none focus:ring-0" placeholder="Enter explanation here..." />

                                </div>
                            </div>
                            
                            <div class="flex justify-between items-center mt-5">

                                <div class="flex justify-start text-warm-gray-500">
                                    <div class="flex justify-start items-center mr-2 text-green-600">
                                        <HandThumbDownIcon class=" w-5 h-5 mr-0.5"/>
                                        <span>{{ updateExplanationForm.data.downvotes }}</span>
                                    </div>

                                    <div class="flex justify-start items-center text-red-600">
                                        <HandThumbUpIcon class=" w-5 h-5 mr-0.5"/>
                                        <span>{{ updateExplanationForm.data.upvotes }}</span>
                                    </div>
                                </div>

                                <div class="flex justify-end items-center space-x-2">
                                    <button @click="resetVoteCounter" class=" rounded p-2 text-sm border border-warm-gray-400 text-warm-gray-400">
                                        Reset vote counter
                                    </button>
                                    <button @click="saveUpdate" class=" rounded p-2 text-sm border border-yellow-600 text-yellow-600">
                                        Update
                                    </button>
                                </div>

                            </div>
                            
                        </div>
                    </div>
                </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>

    </div>

    <ProcessingModal v-if="isProcessing" />

</template>

<script setup>

import {ref, onMounted} from 'vue'
import {fetchExplanations, getSubtopicQuestions, deleteExplanation, updateExplanation} from '../../firebase/controllers/reviewerController';
import { HandThumbDownIcon, HandThumbUpIcon, DocumentTextIcon, TrashIcon, PencilSquareIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import ProcessingModal from '@/components/ProcessingModal';
import SpinningIcon from '@/components/SpinningIcon';

// QuillEditor
import { QuillEditor } from '@vueup/vue-quill';
import BlotFormatter from 'quill-blot-formatter';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
  } from "@headlessui/vue";

const isQuestionModalOpen = ref(false)
const setIsQuestionModalOpen = (value) => {
    isQuestionModalOpen.value = value;
}

const isUpdateExplanationModalOpen = ref(false)
const setIsUpdateExplanationModalOpen = (value) => {
    isUpdateExplanationModalOpen.value = value;
}

const isProcessing = ref(false)
const isRetrieving = ref(false);
const explanations = ref([])
const subtopicQuestions = ref({})
const openedQuestion = ref(null)
const updateExplanationForm = ref(null)


// for the pagination or load more
const pageSize = ref(50)
const lastDoc = ref(null)
const isLastPage = ref(false)
const totalItem = ref(0)

// Part or needed in QuillEditor
const modules = {
    name: 'blotFormatter',  
    module: BlotFormatter, 
    options: {/* options */},
}

const getAllExplanation = async (isFromLaodMore = false) => {

    isRetrieving.value = true;

    if (!isFromLaodMore) {
        lastDoc.value = null;
        explanations.value = [];
    }
    const res = await fetchExplanations( isFromLaodMore ? pageSize.value : totalItem.value, lastDoc.value);
    // explanations.value = sortExplanation(res.explanations)
    explanations.value.push(...res.explanations);
    lastDoc.value = res.lastDoc;
    isLastPage.value = res.isLastPage;
    if (isFromLaodMore) {
        totalItem.value = totalItem.value + res.explanations.length;
    }
    isRetrieving.value = false;
}

const openQuestion = async (subtopicId, qId) => {

    await getQuestion(subtopicId, qId);
    setIsQuestionModalOpen(true);

}

const getQuestion = async (subtopicId, qId) => {

    // check if the subtopic already fetch previously from database
    openedQuestion.value = subtopicQuestions.value[subtopicId] ? subtopicQuestions.value[subtopicId].filter((question) => question.id == qId)[0] : null;
    console.log(subtopicId)
    console.log(subtopicQuestions.value)
    console.log(openedQuestion.value)
    // if already have and found then return
    if (openedQuestion.value != null) {
        console.log('found:', openedQuestion.value)
        return;
    }

    console.log('get from database.')

    // if not yet fetch then fetch from database
    subtopicQuestions.value[subtopicId] = await getSubtopicQuestions(subtopicId);
    openedQuestion.value = subtopicQuestions.value[subtopicId].filter((question) => question.id == qId)[0]
}

const delExplanation = async (explanationId) => {
    isProcessing.value = true;
    await deleteExplanation(explanationId);

    await getAllExplanation();
    
    isProcessing.value = false;
}

const openUpdateExplanationModal = (explanation) => {

    updateExplanationForm.value = JSON.parse(JSON.stringify(explanation));

    // check if the explanation only have the text and no textHtml (meaning explanation was fetch from chatGPT)
    // convert raw data text to WYSIWYG compatible
    let toWYSIWYG = '';
    if (updateExplanationForm.value.data.text && !updateExplanationForm.value.data.textHtml) {
        let processedExplanation = null;
        processedExplanation = updateExplanationForm.value.data.text.replace(/(?:\r\n|\r|\n)/g, '\n\n');
        let prags = processedExplanation.split(/(?:\r\n|\r|\n)/g).filter(item => item !== '');
        prags.forEach(prag => {
            toWYSIWYG = toWYSIWYG + `<p>${prag}<p/>`
        })
    }
    updateExplanationForm.value.data.textHtml = updateExplanationForm.value.data.textHtml ? updateExplanationForm.value.data.textHtml.trim() : updateExplanationForm.value.data.text ? toWYSIWYG : '';


    setIsUpdateExplanationModalOpen(true);
}

const resetVoteCounter = () => {
    updateExplanationForm.value.data.upvotes = 0;
    updateExplanationForm.value.data.downvotes = 0;
}

const saveUpdate = async () => {
    isProcessing.value = true;
    await updateExplanation(updateExplanationForm.value);
    await getAllExplanation();

    // // find the explanation object and then update the data
    // let index = explanations.value.findIndex(e => e.id == updateExplanationForm.value.id)
    // explanations.value[index].id = updateExplanationForm.value.id
    // explanations.value[index].data = {...updateExplanationForm.value.data}

    setIsUpdateExplanationModalOpen(false);
    isProcessing.value = false;
}



onMounted(async ()=> {
    await getAllExplanation(true);
})

const formatExplanationForViewing = (rawExplanation) => {
    let processedExplanation = null;
    processedExplanation = rawExplanation.replace(/(?:\r\n|\r|\n)/g, '\n\n');
    return processedExplanation.split(/(?:\r\n|\r|\n)/g).filter(item => item !== '');
}

</script>