<script setup>
import ROUTER from "@/router";
/**
 * UI dependencies
 */
import {
  RectangleGroupIcon as RectangleGroupIconOutline,
  BookOpenIcon as BookOpenIconOutline,
  UserGroupIcon as UserGroupIconOutline,
  TrophyIcon as TrophyIconOutline,
  BuildingLibraryIcon as BuildingLibraryIconOutline,
  UserCircleIcon as UserCircleIconOutline,
} from "@heroicons/vue/24/outline";
import {
  RectangleGroupIcon as RectangleGroupIconSolid,
  BookOpenIcon as BookOpenIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  TrophyIcon as TrophyIconSolid,
  BuildingLibraryIcon as BuildingLibraryIconSolid,
  UserCircleIcon as UserCircleIconSolid,
} from "@heroicons/vue/24/solid";

const PAGES = [
  {
    name: "dashboard",
    path: "/v2/dash",
    inactiveIcon: RectangleGroupIconOutline,
    activeIcon: RectangleGroupIconSolid,
  },
  {
    name: "users",
    path: "/v2/users",
    inactiveIcon: UserGroupIconOutline,
    activeIcon: UserGroupIconSolid,
  },
  {
    name: "materials",
    path: "/v2/materials",
    inactiveIcon: BookOpenIconOutline,
    activeIcon: BookOpenIconSolid,
  },
  {
    name: "league",
    path: "/v2/league",
    inactiveIcon: TrophyIconOutline,
    activeIcon: TrophyIconSolid,
  },
  // {
  //   name: "academy",
  //   path: "/v2/academy",
  //   inactiveIcon: BuildingLibraryIconOutline,
  //   activeIcon: BuildingLibraryIconSolid,
  // },
  {
    name: "profile",
    path: "/v2/profile",
    inactiveIcon: UserCircleIconOutline,
    activeIcon: UserCircleIconSolid,
  },
];

</script>
<template>
  <footer class="h-footer border-b border-gray-300">
    <ul
      class="
        flex
        w-full
        justify-between
        h-full
        text-xs
        divide-dashed divide-x divide-gray-300
      "
    >
      <li
        v-for="page in PAGES"
        :key="page.name"
        class="w-full flex items-center justify-center"
      >
        <router-link
          @click="ROUTER.currentRoute.value.fullPath = page.path"
          :to="`${page.path}`"
          class="cursor-pointer w-full h-full flex items-center justify-center"
        >
          <component
            :is="
              ROUTER.currentRoute.value.fullPath == page.path
                ? page.activeIcon
                : page.inactiveIcon
            "
            class="h-6 w-6"
            aria-hidden="true"
          />
        </router-link>
      </li>
    </ul>
  </footer>
</template>
<style>
.h-footer{
  min-height: 45pt;
}
</style>