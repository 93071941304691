import { createStore } from 'vuex'

export default createStore({
    state: {
        user: {
            credentials: null,
            otherInfo: null
        },
        slideOver: false,
        modal: false,
        current: {
            topicId: 'item-2',
            itemId: 'item-c1-1',
        },
        // schedules
        announcementPopup: [],
        // transfer of user progress status
        transferUserProgress: [],
        transferUserDocs: {
            totalDocs: 0,
            totalTransferredDocs: 0
        }
    },
    mutations: {
        SET_USER(state, user) {
            state.user.credentials = user ? user.credentials : null;
            state.user.otherInfo = user ? user.otherInfo : null;
        },
        SET_SLIDE_OVER(state, payload) {
            state.slideOver = payload;
        },
        SET_MODAL(state, payload) {
            state.modal = payload;
        },
        SET_CURRENT_TOPIC(state, payload) {
            state.current.topicId = payload;
        },
        SET_CURRENT_ITEM(state, payload) {
            state.current.itemId = payload;
        },
        // schedules
        SET_ANNOUNCEMENT_POPUP(state, announcement) {
            state.announcementPopup.push(announcement)
        },
        REMOVE_ANNOUNCEMENT_POPUP(state, announcementId) {
            const newAnnouncementPopup = state.announcementPopup.filter((obj) => obj.id !== announcementId)
            state.announcementPopup = newAnnouncementPopup;
        },
        // transfer of user progress status
        SET_TRANSFER_USER_PROGRESS(state, newProgress) {
            state.transferUserProgress.push(newProgress)
        },
        CLEAR_TRANSFER_USER_PROGRESS(state) {
            state.transferUserProgress = [];
        },
        ADD_TRANSFER_USER_TOTAL_DOCS(state, toAddCount) {
            state.transferUserDocs.totalDocs = state.transferUserDocs.totalDocs + toAddCount;
        },
        RESET_TRANSFER_USER_TOTAL_DOCS(state) {
            state.transferUserDocs.totalDocs = 0;
        },
        ADD_TRANSFER_USER_TRANSFERRED_DOCS(state, toAddCount) {
            state.transferUserDocs.totalTransferredDocs = state.transferUserDocs.totalTransferredDocs + toAddCount;
        },
        RESET_TRANSFER_USER_TRANSFERRED_DOCS(state) {
            state.transferUserDocs.totalTransferredDocs = 0;
        },
    },
    actions: {
        async setUser({ commit }, user) {
            await commit('SET_USER', user);
            console.log('(Set user completed!)');
        },
        async setSlideOver({ commit }, payload) {
            await commit('SET_SLIDE_OVER', payload);
        },
        async setModal({ commit }, payload) {
            await commit('SET_MODAL', payload);
        },
        async setCurrentTopic({ commit }, payload) {
            await commit('SET_CURRENT_TOPIC', payload);
        },
        async setCurrentItem({ commit }, payload) {
            await commit('SET_CURRENT_ITEM', payload);
        },
        // schedules
        async setAnnouncementPopup({ commit }, announcement) {
            await commit('SET_ANNOUNCEMENT_POPUP', announcement);
        },
        async removeAnnouncementPopup({ commit }, announcementId) {
            await commit('REMOVE_ANNOUNCEMENT_POPUP', announcementId);
        },
        // transfer of user progress status
        async setTransferUserProgress({ commit }, newProgress) {
            await commit('SET_TRANSFER_USER_PROGRESS', newProgress);
        },
        async clearTransferUserProgress({ commit }) {
            await commit('CLEAR_TRANSFER_USER_PROGRESS');
        },
        async addTransferUserTotalDocs({ commit }, toAddCount) {
            await commit('ADD_TRANSFER_USER_TOTAL_DOCS', toAddCount);
        },
        async resetTransferUserTotalDocs({ commit }) {
            await commit('RESET_TRANSFER_USER_TOTAL_DOCS');
        },
        async addTransferUserTransferredDocs({ commit }, toAddCount) {
            await commit('ADD_TRANSFER_USER_TRANSFERRED_DOCS', toAddCount);
        },
        async resetTransferUserTransferredDocs({ commit }) {
            await commit('RESET_TRANSFER_USER_TRANSFERRED_DOCS');
        },
    },
    getters: {
        isAuth: state => state.user.credentials != null ? true : false,
        user: state => state.user,
        slideOver: state => state.slideOver,
        modal: state => state.modal,
        announcementPopup: state => state.announcementPopup,
        transferUserProgress: state => state.transferUserProgress,
        transferUserDocs: state => state.transferUserDocs,
    },
    modules: {}
})