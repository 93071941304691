<template>
  <div class="flex justify-center text-warm-gray-600">
    <div class="w-10/12 lg:w-2/3">
      <div class="border rounded-md shadow overflow-hidden m-2 dark:border-warm-gray-700 mt-14">
        
        <div class="bg-warm-gray-300 flex justify-between items-center p-2 dark:bg-warm-gray-700">
          <div class=" dark:text-warm-gray-300">
            Showing all tags 
          </div>
          <div class="flex justify-end items-center">
            <button @click="openModal()" class=" m-2 bg-yellow-700 rounded text-white font-semibold py-1 px-4 hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none">
              Add
            </button>
          </div>
        </div>

        <div class="p-2">
          <table class="w-full text-left">
            <thead class="border-b-2 dark:border-warm-gray-600 dark:text-warm-gray-200">
              <tr>
                <th class="">Name</th>
                <th class="px-4 text-center">shorthand</th>
                <th class="pr-4">Description</th>
                <th class=" w-16"></th>
              </tr>
            </thead>
            <tbody v-if="isRetrieving" class="animate-pulse">
              <tr v-for="i in 10" :key="i">
                <td> <div class="h-4 m-1 bg-warm-gray-300 dark:bg-warm-gray-700"></div> </td>
                <td> <div class="h-4 m-1 bg-warm-gray-300 dark:bg-warm-gray-700"></div> </td>
                <td colspan="2" class="text-center"> <div class="h-4 m-1 bg-warm-gray-300 dark:bg-warm-gray-700"></div> </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="tag in tags" class="border-b text-white hover:text-warm-gray-600 hover:bg-warm-gray-100 dark:border-warm-gray-700 dark:hover:bg-warm-gray-800 group dark:text-warm-gray-300 dark:hover:text-warm-gray-100">
                <td class="text-warm-gray-600 py-2 whitespace-nowrap dark:text-warm-gray-300 dark:group-hover:text-warm-gray-100">
                  <span class="px-2 py-0.5 border border-warm-gray-500 rounded-full" :class="tag.data.hexColor && ' text-warm-gray-50'" :style="'background-color:'+tag.data.hexColor">{{ tag.data.name }}</span>
                </td>
                <td class="text-warm-gray-600 py-2 text-center dark:text-warm-gray-300 dark:group-hover:text-warm-gray-100">{{ tag.data.shorthand }}</td>
                <td class="text-warm-gray-600 py-2 dark:text-warm-gray-300 dark:group-hover:text-warm-gray-100">
                  <!-- <div v-html="tag.data.description" class="h-6"></div> -->
                    {{ convertToTruncatedText(tag.data.description) }}
                  <!-- <div class="border">
                  </div> -->
                </td>
                <td class="py-2">
                  <div class="flex justify-around items-center text-warm-gray-500">
                    <!-- update icon -->
                    <div class="has-tooltip">
                      <span class='tooltip rounded shadow-lg p-1 bg-warm-gray-600 text-xs text-warm-gray-300 -ml-4 mt-6'>Update</span>
                      <PencilSquareIcon @click="openModal(tag)" class="w-4 h-4 cursor-pointer"/>
                    </div>
                    <!-- delete icon -->
                    <div class="has-tooltip">
                      <span class='tooltip rounded shadow-lg p-1 bg-warm-gray-600 text-xs text-warm-gray-300 -ml-4 mt-6'>Delete</span>
                      <TrashIcon @click="delTag(tag.id, tag.data.name)" class="w-4 h-4 cursor-pointer" />
                    </div>
                  </div>

                </td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
    </div>

    <ProcessingModal v-if="isProcessing" />
    <!-- add update modal -->
    <TransitionRoot appear :show="isModalOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 overflow-y-auto text-sm">
          <div class="min-h-screen px-4 text-center">

            <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40" />

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
              >
              <div
                class="inline-block w-10/12 lg:w-2/3 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-700"
              >
                <!-- title -->
                <DialogTitle
                  as="h3"
                  class="flex items-center text-base font-medium leading-6 text-white bg-yellow-600 p-6 pb-4"
                >
                {{modalType == 'add' ? 'Add' : 'Update'}} Tag
                </DialogTitle>

                <!-- body / form -->
                <div class="mt-2 flex-row items-start text-warm-gray-700 p-6 dark:text-warm-gray-200">

                  <!-- Error Display -->
                  <div v-if="errors.length > 0" class="rounded-md bg-yellow-50 p-4 mb-6">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <ExclamationIcon
                          class="h-5 w-5 text-yellow-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-3 text-yellow-700">
                        <p class="font-bold">Error: Unable to save data.</p>
                        <p class="text-sm">
                          <ul class="list-disc ml-5">
                            <li v-for="(err,i) in errors" :key="i">{{err}}</li>
                          </ul>
                        </p>
                      </div>
                      <div class="ml-auto pl-3">
                        <div class="-mx-1.5 -my-1.5">
                          <button
                            type="button"
                            class="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yelow-50 focus:ring-yelow-600 ">
                            <span class="sr-only">Dismiss</span>
                            <XIcon class="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="flex flex-col">

                    <div class="mb-6 flex flex-col">
                      <label for="tag-name">Name</label>
                      <input v-model="name" id="tag-name" class="p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" placeholder="Enter tag name" />
                    </div>

                    <div class="mb-6 flex flex-col">
                      <label for="tag-name">Color</label>
                      <select v-model="hexColor" class="p-2 mt-2 border border-warm-gray-200 rounded focus:shadow-md focus:border-yellow-400 focus:outline-none focus:ring-0 dark:text-warm-gray-700">
                        <option v-for="tagColor in tagColors" :value="tagColor.hexColor">{{ tagColor.name }}</option>
                      </select>
                    </div>

                    <div class="mb-6 flex flex-col">
                      <label for="tag-shorthand">Shorthand</label>
                      <input v-model="shorthand" id="tag-shorthand" tabindex="-1" class="p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" placeholder="Enter tag shorthand" />
                    </div>

                    <div class="mb-6 flex flex-col">
                      <label for="tag-description">Description</label>
                      <!-- <textarea v-model="description" id="tag-description" rows="3" placeholder="Enter tag description" class="p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700"></textarea> -->
                      <div class=" bg-white overflow-auto mt-2 rounded">
                        <div id="my-toolbar" class="bg-warm-gray-50">
                          <span class="ql-formats">
                            <button class="ql-bold" tabindex="-1"></button>
                            <button class="ql-italic" tabindex="-1"></button>
                            <button class="ql-underline" tabindex="-1"></button>
                            <button class="ql-strike" tabindex="-1"></button>
                            <select class="ql-align" tabindex="-1"></select>
                            <button class="ql-image" tabindex="-1"></button>
                          </span>
                        </div>
                        <div class=" h-56 text-warm-gray-900">
                          <QuillEditor v-model:content="description" :modules="modules" content="html" content-type="html" theme="snow" toolbar="#my-toolbar" class="focus:outline-none focus:ring-0" placeholder="Enter description" />
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>

                <!-- footer -->
                <div class="mt-4 border-t p-6 pt-2 flex justify-between dark:border-warm-gray-400">
                  <div class="flex justify-start items-center">
                    <!-- -->
                  </div>
                  <div class="flex justify-end">
                    <button
                      type="button"
                      class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 dark:bg-warm-gray-500 dark:text-warm-gray-200 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600"
                      @click="closeModal()"
                      >
                      Cancel
                    </button>
                    <button
                      v-if="modalType == 'add'"
                      @click="addTag()"
                      type="button"
                      class="ml-2 flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-700 border border-transparent rounded-md hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
                      Add
                    </button>
                    <button
                      v-else
                      @click="tagUpdate()"
                      type="button"
                      class="ml-2 flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-700 border border-transparent rounded-md hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
                      Update
                    </button>
                  </div>
                </div>
                

              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>


  </div>

</template>

<script setup>

import swal from 'sweetalert2';
import {createTag, getTags, deleteTag, updateTag} from '../../firebase/controllers/reviewerController';
import {ref, watch, onMounted} from 'vue';
import { useRoute } from 'vue-router';
import { PencilSquareIcon, TrashIcon } from "@heroicons/vue/24/solid";
import { ExclamationTriangleIcon as ExclamationIcon, XMarkIcon as XIcon} from '@heroicons/vue/24/outline';
import ProcessingModal from '@/components/ProcessingModal';

import { QuillEditor } from '@vueup/vue-quill';
import BlotFormatter from 'quill-blot-formatter';
import '@vueup/vue-quill/dist/vue-quill.snow.css';


import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue'

  const route = useRoute();
  const isModalOpen = ref(false);
  const isRetrieving = ref(false);
  const isProcessing = ref(false);
  const curRevId = ref('');
  const tags = ref([]);

  const modules = {
      name: 'blotFormatter',  
      module: BlotFormatter, 
      options: {/* options */},
    }

  // form
  const name = ref('');
  const hexColor = ref('');
  const shorthand = ref('');
  const description = ref('');

  const errors = ref([]);
  const modalType = ref('add');
  const curTagId = ref('');

  const tagColors = [
    {name: 'Grey (cool)', hexColor: '#4B5563'},
    {name: 'Grey (dark)', hexColor: '#1F2937'},
    {name: 'Red', hexColor: '#991B1B'},
    {name: 'Red (dark)', hexColor: '#450a0a'},
    {name: 'Orange', hexColor: '#92400E'},
    {name: 'Orange (dark)', hexColor: '#451a03'},
    {name: 'Green', hexColor: '#047857'},
    {name: 'Green (dark)', hexColor: '#022c22'},
    {name: 'Blue', hexColor: '#1E40AF'},
    {name: 'Blue (dark)', hexColor: '#172554'},
    {name: 'Indigo', hexColor: '#312E81'},
    {name: 'Cyab', hexColor: '#083344'},
    {name: 'Purple', hexColor: '#4C1D95'},
    {name: 'Fuchsia', hexColor: '#4a044e'},
    {name: 'Pink', hexColor: '#9D174D'},
    {name: 'Lime', hexColor: '#1a2e05'},
    {name: 'Yellow', hexColor: '#FBBF24'},
    {name: 'Brown', hexColor: '#422006'},
  ];

  const closeModal = () => {
    isModalOpen.value = false;

    // clear the tag form again
    name.value = '';
    hexColor.value = '';
    shorthand.value = '';
    description.value = '';
  }

  const openModal = (tag = null)=>{
    isModalOpen.value = true;
    if (tag) {
      name.value = tag.data.name;
      hexColor.value = tag.data.hexColor ? tag.data.hexColor : ''; // to support prev data without the hexColor field
      shorthand.value = tag.data.shorthand;
      description.value = tag.data.description;
      curTagId.value = tag.id;
      modalType.value = 'update';
    }else{
      modalType.value = 'add';
    }
  }

  watch(name, async (newVal, oldVal) => {
    // transform the new value to ID format.
    const format = /[`!@#$%^&*()+\\=\\[\]{};':"\\|,.<>\\/?~]/g;
    let id = newVal.replaceAll(' ', '-').toLowerCase();
    id = id.replaceAll(format, '');
    shorthand.value = id;
  })

  onMounted(async()=>{
    if (route.query.r) {
      curRevId.value = route.query.r;
    }
    await retrieveTags();
  })

  const addTag = async () => {
    isProcessing.value = true;
    errors.value = [];
    const res = await createTag(curRevId.value,{
      name: name.value,
      hexColor: hexColor.value,
      shorthand: shorthand.value,
      description: description.value,
    });
    console.log(res.data);
    if (!res.data.isError) {
      closeModal();
      retrieveTags();
      swal.fire(
        'Success!',
        `Tag has been added.`,
        'success'
      );
    }else{
      errors.value = res.data.error;
    }
    isProcessing.value = false;
  }

  const retrieveTags = async () => {
    isRetrieving.value = true;
    const res = await getTags(curRevId.value);
    tags.value = res.tags
    isRetrieving.value = false;
  }

  const tagUpdate = async () => {
      isProcessing.value = true;
      errors.value = [];
      let res = await updateTag(curRevId.value, curTagId.value, {
        name: name.value,
        hexColor: hexColor.value,
        shorthand: shorthand.value,
        description: description.value,
      });
      if (!res.data.isError) {
        retrieveTags();
        closeModal();
        swal.fire(
            'Success!',
            `Tag has been updated.`,
            'success'
        );
      }else{
        errors.value = res.data.error;
      }
      isProcessing.value = false;

    }

  const delTag = async (tagId, tagName) => {

    const isConfirmed = await swal.fire({
        title: `Delete ${tagName}?`,
        text: `Are you sure you want to delete this tag?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#db912a',
        cancelButtonColor: '#4a4848',
        confirmButtonText: 'Yes, delete'
      }).then(result => result.isConfirmed);
      if(!isConfirmed){return false;}

      isProcessing.value = true;
      let res = await deleteTag(curRevId.value, tagId);
      if (!res.data.isError) {
        retrieveTags();
        swal.fire(
            'Success!',
            `Tag has been deleted.`,
            'success'
        );
      }else{
        swal.fire({
          title: 'Error!',
          text: 'Unable to delete tag',
          icon: 'error'
        });
      }
      isProcessing.value = false;
  }

  const convertToTruncatedText = (htmlContent) => {

    var div = document.createElement("div");
    div.innerHTML = htmlContent;
    var text = div.textContent || div.innerText || "";
    return text.slice(0,50) + '...';
  }


</script>