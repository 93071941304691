<template>
  <div class="flex">
    <div class="flex w-64 flex-col">
      <div
        class="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white"
      >
        <!-- Header -->
        <div
          class="
            flex flex-shrink-0
            border-b border-gray-300
            h-20
            items-center
            px-4
            bg-white
          "
        >
          <div class="group block w-full flex-shrink-0">
            <div class="flex items-center justify-between">
              <div>
                <p
                  class="
                    text-sm
                    font-medium
                    text-gray-700
                    group-hover:text-gray-900
                  "
                >
                  Items
                </p>
                <p
                  class="
                    text-xs
                    font-medium
                    text-gray-500
                    group-hover:text-gray-700
                  "
                >
                  under Spelling
                </p>
              </div>
              <button
                class="
                  inline-flex
                  justify-center
                  rounded-full
                  border border-gray-300
                  bg-white
                  p-1
                  text-sm
                  font-medium
                  text-gray-400
                  shadow-sm
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-indigo-500
                  focus:ring-offset-2
                "
              >
                <PlusIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <!-- Main Section -->
        <div class="flex flex-1 flex-col overflow-y-auto pb-4">
          <nav
            class="min-h-0 flex-1 overflow-y-auto -mt-0.5"
            aria-label="Directory"
          >
            <div v-for="set in sets" :key="set.id" class="relative">
              <div
                class="
                  sticky
                  top-0
                  z-10
                  border-t border-b border-gray-200
                  bg-gray-50
                  px-4
                  py-1
                  text-sm
                  font-medium
                  text-gray-500
                  flex
                  justify-between
                  items-center
                "
              >
                <h3>{{ set.name }}</h3>
                <div
                  class="
                    text-xs
                    font-normal
                    cursor-pointer
                    text-gray-400
                    hover:opacity-75
                  "
                >
                  <PencilAltIcon class="h-4 w-4" aria-hidden="true" />
                </div>
              </div>
              <ul role="list" class="relative z-0 space-y-1 px-2 py-4">
                <li
                  v-for="item in items.filter((e) => e.setId == set.id)"
                  :key="item.id"
                  :href="item.href"
                  :class="[
                    item.id == store.state.current.itemId
                      ? 'bg-gray-200 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group flex justify-between items-center px-4 py-2 text-sm font-medium rounded-md  cursor-pointer',
                  ]"
                  @click="store.dispatch('setCurrentItem', item.id)"
                >
                  <span>{{ item.name }}</span>
                  <div
                    class="flex-shrink-0 w-2.5 h-2.5 rounded-full bg-pink-600"
                    aria-hidden="true"
                  ></div>
                </li>

                <!-- Contexts -->
                <div
                  v-for="context in contexts.filter((e) => e.setId == set.id)"
                  :key="context.id"
                  class="border border-gray-200 mx-3"
                >
                  <div
                    class="
                      bg-gray-50
                      px-3
                      py-1
                      text-sm
                      font-medium
                      text-gray-500
                      flex
                      justify-between
                      items-center
                      border-b border-gray-200
                    "
                  >
                    <h3>{{ context.name }}</h3>
                    <div
                      class="
                        text-xs
                        font-normal
                        text-gray-400
                        cursor-pointer
                        hover:opacity-75
                      "
                    >
                      <PencilAltIcon class="h-4 w-4" aria-hidden="true" />
                    </div>
                  </div>
                  <ul class="py-3 px-2 space-y-2">
                    <li
                      v-for="item in context.items"
                      :key="item.id"
                      :class="[
                        item.id == store.state.current.itemId
                          ? 'bg-gray-200 text-gray-900'
                          : 'text-gray-600 hover:bg-gray-100 hover:text-gray-700',
                        'group flex justify-between items-center px-4 py-2 text-sm font-medium rounded-md  cursor-pointer',
                      ]"
                      @click="store.dispatch('setCurrentItem', item.id)"
                    >
                      <span>{{ item.name }}</span>
                      <div
                        class="
                          flex-shrink-0
                          w-2.5
                          h-2.5
                          rounded-full
                          bg-yellow-400
                        "
                        aria-hidden="true"
                      ></div>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          </nav>
        </div>
        <!-- Footer -->
        <div
          class="
            flex flex-shrink-0
            border-t border-gray-300
            px-4
            h-12
            items-center
          "
        >
          <p
            class="text-xs font-normal text-gray-600 group-hover:text-gray-900"
          >
            Sets: 5
            <span class="mx-2 text-sm font-light text-gray-300">|</span>
            Contexts: 2
            <span class="mx-2 text-sm font-light text-gray-300">|</span>
            Items: 10
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import store from '@/store';
import { PencilAltIcon, PlusIcon } from "@heroicons/vue/solid";

const sets = [
  { id: "set-a", name: "Set A" },
  { id: "set-b", name: "Set B" },
  { id: "set-c", name: "Set C" },
  { id: "set-d", name: "Set D" },
  { id: "set-e", name: "Set E" },
];

const items = [
  // Set A
  { id: "item-1", setId: "set-a", name: "Item 1A", current: true },
  { id: "item-2", setId: "set-a", name: "Item 2A", current: false },
  { id: "item-3", setId: "set-a", name: "Item 3B", current: false },
  { id: "item-4", setId: "set-a", name: "Item 4B", current: false },
  { id: "item-5", setId: "set-a", name: "Item 3B", current: false },
  { id: "item-6", setId: "set-a", name: "Item 4B", current: false },
  // Set B
  { id: "item-7", setId: "set-b", name: "Item 3B", current: false },
  { id: "item-8", setId: "set-b", name: "Item 4B", current: false },
  { id: "item-9", setId: "set-b", name: "Item 3B", current: false },
  { id: "item-10", setId: "set-b", name: "Item 4B", current: false },
  { id: "item-11", setId: "set-b", name: "Item 4B", current: false },
  { id: "item-12", setId: "set-b", name: "Item 4B", current: false },
  // Set C
  { id: "item-13", setId: "set-c", name: "Item 5C", current: false },
  { id: "item-14", setId: "set-c", name: "Item 6C", current: false },
  { id: "item-15", setId: "set-c", name: "Item 5C", current: false },
  { id: "item-16", setId: "set-c", name: "Item 6C", current: false },
  { id: "item-17", setId: "set-c", name: "Item 5C", current: false },
  { id: "item-18", setId: "set-c", name: "Item 6C", current: false },
  // Set D
  { id: "item-19", setId: "set-d", name: "Item 7D", current: false },
  { id: "item-20", setId: "set-d", name: "Item 8D", current: false },
  { id: "item-21", setId: "set-d", name: "Item 7D", current: false },
  { id: "item-22", setId: "set-d", name: "Item 8D", current: false },
  { id: "item-23", setId: "set-d", name: "Item 7D", current: false },
  { id: "item-24", setId: "set-d", name: "Item 8D", current: false },
  // Set E
  { id: "item-25", setId: "set-e", name: "Item 9E", current: false },
  { id: "item-26", setId: "set-e", name: "Item 9E", current: false },
  { id: "item-27", setId: "set-e", name: "Item 9E", current: false },
  { id: "item-28", setId: "set-e", name: "Item 10E", current: false },
];

const contexts = [
  {
    id: "context-1",
    setId: "set-b",
    name: "Context 1",
    items: [
      { id: "item-c1-1", name: "Item C1-1", current: false },
      { id: "item-c1-2", name: "Item C1-2", current: false },
      { id: "item-c1-3", name: "Item C1-3", current: false },
    ],
  },
  {
    id: "context-2",
    setId: "set-c",
    name: "Context 2",
    items: [
      { id: "item-c2-1", name: "Item C2-1", current: false },
      { id: "item-c2-2", name: "Item C2-2", current: false },
    ],
  },
  {
    id: "context-3",
    setId: "set-c",
    name: "Context 3",
    items: [
      { id: "item-c3-1", name: "Item C3-1", current: false },
      { id: "item-c3-2", name: "Item C3-2", current: false },
      { id: "item-c3-3", name: "Item C3-3", current: false },
      { id: "item-c3-4", name: "Item C3-4", current: false },
    ],
  },
  {
    id: "context-4",
    setId: "set-d",
    name: "Context 4",
    items: [
      { id: "item-c4-1", name: "Item C4-1", current: false },
      { id: "item-c4-2", name: "Item C4-2", current: false },
    ],
  },
];
</script>