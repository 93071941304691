<template>
  <TransitionRoot as="template" :show="store.getters.slideOver">
    <Dialog as="div" class="relative z-10" @close="handleClose">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="
              pointer-events-none
              fixed
              inset-y-0
              right-0
              flex
              max-w-full
              pl-10
            "
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div
                  class="
                    flex
                    h-full
                    flex-col
                    overflow-y-scroll
                    bg-white
                    py-0.5
                    shadow-xl
                  "
                >
                  <div class="flex h-screen w-full flex-col">
                    <div
                      class="
                        flex
                        min-h-0
                        flex-1 flex-col
                        border-r border-gray-200
                        bg-white
                      "
                    >
                      <div
                        class="
                          flex flex-shrink-0
                          border-b border-gray-300
                          h-20
                          items-center
                          px-4
                          bg-white
                        "
                      >
                        <div class="group block w-full flex-shrink-0">
                          <div class="flex items-center justify-between">
                            <div>
                              <p
                                class="
                                  text-sm
                                  font-medium
                                  text-gray-700
                                  group-hover:text-gray-900
                                "
                              >
                                Filters
                              </p>
                              <p
                                class="
                                  text-xs
                                  font-normal
                                  text-gray-500
                                  group-hover:text-gray-700
                                "
                              >
                                Show specific topics by adjusting the settings
                                below:
                              </p>
                            </div>
                            <button
                              type="button"
                              class="
                                rounded-md
                                bg-white
                                text-gray-400
                                hover:text-gray-500
                                focus:outline-none
                                focus:ring-2
                                focus:ring-indigo-500
                                focus:ring-offset-2
                              "
                              @click="handleClose"
                            >
                              <span class="sr-only">Close panel</span>
                              <XIcon class="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          flex flex-1 flex-col
                          overflow-y-auto
                          p-4
                          space-y-4
                          px-8
                        "
                      >
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Facere cupiditate veritatis enim minus incidunt
                          reiciendis accusamus iure expedita inventore eos,
                          debitis, soluta nisi exercitationem modi voluptatibus.
                          Minima deserunt commodi incidunt.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Facere cupiditate veritatis enim minus incidunt
                          reiciendis accusamus iure expedita inventore eos,
                          debitis, soluta nisi exercitationem modi voluptatibus.
                          Minima deserunt commodi incidunt.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Facere cupiditate veritatis enim minus incidunt
                          reiciendis accusamus iure expedita inventore eos,
                          debitis, soluta nisi exercitationem modi voluptatibus.
                          Minima deserunt commodi incidunt.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Facere cupiditate veritatis enim minus incidunt
                          reiciendis accusamus iure expedita inventore eos,
                          debitis, soluta nisi exercitationem modi voluptatibus.
                          Minima deserunt commodi incidunt.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Facere cupiditate veritatis enim minus incidunt
                          reiciendis accusamus iure expedita inventore eos,
                          debitis, soluta nisi exercitationem modi voluptatibus.
                          Minima deserunt commodi incidunt.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Facere cupiditate veritatis enim minus incidunt
                          reiciendis accusamus iure expedita inventore eos,
                          debitis, soluta nisi exercitationem modi voluptatibus.
                          Minima deserunt commodi incidunt.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Facere cupiditate veritatis enim minus incidunt
                          reiciendis accusamus iure expedita inventore eos,
                          debitis, soluta nisi exercitationem modi voluptatibus.
                          Minima deserunt commodi incidunt.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Facere cupiditate veritatis enim minus incidunt
                          reiciendis accusamus iure expedita inventore eos,
                          debitis, soluta nisi exercitationem modi voluptatibus.
                          Minima deserunt commodi incidunt.
                        </p>
                      </div>
                      <div
                        class="
                          flex
                          border-t border-gray-300
                          p-4
                          justify-end
                          px-8
                        "
                      >
                        <button
                          type="button"
                          class="
                            inline-flex
                            items-center
                            rounded-md
                            border border-transparent
                            bg-indigo-600
                            px-4
                            py-2
                            text-sm
                            font-medium
                            text-white
                            shadow-sm
                            hover:bg-indigo-700
                            focus:outline-none
                            focus:ring-2
                            focus:ring-indigo-500
                            focus:ring-offset-2
                          "
                        >
                          Save Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
  
<script setup>
import store from '@/store';
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

const handleClose = () => {
  store.dispatch('setSlideOver', false)
}


</script>