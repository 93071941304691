import { ref, onValue, onDisconnect, set, serverTimestamp } from "firebase/database";
import { rtdb } from '@/firebase/config.js';

import store from '@/store';

export const setPresence = async(presenceStatus) => {

    // get authenticated user details
    const authUser = store.state.user.credentials;
    // check if user is logged in
    if (authUser) {
        const userAdminRef = ref(rtdb, 'users-admin/' + authUser.uid);
        await set(userAdminRef, {
            status: presenceStatus,
            lastOnline: serverTimestamp()
        });
    }

}


export const infoConnected = () => {

    const authUser = store.state.user.credentials;

    const connectedRef = ref(rtdb, ".info/connected");
    onValue(connectedRef, (snap) => {

        if (authUser) {

            const userAdminRef = ref(rtdb, 'users-admin/' + authUser.uid);

            if (snap.val() === true) {

                set(userAdminRef, {
                    status: 'online',
                    lastOnline: serverTimestamp()
                });

                onDisconnect(userAdminRef).set({
                    status: 'offline',
                    lastOnline: serverTimestamp()
                });
            }
        }

    });
}


export const updateOnAway = () => {

    window.onblur = () => {
        setPresence('away')
    };

    window.onfocus = () => {
        setPresence('online')
    };
}