<template>
  <div class="flex justify-center text-warm-gray-600">

    <div class=" w-10/12 lg:w-2/3"> 

      <div class="border rounded-md shadow overflow-hidden m-2 dark:border-warm-gray-700 mt-14">
        <div class="bg-warm-gray-300 flex justify-between items-center p-2 dark:bg-warm-gray-700">
          <div class=" dark:text-warm-gray-300">
            Showing all subtopics 
          </div>
          <div class="flex justify-end items-center">
            <button @click="getSubtopics" class=" rounded-full p-2 w-8 h-8 bg-warm-gray-400 hover:bg-warm-gray-500 focus:bg-warm-gray-500 focus:outline-none dark:bg-warm-gray-600 dark:hover:bg-warm-gray-500 dark:focus:bg-warm-gray-500">
              <RefreshIcon class=" text-warm-gray-50" />
            </button>
            <button @click="openAddForm()"  class=" m-2 bg-yellow-700 rounded text-white font-semibold py-1 px-4 hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none">
              Add
            </button>
          </div>
        </div>

        <div class="p-2">
          <table class="w-full text-left">
            <thead class="border-b-2 dark:border-warm-gray-600 dark:text-warm-gray-200">
              <tr>
                <th class="w-6"></th>
                <th>Name</th>
                <th class="px-4 text-center">Items</th>
                <th class="pr-4">Description</th>
                <th class="w-14"></th>
              </tr>
            </thead>
            <tbody v-if="isRetrieving" class="animate-pulse">
              <tr v-for="i in 10" :key="i">
                <td> <div class="h-4 m-1 bg-warm-gray-300 dark:bg-warm-gray-700"></div> </td>
                <td> <div class="h-4 m-1 bg-warm-gray-300 dark:bg-warm-gray-700"></div> </td>
                <td class="text-center"> <div class="h-4 m-1 bg-warm-gray-300 dark:bg-warm-gray-700"></div> </td>
                <td colspan="2"> <div class="h-4 m-1 bg-warm-gray-300 dark:bg-warm-gray-700"></div> </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(subtopic,i) in subtopics" :key="i" class="border-b text-white hover:text-warm-gray-600 hover:bg-warm-gray-100 dark:border-warm-gray-700 dark:hover:bg-warm-gray-800 group dark:text-warm-gray-300 dark:hover:text-warm-gray-100">
                <td class=" text-blue-700">
                  <DuplicateIcon v-if="subtopic.data.subjects.length > 1" class="h-4 w-4" />
                </td>
                <td class="text-warm-gray-600 py-2 whitespace-nowrap dark:text-warm-gray-300 dark:group-hover:text-warm-gray-100">{{ subtopic.data.name }}</td>
                <td class="text-warm-gray-600 py-2 text-center dark:text-warm-gray-300 dark:group-hover:text-warm-gray-100">{{ subtopic.data.items }}</td>
                <td class="text-warm-gray-600 py-2 dark:text-warm-gray-300 dark:group-hover:text-warm-gray-100">{{ subtopic.data.description }}</td>
                <td class="py-2">
                  <div class="flex justify-around items-center">
                    <!-- update icon -->
                    <div class="has-tooltip">
                      <span class='tooltip rounded shadow-lg p-1 bg-warm-gray-600 text-xs text-warm-gray-300 -ml-4 mt-6'>Update</span>
                      <PencilAltIcon @click="subtopicForm.type = 'update'; openUpdateForm(subtopic);" class="w-4 h-4 cursor-pointer"/>
                    </div>
                    <!-- delete icon -->
                    <div class="has-tooltip">
                      <span class='tooltip rounded shadow-lg p-1 bg-warm-gray-600 text-xs text-warm-gray-300 -ml-4 mt-6'>Delete</span>
                      <TrashIcon @click="deleteSubtopic(subtopic.id,subtopic.data.name)" class="w-4 h-4 cursor-pointer" />
                    </div>
                    <!-- More Options -->
                    <!-- <Menu as="div" class="inline-block text-left">
                      <div>
                        <MenuButton class=" has-tooltip focus:outline-none flex items-center">
                            <span class='tooltip rounded shadow-lg p-1 bg-warm-gray-600 text-xs text-warm-gray-300 -ml-4 mt-14'>More option</span>
                            <DotsVerticalIcon class="w-4 h-4 cursor-pointer" />
                        </MenuButton>
                      </div>

                      <transition
                        enter-active-class="transition duration-100 ease-out"
                        enter-from-class="transform scale-95 opacity-0"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-in"
                        leave-from-class="transform scale-100 opacity-100"
                        leave-to-class="transform scale-95 opacity-0"
                      >
                        <MenuItems class="absolute mt-2 -ml-14 p-1 bg-white rounded-md shadow-lg focus:outline-none text-xs">                 
                          <MenuItem v-for="(option,i) in moreOptions" :key="i">
                            <button class="text-warm-gray-700 flex items-center w-full px-4 rounded py-2 hover:bg-warm-gray-200">
                              {{option.name}}
                            </button>
                          </MenuItem>
                        </MenuItems>
                      </transition>
                    </Menu> -->
                    <!-- ./ More Options -->
                  </div>
                </td>
              </tr>
            </tbody>
            <!-- <tfoot>
              <tr>
                <td colspan="6" class="p-2 pt-6">
                <div class="flex justify-end px-4 text-xs text-warm-gray-600">
                  <span class="mr-4">
                    Page 1 of 1
                  </span>
                  <div class="grid grid-cols-2 gap-1">
                    <button  :class="[true ? 'text-warm-gray-700':'text-warm-gray-400 cursor-default', 'rounded-md focus:bg-warm-gray-200 focus:outline-none']">
                      <ChevronLeftIcon class="h-4 w-4" />
                    </button>
                    <button :class="[true ? 'text-warm-gray-700':'text-warm-gray-400 cursor-default', 'rounded-md focus:bg-warm-gray-200 focus:outline-none']">
                      <ChevronRightIcon class="h-4 w-4" />
                    </button>
                  </div>
                </div>
                </td>
              </tr>
            </tfoot> -->
          </table>
        </div>
      </div>
    </div>

    <ProcessingModal v-if="isProcessing" />

    <!-- Add / Edit Subtopic Modal -->
    <TransitionRoot appear :show="isAddEditModalOpen" as="template">
      <Dialog as="div" @close="closeAddEditModal">
        <div class="fixed inset-0 z-10 overflow-y-auto text-sm">
          <div class="min-h-screen px-4 text-center">

            <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40" />

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
              >
              <div
                class="inline-block w-10/12 lg:w-1/3 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-700"
              >
                <DialogTitle
                  as="h3"
                  class="flex items-center text-base font-medium leading-6 text-white bg-yellow-600 p-6 pb-4"
                >
                {{ subtopicForm.type == 'add' ? 'Add': 'Update'}} Subtopic
                </DialogTitle>

                <div class="mt-2 flex-row items-start text-warm-gray-700 p-6 dark:text-warm-gray-200">
                  
                  <!-- Error Display -->
                  <div v-if="error.for == 'add-edit-subtopic'" class="rounded-md bg-yellow-50 p-4 mb-6">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <ExclamationIcon
                          class="h-5 w-5 text-yellow-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-3 text-yellow-700">
                        <p class="font-bold">Error: Unable to save data.</p>
                        <p class="text-sm">
                          <ul class="list-disc ml-5">
                            <li v-for="(err,i) in error.errors" :key="i">{{err}}</li>
                          </ul>
                        </p>
                      </div>
                      <div class="ml-auto pl-3">
                        <div class="-mx-1.5 -my-1.5">
                          <button
                            type="button"
                            class="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yelow-50 focus:ring-yelow-600 ">
                            <span class="sr-only">Dismiss</span>
                            <XIcon class="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-col mx-2">
                    
                    <div class="mb-6 flex flex-col">
                      <div class="flex justify-between items-center">
                        <label for="subtopic-is">Subtopic ID</label>
                        <div v-if="this.subtopicForm.type == 'add'">
                          <a v-if="!customId.isSet" href="#" @click="customId.isSet = true" class="text-xs text-yellow-600">Set custom id</a>
                          <a v-else href="#" @click="customId.isSet = false" class="text-xs text-yellow-600">Auto generate id</a>
                        </div>
                      </div>
                      <input v-model="subtopicForm.id" id="subtopic-id" :class="[ customId.isSet ? 'dark:text-warm-gray-700':'text-warm-gray-400 dark:bg-warm-gray-600 dark:border-warm-gray-500 dark:text-warm-gray-300', 'p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none']" :placeholder="customId.isSet ? 'Enter subtopic ID':'Auto generated subtopic ID'" :disabled="!customId.isSet" />
                      <small v-if="!customId.isValid" class="text-red-600"> <b>Invalid format:</b> Spaces and special characters other than <code class="bg-red-100 px-1">_</code> and <code class="bg-red-100 px-1">–</code> are not allowed.</small>
                    </div>
                    
                    <div class="mb-6 flex flex-col">
                      <label for="subtopic-name">Name</label>
                      <input v-model="subtopicForm.name" id="subtopic-name" class="p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" placeholder="Enter subtopic name" />
                    </div>

                    <div class="mb-6 flex flex-col">
                      <label for="subtopic-description">Description</label>
                      <textarea v-model="subtopicForm.description" id="subtopic-description" rows="3" placeholder="Enter subtopic description" class="p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700"></textarea>
                    </div>

                    <div>List of subjects that this subtopic belongs to.</div>
                    <table class="mt-2">
                      <thead class="border-t border-b border-warm-gray-400 dark:border-warm-gray-800">
                        <tr class="bg-warm-gray-200 dark:bg-warm-gray-800">
                          <th colspan="2" class="px-2 py-1">
                            <div class="flex justify-between items-center">                            
                              <span>Subject ID</span>
                              <div class="border w-1/2 flex justify-around space-x-2 dark:border-warm-gray-800">
                                <select v-model="newSubjectId" :class="[!newSubjectId && ' text-warm-gray-400', 'p-1 w-full rounded-sm border focus:outline-none focus:border-yellow-400 focus:shadow-md dark:text-warm-gray-700']">
                                  <option value="" disabled>Select subject</option>
                                  <option v-for="(subject,i) in subjectsForDropDown" :key="i" :value="subject.id" class=" text-warm-gray-600">{{subject.data.name}}</option>
                                </select>
                                <button @click="addSubject" class=" bg-warm-gray-400 rounded-sm px-2 hover:bg-warm-gray-500 focus:outline-none focus:bg-warm-gray-500 dark:bg-warm-gray-600 dark:hover:bg-warm-gray-500 dark:focus:bg-warm-gray-500">
                                  <PlusIcon class="w-4 h-4" />
                                </button>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(subject,i) in subtopicForm.subjects" :key="i" class="text-white hover:text-warm-gray-600 hover:bg-warm-gray-100 border-b dark:text-warm-gray-200 dark:hover:bg-warm-gray-600 dark:border-warm-gray-500">
                          <td class="text-warm-gray-600 px-2 py-1 flex justify-start items-center dark:text-warm-gray-200">
                            {{subject}}
                            <KeyIcon v-if="subtopicForm.keys[i]" class=" h-4 w-4 text-yellow-600 ml-1" />
                            <small v-if=" !subjects.some(s => s.id == subject)" class="text-white text-xxs px-1 bg-red-300 rounded">ID not found</small></td>
                          <td class="text-center w-10">
                            <!-- More Options -->
                            <Menu as="div" class="inline-block text-left">
                              <div>
                                <MenuButton class=" has-tooltip focus:outline-none flex items-center">
                                    <span class='tooltip rounded shadow-lg p-1 bg-warm-gray-600 text-xs text-warm-gray-300 -ml-4 mt-14'>More option</span>
                                    <DotsVerticalIcon class="w-4 h-4 cursor-pointer" />
                                </MenuButton>
                              </div>

                              <transition
                                enter-active-class="transition duration-100 ease-out"
                                enter-from-class="transform scale-95 opacity-0"
                                enter-to-class="transform scale-100 opacity-100"
                                leave-active-class="transition duration-75 ease-in"
                                leave-from-class="transform scale-100 opacity-100"
                                leave-to-class="transform scale-95 opacity-0"
                              >
                                <MenuItems class="absolute -ml-14 p-1 bg-white rounded-md shadow-lg focus:outline-none text-xs dark:bg-warm-gray-800">                 
                                  <MenuItem v-for="(option,ii) in moreOptions" :key="ii">
                                    <button v-show="(option.hasCon == false) || (option.code == 'sk' && !subtopicForm.keys[i]) || (option.code == 'uk' && subtopicForm.keys[i])" @click="option.func(i)" class="text-warm-gray-700 flex items-center w-full px-4 rounded py-2 hover:bg-warm-gray-200 dark:text-warm-gray-200 dark:hover:bg-warm-gray-700">
                                      {{option.name}}
                                    </button>
                                  </MenuItem>
                                </MenuItems>
                              </transition>
                            </Menu>
                            <!-- ./ More Options -->
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <!-- <div class="flex justify-start items-center mt-6">
                      <input type="checkbox" id="subtopic-key" class="mr-2 focus:outline-none" v-model="subtopicForm.isKey" />
                      <label for="subtopic-key"> is a key subtopic.</label>
                    </div> -->

                  </div>
                  
                </div>

                <div class="mt-4 border-t p-6 pt-2 flex justify-between dark:border-warm-gray-400">
                  <div class="flex justify-start items-center">
                    <!-- -->
                  </div>
                  <div class="flex justify-end">
                    <button
                      type="button"
                      class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 dark:bg-warm-gray-500 dark:text-warm-gray-200 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600"
                      @click="closeAddEditModal"
                      >
                      Cancel
                    </button>
                    <button
                      @click="subtopicForm.type == 'add' ? createSubtopic() : updateSubtopic()"
                      type="button"
                      class="ml-2 flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-700 border border-transparent rounded-md hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
                      {{ subtopicForm.type == 'add' ? 'Add': 'Update'}}
                    </button>
                  </div>
                </div>
                
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

  </div>

</template>

<script>
import swal from 'sweetalert2';
import cloneDeep from 'lodash/cloneDeep';
import ProcessingModal from '@/components/ProcessingModal';

import {
  getSubtopics,
  getSubjects,
  createSubtopic,
  updateSubtopic,
  deleteSubtopic,
} from '@/firebase/controllers/reviewerController';

import {
  ArrowPathIcon as RefreshIcon,
  KeyIcon as KeyIcon,
  DocumentDuplicateIcon as DuplicateIcon,
  ExclamationTriangleIcon as ExclamationIcon,
  XMarkIcon as XIcon,
} from '@heroicons/vue/24/outline';

import {
  PencilSquareIcon as PencilAltIcon,
  TrashIcon as TrashIcon,
  EllipsisVerticalIcon as DotsVerticalIcon,
  PlusIcon as PlusIcon
} from '@heroicons/vue/24/solid';


import {
  // modal
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
  // menu dropdown
  Menu,
  MenuButton,
  MenuItems,
  MenuItem 
} from '@headlessui/vue';

export default {
  data() {
    return {
      curRevId: '',
      isRetrieving: false,
      isProcessing: false,
      isAddEditModalOpen: false,
      isSortByName: true,
      customId:{
        isValid: true,
        isSet: false
      },
      subtopics:[],
      subjects: [],
      subjectsForDropDown: [],
      moreOptions:[
        // hasCon = hasCondition
        {hasCon: false, code: 'rm', name: 'remove', func: this.removeSubject},
        {hasCon: true, code: 'sk', name: 'set as key', func: this.setAsKey},
        {hasCon: true, code: 'uk', name: 'unset as key', func: this.unsetAsKey}
      ],
      error:{
        for: '',
        errors:[]
      },
      subtopicForm:{
        type: 'add',
        id: '',
        name: '',
        description: '',
        // isKey: false,
        subjects: [],
        keys: []
      },
      newSubjectId: '',
    }
  },
  watch:{
    'customId.isSet' : function(newVal){
      const format = /[`!@#$%^&*()+\\=\\[\]{};':"\\|,.<>\\/?~]/g;
      if (!newVal) {
        let id = this.subtopicForm.name.replaceAll(' ', '-').toLowerCase();
        id = id.replaceAll(format, '');
        this.subtopicForm.id = id;
      }
    },
    'subtopicForm.name' : function(newVal){
      // check if auto generate id is active
      if(!this.customId.isSet && this.subtopicForm.type == 'add'){
        // transform the new value to ID format.
        const format = /[`!@#$%^&*()+\\=\\[\]{};':"\\|,.<>\\/?~]/g;
        let id = newVal.replaceAll(' ', '-').toLowerCase();
        id = id.replaceAll(format, '');
        this.subtopicForm.id = id;
      }
    },
    'subtopicForm.id' : function(newVal){
      const format = /[ `!@#$%^&*()+\\=\\[\]{};':"\\|,.<>\\/?~]/g;
      if (format.test(newVal)) {
        this.customId.isValid = false;
      }else{
        this.customId.isValid = true;
      }
    },
    'subtopicForm.subjects': {
      handler(newArr){
        this.subjectsForDropDown = this.subjects.filter((el)=>!newArr.includes(el.id));
      },
      deep: true
    }
  },
  components:{
    ProcessingModal,
    RefreshIcon,
    KeyIcon,
    DuplicateIcon,
    // ChevronLeftIcon,
    // ChevronRightIcon,
    PencilAltIcon,
    TrashIcon,
    PlusIcon,
    DotsVerticalIcon,
    // for error alert
    ExclamationIcon,
    XIcon,
    // headless/ui (menu dropdown)
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    // headless/ui (modal)
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  mounted() {
    if (this.$route.query.r) {
      this.curRevId = this.$route.query.r;
      this.getSubtopics();
    }
  },
  methods: {
    async getSubtopics(){
      this.isRetrieving = true;
      const res = await getSubtopics(this.curRevId);
      this.subtopics = !this.isSortByName ? res.subtopics : res.subtopics.sort((a,b) => (a.data.name > b.data.name) ? 1 : ((b.data.name > a.data.name) ? -1 : 0));
      this.isRetrieving = false;
      this.getSubjects();
    },
    async getSubjects(){
      const res = await getSubjects(this.curRevId);
      this.subjects = res.subjects;
    },
    async createSubtopic(){
      this.isProcessing = true;
      let res = await createSubtopic(this.curRevId, this.subtopicForm);
      if (!res.data.isError) {
        this.getSubtopics();
        swal.fire(
            'Success!',
            `${this.subtopicForm.name} has been added.`,
            'success'
        );
        this.closeAddEditModal();
      }else{
        this.error.for = 'add-edit-subtopic';
        this.error.errors = res.data.error;
      }
      this.isProcessing = false;
    },
    async updateSubtopic(){
      this.isProcessing = true;
      let res = await updateSubtopic(this.curRevId, this.subtopicForm);
      if (!res.data.isError) {
        this.getSubtopics();
        swal.fire(
            'Success!',
            `${this.subtopicForm.name} has been updated.`,
            'success'
        );
        this.closeAddEditModal();
      }else{
        this.error.for = 'add-edit-subtopic';
        this.error.errors = res.data.error;
      }
      this.isProcessing = false;
    },
    async deleteSubtopic(subtopicId, subtopicName){

      const isConfirmed = await swal.fire({
        title: `Delete ${subtopicName}?`,
        text: `Are you sure you want to delete this subtopic? All questions under this subtopic will be deleted as well.`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#db912a',
        cancelButtonColor: '#4a4848',
        confirmButtonText: 'Yes, delete'
      }).then(result => result.isConfirmed);
      if(!isConfirmed){return false;}

      this.isProcessing = true;
      let res = await deleteSubtopic(this.curRevId, subtopicId);
      if (!res.data.isError) {
        this.getSubtopics();
        swal.fire(
            'Success!',
            `Subtopic ${subtopicName} has been deleted.`,
            'success'
        );
      }else{
        swal.fire({
          title: 'Error!',
          text: 'Unable to delete subtopic',
          icon: 'error'
        });
      }
      this.isProcessing = false;
    },
    addSubject(){
      if(!this.newSubjectId) return null;
      this.subtopicForm.subjects.push(this.newSubjectId);
      this.subtopicForm.keys.push(false);
      this.newSubjectId = '';
    },
    removeSubject(i){
      this.subtopicForm.subjects.splice(i,1);
    },
    setAsKey(i){
      this.subtopicForm.keys[i] = true;
    },
    unsetAsKey(i){
      this.subtopicForm.keys[i] = false;
    },
    openAddForm(){
      this.subtopicForm.type = 'add';
      this.subjectsForDropDown = this.subjects;
      this.openAddEditModal();
    },
    openUpdateForm(subtopic){
      this.subtopicForm.id = subtopic.id;
      this.subtopicForm.name = subtopic.data.name;
      this.subtopicForm.description = subtopic.data.description;
      // this.subtopicForm.isKey = subtopic.data.isKey;
      this.subtopicForm.subjects = cloneDeep(subtopic.data.subjects);
      // populating the keys field if not yet set in firebase db
      if (subtopic.data.keys) {
        this.subtopicForm.keys = subtopic.data.keys;
      }else{
        this.subtopicForm.subjects.forEach(() => this.subtopicForm.keys.push(false));
      }
      this.openAddEditModal();
    },
    openAddEditModal(){
      this.isAddEditModalOpen = true;
    },
    closeAddEditModal(){
      this.isAddEditModalOpen = false;
      // clear error
      this.error.for = '';
      this.error.errors = [];
      // clear data first
      this.subtopicForm.id = '';
      this.subtopicForm.name = '';
      this.subtopicForm.description = '';
      this.subtopicForm.subjects = [];
      // this.subtopicForm.isKey = false;
      this.subtopicForm.keys = [];
      this.newSubjectId = '';
      // custom id reset
      this.customId.isValid = true;
      this.customId.isSet = false;
    },
  },
}
</script>