import { db, isLocalDB } from "./config";
import { doc, setDoc } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";

import { caap_subjects, caap_subtopics, _endorser_codes, users, schools } from "./collectionsData";


export const retrieve = async() => {

    if (isLocalDB) {
        let isConfirm = confirm("You are about to retrieve the data from firebase emulator. Do you wan't to continue?");
        if (isConfirm) {
            return;
        }
    }



    try {
        let data = [];
        let snapshot;
        let allDocs = [];
        // Getting all subjects documents from CAAP
        if (true) {
            allDocs = [];
            snapshot = await getDocs(collection(db, "reviewers", "caap", "subjects"));
            snapshot.forEach((doc) => {
                allDocs.push({ id: doc.id, data: doc.data() });
            });
            data.push(allDocs);
            console.log('SUCCESS: Retrieving docs from subjects collection...');
        }

        // Getting all subtopics documents from CAAP
        if (true) {
            allDocs = [];
            snapshot = await getDocs(collection(db, "reviewers", "caap", "subtopics"));
            snapshot.forEach(doc => {
                allDocs.push({ id: doc.id, data: doc.data() })
            });
            data.push(allDocs);
            console.log('SUCCESS: Retrieving docs from subtopics collection...');
        }

        // Getting all _endorser_codes docs
        if (true) {
            allDocs = [];
            snapshot = await getDocs(collection(db, "_endorser_codes"));
            snapshot.forEach(doc => {
                allDocs.push({ id: doc.id, data: doc.data() })
            });
            data.push(allDocs);
            console.log('SUCCESS: Retrieving docs from _endorser_codes collection...');
        }

        // Getting all users docs
        if (true) {
            allDocs = [];
            snapshot = await getDocs(collection(db, "users"));
            snapshot.forEach(doc => {
                allDocs.push({ id: doc.id, data: doc.data() })
            });
            data.push(allDocs);
            console.log('SUCCESS: Retrieving docs from users collection...');
        }

        // Getting all schools docs
        if (true) {
            allDocs = [];
            snapshot = await getDocs(collection(db, "schools"));
            snapshot.forEach(doc => {
                allDocs.push({ id: doc.id, data: doc.data() })
            });
            data.push(allDocs);
            console.log('SUCCESS: Retrieving docs from schools collection...');
        }

        return { isError: false, data };

    } catch (e) {
        console.error("Error retrieving document: ", e);
        return { isError: true, error: e };
    }
}

export const populate = () => {

    if (!isLocalDB) {
        alert('Populate data is only allowed in emulator.')
        return;
    }


    try {
        // populate subtopics data for CAAP
        caap_subtopics.forEach(async(subtopic) => {
            await setDoc(doc(db, "reviewers", "caap", "subtopics", subtopic.id), subtopic.data);
        });
        // populate subjects data for CAAP
        caap_subjects.forEach(async(subject) => {
            await setDoc(doc(db, "reviewers", "caap", "subjects", subject.id), subject.data);
        });

        // populate _endorser_codes data
        _endorser_codes.forEach(async(ec) => {
            await setDoc(doc(db, "_endorser_codes", ec.id), ec.data);
        });

        // populate users data
        users.forEach(async(user) => {
            await setDoc(doc(db, "users", user.id), user.data);
        });

        // populate schools data
        schools.forEach(async(school) => {
            await setDoc(doc(db, "schools", school.id), school.data);
        });






        return { isError: false };
    } catch (e) {
        console.error("Error adding document: ", e);
        return { isError: true, error: e };
    }
}