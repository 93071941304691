<template>

  <div class="flex justify-center">

    <!-- form wrapper -->
    <div class="flex justify-center mt-14 mb-2 text-warm-gray-600 dark:text-warm-gray-200 w-full">
      <div class=" w-full lg:w-7/12">

        <!-- header 1 -->
        <div class="flex justify-between items-end">
        <div class="flex justify-start items-center pl-2">
          <h2 class="text-warm-gray-600 font-bold text-lg dark:text-warm-gray-300">Add new announcement</h2>
        </div>
        </div>  
        <!-- Course Information Form -->
        <div class="border shadow rounded-md p-4 mt-4 dark:border-warm-gray-700">
          

          <div class="mb-4">
            <div><label for="title" class="font-semibold">Title</label></div>
            <input type="text" id="title" v-model="title" class="w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700"  placeholder="Enter title" />
          </div>

          <div class="mb-4">
            <div><label for="message" class="font-semibold">Message</label></div>
            <textarea id="message" rows="3" v-model="message" placeholder="Enter message" class="w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700"></textarea>
          </div>

          <div class="lg:flex w-full gap-2">
            <div class="lg:w-1/2">
              <label for="announce-at" class="font-semibold">Announce at</label>
              <input type="datetime-local" id="announce-at" v-model="announce_at" class="w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700">
            </div>
            <div class="lg:w-1/2">
              <label for="expires-at" class="font-semibold">Expires at</label>
              <input type="datetime-local" id="expires-at"  v-model="expires_at" class="w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700">
            </div>
          </div>

          <div class="lg:flex justify-between items-center">
            <div class="flex justify-start items-center mt-5 gap-4">
              <div class="flex items-center">
                <input type="radio" id="pop-up" v-model="type" name="type" value="pop-up" class="mr-1" checked />
                <label for="pop-up">Pop-up</label>
              </div>

              <div class="flex items-center">
                <input type="radio" id="post" v-model="type" name="type" value="post" class="mr-1" />
                <label for="post">Post</label>
              </div>
            </div>
            <div class="lg:flex justify-end items-center mt-6">
              <button @click="$router.go(-1)" class="mr-2 bg-warm-gray-300 font-semibold py-2 px-4 rounded-md hover:bg-warm-gray-400 focus:bg-warm-gray-400 focus:outline-none dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600">Cancel</button>
              <button @click="create()" class="bg-yellow-700 text-white font-semibold py-2 px-4 rounded-md hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none ">Save</button>
            </div>
          </div>

        </div>
      </div>
    </div>

    <ProcessingModal v-if="isProccessing"/>
  </div>
</template>

<script setup>
import {ref } from 'vue';
import swal from 'sweetalert2';
import ProcessingModal from '@/components/ProcessingModal';

import {createAnnouncement} from '../../../firebase'


const isProccessing = ref(false);

const title = ref('');
const message = ref('');
const announce_at = ref(null);
const expires_at = ref(null);
const type = ref('pop-up')


const create = async () => {
  isProccessing.value = true;

  const data = {
    title: title.value,
    message: message.value,
    announce_at: announce_at.value,
    expires_at: expires_at.value,
    type: type.value,
    status: 'active',
    seen_by: [],
  }

  let res = await createAnnouncement(data);
  console.log(res)

  isProccessing.value = false;

  if(!res.error){ 
    
    // clear fields
    title.value = '';
    message.value = '';
    announce_at.value = null;
    expires_at.value = null;
    type.value = 'pop-up';


    swal.fire(
        'Success!',
        `Announcement has been added.`,
        'success'
    );
  }else{

    swal.fire(
        'Error!',
        res.message,
        'error'
    );
  }

}

</script>
<style lang="">
  
</style>