<template>
  
  <div class="flex justify-center mt-10 p-4">
    <div class="shadow rounded-md w-full lg:w-5/6">
      <div class="p-4 bg-warm-gray-200 flex justify-between items-center dark:bg-warm-gray-700 dark:text-warm-gray-200">
        <div class="bg-warm-gray-100 md:w-3/4 py-1 px-4 text-warm-gray-600 dark:bg-warm-gray-300 rounded-md flex items-center justify-start">
          <SearchIcon class="h-4 w-4 mr-2" />
          <input type="text" class="bg-warm-gray-100 dark:bg-warm-gray-300 p-1 w-full focus:outline-none" placeholder="Search by email address" v-model="sUser.value" @keydown="searchUser">
        </div>
        <div class="flex items-center">
          <button class="p-2 mx-4 text-warm-gray-500 rounded-full bg-warm-gray-300 focus:outline-none hover:bg-warm-gray-400 focus:bg-warm-gray-400">
            <RefreshIcon @click="getUser()" class="h-6 w-6" />
          </button>
          <button class=" bg-yellow-600 text-white font-semibold p-2 rounded-md focus: outline-none hover:bg-yellow-700 focus:bg-yellow-700" @click="openCopyDataModal">
            <DocumentDuplicateIcon class="h-6 w-6"/>
          </button>
          <!-- <button class=" bg-yellow-600 text-white font-semibold p-2 rounded-md focus: outline-none hover:bg-yellow-700 focus:bg-yellow-700" @click="sendSMS()">Send test SMS</button> -->
        </div>
      </div>
      <table class="w-full">
          <thead>
              <tr class="hidden lg:table-row text-warm-gray-700 border-b border-warm-gray-300 dark:text-warm-gray-100">
                  <th class="lg:w-3/10 py-2 text-left pl-2">Email</th>
                  <th class="py-2 w-2/10">Provider</th>
                  <th class="lg:w-2/10 py-2">Purchased</th>
                  <th class="w-2/10 py-2 hidden lg:table-cell">Created</th>
                  <th class="w-2/10 py-2 hidden md:table-cell">Signed In</th>
                  <th class="lg:w-1/10 py-2"></th>
              </tr>
          </thead>
          <template v-if="!isUserRetrieving">
            <tbody v-if="users[curPageIndex]" class=" overflow-visible text-center">
              <template v-for="(listUser,i) in (sUser.isSearch==false ? users[curPageIndex].listUsersResult.users : sUser.result)" :key="i">
                <!-- for mobile -->
                <tr v-if="!listUser.disabled" colspan="5" class=" lg:hidden dark:text-warm-gray-300">
                  <td>
                    <div class="border text-warm-gray-700 dark:text-warm-gray-300 dark:border-warm-gray-600 rounded-md m-2 p-2 text-left">
                      <div class="flex justify-start items-center"><b class="mr-2">Email:</b> 
                        <span v-if="listUser.presence && listUser.presence.status == 'online'" class="w-2 h-2 bg-green-400 rounded-full mr-0.5"></span>
                        <span v-if="listUser.presence && listUser.presence.status == 'offline'" class="w-2 h-2 bg-warm-gray-600 rounded-full mr-0.5"></span>
                        <span v-if="listUser.presence && listUser.presence.status == 'away'" class="w-2 h-2 bg-yellow-300 rounded-full mr-0.5"></span>
                        {{listUser.email}} 
                        <BadgeCheckIcon v-show ="listUser.customClaims && listUser.customClaims.admin" class="h-4 w-4 ml-1 text-yellow-500" />
                      </div>
                      <div  class="flex justify-start items-center"><b>Provider:</b>
                        <div v-for="(provider,i) in listUser.providerData" :key="i" class="text-warm-gray-600 ml-2">  
                          <Envelope v-if="provider.providerId == 'password'" class="w-4 h-4" />
                          <Gmail v-else-if="provider.providerId == 'google.com'" class="w-4 h-4 ml-0.5"/>
                          <YahooMail v-else-if="provider.providerId == 'yahoo.com'"  class="w-5 h-5 ml-0.5"/>
                        </div>
                      </div>
                      <div class="flex justify-start items-center"><b>Purchased Reviewer:</b>
                        <div class="flex items-center justify-center ml-1">
                          <div v-show ="listUser.customClaims && listUser.customClaims.caap" class=" bg-blue-800 text-white text-xs mx-0.5 rounded-md py-0.5 px-2">caap</div>
                          <div v-show ="listUser.customClaims && listUser.customClaims.mtp" class=" bg-blue-800 text-white text-xs mx-0.5 rounded-md py-0.5 px-2">mtp</div>
                          <div v-show ="listUser.customClaims && listUser.customClaims.caap_lite" class=" bg-blue-800 text-white text-xs mx-0.5 rounded-md py-0.5 px-2">caap_lite</div>
                          <div v-show ="listUser.customClaims && listUser.customClaims.caap_plus" class=" bg-blue-800 text-white text-xs mx-0.5 rounded-md py-0.5 px-2">caap_plus</div>
                          <!-- <div v-show ="listUser.customClaims && listUser.customClaims.mtp" class="bg-warm-gray-600 text-white text-xs mx-1 rounded-md py-0.5 px-2">MTP</div> -->
                        </div>
                      </div>
                      <p><b>Created:</b> {{ $filters.formatDate(listUser.metadata.creationTime)}}</p>
                      <p><b>Signed in:</b> {{ $filters.formatDate(listUser.metadata.lastSignInTime)}}</p>

                      <div class=" border-t dark:border-warm-gray-700 flex justify-between items-center mt-2 pt-2">
                        <button v-if="!listUser.customClaims.admin" class="border dark:border-warm-gray-700 rounded px-2 py-1" @click="setAsAdmin(listUser)">Set as admin</button>
                        <button v-else class="border dark:border-warm-gray-700 rounded px-2 py-1" @click="removeAsAdmin(listUser)">Remove as admin</button>
                        <div class="flex justify-end items-center gap-2">
                          <button class="border dark:border-warm-gray-700 rounded px-2 py-1" @click="openDisableUserModal(listUser)">Disable</button>
                          <button class="border dark:border-warm-gray-700 rounded px-2 py-1" @click="deleteAccount(listUser)">Delete</button>
                        </div>
                      </div>

                    </div>
                  </td>
                </tr>

                <!-- for pc -->
                <tr v-if="!listUser.disabled" class=" hidden lg:table-row border-b hover:text-warm-gray-600 text-warm-gray-600 lg:text-white hover:bg-warm-gray-100 dark:text-warm-gray-900 dark:border-warm-gray-700 dark:hover:bg-warm-gray-800 dark:hover:text-warm-gray-200">
                    <td class="text-warm-gray-700 dark:text-warm-gray-200 py-1 text-left pl-2">
                      
                      <div class="flex items-center w-full justify-start">
                        <span v-if="listUser.presence && listUser.presence.status == 'online'" class="w-2 h-2 bg-green-400 rounded-full mr-0.5"></span>
                        <span v-if="listUser.presence && listUser.presence.status == 'offline'" class="w-2 h-2 bg-warm-gray-600 rounded-full mr-0.5"></span>
                        <span v-if="listUser.presence && listUser.presence.status == 'away'" class="w-2 h-2 bg-yellow-300 rounded-full mr-0.5"></span>
                        {{listUser.email}}
                        <BadgeCheckIcon v-show ="listUser.customClaims && listUser.customClaims.admin" class="h-4 w-4 ml-1 text-yellow-500" />
                        <span v-show="listUser.disabled" class="bg-warm-gray-300 text-warm-gray-500 text-xs px-1 rounded-md ml-1">disabled</span>


                        <!-- ({{  listUser.presence ? listUser.presence.status : ''}}) -->
                      </div>
                    </td>
                    
                    <td class="text-warm-gray-700 dark:text-warm-gray-200 py-1 flex justify-center items-center gap-2">
                      <div v-for="(provider,i) in listUser.providerData" :key="i" class="text-warm-gray-600">
                      
                        <Envelope v-if="provider.providerId == 'password'" class="w-4 h-4" />
                        <Gmail v-else-if="provider.providerId == 'google.com'" class="w-4 h-4"/>
                        <YahooMail v-else-if="provider.providerId == 'yahoo.com'"  class="w-5 h-5"/>
                      </div>
                    </td>
                  
                    <td class="py-1">
                      <div class="flex items-center justify-center">
                        <div v-show ="listUser.customClaims && listUser.customClaims.caap" class=" bg-blue-800 text-white text-xs mx-0.5 rounded-md py-0.5 px-2">caap</div>
                        <div v-show ="listUser.customClaims && listUser.customClaims.mtp" class=" bg-blue-800 text-white text-xs mx-0.5 rounded-md py-0.5 px-2">mtp</div>
                        <div v-show ="listUser.customClaims && listUser.customClaims.caap_lite" class=" bg-blue-800 text-white text-xs mx-0.5 rounded-md py-0.5 px-2">caap_lite</div>
                        <div v-show ="listUser.customClaims && listUser.customClaims.caap_plus" class=" bg-blue-800 text-white text-xs mx-0.5 rounded-md py-0.5 px-2">caap_plus</div>
                        <!-- <div v-show ="listUser.customClaims && listUser.customClaims.mtp" class="bg-warm-gray-600 text-white text-xs mx-1 rounded-md py-0.5 px-2">MTP</div> -->
                      </div>
                    </td>
                    <td class="text-warm-gray-700 dark:text-warm-gray-200 py-1 hidden lg:table-cell">{{ $filters.formatDate(listUser.metadata.creationTime)}}</td>
                    <td class="text-warm-gray-700 dark:text-warm-gray-200 py-1 hidden md:table-cell">{{ $filters.formatDate(listUser.metadata.lastSignInTime)}}</td>
                    <td class="py-1 flex items-center justify-evenly ">
                      <!-- <div class="has-tooltip">
                        <span class='tooltip rounded shadow-lg p-1 bg-warm-gray-600 text-xs text-warm-gray-300 -ml-6 mt-8'>Purchase</span>
                        <CashIcon @click="openPurchaseModal(listUser)" class="h-6 w-6  cursor-pointer" aria-hidden="true" />
                      </div>       -->
                      <!-- More Options -->
                      <Menu as="div" class="relative inline-block text-left">
                        <div>
                          <MenuButton class=" has-tooltip focus:outline-none flex items-center">
                              <span class='tooltip rounded shadow-md p-1 bg-warm-gray-600 text-xs  text-warm-gray-300 w-32 right-2 -mt-8'>View more options</span>
                              <DotsVerticalIcon class="h-6 w-6 cursor-pointer hover:text-warm-gray-700" aria-hidden="true" />
                          </MenuButton>
                        </div>

                        <transition
                          enter-active-class="transition duration-100 ease-out"
                          enter-from-class="transform scale-95 opacity-0"
                          enter-to-class="transform scale-100 opacity-100"
                          leave-active-class="transition duration-75 ease-in"
                          leave-from-class="transform scale-100 opacity-100"
                          leave-to-class="transform scale-95 opacity-0"
                        >
                          <MenuItems class="absolute right-0 w-32 mt-2 origin-top-right p-1 bg-white rounded-md shadow-lg focus:outline-none text-xs dark:bg-warm-gray-800 dark:text-warm-gray-200 z-50">                 
                            <MenuItem v-for="(option,i) in moreOptions" :key="i">
                              <button v-show="(option.hasCon == false) || (option.code == 'ue' && listUser.providerData.find(p=>p.providerId == 'password')) || (option.code == 'da' && !listUser.disabled) || (option.code == 'ea' && listUser.disabled) || (option.code == 'saa' && !(listUser.customClaims && listUser.customClaims.admin)) || (option.code == 'rfa' && (listUser.customClaims && listUser.customClaims.admin))" @click="option.func(listUser)" class="text-warm-gray-700 flex items-center w-full px-4 rounded py-2 hover:bg-warm-gray-200 dark:text-warm-gray-200 dark:hover:bg-warm-gray-700">
                                {{option.name}}
                              </button>
                            </MenuItem>
                          </MenuItems>
                        </transition>
                      </Menu>
                      <!-- ./ More Options -->

                    </td>
                </tr>
              </template>
            </tbody>
          </template>
          <template v-else>
            <tbody class="animate-pulse">
              <!-- for mobile -->
              <tr v-for="i in 3" :key="i" colspan="5" class=" lg:hidden">
                <td><div class=" h-36 bg-warm-gray-200 rounded-md m-2 p-2 "></div></td>
              </tr>
              <!-- for PC -->
              <tr v-for="i in 3" :key="i"  class="hidden lg:table-row">
                <td><div class="h-4 bg-warm-gray-200 rounded m-2"></div></td>
                <td><div class="h-4 bg-warm-gray-200 rounded m-2"></div></td>
                <td><div class="h-4 bg-warm-gray-200 rounded m-2"></div></td>
                <td><div class="h-4 bg-warm-gray-200 rounded m-2"></div></td>
                <td><div class="h-4 bg-warm-gray-200 rounded m-2"></div></td>
              </tr>
            </tbody>
          </template>
          <tfoot>
              <tr>
                <td colspan="5" class="p-2">
                <div class="flex justify-end px-4 text-xs text-warm-gray-600 dark:text-warm-gray-200">
                  <span class="mr-4">
                    Page {{curPageIndex + 1}} of {{users.length}}
                  </span>
                  <div class="grid grid-cols-2 gap-1">
                    <button @click="this.curPageIndex -= 1" :class="[curPageIndex > 0 ? 'text-warm-gray-700 dark:text-warm-gray-200':'text-warm-gray-400 dark:text-warm-gray-700 cursor-default', 'rounded-md focus:bg-warm-gray-200 focus:outline-none']" :disabled="!(curPageIndex > 0)">
                      <ChevronLeftIcon class="h-4 w-4" />
                    </button>
                    <button @click="this.curPageIndex += 1" :class="[curPageIndex < (users.length - 1) ? 'text-warm-gray-700 dark:text-warm-gray-200':'text-warm-gray-400 dark:text-warm-gray-700 cursor-default', 'rounded-md focus:bg-warm-gray-200 focus:outline-none']" :disabled="!(curPageIndex < (users.length - 1))">
                      <ChevronRightIcon class="h-4 w-4" />
                    </button>
                  </div>
                </div>
                </td>
              </tr>
          </tfoot>
      </table>
    </div>
  </div>

  <!-- Purchased Modal -->
  <TransitionRoot appear :show="isPurchasedModalOpen" as="template">
    <Dialog as="div" @close="closePurchasedModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">

          <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            >
            <div
              class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-800"
            >
              <DialogTitle
                as="h3"
                class="flex text-lg font-medium leading-6 text-warm-gray-700 pb-2 border-b dark:border-warm-gray-600 dark:text-warm-gray-200"
               >
               <CashIcon class="h-6 w-6  cursor-pointer hover:text-warm-gray-700 mr-2" aria-hidden="true" />
                Purchased Item
              </DialogTitle>
              <div class="mt-2 flex-row items-start text-warm-gray-700 pl-5 dark:text-warm-gray-400">
                <div class="flex mt-6">
                  <label class="flex items-center">
                    <input type="checkbox" class="form-checkbox" v-model="purchaseForm.reviewers.caap">
                    <span class="ml-2">CAAP</span>
                  </label>
                </div>
                 <div class="flex mt-2">
                  <label class="flex items-center">
                    <input type="checkbox" class="form-checkbox"  v-model="purchaseForm.reviewers.mtp">
                    <span class="ml-2">MTP</span>
                  </label>
                </div>
              </div>

              <div class="mt-4 border-t pt-2 flex justify-end dark:border-warm-gray-600">
                <button
                  type="button"
                  class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 dark:bg-warm-gray-700 dark:text-warm-gray-200 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600"
                  @click="closePurchasedModal"
                  >
                  Cancel
                </button>
                <button
                  type="button"
                  :class="[ isProcessing && 'cursor-wait', 'ml-2 flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md hover:bg-yellow-700 focus:bg-yellow-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500']"
                  @click="purchaseReviewersSaved"
                  :disable="isProcessing"
                  >
                  <SpinningIcon v-if="isProcessing" class="h-4 w-4 mr-1" />
                  Save
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Add User Modal -->
  <TransitionRoot appear :show="isAddUserModalOpen" as="template">
    <Dialog as="div" @close="closeAddUserModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">

          <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            >
            <div
              class="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-800"
            >
              <DialogTitle
                as="h3"
                class="flex items-center text-lg font-medium leading-6 text-white bg-yellow-600 p-6 pb-2"
               >
               <UserIcon class="h-6 w-6 mr-2" />
               Add new user
              </DialogTitle>
              <div class="mt-2 flex-row items-start text-warm-gray-700 p-6 dark:text-warm-gray-200">
                
                <!-- Error Display -->
                <div v-if="error.for == 'add-user'" class="rounded-md bg-yellow-50 p-4 mb-6">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <ExclamationIcon
                        class="h-5 w-5 text-yellow-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="ml-3 text-yellow-700">
                      <p class="font-bold">Error:</p>
                      <p class="text-sm">
                        <ul class="list-disc ml-5">
                          <li>{{error.errors[0]}}</li>
                        </ul>
                      </p>
                    </div>
                    <div class="ml-auto pl-3">
                      <div class="-mx-1.5 -my-1.5">
                        <button
                          type="button"
                          class="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yelow-50 focus:ring-yelow-600 ">
                          <span class="sr-only">Dismiss</span>
                          <XIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-full">
                  <label for="email">Email:</label>
                  <input type="email" id="email" v-model="newUserForm.email" class=" w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2" />
                </div>
                <div class="w-full mt-4">
                  <label for="password">Password:</label>
                  <input type="password" id="password" v-model="newUserForm.password" class=" w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2" />
                </div>
              </div>

              <div class="mt-4 border-t p-6 pt-2 flex justify-end dark:border-warm-gray-600">
                <button
                  type="button"
                  class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600 dark:text-warm-gray-200"
                  @click="closeAddUserModal"
                  >
                  Cancel
                </button>
                <button
                  @click="addUser"
                  type="button"
                  :class="[ isProcessing && 'cursor-wait', 'ml-2 flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md hover:bg-yellow-700 focus:bg-yellow-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500']"
                  :disable="isProcessing"
                  >
                  <SpinningIcon v-if="isProcessing" class="h-4 w-4 mr-1" />
                  Add user
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Disable User Reason Modal -->
  <TransitionRoot appear :show="isReasonModalOpen" as="template">
    <Dialog as="div"  @close="closeReasonModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">

          <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            >
            <div
              class="inline-block w-full max-w-3xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-800"
            >
              <DialogTitle
                as="h3"
                class="flex items-center text-lg font-medium leading-6 text-white bg-yellow-600 p-6 pb-2"
               >
               <UserIcon class="h-6 w-6 mr-2" />
               Disable user
              </DialogTitle>
              <div class="mt-2 flex-row items-start text-warm-gray-700 p-6 dark:text-warm-gray-200">
                
     

                <div class="w-full">
                  <p>Please write the reason below as to why you want to disable <code class=" bg-warm-gray-300 dark:bg-warm-gray-700">{{ this.toDisableUser.user.email }}</code> account.</p>
                  <textarea id="reason" rows="3" v-model="toDisableUser.reason" class=" w-full p-2 rounded border text-warm-gray-800 bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2" />
                </div>

              </div>

              <div class="mt-4 border-t p-6 pt-2 flex justify-end dark:border-warm-gray-600">
                <button
                  type="button"
                  class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600 dark:text-warm-gray-200"
                  @click="closeReasonModal"
                  >
                  Cancel
                </button>
                <button
                  @click="disableAccount(toDisableUser.user)"
                  type="button"
                  :class="[ isProcessing && 'cursor-wait', 'ml-2 flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md hover:bg-yellow-700 focus:bg-yellow-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500']"
                  :disable="isProcessing"
                  >
                  <SpinningIcon v-if="isProcessing" class="h-4 w-4 mr-1" />
                  Disable user
                </button>
              </div>
              
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Copy User Data Modal -->
  <TransitionRoot appear :show="isCopyDataModalOpen" as="template">
    <Dialog as="div" @close="closeCopyDataModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">

          <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            >
            <div
              class="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-800"
            >
              <DialogTitle
                as="h3"
                class="flex items-center text-lg font-medium leading-6 text-white bg-yellow-600 p-6 pb-2"
               >
               <DocumentDuplicateIcon class="h-6 w-6 mr-2" @click="transferUserAdvance = !transferUserAdvance" />
               Transfer user data
              </DialogTitle>
              <div class="mt-2 flex-row items-start text-warm-gray-700 p-6 dark:text-warm-gray-200">
                
                <!-- Error Display -->
                <div v-if="error.for == 'copy-data'" class="rounded-md bg-yellow-50 p-4 mb-6">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <ExclamationIcon
                        class="h-5 w-5 text-yellow-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="ml-3 text-yellow-700">
                      <p class="font-bold">Error:</p>
                      <p class="text-sm">
                        <ul class="list-disc ml-5">
                          <li>{{error.errors[0]}}</li>
                        </ul>
                      </p>
                    </div>
                    <div class="ml-auto pl-3">
                      <div class="-mx-1.5 -my-1.5">
                        <button
                          type="button"
                          class="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yelow-50 focus:ring-yelow-600 ">
                          <span class="sr-only">Dismiss</span>
                          <XIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-full">
                  <label for="emailFrom">From:</label>
                  <input type="email" id="emailFrom" v-model="copyData.fromEmail" class=" w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" placeholder="Enter email address" />
                  <p v-if="copyData.fromUid" class=" text-sm dark:text-yellow-600 text-yellow-700">{{copyData.fromUid}}</p>
                </div>
                <div class="w-full mt-4">
                  <label for="emailTo">To:</label>
                  <input type="email" id="emailTo" v-model="copyData.toEmail" class=" w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" placeholder="Enter email address" />
                  <p v-if="copyData.toUid" class=" text-sm dark:text-yellow-600 text-yellow-700">{{copyData.toUid}}</p>
                </div>

                <div class="mt-5">
                  <ul v-if="transferUserProgress.length > 0 || isTransferringUser" class=" text-xs text-yellow-700">
                    <li>
                        Total Docs: {{ transferUserDocs.totalDocs }} | Total Transferred: {{ transferUserDocs.totalTransferredDocs }} 
                        ( {{ transferUserDocs.totalDocs > 0 ? Math.floor((transferUserDocs.totalTransferredDocs / transferUserDocs.totalDocs) * 100) : 0 }}% Transferred )
                    </li>
                    <li v-for="progStat in transferUserProgress">
                    <span v-if="progStat.type == 'success'">✔️</span>
                    <span v-if="progStat.type == 'error'">❗</span>
                    <span v-if="progStat.type == 'warning'">⚠️</span>
                    {{progStat.message}}
                    </li>
                  </ul>
                  <p  v-if="isTransferringUser" class="text-yellow-600 mt-2 flex justify-start items-center">
                    <SpinningIcon class="h-4 w-4 mr-1" />
                    <small>Processing... </small>
                  </p>
                </div>

              </div>

              <div class="mt-4 border-t p-6 pt-2 flex justify-end dark:border-warm-gray-600">
                <button
                  type="button"
                  :class="isTransferringUser && 'cursor-wait'"
                  class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600 dark:text-warm-gray-200"
                  @click="closeCopyDataModal"
                  :disabled="isTransferringUser"
                  >
                  Cancel
                </button>
                <button
                  @click="copyUserData"
                  type="button"
                  :class="[ isTransferringUser && 'cursor-wait', 'ml-2 flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md hover:bg-yellow-700 focus:bg-yellow-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500']"
                  :disabled="isTransferringUser"
                  >
                  Start
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <ProcessingModal v-if="isProcessing" />

</template>

<script>
import {purchaseReviewers, setAdmin, deleteUser, disableUser, addUser, updateUser, sendSMS} from '@/firebase/controllers/userController';
import {transferUserData} from '@/firebase/utils/transferUserData';

import { collection, addDoc} from "firebase/firestore"; 
import { db } from '@/firebase/config.js';
import moment from "moment";

import {mapGetters} from 'vuex';
import store from '@/store';

import SpinningIcon from '@/components/SpinningIcon';
import ProcessingModal from '@/components/ProcessingModal';
import Envelope from '@/components/icons/Envelope'
import Gmail from '@/components/icons/Gmail'
import YahooMail from '@/components/icons/YahooMail'

// import {
//   CashIcon,
//   DotsVerticalIcon,
//   RefreshIcon,
//   ChevronLeftIcon,
//   ChevronRightIcon,
//   SearchIcon,
//   BadgeCheckIcon,
//   // for error alert
//   ExclamationIcon,
//   XIcon,
// } from "@heroicons/vue/outline";

// import {UserIcon} from "@heroicons/vue/solid";

import {
  BanknotesIcon as CashIcon,
  EllipsisVerticalIcon as DotsVerticalIcon,
  ArrowPathIcon as RefreshIcon,
  ChevronLeftIcon as ChevronLeftIcon,
  ChevronRightIcon as ChevronRightIcon,
  MagnifyingGlassIcon as SearchIcon,
  CheckBadgeIcon as BadgeCheckIcon,
  // for error alert
  ExclamationTriangleIcon as ExclamationIcon,
  XMarkIcon as XIcon,
  DocumentDuplicateIcon as DocumentDuplicateIcon
} from "@heroicons/vue/24/outline";

import {
  UserIcon as UserIcon,
} from "@heroicons/vue/24/solid";

import {
  // modal
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
  // menu dropdown
  Menu,
  MenuButton,
  MenuItems,
  MenuItem 
} from '@headlessui/vue';

import swal from 'sweetalert2';

// Init a timeout variable to be used below
let timeout = null;


export default {
  props: {
    users: Array,
    isUserRetrieving: Boolean,
    getUser: {
        type: Function
      }
  },
  data() {
    return {
      isPurchasedModalOpen: false,
      isAddUserModalOpen: false,
      isCopyDataModalOpen: false,
      isReasonModalOpen: false,
      isBusy: false,
      isProcessing: false,
      isTransferringUser: false,
      error:{
        for: '',
        errors:[]
      },
      sUser: {
        isSearch: false,
        value: '',
        result: []
      },
      // users: [],
      curPageIndex: 0,
      purchaseForm:{
        email: '',
        reviewers:{
          caap: false,
          mtp: false
        }
      },
      newUserForm:{
        email: '',
        password: ''
      },
      toDisableUser:{
        user: null,
        reason: '',
      },
      moreOptions:[
        // hasCon = hasCondition
        // {hasCon: false, code: 'rp', name: 'Reset password', func: this.resetPassword},
        {hasCon: true, code: 'ue', name: 'Update email', func: this.updateUser},
        {hasCon: true, code: 'da', name: 'Disable account', func: this.openDisableUserModal},
        {hasCon: true, code: 'ea', name: 'Enable account', func: this.enableAccount},
        {hasCon: false, code: 'dla', name: 'Delete account', func: this.deleteAccount},
        {hasCon: true, code: 'saa', name: 'Set as admin', func: this.setAsAdmin},
        {hasCon: true, code: 'rfa', name: 'Remove as admin', func: this.removeAsAdmin},
      ],
      copyData: {
        fromEmail: '',
        toEmail: '',
        fromUid: '',
        ToUid: ''
      },
      transferUserAdvance: false,
    }
  },
  components:{
    // heroicons
    CashIcon,
    DotsVerticalIcon,
    RefreshIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    SearchIcon,
    BadgeCheckIcon,
    UserIcon,
    DocumentDuplicateIcon,
    // for error alert
    ExclamationIcon,
    XIcon,
    // headless/ui (modal)
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    // headless/ui (menu dropdown)
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    // vue components
    SpinningIcon,
    ProcessingModal,
    Envelope,
    Gmail,
    YahooMail
  },
  computed:{
    ...mapGetters(['transferUserProgress', 'transferUserDocs'])
  },
  watch:{
    'copyData.fromEmail': function (newVal, oldVal) {
      //debounce
      clearTimeout(timeout);
      timeout = setTimeout(() => {        
        if (newVal) {
          // get the UID
          const uid = this.getUID(newVal);
          this.copyData.fromUid = uid;
        }
      }, 500);
    },
    'copyData.toEmail': function (newVal, oldVal) {
      //debounce
      clearTimeout(timeout);
      timeout = setTimeout(() => {        
        if (newVal) {
          // get the UID
          this.copyData.toUid = this.getUID(newVal);
        }
      }, 500);
    }
  },
  mounted() {

    // to show add user modal ctrl+alt+u(lowercase)
    document.addEventListener('keydown', (event) => {
      if (event.ctrlKey && event.altKey && event.key === 'u') {
        this.openAddUserModal();
      }
    });

  },
  
  methods: {
    getUID(email){

      let toRet = null;
      try {
        // loop thru users
        this.users.some(lu => {
          // loop thru listUsersResult.users
          lu.listUsersResult.users.some(u => {
            if(u.email == email){
              toRet = u.uid;
              throw BreakException;
            }
          })
        });
      }catch (error) {
        // nothing here...
        // this is just to break the foreach loop
      }
      return toRet;
    },
    openPurchaseModal(user){
      // open modal
      this.openPurchasedModal();
      // set modal data
      this.purchaseForm.email = user.email;
      this.purchaseForm.reviewers.caap = user.customClaims && user.customClaims.caap;
      this.purchaseForm.reviewers.mtp = user.customClaims && user.customClaims.mtp;
    },
    async purchaseReviewersSaved(){
      this.isProcessing = true;
      const res = await purchaseReviewers(this.purchaseForm);
      if(!res.error){
        this.getUser();
        this.closePurchasedModal();
        swal.fire(
            'Updated!',
            `For user ${this.purchaseForm.email}`,
            'success'
        );
      }
      this.isProcessing = false;
    },
    resetPassword(user){
      alert(`Sorry, unable to reset ${user.email} password. This function not yet implemented.`);
    },
    disableAccount(user){

      swal.fire({
        title: 'Disable account?',
        text: `Are you sure you want to disable user ${user.email}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#db912a',
        cancelButtonColor: '#4a4848',
        confirmButtonText: 'Yes, disable'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isProcessing = true;
          const res = await disableUser(user.uid, true);
          if(res.success){

            // add to disabledUsers collection
            await addDoc(collection(db, "disabledUsers"), {
              email: user.email,
              uid: user.uid,
              reason: this.toDisableUser.reason,
              date: moment().valueOf().toString()
            });

            this.getUser();
            swal.fire(
                'Success!',
                `${user.email} has been disabled.`,
                'success'
            );

          }
          // this.isReasonModalOpen = false;
          this.closeReasonModal()
          this.isProcessing = false;
        }
      });

    },
    enableAccount(user){

      swal.fire({
        title: 'Enable account?',
        text: `Are you sure you want to enable user ${user.email}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#db912a',
        cancelButtonColor: '#4a4848',
        confirmButtonText: 'Yes, enable'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isProcessing = true;
          const res = await disableUser(user.uid, false);
          if(res.success){

            this.getUser();
            swal.fire(
                'Success!',
                `${user.email} has been enabled.`,
                'success'
            );
          }
          this.isProcessing = false;
        }
      });

    },
    deleteAccount(user){

      swal.fire({
        title: 'Delete account?',
        text: `Are you sure you want to delete user ${user.email}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#db912a',
        cancelButtonColor: '#4a4848',
        confirmButtonText: 'Yes, delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isProcessing = true;
          const res = await deleteUser(user.uid);
          if(res.success){
            this.getUser();
            swal.fire(
                'Success!',
                `${this.purchaseForm.email} has been deleted.`,
                'success'
            );
          }
          this.isProcessing = false;
        }
      });

    },
    setAsAdmin(user){

      swal.fire({
        title: 'Set as admin?',
        text: `Are you sure you want to set user ${user.email} as admin?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#db912a',
        cancelButtonColor: '#4a4848',
        confirmButtonText: 'Yes, set as admin'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isProcessing = true;
          const res = await setAdmin(user.email, true);
          if(!res.error){
            this.getUser();
            swal.fire(
                'Success!',
                `${this.purchaseForm.email} has been added to admin.`,
                'success'
            );
          }
          this.isProcessing = false;
        }
      });

    },
    removeAsAdmin(user){

      swal.fire({
        title: 'Remove from admin?',
        text: `Are you sure you want to remove user ${user.email} from admin?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#db912a',
        cancelButtonColor: '#4a4848',
        confirmButtonText: 'Yes, remove'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isProcessing = true;
          const res = await setAdmin(user.email, false);
          if(!res.error){
            this.getUser();
            swal.fire(
                'Success!',
                `${this.purchaseForm.email} has been added to admin.`,
                'success'
            );
          }
          this.isProcessing = false;
        }
      });

    },
    async addUser(){
      this.isProcessing = true;
      const res = await addUser(this.newUserForm);
      if(!res.data.isError){
        this.getUser();
        this.closeAddUserModal();
        swal.fire(
            'Success!',
            `${this.newUserForm.email} has been added.`,
            'success'
        );
      }else{
        this.error.for = 'add-user';
        this.error.errors = [res.data.error.errorInfo?.message];
      }
      this.isProcessing = false;
    },

    async updateUser(user){

      const { value: email } = await swal.fire({
        title: 'Update email address',
        input: 'email',
        inputLabel: 'Enter new email address',
        inputPlaceholder: 'Enter email address',

      })

      if (email) {

        this.isProcessing = true;
        const res = await updateUser(user.uid, {email});
        if(res.success){
          this.getUser();
          swal.fire(
              'Success!',
              `${user.email} has been change to ${email}.`,
              'success'
          );
        }
        this.isProcessing = false;

      }

    },
    async sendSMS(){
      this.isProcessing = true;
      let to = ['+255766070318','+255746777880'];
      let body = 'Sample SMS Notification from Skilltech Team!';
      const res = await sendSMS(to, body);
      if(res.success){
        swal.fire(
            'Success!',
            `Successfully send SMS.`,
            'success'
        );
      }
      this.isProcessing = false;
    },
    searchUser(e){
      if(e.key == 'Enter'){
        if(this.sUser.value){
          this.sUser.isSearch = true;
          const foundUsers = [];
          // loop thru users
          this.users.forEach(lu => {
            // loop thru listUsersResult.users
            lu.listUsersResult.users.forEach(u=>{
              if(u.email.match(this.sUser.value)){
                foundUsers.push(u);
              }
            })
          });

          this.sUser.result = foundUsers;
            
        }else{
          this.sUser.isSearch = false;
          this.sUser.result = [];
        }
      }
    },
    async copyUserData(){

      this.error.for = '';
      this.error.errors = [];

      this.isTransferringUser = true;

      if(!this.copyData.fromEmail || !this.copyData.toEmail){
        this.error.for = 'copy-data';
        this.error.errors.push('Please fill out all fields.');
      }
 
      const res = await transferUserData(this.copyData)

      if(!res.error){
        this.getUser();
        // this.closeCopyDataModal();
        swal.fire(
            'Success!',
            `Successfully transfer data.`,
            'success'
        );
      }else{
        this.error.for = 'copy-data';
        this.error.errors.push(res.message);
      }
    

      this.isTransferringUser = false;
    },

    // for modal methods
    closePurchasedModal() {
      this.isPurchasedModalOpen = false;
    },
    openPurchasedModal() {
      this.isPurchasedModalOpen = true;
    },
    closeAddUserModal() {
      this.isAddUserModalOpen = false;
      this.newUserForm.email = '';
      this.newUserForm.password = '';
      this.error.for = '';
      this.error.errors = [];
    },
    openAddUserModal() {
      this.isAddUserModalOpen = true;
    },
    openDisableUserModal(user){
      this.isReasonModalOpen = true;
      this.toDisableUser.user = user;

    },
    closeReasonModal(){
      console.log('test ko 1')
      this.isReasonModalOpen = false;
      this.toDisableUser.reason = '';
      console.log('test ko 2')
    },
    async closeCopyDataModal() {

      if (!this.isTransferringUser) {
        this.isCopyDataModalOpen = false;
        this.copyData.fromEmail = '';
        this.copyData.toEmail = '';
        this.copyData.fromUid = '';
        this.copyData.toUid = '';
        this.error.for = '';
        this.error.errors = [];
  
        await store.dispatch('clearTransferUserProgress');
        await store.dispatch('resetTransferUserTotalDocs');
        await store.dispatch('resetTransferUserTransferredDocs');
      }


    },
    openCopyDataModal() {
      this.isCopyDataModalOpen = true;
    },
  },
  
}
</script>