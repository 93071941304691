// CouponService.js
import { v4 } from 'uuid'
import { db, auth } from '@/firebase/config.js'
import { collection, query, setDoc, addDoc, getDocs, getDoc, doc, updateDoc, deleteDoc, where, orderBy, limit, Timestamp } from "firebase/firestore"

const CouponStatus = {
    ACTIVE: "active",
    ASSIGNED: "assigned",
    USED: "used",
    DEACTIVATED: "deactivated"
};

/**
 * Generates a new coupon with a unique code and other specified attributes.
 * @param {Object} couponDetails - The details of the coupon to be created.
 * 
 * Notes by @john :
 *  ✅ Done with function
 */
function createCoupon(couponDetails) {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(`%c🎫 ----------- createCoupon 🏁 -----------`, 'background: #222; color: #bada55')

            var coupon = {
                code: v4().split('-')[0].toUpperCase(), // take the first section of the uuid which is an 8 hex digit. 4,294,967,296 possible combinations.
                status: CouponStatus.ACTIVE,   // status is active by default
                discountAmount: couponDetails.discountAmount,
                expiryDate: couponDetails.expiryDate,
                tags: [],

                /**
                 * `created` metadata.
                 * 
                 * Should be current logged in admin.
                 * Include Email for easy UI display later.
                 */
                createdAt: new Date(),
                createdById: auth.currentUser.uid,
                createdByEmail: auth.currentUser.email, // current logged in user which is an admin

                /**
                 * `assigned` metadata.
                 * 
                 * A coupon can be assigned on create. However, it can also be done later
                 * If there's no assigned user passed on crease, set to null
                 */
                assignedAt: (couponDetails.assignedToId) ? new Date() : null,
                assignedToId: (couponDetails.assignedToId) ? couponDetails.assignedToId : null,
                assignedToEmail: (couponDetails.assignedToEmail) ? couponDetails.assignedToEmail : null,

                /**
                 * `used` metadata.
                 * 
                 * A coupon can be used by a user on purchase later.
                 */
                usedAt: null,
                usedById: null,
                usedByEmail: null,
            }

            /**
             * For better error handling, check first if there are any duplicate coupon codes.
             * Throw an error if a duplicate coupon is found.
             */
            console.log(`🎫 Checking for any duplicate for the coupon code "${coupon.code}" before proceeding.`)
            const existingCouponQuery = query(collection(db, 'coupons'), where('code', '==', coupon.code))
            const existingCouponSnapshot = await getDocs(existingCouponQuery)
            if (!existingCouponSnapshot.empty) throw new Error('Duplicate coupon code found.')

            /**
             * Otherwise, proceed normally with coupon creation
             */
            let docRef = await addDoc(collection(db, 'coupons'), coupon);
            console.log(`🎫 No duplicates found. Creating coupon...`)
            console.log(`🎫 Coupon with the code "${coupon.code}" has been created successfully with the following ID: "${docRef.id}"`)

            /**
             * Make sure to log this action for coupon audit.
             * @TODO This should be automated through Firebase Functions
             * everytime a new item is created in the coupons collection.
             */
            await recordCouponAction({
                actionType: 'createCoupon',
                details: 'Coupon was created.',
                couponId: docRef.id,
                actorId: coupon.createdById,
                actorEmail: coupon.createdByEmail,
                timestamp: coupon.createdAt,
                beforeState: null,
                afterState: null,
            })

            /**
             * We will try to query the created coupon from the database
             * using the returned ID. If found, we return it to the UI.
             */
            let result = await readCoupon(docRef.id)
            resolve(result)

        } catch (error) {
            console.error(`🎫 An error occurred while creating the coupon with coupon code: "${coupon.code}".`, error)
            reject(error)
        }
    })
}

/**
 * Retrieves the details of a specific coupon using its unique code.
 * @param {String} couponId - The coupon id of the coupon to read.
 * 
 * Notes by @john :
 *  ✅ Done with function
 */
function readCoupon(couponId) {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(`%c🎫 ----------- readCoupon 🏁 -----------`, 'background: #222; color: #bada55')
            console.log(`🎫 Reading coupon from the DB with ID: "${couponId}"...`)

            const docRef = doc(db, "coupons", couponId);
            const docSnap = await getDoc(docRef);

            if (!docSnap.exists()) throw new Error(`The coupon with ID "${couponId}" was not found.`)
            console.log(`🎫 Coupon with ID "${couponId}" was successfully found: `, docSnap.data())

            resolve({
                id: docSnap.id,
                data: docSnap.data()
            })

        } catch (error) {
            console.error(`🎫 An error occurred while retreiving the coupon with coupon ID "${couponId}" from the DB.`, error)
            reject(error)
        }
    })
}

/**
 * Removes an unused coupon from the database.
 * @param {String} coupon - The coupon object
 * 
 * Notes by @john :
 *  ✅ Done with function
 */
function deleteCoupon(coupon) {
    return new Promise(async (resolve, reject) => {
        try {
            if (coupon.data.status !== CouponStatus.USED || auth.currentUser.email === "root@skilltech.team") {
                console.log(`%c🎫 ----------- deleteCoupon 🏁 -----------`, 'background: #222; color: #bada55')
                console.log(`🎫 Checking if coupon with ID: ${coupon.id} exists before deleting.`)
                await readCoupon(coupon.id)

                console.log(`🎫 Coupon was found. Deleting coupon from the DB with ID: "${coupon.id}"...`)
                const docRef = doc(db, "coupons", coupon.id)
                await deleteDoc(docRef)

                /**
                 * Make sure to log this action for coupon audit.
                 * @TODO This should be automated through Firebase Functions
                 * everytime a new item is created in the coupons collection.
                 */
                await recordCouponAction({
                    actionType: 'delete',
                    details: 'Coupon was deleted.',
                    couponId: docRef.id,
                    actorId: auth.currentUser.uid,
                    actorEmail: auth.currentUser.email,
                    timestamp: new Date(),
                    beforeState: null, // @TODO
                    afterState: null, // @TODO
                })

                console.log(`🎫 Coupon with ID "${coupon.id}" was successfully deleted.`)
                resolve(true)
            }else{
                throw new Error(`The coupon can only deleted if it is not yet used or the action is made by a super-admin.`)
            }

        } catch (error) {
            console.error(`🎫 An error occurred while deleting the coupon with coupon ID "${coupon.id}" from the DB.`, error)
            reject(error)
        }
    })
}

/**
 * Generic method for updating various attributes of a specific coupon.
 * @param {String} couponId - The coupon id of the coupon to update.
 * @param {Object} updateDetails - The details to update in the coupon.
 */
function updateCoupon(couponId, updateDetails, action) {
    return new Promise(async (resolve, reject) => {
        try {
            const docRef = doc(db, "coupons", couponId)
            await updateDoc(docRef, updateDetails)

            /**
            * Make sure to log this action for coupon audit.
            * @TODO This should be automated through Firebase Functions
            * everytime a new item is created in the coupons collection.
            */
            await recordCouponAction({
                actionType: 'updateCoupon',
                details: `Coupon was ${action}.`,
                couponId: docRef.id,
                actorId: auth.currentUser.uid,
                actorEmail: auth.currentUser.email,
                timestamp: new Date(),
                beforeState: null, // @TODO
                afterState: null, // @TODO
            })

            /**
             * We will try to query the updated coupon from the database
             * using the returned ID. If found, we return it to the UI.
             */
            let result = await readCoupon(docRef.id)
            resolve(result)

        } catch (error) {
            console.error(`🎫 An error occurred while updating the coupon with coupon ID "${couponId}" from the DB.`, error)
            reject(error)
        }
    })
}


/**
 * Changes the expiration date of a coupon.
 * @param {String} couponCode - The unique code of the coupon.
 * @param {Date} newExpiryDate - The new expiration date for the coupon.
 */
function modifyCouponExpiry(couponCode, newExpiryDate) {
    return new Promise((resolve, reject) => {
        try {
            const results = doSomethingAsync()
            console.log('🎫 [FUNCTION_NAME SUCCESSFUL]')
            resolve(results)
        } catch (error) {
            console.error('[ERROR ON FUNCTION_NAME]', error)
            reject(error)
        }
    })
}

/**
 * Adjusts the discount value of a coupon.
 * @param {String} couponCode - The unique code of the coupon.
 * @param {Number} newDiscountAmount - The new discount amount for the coupon.
 */
function modifyCouponDiscount(couponCode, newDiscountAmount) {
    return new Promise((resolve, reject) => {
        try {
            const results = doSomethingAsync()
            console.log('🎫 [FUNCTION_NAME SUCCESSFUL]')
            resolve(results)
        } catch (error) {
            console.error('[ERROR ON FUNCTION_NAME]', error)
            reject(error)
        }
    })
}

/**
 * Updates the tags associated with a coupon for categorization or special campaigns.
 * @param {String} couponCode - The unique code of the coupon.
 * @param {Array} newTags - The new set of tags for the coupon.
 */
function modifyCouponTags(couponCode, newTags) {
    return new Promise((resolve, reject) => {
        try {
            const results = doSomethingAsync()
            console.log('🎫 [FUNCTION_NAME SUCCESSFUL]')
            resolve(results)
        } catch (error) {
            console.error('[ERROR ON FUNCTION_NAME]', error)
            reject(error)
        }
    })
}

/**
 * 🎫 This section is where the status of the coupon can be modified.
 */
async function activateCoupon(coupon) {
    return new Promise(async (resolve, reject) => {
        try {
            if(checkCouponIfExpired(coupon)) throw new Error(`The coupon already expired.`)
            if (coupon.data.status === CouponStatus.DEACTIVATED) {
                /**
                 * if the coupon has an existing 'assignedToId',
                 * then it was already assigned before it was deactivated.
                 * In this case, set status to 'assigned' instead of 'active'.
                 */
                let newStatus = (coupon.data.assignedToId != null) ? CouponStatus.ASSIGNED : CouponStatus.ACTIVE
                let updatedCouponData = { status: newStatus }
                const docRef = doc(db, "coupons", coupon.id)
                await updateDoc(docRef, updatedCouponData)
                await recordCouponAction({
                    actionType: 'activateCoupon',
                    details: `Coupon was activated.`,
                    couponId: coupon.id,
                    actorId: auth.currentUser.uid,
                    actorEmail: auth.currentUser.email,
                    timestamp: new Date(),
                    beforeState: { status: coupon.data.status },
                    afterState: { status: updatedCouponData.status },
                })
                resolve(await readCoupon(docRef.id))
            }else{
                throw new Error(`The coupon can only activated if it is deactivated.`)
            }
        } catch (error) {
            console.error(`🎫 An error occurred while activating coupon with ID "${coupon.id}".`, error)
            reject(error)
        }
    })
}

async function deactivateCoupon(coupon) {
    return new Promise(async (resolve, reject) => {
        try {
            if(checkCouponIfExpired(coupon)) throw new Error(`The coupon already expired.`)
            if (coupon.data.status === CouponStatus.ACTIVE || coupon.data.status === CouponStatus.ASSIGNED) {
                let updatedCouponData = { status: CouponStatus.DEACTIVATED }
                const docRef = doc(db, "coupons", coupon.id)
                await updateDoc(docRef, updatedCouponData)
                await recordCouponAction({
                    actionType: 'deactivateCoupon',
                    details: `Coupon was deactivated.`,
                    couponId: coupon.id,
                    actorId: auth.currentUser.uid,
                    actorEmail: auth.currentUser.email,
                    timestamp: new Date(),
                    beforeState: { status: coupon.data.status },
                    afterState: { status: updatedCouponData.status },
                })
                resolve(await readCoupon(docRef.id))
            }else{
                throw new Error(`The coupon can only deactivated if it is active or assigned.`)
            }
        } catch (error) {
            console.error(`🎫 An error occurred while deactivating coupon with ID "${coupon.id}".`, error)
            reject(error)
        }
    })
}

async function assignCoupon(coupon, user) {
    return new Promise(async (resolve, reject) => {
        try {
            if(checkCouponIfExpired(coupon)) throw new Error(`The coupon already expired.`)
            // if (coupon.data.status === CouponStatus.ACTIVE || coupon.data.status === CouponStatus.ASSIGNED) {
                let updatedCouponData = { 
                    status: CouponStatus.ASSIGNED,
                    assignedAt: new Date(),
                    assignedToId: user.id,
                    assignedToEmail: user.email,
                    assignedToEndorserCode: user.endorserCode,
                }
                const docRef = doc(db, "coupons", coupon.id)
                await updateDoc(docRef, updatedCouponData)
                await recordCouponAction({
                    actionType: 'assignCoupon',
                    details: `Coupon was assigned.`,
                    couponId: coupon.id,
                    actorId: auth.currentUser.uid,
                    actorEmail: auth.currentUser.email,
                    timestamp: new Date(),
                    beforeState: {
                        status: coupon.data.status,
                        assignedAt: coupon.data.assignedAt,
                        assignedToId: coupon.data.assignedToId,
                        assignedToEmail: coupon.data.assignedToEmail
                    },
                    afterState: {
                        status: updatedCouponData.status,
                        assignedAt: updatedCouponData.assignedAt,
                        assignedToId: updatedCouponData.assignedToId,
                        assignedToEmail: updatedCouponData.assignedToEmail
                    },
                })
                resolve(await readCoupon(docRef.id))
            // }else{
            //     throw new Error(`The coupon can only assigned if it is active or already assigned.`)
            // }
        } catch (error) {
            console.error(`🎫 An error occurred while assigning coupon with ID "${coupon.id}".`, error)
            reject(error)
        }
    })
}

async function unassignCoupon(coupon) {
    return new Promise(async (resolve, reject) => {
        try {
            if(checkCouponIfExpired(coupon)) throw new Error(`The coupon already expired.`)
            if (coupon.data.status === CouponStatus.ASSIGNED) {
                let updatedCouponData = { 
                    status: CouponStatus.ACTIVE, // set back to active
                    assignedAt: null,
                    assignedToId: null,
                    assignedToEndorserCode: null,
                    assignedToEmail: null
                }
                const docRef = doc(db, "coupons", coupon.id)
                await updateDoc(docRef, updatedCouponData)
                await recordCouponAction({
                    actionType: 'unassignCoupon',
                    details: `Coupon was unassigned.`,
                    couponId: coupon.id,
                    actorId: auth.currentUser.uid,
                    actorEmail: auth.currentUser.email,
                    timestamp: new Date(),
                    beforeState: {
                        status: coupon.data.status,
                        assignedAt: coupon.data.assignedAt,
                        assignedToId: coupon.data.assignedToId,
                        assignedToEndorserCode: coupon.data.assignedToEndorserCode,
                        assignedToEmail: coupon.data.assignedToEmail
                    },
                    afterState: {
                        status: updatedCouponData.status,
                        assignedAt: updatedCouponData.assignedAt,
                        assignedToId: updatedCouponData.assignedToId,
                        assignedToEndorserCode: updatedCouponData.assignedToEndorserCode,
                        assignedToEmail: updatedCouponData.assignedToEmail
                    },
                })
                resolve(await readCoupon(docRef.id))
            }else{
                throw new Error(`The coupon can only be unassigned if it is already assigned.`)
            }
        } catch (error) {
            console.error(`🎫 An error occurred while unassigning coupon with ID "${coupon.id}".`, error)
            reject(error)
        }
    })
}

async function useCoupon(coupon, user) {
    return new Promise(async (resolve, reject) => {
        try {

            if(checkCouponIfExpired(coupon)) throw new Error(`The coupon already expired.`)
            if(coupon.data.status === CouponStatus.USED) throw new Error(`The coupon is already in use.`)

            if ((coupon.data.status === CouponStatus.ACTIVE || coupon.data.status === CouponStatus.ASSIGNED)) {
                let updatedCouponData = { 
                    status: CouponStatus.USED, // set back to active
                    usedAt: new Date(),
                    usedByEmail: user.email,
                    usedById: user.id
                }
                const docRef = doc(db, "coupons", coupon.id)
                await updateDoc(docRef, updatedCouponData)
                await recordCouponAction({
                    actionType: 'useCoupon',
                    details: `Coupon was used.`,
                    couponId: coupon.id,
                    actorId: auth.currentUser.uid,
                    actorEmail: auth.currentUser.email,
                    timestamp: new Date(),
                    beforeState: {
                        status: coupon.data.status,
                        usedAt: coupon.data.usedAt,
                        usedById: coupon.data.usedById,
                        usedByEmail: coupon.data.usedByEmail,
                    },
                    afterState: {
                        status: updatedCouponData.status,
                        usedAt: updatedCouponData.usedAt,
                        usedById: updatedCouponData.usedById,
                        usedByEmail: updatedCouponData.usedByEmail,
                    },
                })
                resolve(await readCoupon(docRef.id))
            }else{
                throw new Error(`The coupon can only be used if it is active, assigned and not expired.`)
            }
        } catch (error) {
            console.error(`🎫 An error occurred while using coupon with ID "${coupon.id}".`, error)
            reject(error)
        }
    })
}

/**
 * "Un-use" a coupon. Testing only.
 */
async function releaseCoupon(coupon) {
    return new Promise(async (resolve, reject) => {
        try {

            if(checkCouponIfExpired(coupon)) throw new Error(`The coupon already expired.`)
            if (coupon.data.status === CouponStatus.USED) {
                let updatedCouponData = { 
                    status: CouponStatus.ACTIVE,
                    usedAt: null,
                    usedByEmail: null,
                    usedById: null
                }
                const docRef = doc(db, "coupons", coupon.id)
                await updateDoc(docRef, updatedCouponData)
                await recordCouponAction({
                    actionType: 'useCoupon',
                    details: `Coupon was used.`,
                    couponId: coupon.id,
                    actorId: auth.currentUser.uid,
                    actorEmail: auth.currentUser.email,
                    timestamp: new Date(),
                    beforeState: {
                        status: coupon.data.status,
                        usedAt: coupon.data.usedAt,
                        usedById: coupon.data.usedById,
                        usedByEmail: coupon.data.usedByEmail,
                    },
                    afterState: {
                        status: updatedCouponData.status,
                        usedAt: updatedCouponData.usedAt,
                        usedById: updatedCouponData.usedById,
                        usedByEmail: updatedCouponData.usedByEmail,
                    },
                })
                resolve(await readCoupon(docRef.id))
            }else{
                throw new Error(`The coupon can only be used if it is active, assigned and not expired.`)
            }
        } catch (error) {
            console.error(`🎫 An error occurred while using coupon with ID "${coupon.id}".`, error)
            reject(error)
        }
    })
}

async function checkCouponValidity(coupon) {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(`🎫 Checking validity of coupon with code: "${coupon.data.code}".`)
            if(checkCouponIfExpired(coupon)) throw new Error(`The coupon is expired.`)
            if(coupon.data.status === CouponStatus.USED) throw new Error(`The coupon is already in use.`)
            if(coupon.data.status === CouponStatus.DEACTIVATED) throw new Error(`The coupon is not active.`)
            console.log(`🎫 Coupon with code: "${coupon.data.code}" is valid.`)
            resolve(true)
        } catch (error) {
            console.error(`🎫 An error occurred while checking validity of coupon withe code: "${coupon.data.code}".`, error)
            reject(error)
        } finally {
            await recordCouponAction({
                actionType: 'checkCouponValidity',
                details: 'A coupon code was checked if it\'s valid.',
                couponId: coupon.id,
                actorId: auth.currentUser.uid,
                actorEmail: auth.currentUser.email,
                timestamp: new Date(),
                beforeState: {
                    code: coupon.data.code
                },
                afterState: null,
            })
        }
    })
}

async function checkCouponCodeIfExists(couponCode) {
    return new Promise(async (resolve, reject) => {
        try {
            couponCode = couponCode.toUpperCase()
            console.log(`🎫 Checking if coupon with code: "${couponCode}" exists.`)
            const existingCouponQuery = query(collection(db, 'coupons'), where('code', '==', couponCode))
            const existingCouponSnapshot = await getDocs(existingCouponQuery)
            if (existingCouponSnapshot.empty){
                console.warn(`🎫 No coupon with code: "${couponCode}" was found.`)
                throw new Error(`The coupon is not found.`)
            }else{
                console.log(`🎫 Coupon with code: "${couponCode}" was found.`)
                resolve({
                    id: existingCouponSnapshot.docs[0].id,
                    data: existingCouponSnapshot.docs[0].data()
                })
            }
        } catch (error) {
            console.error(`🎫 An error occurred while using coupon with ID "${couponCode}".`, error)
            reject(error)
        } finally {
            await recordCouponAction({
                actionType: 'checkCouponCodeIfExists',
                details: 'A coupon code was checked if it exists.',
                couponId: null,
                actorId: auth.currentUser.uid,
                actorEmail: auth.currentUser.email,
                timestamp: new Date(),
                beforeState: {
                    code: couponCode
                },
                afterState: null,
            })
        }
    })
}

// utility 
function checkCouponIfExpired(coupon) {
    const currentDate = new Date()
    const currentDateTs = Timestamp.fromDate(currentDate)
    const expirationDateTs = coupon.data.expiryDate
    return (currentDateTs.seconds > expirationDateTs.seconds)
}

/**
 * Retrieves a set of coupons, with an optional limit for pagination.
 * @param {Object} queryOptions - The options for filtering and sorting the list of coupons.
 */
function listCoupons(queryOptions) {
    return new Promise((resolve, reject) => {
        try {
            const results = doSomethingAsync()
            console.log('🎫 [FUNCTION_NAME SUCCESSFUL]')
            resolve(results)
        } catch (error) {
            console.error('[ERROR ON FUNCTION_NAME]', error)
            reject(error)
        }
    })
}


/**
 * Retrieves all coupons from the database.
 * 
 * Notes by @john :
 *  ✅ Done with function
 */
function listAllCoupons() {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(`%c🎫 ----------- listAllCoupons 🏁 -----------`, 'background: #222; color: #bada55')
            console.log(`🎫 Retreiving all coupons from the DB...`)
            const q = query(collection(db, "coupons"), orderBy("createdAt", "desc")); // latest first
            const qSnap = await getDocs(q);
            const coupons = [];
            qSnap.forEach((doc) => {
                let data = {
                    id: doc.id,
                    data: doc.data()
                }
                coupons.push(data)
            });
            console.log(`🎫 All coupons have been retreived successfully.`, coupons)
            resolve(coupons)
        } catch (error) {
            console.error(`🎫 An error occurred while retreiving all coupons.`, error)
            reject(error)
        }
    })
}

function listCouponLogs(queryLimit) {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(`%c🎫 ----------- listCouponLogs 🏁 -----------`, 'background: #222; color: #bada55')
            console.log(`🎫 Retreiving ${queryLimit} coupon logs from the DB...`)
            // let q = query(collection(db, "users"), orderBy('date_created'), where('date_created', '>', sixMonthsAgo.toString()), limit(queryLimit));
            const q = query(collection(db, "coupon_actions"), orderBy("timestamp", "desc"), limit(queryLimit)); // latest first
            const qSnap = await getDocs(q);
            const couponLogs = [];
            qSnap.forEach((doc) => {
                let data = {
                    id: doc.id,
                    data: doc.data()
                }
                couponLogs.push(data)
            });
            console.log(`🎫 ${queryLimit} coupons logs have been retreived successfully.`, couponLogs)
            resolve(couponLogs)
        } catch (error) {
            console.error(`🎫 An error occurred while retreiving coupon logs.`, error)
            reject(error)
        }
    })
}

/**
 * Retrieves coupons filtered by their active/inactive status.
 * @param {Boolean} status - The status to filter the coupons by.
 */
function listCouponsByStatus(status) {
    return new Promise((resolve, reject) => {
        try {
            const results = doSomethingAsync()
            console.log('🎫 [FUNCTION_NAME SUCCESSFUL]')
            resolve(results)
        } catch (error) {
            console.error('[ERROR ON FUNCTION_NAME]', error)
            reject(error)
        }
    })
}

/**
 * Retrieves coupons sorted by their expiration date.
 */
function listCouponsByExpiry() {
    return new Promise((resolve, reject) => {
        try {
            const results = doSomethingAsync()
            console.log('🎫 [FUNCTION_NAME SUCCESSFUL]')
            resolve(results)
        } catch (error) {
            console.error('[ERROR ON FUNCTION_NAME]', error)
            reject(error)
        }
    })
}

/**
 * Retrieves coupons associated with a specific endorser.
 * @param {String} endorserId - The ID of the endorser whose coupons to retrieve.
 */
function listCouponsByEndorser(endorserId) {
    return new Promise((resolve, reject) => {
        try {
            const results = doSomethingAsync()
            console.log('🎫 [FUNCTION_NAME SUCCESSFUL]')
            resolve(results)
        } catch (error) {
            console.error('[ERROR ON FUNCTION_NAME]', error)
            reject(error)
        }
    })
}

/**
 * Retrieves coupons sorted by their creation date.
 */
function listCouponsByCreationDate() {
    return new Promise((resolve, reject) => {
        try {
            const results = doSomethingAsync()
            console.log('🎫 [FUNCTION_NAME SUCCESSFUL]')
            resolve(results)
        } catch (error) {
            console.error('[ERROR ON FUNCTION_NAME]', error)
            reject(error)
        }
    })
}

/**
 * Retrieves coupons sorted by the amount of discount they offer.
 */
function listCouponsByDiscount() {
    return new Promise((resolve, reject) => {
        try {
            const results = doSomethingAsync()
            console.log('🎫 [FUNCTION_NAME SUCCESSFUL]')
            resolve(results)
        } catch (error) {
            console.error('[ERROR ON FUNCTION_NAME]', error)
            reject(error)
        }
    })
}

/**
 * General method for sorting coupons by any given attribute.
 * @param {String} attribute - The attribute to sort the coupons by.
 * @param {String} order - The order to sort the coupons ('asc' or 'desc').
 */
function sortByAttribute(attribute, order) {
    return new Promise((resolve, reject) => {
        try {
            const results = doSomethingAsync()
            console.log('🎫 [FUNCTION_NAME SUCCESSFUL]')
            resolve(results)
        } catch (error) {
            console.error('[ERROR ON FUNCTION_NAME]', error)
            reject(error)
        }
    })
}



/**
 * Extends the expiration date of a coupon.
 * @param {String} couponCode - The unique code of the coupon.
 * @param {Date} newExpiryDate - The new expiration date for the coupon.
 */
function extendCouponExpiry(couponCode, newExpiryDate) {
    return new Promise((resolve, reject) => {
        try {
            const results = doSomethingAsync()
            console.log('🎫 [FUNCTION_NAME SUCCESSFUL]')
            resolve(results)
        } catch (error) {
            console.error('[ERROR ON FUNCTION_NAME]', error)
            reject(error)
        }
    })
}

/**
 * Retrieves usage details of a coupon, including which user used it and the purchase it was applied to.
 * @param {String} couponCode - The unique code of the coupon.
 */
function trackCouponUsage(couponCode) {
    return new Promise((resolve, reject) => {
        try {
            const results = doSomethingAsync()
            console.log('🎫 [FUNCTION_NAME SUCCESSFUL]')
            resolve(results)
        } catch (error) {
            console.error('[ERROR ON FUNCTION_NAME]', error)
            reject(error)
        }
    })
}

/**
 * Logs any action taken on a coupon for audit purposes.
 * @param {Object} actionDetails - The details of the action taken on the coupon.
 * 
 * Notes by @john :
 *  ✅ Done with function
 */
function recordCouponAction(actionDetails) {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(`%c🎫 ----------- recordCouponAction 🏁 -----------`, 'background: #222; color: #bada55')
            console.log(`🎫 Recording coupon action for audit purposes.`)
            await setDoc(doc(collection(db, 'coupon_actions')), actionDetails);
            console.log(`🎫 Coupon action has been recorded successfully.`)
            resolve(true)
        } catch (error) {
            console.error(`🎫 An error occurred while recording the coupon action: "${actionDetails.couponCode}".`, error)
            reject(error)
        }
    })
}


/**
 * Helper functions
 */
const generateCouponExpiry = (durationInDays) => {
    durationInDays = 30; // 30 days default. Ideally, it should be set by user
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() + durationInDays)
    const expiryTimestamp = Timestamp.fromDate(currentDate)
    return expiryTimestamp
}

function convertTimestampToDate(t) {

    if (!t) return 'NA'
    // Returned Format: 2023-11-29 08:53 PM
    const timestamp = new Timestamp(t.seconds, t.nanoseconds)
    const date = timestamp.toDate()

    const year = date.getFullYear()
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const month = monthNames[date.getMonth()] // Use this for month names
    // const month = String(date.getMonth() + 1).padStart(2, '0'); // Use this if months will be 0-based formatted
    const day = String(date.getDate()).padStart(2, '0')
    const hours = date.getHours()
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    let formattedHours = hours % 12 || 12 // Convert to 12-hour format
    formattedHours = String(formattedHours).padStart(2, '0') // Add leading zero if needed
    const ampm = hours >= 12 ? 'PM' : 'AM'

    return `${year}-${month}-${day} ${formattedHours}:${minutes}:${seconds} ${ampm}`
}

// Export the functions to be available for import in other modules
export {
    createCoupon,
    readCoupon,
    deleteCoupon,
    updateCoupon,
    modifyCouponExpiry,
    modifyCouponDiscount,
    modifyCouponTags,
    listCoupons,
    listAllCoupons,
    listCouponsByStatus,
    listCouponsByExpiry,
    listCouponsByEndorser,
    listCouponsByCreationDate,
    listCouponsByDiscount,
    listCouponLogs,
    sortByAttribute,
    extendCouponExpiry,
    trackCouponUsage,
    recordCouponAction,
    // coupon status functions
    activateCoupon,
    deactivateCoupon,
    assignCoupon,
    unassignCoupon,
    useCoupon,
    releaseCoupon,
    // utility functions
    checkCouponIfExpired,
    checkCouponCodeIfExists,
    checkCouponValidity,
    convertTimestampToDate
}
