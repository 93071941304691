// EndorserService.js
import { v4 } from 'uuid'
import { db, auth } from '@/firebase/config.js'
import { collection, query, getDocs } from "firebase/firestore"

/**
 * Retrieves all endorser codes from the database.
 * 
 * Notes by @john :
 *  ✅ Done with function
 */
function listAllEndorserCodes() {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(`%c🎫 ----------- listAllEndorsers 🏁 -----------`, 'background: #222; color: #bada55')
            console.log(`🎫 Retreiving all endorser codes from the DB...`)
            const q = query(collection(db, "_endorser_codes")); // latest first
            const qSnap = await getDocs(q);
            const coupons = [];
            qSnap.forEach((doc) => {
                let data = {
                    id: doc.id,
                    data: doc.data()
                }
                coupons.push(data)
            });
            console.log(`🎫 All endorser codes have been retreived successfully.`, coupons)
            resolve(coupons)
        } catch (error) {
            console.error(`🎫 An error occurred while retreiving all endorser codes.`, error)
            reject(error)
        }
    })
}

// Export the functions to be available for import in other modules
export {
    listAllEndorserCodes
}
