<template>
  <div class="flex-row border-b dark:border-warm-gray-700 p-8">
    <h2 class="text-xl font-extrabold text-warm-gray-700 tracking-wider mb-8 dark:text-warm-gray-100">Auth users</h2>
    <ul class="flex justify-start">
      <li @click="activeTab = 'users'" :class="[activeTab == 'users' && 'border-b-4 border-yellow-600','py-2 px-2 cursor-pointer']">Active</li>
      <li @click="activeTab = 'disabled-users'" :class="[activeTab == 'disabled-users' && 'border-b-4 border-yellow-600','py-2 px-2 cursor-pointer ml-4']">Disabled</li>
    </ul>
  </div>
  <Users v-if="activeTab == 'users'" :users="users" :isUserRetrieving="isUserRetrieving" :getUser="retrieveUsers" />
  <DisabledUsers v-if="activeTab == 'disabled-users'" :users="disabledUsersList" :isUserRetrieving="isUserRetrieving" :getUser="retrieveUsers" />
</template>
<script>
import { collection, getDocs } from "firebase/firestore";
import { db } from '@/firebase/config.js';

import DisabledUsers from '../components/Authentication/DisabledUsers';
import Users from '../components/Authentication/Users';
import {getUsers} from '@/firebase/controllers/userController';

import { ref, onValue, off} from "firebase/database";
import { rtdb } from '@/firebase/config.js';

export default {
  data() {
    return {
      activeTab: 'users',
      isUserRetrieving: false,
      users: [],
      disabledUsersList: [],
    }
  },
  computed: {
    activeUsers(){

    }
  },
  components:{
    DisabledUsers,
    Users,
  },
  async mounted() {
    await this.retrieveUsers();
    this.getUsersPresence();
    console.log('mounted here at authentication...')
  },
  beforeUnmount(){
    this.stopListeningUsersPresence();
  },
  methods: {
    // call this on mounted
    async getUsersPresence(){
      const userAdminRef = ref(rtdb, 'users-app');

      onValue(userAdminRef, (snapshot) => {
        snapshot.forEach(childSnapshot => {
          console.log('user presence change...')
          let foundIndex = this.users[0].listUsersResult.users.findIndex(u => u.uid == childSnapshot.key);
          if(foundIndex >= 0){
            this.users[0].listUsersResult.users[foundIndex].presence = childSnapshot.val()
          }    
        })
      })
    },
    // call this before unmount
    stopListeningUsersPresence(){
      console.log('stop listening to user presence change...')
      const userAdminRef = ref(rtdb, 'users-app');
      off(userAdminRef);
    },
    async retrieveUsers(userPerPage=100){
      this.isUserRetrieving = true;
      const res = await getUsers(userPerPage);
      if(!res.error){
        this.users = res.data.data.data;
        // retrieved disabled users from firestore collection also
        this.retrieveDisabledUsers();
      }
      this.isUserRetrieving = false;
    },
    async retrieveDisabledUsers(){
      this.disabledUsersList = [];
      const querySnapshot = await getDocs(collection(db, "disabledUsers"));
      querySnapshot.forEach((doc) => {
        this.disabledUsersList.push({docId: doc.id, data: doc.data()});
      });
    }
  },
}
</script>