import moment from 'moment';

export default  (app) => {
  app.config.globalProperties.$filters = {
    formatNumber(number){
        return Math.round(number).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatDate(date,f='ll'){
        return moment(date).format(f);
    }
  }
}