<template>
 <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 395 395" fill="none" xml:space="preserve">
<g>
	<polygon points="395,320.089 395,74.911 258.806,197.5 	" fill="currentColor"/>
	<polygon points="197.5,252.682 158.616,217.682 22.421,340.271 372.579,340.271 236.384,217.682 	" fill="currentColor"/>
	<polygon points="372.579,54.729 22.421,54.729 197.5,212.318 	" fill="currentColor"/>
	<polygon points="0,74.911 0,320.089 136.194,197.5 	" fill="currentColor"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

</template>