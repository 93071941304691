<template>
  <div class="h-100 bg-white dark:bg-warm-gray-900">
    <LoadingScreen v-if="isLoading" />
    <main v-else class="mt-4 sm:mt-8 pb-8">
      <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-full lg:px-8">
        <h1 class="sr-only">Profile</h1>
        <h1 class="text-base font-medium text-warm-gray-600 dark:text-gray-200">
          Welcome back, <strong class="text-warm-gray-700 dark:text-white">{{ user.email }}</strong>! 🍕
        </h1>

        <!-- <div class="grid grid-cols-2 gap-4 text-white"> -->
        <div class="text-warm-gray-700 dark:text-white text-xs">
          <!-- <div>
            LEADERBOARD
            <ul>
              <li v-for="(user, i) in leaderboard" :key="user.email">
                {{user.id}} {{i+1}} - {{user}}
              </li>
            </ul>
          </div> -->
          <div class="mt-6">
            <table>
              <!-- <thead class="bg-warm-gray-300 dark:bg-true-gray-800">
                <tr>
                  <th scope="col" class="px-6 py-3 text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    ID
                  </th>
                  <th scope="col" class="px-6 py-3 align-center text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Email
                  </th>
                  <th scope="col" class="px-6 py-3 align-center text-left text-xs hidden lg:block font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Endorser
                  </th>
                  <th scope="col" class="px-6 py-3 align-center text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" class="px-6 py-3 align-center text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Account Type
                  </th>
                  <th scope="col" class="px-6 py-3 align-center text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Endroser Code
                  </th>
                  <th scope="col" class="px-6 py-3 align-center text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">

                  </th>
                  <th scope="col" class="px-6 py-3 align-center text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">  

                  </th>
                </tr>
              </thead> -->
              <tbody class="text-warm-gray-700 dark:text-warm-gray-300">
              <tr v-for="(user, i) in users" :key="user.email" :class="[(leaderboard != null && leaderboard.includes(user.email)) ? '' : 'text-gray-400 dark:text-gray-600', ' hover:bg-gray-300 dark:hover:bg-gray-700']">
                <td class="px-4 py-1">{{i+1}}: {{user.id.substring(0,5)}}</td>
                <td class="px-4">{{moment.unix(user.date_created/1000).format('MM-DD-YY, h:mm:ss A')}}</td>
                <td class="px-4">
                  <button :class="[(user.type == 'endorser') ? 'text-purple-500 font-bold' : '']" @click="showProfile(user)"> {{user.email}} </button>
                  <span v-if="!user.date_created || ((Date.now() - user.date_created) < 86400000)" class="ml-2 shadow-sm px-1.5 py-0.5 text-xs uppercase leading-5 font-medium rounded-full text-true-gray-700 bg-purple-400 hover:bg-purple-500">
                    NEW
                  </span>
                </td>
                <!-- <td class="px-4">{{user.phone}}</td> -->                
                <!-- <td :class="[(user.endorser) ? '' : 'text-gray-400', 'px-4']">{{(user.endorser) ? `By: ${user.endorser}` : 'No Endorser'}}</td> -->
                <td :class="[(user.endorser) ? '' : 'text-gray-400 dark:text-gray-700']">{{(user.endorser) ? `${user.endorser}` : '-'}}</td>
                <!-- <td class="px-4">{{(leaderboard != null && leaderboard.includes(user.email)) ? 'Onboarded' : 'Created'}}</td> -->
                <!-- <td v-if="user.type" class="px-4 uppercase">
                  {{(user.type) ? user.type : '--'}}
                </td>
                <td v-else class=" text-gray-700">
                  -
                </td> -->
                
                <!-- <td class="px-4">{{user.endorser}}</td> -->
                
                <!-- <td v-if="user.type == 'endorser'" class="px-0 ">{{user.endorser_code}}</td>
                <td v-else class="px-0  text-gray-700 ">-</td> -->
                
                <td v-if="user.type == 'endorser'" class="px-4"><a href="#" @click="demoteToUser(user)" class="text-xs text-yellow-500 hover:text-yellow-400 flex" >
                  DEMOTE
                  <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6" />
                  </svg>
                </a></td>
                <td v-else class="px-4"><a href="#" @click="promoteToEndorser(user)" class="text-xs text-purple-500 hover:text-purple-400 flex" >
                  PROMOTE
                  <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                  </svg>
                </a></td>
                
                <td v-if="user.reviewers" class=" pl-10  border-l border-solid border-gray-500">
                  <span v-if="user.reviewers.caap == 'paid'" class="animate-pulse font-bold uppercase text-red-700">⚠️ {{user.reviewers.caap}}</span>
                  <span v-else-if="user.reviewers.caap == 'active'" class="uppercase">{{user.reviewers.caap}}</span>
                  <span v-else-if="user.reviewers.caap == 'inactive'" class="text-gray-400 dark:text-gray-600 uppercase">{{user.reviewers.caap}}</span>
                  <span v-else>--</span>
                </td>
                <td v-else class=" pl-10 text-gray-700 border-l border-solid border-gray-500">
                  -
                </td>
                <td class="pl-4">
                  REFNO: {{user.reviewers.refno}}
                </td>
                <td class="px-4">
                  <div v-if="user.reviewers && user.reviewers.caap == 'paid'">
                    <!-- <button href="#" @click="confirmPurchase(user)" class="text-xs text-green-500 hover:text-green-400 uppercase" > -->
                    <button href="#" @click="prepareSubscription(user)" class="text-xs text-green-500 hover:text-green-400 uppercase" >
                      Confirm
                    </button> | 
                    <button href="#" @click="prepareRecapture(user)" class="text-xs text-pink-300 hover:text-pink-200 uppercase" >
                      Recapture
                    </button> | 
                    <button href="#" @click="revokePurchase(user)" class="text-xs text-red-500 hover:text-red-400 uppercase" >
                      Revoke
                    </button>
                  </div>
                  <button v-else-if="user.reviewers && user.reviewers.caap == 'active'" href="#" @click="revokePurchase(user)" class="text-xs text-red-500 hover:text-red-400 uppercase" >
                    Revoke
                  </button>
                  <button v-if="!user.reviewers || user.reviewers.caap == 'inactive'"  href="#" @click="purchaseForUser(user)" class="text-xs text-blue-500 hover:text-blue-400 uppercase" >
                    Purchase
                  </button>
                </td>
                <!-- <button href="#" @click="prepareSubscription(user)" class="text-xs text-blue-500 hover:text-blue-400 uppercase" >
                  Subsrcibe
                </button> -->
                <td class="px-4 pl-10">
                  <button href="#" @click="confirmDelete(user)" class="text-xs text-red-500 hover:text-red-400" >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>  
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <!-- <ul>
              <li v-for="(user, i) in users" :key="user.email" :class="[(leaderboard.includes(user.email)) ? '' : 'text-gray-400']">
                {{i+1}}: [{{user.id.substring(0,5)}}] - {{user.email}} - <a href="#" class="text-xs text-red-500 hover:text-red-400" >DELETE</a>
              </li>
            </ul> -->
          </div>
        </div>
        
        <div class="text-xs my-2 mt-5 text-gray-400 dark:text-gray-600 w-1/6 ">* Not found on leaderboard.</div>

      </div>
    </main>

    <EndorserModal 
        :isEndorserModalOpen="isEndorserModalOpen"
        :user="selected_user"
        @closeEndorserModal="closeEndorserModal()"
        @updateUser="(isEndorserModalOpen = false), updateUser()" 
      />

    <DeleteModal 
        :isDeleteModalOpen="isDeleteModalOpen"
        :user="selected_user"
        @closeDeleteModal="closeDeleteModal()"
        @startDelete="(isDeleteModalOpen = false), startDelete()" 
      />

    <ProfileModal 
        :isProfileModalOpen="isProfileModalOpen"
        :user="selected_user"
        @closeProfileModal="closeProfileModal()"        
      />
      
  </div>
</template>

<script>

import { ref } from "vue";

const NAME = "Dashboard";

import { getDocs,collection, orderBy, query, doc, updateDoc, setDoc, deleteDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { auth, db, functions } from '@/firebase/config.js';
import { sendSMS } from "@/firebase/controllers/userController";


// Firebase Deps
// import firebase from "@/firebase/config";

// import "firebase/auth";
// import "firebase/firestore";
// import "firebase/functions";
// const db = firebase.firestore();
// const functions = firebase.functions();

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

// import { DotsVerticalIcon } from '@heroicons/vue/solid'


import {
  // for error alert
  EllipsisVerticalIcon as DotsVerticalIcon,
} from "@heroicons/vue/24/outline";


// Components Deps
import LoadingScreen from "@/_for_dash/screens/LoadingScreen"
import EndorserModal from "@/_for_dash/modals/EndorserModal"
import DeleteModal from "@/_for_dash/modals/DeleteModal"
import ProfileModal from "@/_for_dash/modals/ProfileModal"
import DefaultUserIcon from "@/_for_dash/icons/DefaultUserIcon";

import { subscribe} from "@/convertkit/subscribe"
import { DocumentDuplicateIcon } from "@heroicons/vue/outline";
import moment from "moment";

export default {
  name: NAME,
  site_title: NAME,
  components: {
    LoadingScreen,
    DotsVerticalIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DefaultUserIcon,
    EndorserModal, DeleteModal, ProfileModal
  },
  setup() {
    const isEndorserModalOpen = ref(false)
    const isDeleteModalOpen = ref(false)
    const isProfileModalOpen = ref(false)
    return {
      isEndorserModalOpen,
      closeEndorserModal() { isEndorserModalOpen.value = false },
      openEndorserModal() { isEndorserModalOpen.value = true },
      isDeleteModalOpen,
      closeDeleteModal() { isDeleteModalOpen.value = false },
      openDeleteModal() { isDeleteModalOpen.value = true },
      isProfileModalOpen,
      closeProfileModal() { isProfileModalOpen.value = false },
      openProfileModal() { isProfileModalOpen.value = true },
    }
  },
  data() {
    return {
      isLoading: null, // Loading means the view is being loaded. Show a loading screen
      isWaiting: null, // Waiting means the view is doing something. Adjust the UI if needed
      user: {
        id: null,
        email: null
      },
      selected_user: null,
      users: null,
      leaderboard: null,
      reviewers: {
        caap: '',
        mtp: '',
      },
      subscribe: subscribe,
      moment: moment
    }
  },
  methods: {

    async prepareSubscription(user){
      console.log("Subscribing user ", user)
      let result = await this.subscribe(user, "purchased", rev)
      if(result){
        console.log(`User ${user.email} is successfully subscribed.`)
      }
      this.confirmPurchase(user)
    },

    async prepareRecapture(user){
      console.log("Recapturing user ", user)
      let result = await this.subscribe(user, "recapture", rev)
      // console.log("result", result)
      if(result.statusCode == 200){
        console.log(`User ${user.email} is successfully recaptured.`)
      }else{
        console.error(`An error occurred when subscribing ${user.email}.`)
      }
      
    },

    demoteToUser(user){
      console.log("Demoting user:")
      console.log(user.id)
      console.log(user.email)
      console.log(user.endorser_code)

      user.type = "user"
      user.endorser_code = ""

      // Update User Data
      // db.collection("users").doc(user.id).update({ type: "user", endorser_code: "" })
      updateDoc(doc(db, "users", user.id), { type: "user", endorser_code: "" })
      .then(() => { console.log("Successfully updated user") })
      .catch((error) => {  console.error("Error updating user data: ", error) });

      // Update Endorser Codes
      // db.collection("_endorser_codes").doc(this.selected_user.id).delete()
      deleteDoc(doc(db, "_endorser_codes", user.id))
      .then(() => { console.error("Successfully deleted endorser codes") })
      .catch((error) => { console.error("Error deleted endorser codes: ", error)  });

    },

    promoteToEndorser(user){
      console.log("Promoting user to endorser: ", user)
      this.selected_user = user
      this.openEndorserModal();
    },

    showProfile(user){
      console.log("Showing User Data: ", user)
      this.selected_user = user
      this.openProfileModal();
    },

    async updateUser(){
      console.log('updating user')
      
      console.log(this.selected_user.id)
      console.log(this.selected_user.email)
      console.log(this.selected_user.endorser_code)

      // Update User Data
      // db.collection("users").doc(this.selected_user.id).update({ type: "endorser", endorser_code: this.selected_user.endorser_code })
      updateDoc(doc(db, "users", this.selected_user.id), { type: "endorser", endorser_code: this.selected_user.endorser_code })
      .then(() => { console.error("Successfully updated user") })
      .catch((error) => { console.error("Error updating user data: ", error)  });

      // Update Endorser Codes
      // db.collection("_endorser_codes").doc(this.selected_user.id).set({ code: this.selected_user.endorser_code })
      setDoc(doc(db, "_endorser_codes", this.selected_user.id), { code: this.selected_user.endorser_code })
      .then(() => { console.error("Successfully updated endorser codes") })
      .catch((error) => { console.error("Error updating endorser codes: ", error)  });
    },


    /**
     * Ask User for the ADMIN_KEY before delete
     */
    confirmDelete(user){
      console.log("Deleting user: ", user)
      this.selected_user = user
      this.openDeleteModal();
    },

    /**
     * @TODO: This will delete the user's data in users and leaderboard. User will not be deleted in AUTH
     */
    startDelete(){
      console.log("Starting to delete user: ", this.selected_user)
      
      console.log("Deleting user in user info...")
      // db.collection("users").doc(this.selected_user.id).delete()
      deleteDoc(doc(db, "users", this.selected_user.id))
      .then(() => {
        console.log("User info document successfully deleted!");
        
        console.log("Deleting user in leaderboard...")
        // db.collection("leaderboard").doc(this.selected_user.id).delete()
        deleteDoc(doc(db, "leaderboard", this.selected_user.id))
        .then(() => {
          console.log("User in leaderboard document successfully deleted!");
          location.reload()
        }).catch((error) => {
          console.error("Error removing user in leaderboard: ", error);
        });
        
      }).catch((error) => {
        console.error("Error removing user info document: ", error);
      });

      // @TODO: Delete User
      // console.log("Deleting user...")
      // getAuth()
      // .deleteUser(user.id)
      // .then(() => {
      //   console.log('Successfully deleted user');
      // })
      // .catch((error) => {
      //   console.log('Error deleting user:', error);
      // });

    },

    /**
     * Revoke someone's purchase
     */
    revokePurchase(user){
      console.log("Revoking user's caap")
      this.selected_user = user
      this.reviewers.caap = false;
      // const purchaseCaap = firebase.functions().httpsCallable('purchaseCaap');
      // const purchaseReviewers = functions.httpsCallable('purchaseReviewers');  
      const purchaseReviewers = httpsCallable(functions, "purchaseReviewers")
      purchaseReviewers({email: this.selected_user.email ,reviewers: this.reviewers})
        .then((result) => {
            console.log('Reviewer unpurchased.');
            
            // Update User Data for CAAP
            this.reviewers.caap = 'inactive';
            // db.collection("users").doc(this.selected_user.id).update({ reviewers: this.reviewers })
            updateDoc(doc(db,"users", this.selected_user.id), { reviewers: this.reviewers })
            .then(() => { 
              console.log("Successfully updated user") 
              location.reload()
            })
            .catch((error) => { console.error("Error updating user data: ", error)  });
        })
        .catch((error) => {
           console.log('Error purchasing reviewer:', error);
        });

    },

    /**
     * Confirm someone's purchase
     */
    async confirmPurchase(user){
      console.log("Purchasing user: ", user)
      this.selected_user = user

      this.reviewers.caap = true;
      // const purchaseCaap = firebase.functions().httpsCallable('purchaseCaap');
      // const purchaseReviewers = functions.httpsCallable('purchaseReviewers');  
      const purchaseReviewers =httpsCallable(functions, "purchaseReviewers");
      purchaseReviewers({email: this.selected_user.email ,reviewers: this.reviewers})
        .then((result) => {
            console.log('Reviewer purchased.');

            // send SMS to user!
            // let to = user.phone;
            // let body = 'Thank you for purchasing CAAP reviewer from Skilltech Team!';
            // const res = await sendSMS(to, body);
            // if(res.success){ console.log('SMS ID:', res.data);}
            
            // Update User Data for CAAP
            this.reviewers.caap = 'active';
            // db.collection("users").doc(this.selected_user.id).update({ reviewers: this.reviewers })
            updateDoc(doc(db,"users", this.selected_user.id), { reviewers: this.reviewers })
            .then(() => { 
              console.log("Successfully updated user") 
              location.reload()
            })
            .catch((error) => { console.error("Error updating user data: ", error)  });

        })
        .catch((error) => {
           console.log('Error purchasing reviewer:', error);
        });
    },

    /**
     * Purchase CAAP reviewer for the user
     */
    purchaseForUser(user){

      console.log("Purchasing user: ", user)
      this.selected_user = user

      this.reviewers.caap = 'paid';
      // Update User Data
      // db.collection("users").doc(this.selected_user.id).update({ reviewers: this.reviewers })
      updateDoc(doc(db,"users", this.selected_user.id), { reviewers: this.reviewers })
      .then(async () => { 
        
        // send SMS to user!
        // let to = user.phone;
        // let body = 'Thank you for purchasing CAAP reviewer from Skilltech Team!';
        // const res = await sendSMS(to, body);
        // if(res.success){ console.log('SMS ID:', res.data);}

        console.log("Successfully updated user") 
        location.reload()
        })
      .catch((error) => { console.error("Error updating user data: ", error)  });

    }
    
  },
  // TODO: Dissect these functions into methods and transform into promises
  mounted() {
    console.log('mounted test dash...');
    this.isLoading = true;
    this.user.id = auth.currentUser.uid;
    this.user.email = auth.currentUser.email;

    auth.currentUser.getIdTokenResult()
      .then((tokenResult) => {
        console.log(`Claims retreived for user with id: ${this.user.id} => `, tokenResult.claims)
        
        /**
         * NOTE: Connects To Firebase
         * fetch the current logged in user's data 
         */
        // db.collection("users").orderBy("date_created", "desc").get()
        getDocs( query(collection(db, "users"), orderBy("date_created", "desc")))
          .then((querySnapshot) => { 
            if(querySnapshot.size > 0){ // only check if there are existing practice exams started
              var users = [];
              querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                users.push(data)
              });
              this.users = users
            }else{
              console.log("No previous mock exams were found!")
            }
          })
          .catch((error) => {
            console.error("Error getting user document:", error);
            throw `Error getting user document: ${error}`;
          });

         
         // Test modify          


          /**
         * NOTE: Connects To Firebase
         * fetch the current logged in user's data 
         */
        // db.collection("leaderboard").get()
        getDocs(collection(db, "leaderboard"))
          .then((querySnapshot) => { 
            if(querySnapshot.size > 0){ // only check if there are existing practice exams started
              var leaderboard = [];
              querySnapshot.forEach((doc) => {
                let data = doc.data()
                // data.id = doc.id
                leaderboard.push(data.email)
              });
              this.leaderboard = leaderboard
            }else{
              console.log("No previous mock exams were found!")
            }
          })
          .catch((error) => {
            console.error("Error getting user document:", error);
            throw `Error getting user document: ${error}`;
          });

      })
      .catch((error) => {
        console.error("Error getting user id token", error);
        // TODO: Handle Error. Redirect.
      })
      .finally(() => {
        this.isLoading = false 
      })

      // THis is a comment
  },
};
</script>