<template>
    <div :class="[(isCouponValid) ? 'py-10' : 'py-0', 'dark:bg-warm-gray-900 bg-gray-200']">
        <main v-if="state === STATE.WAITING" class="waiting-state">
            <div class="flex flex-col items-center">
                <Logo class="logo-style" />
                <div class="waiting-text">Please wait...</div>
            </div>
        </main>

        <div v-else-if="state === STATE.ACCEPTING" class="accepting-wrapper">
            <!-- Your 'accepting' content here -->
            <div class="accepting-container">
                <!-- SVG graphic for the inclined triangles -->
                <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                    <svg class="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" fill="none"
                        viewBox="0 0 1463 360">
                        <path class="text-warm-gray-200 text-opacity-40" fill="currentColor"
                            d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z" />
                        <path class="text-warm-gray-300 text-opacity-40" fill="currentColor"
                            d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z" />
                    </svg>
                </div>
                <div class="relative px-2 text-center">
                    <h2>
                        Verify Your Discount Coupon
                    </h2>
                    <p>
                        Welcome to our coupon verification page! To ensure you receive the discounts and offers
                        you're entitled to, please verify the validity of your coupon by typing your unique 8-digit coupon
                        code in the field below.
                    </p>
                    <p>
                        If you have a physical coupon code with you, please scan the QR code at the back to automatically
                        verify if it's still valid.
                    </p>
                    <div class="accepting-form">
                        <div class="min-w-0 flex-1">
                            <label for="coupon-code" class="sr-only">Coupon Code</label>
                            <input v-model="code" id="coupon-code" type="text" placeholder="ENTER COUPON CODE" />
                            <div class="error-message">{{ (code.length > 8) ? 'Code should be 8 digits.' : '&nbsp;' }}</div>
                        </div>
                        <div class="mt-4 sm:ml-3 sm:mt-0">
                            <button :disabled="isInputCodeInvalid" type="button" @click="handleVerifyClick()">
                                Verify
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="verified-state">
            <!-- Your 'verified' content here -->
            <div class="coupon-container">
                <div class="coupon-wrapper">

                    <!-- First section of coupon where we show the validation result -->
                    <section>
                        <div class="coupon-section">
                            <div v-if="isCouponValid" class="coupon-discount-label">
                                <h4>{{ discountLabel }}</h4>
                            </div>
                            <div :class="[(code.length > 8) ? 'text-2xl sm:text-4xl' : 'text-3xl sm:text-5xl', 'coupon-discount-code']">
                                {{ couponCodeLabel }}
                            </div>
                            <div class="validation-result">
                                <span :class="(isCouponValid) ? 'text-green-500' : 'text-red-500'">
                                    {{ isCouponValid ? '✅' : '❌' }} Coupon code is {{ isCouponValid ? 'valid' : 'invalid' }}!
                                </span>
                            </div>

                            <div v-if="isCouponValid">
                                <p>
                                    Congratulations! You can now avail the CAAP License Exam Reviewer at a discounted price!
                                    Please see the breakdown below and proceed with the purchase.
                                </p>
                            </div>
                            <div v-else>
                                <p>
                                    Please ensure you've entered the correct 8-digit code and try again.
                                </p>
                                <p>
                                    You can also follow our social media pages and reach out to one of our endorsers for any
                                    available discount coupons. They're ready to help you get started!
                                </p>
                            </div>

                        </div>

                    </section>

                    <!-- Second section where we display the CTA, regardless if coupon is valid or not. This is where the code is valid -->
                    <section>
                        <!-- Punched hole aesthetic -->
                        <div class="relative">
                            <div class="coupon-puncher -top-4 -left-8"></div>
                            <div class="coupon-puncher -top-4 -right-8"></div>
                        </div>
                        <div class="coupon-section dashed-border">
                            <h2>CAAP Licensure Exam Reviewer</h2>
                            <p>
                                Review for your Civil Aviation Authority of the Philippines (CAAP) AMT or AVT
                                licensure exam. Complete coverage for all Knowledge Test subjects in practice or mock exam
                                modes.
                            </p>
                            <div class="breakdown">
                                <div class="text-with-icon-wrapper">
                                    <p>⏱️ Access Duration: <strong>6 Months</strong></p>
                                </div>
                                <div class="text-with-icon-wrapper">
                                    <p>🏷️ Current Price: <strong>₱ {{ ORIGINAL_PRICE.toLocaleString() }}</strong></p>
                                </div>
                                <div v-if="isCouponValid" class="text-with-icon-wrapper">
                                    <p>🎫 Coupon Discount: <strong>₱ {{ coupon.data.discountAmount.toLocaleString()
                                    }}</strong></p>
                                </div>
                                <div v-if="isCouponValid" class="text-with-icon-wrapper font-black">
                                    <p>💎 Discounted Price: <strong class="text-green-500">₱ {{ (ORIGINAL_PRICE - coupon.data.discountAmount).toLocaleString() }}</strong></p>
                                </div>
                            </div>
                            <button :disabled="state === STATE.WAITING">{{ finalPriceLabel }}</button>
                        </div>
                    </section>

                    <!--  Extra section to buy now, activate later -->
                    <section v-if="isCouponValid">
                        <div class="relative">
                            <div class="coupon-puncher -top-4 -left-8"></div>
                            <div class="coupon-puncher -top-4 -right-8"></div>
                        </div>
                        <div class="coupon-section dashed-border">
                            <p>
                                This coupon will expire on 
                                <strong class="text-red-500">{{ convertTimestampToDate(coupon.data.expiryDate) }}</strong>.
                            </p>
                            <p>
                                To take advantage of this limited offer, you also have the option to 
                                <strong>buy the reviewer now</strong> but <strong>activate it later</strong> 
                                once you're ready to review. The 6 month access duration will start only when you activate it.
                            </p>
                            <button :disabled="state === STATE.WAITING" class="button--activate-later">
                                Buy Now, Activate Later
                            </button>
                        </div>
                    </section>

                    <!-- Lastly, button to verify another code -->
                    <div class="w-full text-center flex justify-center">
                        <button :disabled="state === STATE.WAITING" @click="handleGoBackToScanClick()"
                            class="button--plain">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                                <path fill-rule="evenodd"
                                    d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span>Verify another code</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import Logo from "@/_for_dash/icons/Logo"
import { ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { checkCouponCodeIfExists, checkCouponValidity, convertTimestampToDate } from '@/v3/CouponService'

// constants
const ORIGINAL_PRICE = 3999
const STATE = {
    ACCEPTING: "accepting", // accepting coupon to validate from user
    WAITING: "waiting", // ui is waiting for a process
    COMPLETE: "complete", // validation complete
}

// ui variables
const route = useRoute()
const router = useRouter()
const code = ref('')
const coupon = ref(null)
const isCouponValid = ref(true)
const state = ref('waiting') // waiting, verifying

// below are computed properties solely for ui

const isInputCodeInvalid = computed(() => {
    return (code.value.length < 8 || code.value.length > 8)
})

const discountLabel = computed(() => {
    return `₱ ${coupon.value.data.discountAmount.toLocaleString()} OFF`;
})

const finalPriceLabel = computed(() => {
    /**
     * If the coupon is valid, return the discounted price: original price - discount amount.
     * If the coupon is invalid, return the original price.
     * Format them for the button label.
     */
    return `Buy now at ₱${(isCouponValid.value) ? (ORIGINAL_PRICE - coupon.value.data.discountAmount).toLocaleString() : ORIGINAL_PRICE.toLocaleString() }`
})

const couponCodeLabel = computed(() => {
    return `${code.value.substring(0, 8)}${(code.value.length > 8) ? '...' : ''}`
})

const initialize = async () => {
    /**
     * If user has a coupon code passed (i.e. via coupon validator
     * page or via QR code), then set the status to waiting while 
     * we verify the coupon code.
     * 
     * Otherwise, set the state to accept coupon code for verification.
     */
    if (route.query.code) {
        state.value = STATE.WAITING

        /**
         * Set the passed code and proceed with validation
         */
        code.value = route.query.code
        console.log(`🎫 A coupon code has been passed: ${code.value}`)
        try {
            console.log(`🎫 Checking if code exists...`)
            coupon.value = await checkCouponCodeIfExists(code.value)

            /**
             * Any error encountered before this line will be caught. '
             * Otherwise, it will proceed normally.
             */

            console.log(`🎫 Coupon code ${code.value} found. Checking if coupon is valid...`)
            await checkCouponValidity(coupon.value)

            /**
             * Any error encountered before this line will be caught. 
             * Otherwise, the code is now fully validated. We set the 
             * isCouponValid flag to true.
             */

            console.log(`🎫 Coupon code ${code.value} is valid. Ready for use.`)
            isCouponValid.value = true

        } catch (error) {
            /**
             * If an error occurred, it will be caught here.
             * Therefore, the coupon is not valid and we set the 
             * isCouponValid flag to false.
             */
            isCouponValid.value = false
        } finally {
            /**
             * Finally, regardless of the verification results (valid or not),
             * set the state to complete and show the results to the user.
             */
            state.value = STATE.COMPLETE
        }
    } else {
        state.value = STATE.ACCEPTING
    }
}
initialize() // run the initialize function above


/**
 * This is a watcher that will check for changes in the 
 * query parameters. If the query parameter is changed,
 * we reinitialize the page.
 */
watch(
    () => route.query,
    (newQuery, oldQuery) => {
        console.log('Query parameters changed:', newQuery)
        initialize()
        // Perform your actions based on the new query parameters
    },
    { deep: true }
)

/**
 * Handle 'Verify' button click. This will just redirect the user
 * to the same route, but with the coupon code. It will trigger the 
 * watcher and in turn reinitialize the page.
 */
const handleVerifyClick = () => {
    router.push({ path: route.path, query: { code: code.value } })
}

/**
 * Handle the 'Go Back' button click. This will just remove the 
 * existing ui variable values and remove the coupon code. 
 * Basically setting back the state the accept new coupon code.
 */
const handleGoBackToScanClick = () => {
    router.push({ path: '/coupon' })
    code.value = ''
    coupon.value = null
    isCouponValid.value = null
    state.value = STATE.ACCEPTING
}

</script>
  
<style scoped>
@layer components {
    .baseClass {
        @apply relative dark:bg-warm-gray-900 bg-gray-200;
    }

    .waiting-state {
        @apply flex justify-center items-center dark:bg-warm-gray-900 bg-gray-200 py-8;
    }

    .logo-style {
        @apply w-24 h-24 animate-pulse dark:text-warm-gray-700 text-warm-gray-300;
    }

    .waiting-text {
        @apply mt-4 py-1 px-2 text-center dark:text-warm-gray-700 text-warm-gray-300;
    }

    .accepting-wrapper {
        @apply sm:mt-8 mx-auto max-w-md px-3 sm:px-6 sm:max-w-3xl lg:max-w-7xl lg:px-8 py-8;
    }

    .accepting-container {
        @apply relative overflow-hidden rounded-2xl bg-white px-4 sm:px-6 py-10 shadow-xl sm:px-12 sm:py-20
    }

    .accepting-container h2 {
        @apply text-xl sm:text-3xl font-bold tracking-tight text-gray-800 sm:text-4xl;
    }

    .accepting-container p {
        @apply mx-auto mt-6 max-w-xl text-sm sm:text-lg text-warm-gray-600;
    }

    .accepting-form {
        @apply mt-8 sm:mt-12 sm:mx-auto sm:flex sm:max-w-lg;
    }

    .accepting-form input {
        @apply uppercase block w-full rounded-md border border-gray-300 px-5 py-3 text-base text-warm-gray-900 placeholder-warm-gray-500 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-warm-gray-600;
    }

    .accepting-form .error-message {
        @apply mt-2 text-red-400 text-left;
    }

    .accepting-form button {
        @apply disabled:opacity-50 disabled:cursor-default hover:opacity-80 transition-all block w-full rounded-md border border-transparent bg-warm-gray-500 px-5 py-3 text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-warm-gray-600 sm:px-10;
    }

    .verified-state {
        @apply flex justify-center items-center dark:bg-warm-gray-900 bg-gray-200;
    }

    .coupon-container {
        @apply max-w-sm rounded-xl overflow-hidden bg-white;
    }

    .coupon-wrapper {
        @apply max-w-sm rounded-xl bg-white p-4;
    }

    .coupon-section {
        @apply px-6 py-4 text-gray-700 text-sm my-2;
    }

    .dashed-border {
        @apply border-t border-gray-300 border-dashed;
    }

    .coupon-section p {
        @apply mt-4 text-gray-700 text-sm;
    }

    .coupon-section h2 {
        @apply text-base font-extrabold text-gray-900 mt-2;
    }

    .coupon-section .validation-result {
        @apply flex items-center space-x-1;
    }

    .coupon-section .validation-result span {
        @apply font-bold text-sm flex items-center space-x-1;
    }

    .coupon-section button {
        @apply mb-2 mt-2 py-4 disabled:opacity-25 disabled:cursor-default w-full rounded-md bg-yellow-500 px-2 text-base font-bold text-white hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-yellow-50;
    }

    .coupon-section .button--activate-later {
        @apply mt-4 py-4 disabled:opacity-25 disabled:cursor-default w-full rounded-md bg-indigo-500 px-2 text-base font-bold text-white hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-indigo-50;
    }

    .button--plain { 
        @apply -mt-2 mb-2 flex items-center space-x-1 text-gray-500 text-sm font-semibold text-center hover:text-gray-400;
    }

    .coupon-puncher {
        /** punched hole design */
        @apply h-8 w-8 rounded-full dark:bg-warm-gray-900 bg-gray-200 absolute;
    }

    .coupon-discount-label {
        @apply font-bold text-xl mb-2 flex;
    }

    .coupon-discount-label h4 {
        @apply py-1 px-4 text-center bg-black text-white;
    }

    .coupon-discount-code {
        @apply py-4 border-8 border-black text-black text-center font-extrabold mb-4 uppercase;
    }

    .breakdown {
        @apply space-y-1 mb-2 mt-4;
    }

    .breakdown p {
        @apply my-0;
    }

    .text-with-icon-wrapper {
        @apply flex items-center space-x-1;
    }

    /* Add more @apply classes as needed */
}
</style>
  