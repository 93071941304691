<template>
  
  <div v-if="!isRetrieving" class=" flex justify-center mt-14 mb-2 text-warm-gray-600 dark:text-gray-200">
    <!-- not found error display -->
    <div v-if="subjectIdNotFound" class="lg:w-7/12 border border-red-400 bg-yellow-100 text-yellow-600 text-center flex justify-center items-center p-6 rounded-md">
        <ExclamationCircleIcon class="w-6 h-6 mr-2"/>
        <span class=" font-semibold">Subject ID not found! <a href="#" @click="$router.go(-1)" class="underline text-yellow-700"> return </a> </span>
    </div>
    <!-- main content view -->
    <div v-else class="w-10/12 lg:w-7/12">
      <div v-if="subjectData">
        <!-- header 1 -->
        <div class="flex justify-between items-end">
          <div class="flex justify-start items-center pl-2">
            <span class="text-warm-gray-500 dark:text-warm-gray-300">Viewing {{subjectData.name}} information.</span>
          </div>
          <div class="flex justify-end items-center">
            <router-link :to="`/data/subjects/update/${subjectId}?r=${curRevId}`" class="bg-yellow-700 text-white font-semibold py-2 px-4 mr-2 rounded-md hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none ">Update</router-link>
            <button @click="$router.go(-1)" class="bg-warm-gray-300 font-semibold py-2 px-4 rounded-md hover:bg-warm-gray-400 focus:bg-warm-gray-400 focus:outline-none dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600">Return</button>
          </div>
        </div>  

        <!-- Information -->
        <div class="border shadow rounded-md p-4 mt-4 dark:border-warm-gray-700">
          <div class="mb-4">
            <div class="font-semibold">Subject ID</div>
            <div class="dark:text-warm-gray-400">{{subjectId}}</div>
          </div>
          <div class="mb-4">
            <div class="font-semibold">Subject name</div>
            <div class="dark:text-warm-gray-400">{{subjectData.name}}</div>
          </div>
          <div class="mb-4">
            <div class="font-semibold">Description</div>
            <div class="dark:text-warm-gray-400">{{subjectData.description}}</div>
          </div>
          <div class="mb-4">
            <div class="font-semibold mb-2">Exam Limits</div>
            <div class="grid xl:grid-cols-4 gap-4">
              <div class="border bg-gray-100 p-2 text-center dark:bg-warm-gray-700 dark:border-warm-gray-700">
                <div class="flex justify-start items-center">
                  <PaperClipIcon class="h-4 w-4 mr-1" /> Passer
                </div>
                <div class="font-bold my-2">{{subjectData.exam.limits.passer}}</div>
              </div>
              <div class="border bg-gray-100 p-2 text-center dark:bg-warm-gray-700 dark:border-warm-gray-700">
                <div class="flex justify-start items-center">
                  <LightningBoltIcon class="h-4 w-4 mr-1" />Speed Runner
                </div>
                <div class=" font-bold  my-2">{{subjectData.exam.limits.speedrunner}}</div>
              </div>
              <div class="border bg-gray-100 p-2 text-center dark:bg-warm-gray-700 dark:border-warm-gray-700">
                <div class="flex justify-start items-center">
                  <FireIcon class="h-4 w-4 mr-1" />Master
                </div>
                <div class=" font-bold  my-2">{{subjectData.exam.limits.master}}</div>
              </div>
              <div class="border bg-gray-100 p-2 text-center dark:bg-warm-gray-700 dark:border-warm-gray-700">
                <div class="flex justify-start items-center">
                  <ClockIcon class="h-4 w-4 mr-1" />Time <div class=" text-warm-gray-500 text-xs ml-1">(in seconds)</div>
                </div>
                <div class=" font-bold  my-2">{{subjectData.exam.limits.time}}</div>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <div class="font-semibold mb-2">Exam Points</div>
            <div class="grid xl:grid-cols-4 gap-4">
              <div class="border bg-gray-100 p-2 text-center dark:bg-warm-gray-700 dark:border-warm-gray-700">
                <div class="flex justify-start items-center">
                  <PaperClipIcon class="h-4 w-4 mr-1" /> Passer
                </div>
                <div class="font-bold my-2">{{subjectData.exam.points.passer}}</div>
              </div>
              <div class="border bg-gray-100 p-2 text-center dark:bg-warm-gray-700 dark:border-warm-gray-700">
                <div class="flex justify-start items-center">
                  <LightningBoltIcon class="h-4 w-4 mr-1" />Speed Runner
                </div>
                <div class=" font-bold  my-2">{{subjectData.exam.points.speedrunner}}</div>
              </div>
              <div class="border bg-gray-100 p-2 text-center dark:bg-warm-gray-700 dark:border-warm-gray-700">
                <div class="flex justify-start items-center">
                  <FireIcon class="h-4 w-4 mr-1" />Master
                </div>
                <div class=" font-bold  my-2">{{subjectData.exam.points.master}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid xl:grid-cols-2 xl:gap-8">
          <!-- courses wrapper -->
          <div>
            <!-- header -->
            <div class="mt-6 mb-2">
              <span class="text-warm-gray-500 dark:text-warm-gray-300">List of courses that this subject belongs to.</span>
            </div>
            <!-- course table -->
            <div class="border rounded-md overflow-hidden dark:border-warm-gray-700">
              <table class="w-full text-left">
                <thead class=" bg-warm-gray-300 dark:bg-warm-gray-700">
                  <tr>
                    <th class="p-4">Course ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(course,i) in subjectData.course" :key="i" class="hover:bg-warm-gray-100 dark:hover:bg-warm-gray-800">
                    <td class="p-2 pl-4">{{course}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- subtopics wrapper -->
          <div>
              <!-- header -->
              <div class="mt-6 mb-2">
                <span class="text-warm-gray-500 dark:text-warm-gray-300">List of subtopics that are under this subject.</span>
              </div>
              <!-- subtopics table -->
              <div class="border rounded-md overflow-hidden dark:border-warm-gray-700">
                <table class="w-full text-left">
                  <thead class=" bg-warm-gray-300 dark:bg-warm-gray-700">
                    <tr>
                      <th class="p-4">Subtopic ID</th>
                      <th class="p-4 text-center">items</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(subtopic,i) in subjectData.exam.subtopics" :key="i" class="hover:bg-warm-gray-100 dark:hover:bg-warm-gray-800">
                      <td class="p-2 pl-4 flex justify-start items-center">
                        {{subtopic}}
                        <keyIcon v-if="subtopics.length > 0 && subtopics.some(st => st.id == subtopic && (st.data.subjects.some(sj => sj == subjectId)) && (st.data.keys && st.data.keys[st.data.subjects.indexOf(subjectId)]))" class="h-4 w-4 text-yellow-600 ml-1" />
                        <small v-if="subtopics.length > 0 && !subtopics.some(s => s.id == subtopic)" class="text-white text-xxs px-1 bg-red-300 rounded">ID not found</small></td>
                      <td class="p-2 pl-4 text-center">{{subjectData.exam.items[i]}}</td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div  v-else class="flex justify-center mt-10 ">
    <SpinningIcon class="h-6 w-6 text-yellow-600" />
  </div>

</template>
<script>
import {getSubject,getSubtopics} from '@/firebase/controllers/reviewerController';
import {
  BoltIcon as LightningBoltIcon,
  PaperClipIcon as PaperClipIcon,
  FireIcon as FireIcon,
  ClockIcon as ClockIcon, 
  KeyIcon as KeyIcon
} from '@heroicons/vue/24/outline';
import {
  ExclamationCircleIcon as ExclamationCircleIcon
} from '@heroicons/vue/24/solid';
import SpinningIcon from '@/components/SpinningIcon';

export default {
  data() {
    return {
      curRevId: '',
      isRetrieving: false,
      subjectIdNotFound: false,
      subjectId: '',
      subjectData: null,
      subtopics: []
    }
  },
  components:{
    LightningBoltIcon,
    PaperClipIcon,
    FireIcon,
    ClockIcon,
    ExclamationCircleIcon,
    KeyIcon,
    SpinningIcon
  },
  updated() {
    if (this.$route.query.r) {
      this.curRevId = this.$route.query.r;
    }
  },
  mounted() {
    if (this.$route.query.r) {
      this.curRevId = this.$route.query.r;
    }
    this.subjectId = this.$route.params.subject;
    this.getSubject();
    this.getSubtopics();
  },
  methods: {
    async getSubject(){
      this.isRetrieving = true;
      const res = await getSubject(this.curRevId,this.subjectId);
      if (res.isError) {
        this.subjectIdNotFound = true
      }else{
        this.subjectData = res.data;
      }
      this.isRetrieving = false;
    },
    async getSubtopics(){
      const res = await getSubtopics(this.curRevId);
      this.subtopics = res.subtopics;
    },
  },
}
</script>