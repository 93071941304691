<template>
 <div class=" flex justify-center mt-14 mb-2 text-warm-gray-600 dark:text-warm-gray-300">
    <div v-if="course" class="w-10/12 lg:w-7/12">
      <!-- header 1 -->
      <div class="flex justify-between items-end">
        <div class="flex justify-start items-center pl-2">
          <span class="text-warm-gray-500 dark:text-warm-gray-400">Viewing {{ course.name }} information.</span>
        </div>
        <div class="flex justify-end items-center">
          <router-link :to="`/data/courses/${courseId}/update?r=${curRevId}`" class="bg-yellow-700 text-white font-semibold py-2 px-4 mr-2 rounded-md hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none ">Update</router-link>
          <!-- <button class="bg-yellow-700 text-white font-semibold py-2 px-4 mr-2 rounded-md hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none ">Update</button> -->
          <button @click="$router.go(-1)" class="bg-warm-gray-300 font-semibold py-2 px-4 rounded-md hover:bg-warm-gray-400 focus:bg-warm-gray-400 focus:outline-none dark:bg-warm-gray-800 dark:hover:bg-warm-gray-700 dark:focus:bg-warm-gray-700">Return</button>
        </div>
      </div>  

      <!-- Information -->
      <div class="border shadow rounded-md p-4 mt-4 dark:border-warm-gray-700">
        <p class=" text-warm-gray-500 italic mb-4">{{ course.id  }}</p>
        <div class="mb-2">
          <div class="font-semibold dark:text-warm-gray-100 ">Course name</div>
          <div>{{ course.name  }}</div>
        </div>
        <div class="mb-2">
          <div class="font-semibold dark:text-warm-gray-100">Description</div>
          <div>{{ course.description }}</div>
        </div>
      </div>

      <!-- courses wrapper -->
      <div>
        <!-- header -->
        <div class="mt-6 mb-2">
          <span class="text-warm-gray-500 dark:text-warm-gray-400">Subject/s under this course.</span>
        </div>
        <!-- course table -->
        <div class="border rounded-md overflow-hidden dark:border-warm-gray-700">
          <table class="w-full text-left">
            <thead class=" bg-warm-gray-300 dark:bg-warm-gray-700">
              <tr>
                <th class="p-4">Subject ID</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="subject in course.subjects" class="hover:bg-warm-gray-100 dark:hover:bg-warm-gray-800">
                <td class="p-2 pl-4">{{subject}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import {XMarkIcon as XIcon} from '@heroicons/vue/24/solid';
import {getCourse} from '@/firebase/controllers/reviewerController';
export default {
  data() {
    return {
      course: null,
      curRevId:'',
      courseId: ''
    }
  },
  components:{
    XIcon
  },
  mounted() {
    if (this.$route.query.r && this.$route.params.course) {
      this.curRevId = this.$route.query.r;
      this.courseId = this.$route.params.course;
      this.getCourse()
    }
  },
  methods: {
    async getCourse(){
      let res = await getCourse(this.curRevId,this.courseId);
      this.course = res.data;
    }
  },
}
</script>