<template>
  <TransitionRoot as="template" :show="isProfileModalOpen">
      <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="this.closeProfileModal()">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
          </TransitionChild>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-bottom dark:bg-true-gray-800 bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4"></div>
              <div class="sm:flex sm:items-start">
                <!-- <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                </div> -->
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium dark:text-gray-200 text-warm-gray-900">
                    User Profile
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-warm-gray-500 dark:text-warm-gray-400">
                      <ul>
                        <li>ID: {{user.id}}</li>
                        <li>Date Signed Up: {{ moment.unix(user.date_created/1000).format('MMMM Do YYYY, h:mm:ss a') }}</li>
                      </ul>
                    </p>
                    <p class="text-sm text-warm-gray-500 dark:text-warm-gray-400 border-t border-gray-300 mt-4 pt-4">
                      <ul>
                        <li>Email: {{user.email}}</li>
                        <li>First Name: {{user.firstname}}</li>
                        <li>Last Name: {{user.lastname}}</li>
                      </ul>
                    </p>
                    <p class="text-sm text-warm-gray-500 dark:text-warm-gray-400 border-t border-gray-300 mt-4 pt-4">
                      <ul>
                        <li>Endorsed By: {{user.endorser}}</li>
                        <li v-if="user.type == 'endorser'">Endorser Code: {{user.endorser_code}}</li>
                        <!-- <li>Last Name: {{user}}</li> -->
                      </ul>
                    </p>
                    
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  @click="this.closeProfileModal()"
                  type="button"
                  class="mt-3 w-full inline-flex justify-center text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-700 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 rounded-md border dark:border-warm-gray-600 border-warm-gray-300 shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
</template>
 
<script>
import moment from "moment";

// import { 
//   ExclamationIcon 
// } from "@heroicons/vue/outline";


import {
  // for error alert
  ExclamationTriangleIcon as ExclamationIcon,
} from "@heroicons/vue/24/outline";


import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
export default {
  props: ["isProfileModalOpen", "user"],
  data(){
    return {
      moment: moment
    }
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    ExclamationIcon
  },
  methods: {
    // updateUser() {
    //   this.user.type = "endorser"
    //   this.$emit("updateUser");
    // },
    closeProfileModal() {
      this.$emit("closeProfileModal");
    },
  },
};
</script>