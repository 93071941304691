<template>

  <div v-if="!isRetrieving" class="flex justify-center mt-14 mb-2 text-warm-gray-600 dark:text-warm-gray-200">
    <!-- not found error display -->
    <div v-if="subjectIdNotFound" class="lg:w-7/12 border border-red-400 bg-yellow-100 text-yellow-600 text-center flex justify-center items-center p-6 rounded-md">
        <ExclamationCircleIcon class="w-6 h-6 mr-2"/>
        <span class=" font-semibold">Subject ID not found! <a href="#" @click="$router.go(-1)" class="underline text-yellow-700"> return </a> </span>
    </div>
    <!-- main form view -->
    <div v-else class="w-10/12 lg:w-7/12">

        <!-- Error Display -->
        <div v-if="error.for == 'add-update-subject'" class="rounded-md bg-yellow-50 p-4 mb-6">
          <div class="flex">
            <div class="flex-shrink-0">
              <ExclamationIcon
                class="h-5 w-5 text-yellow-600"
                aria-hidden="true"
              />
            </div>
            <div class="ml-3 text-yellow-700">
              <p class="font-bold">Error: Unable to save data.</p>
              <p class="text-sm">
                <ul class="list-disc ml-5">
                  <li v-for="(err,i) in error.errors" :key="i">{{err}}</li>
                </ul>
              </p>
            </div>
            <div class="ml-auto pl-3">
              <div class="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  class="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yelow-50 focus:ring-yelow-600 ">
                  <span class="sr-only">Dismiss</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>

       <!-- header 1 -->
      <div class="flex justify-between items-end">
        <div class="flex justify-start items-center pl-2">
          <h2 class="text-warm-gray-600 font-bold text-lg dark:text-warm-gray-300"> {{isAdd ? 'Add new subject': `Update ${currentSubjectName}`}}</h2>
        </div>
        <div class="flex justify-end items-center">
          <button @click="isAdd ? createSubject() : updateSubject()" class="bg-yellow-700 text-white font-semibold py-2 px-4 mr-2 rounded-md hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none ">Save</button>
          <button @click="$router.go(-1)" class="bg-warm-gray-300 font-semibold py-2 px-4 rounded-md hover:bg-warm-gray-400 focus:bg-warm-gray-400 focus:outline-none dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600">Return</button>
        </div>
      </div>  
      <!-- Subject Information Form -->
      <div class="border shadow rounded-md p-4 mt-4 dark:border-warm-gray-700">
        
        <div class="mb-4">
          <div class="flex justify-between items-center">
            <label for="subject-id" class="font-semibold">Subject ID</label>
            <div v-if="isAdd">
              <a v-if="!customId.isSet" href="#" @click="customId.isSet = true" class="text-xs text-yellow-600">Set custom id</a>
              <a v-else href="#" @click="customId.isSet = false" class="text-xs text-yellow-600">Auto generate id</a>
            </div>
          </div>
          <input v-model="subjectForm.id" id="subtopic-id" :class="[ customId.isSet ? 'dark:text-warm-gray-700':'text-warm-gray-400 dark:bg-warm-gray-800 dark:text-warm-gray-100 dark:border-warm-gray-700', 'w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:border-warm-gray-700']" :placeholder="customId.isSet ? 'Enter subtopic ID':'Auto generated subtopic ID'" :disabled="!customId.isSet" />
          <small v-if="!customId.isValid" class="text-red-600"> <b>Invalid format:</b> Spaces and special characters other than <code class="bg-red-100 px-1">_</code> and <code class="bg-red-100 px-1">–</code> are not allowed.</small>
        </div>

        <div class="mb-4">
          <div><label for="subject-name" class="font-semibold">Subject name</label></div>
          <input v-model="subjectForm.data.name" type="text" id="subject-name" class="w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" placeholder="Enter subject name" />
        </div>
        <div class="mb-4">
          <div><label for="subject-description" class="font-semibold">Description</label></div>
          <textarea v-model="subjectForm.data.description" id="subject-description" rows="3" placeholder="Enter subject description" class="w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700"></textarea>
        </div>
        <!-- exam limits -->
        <div class="mb-4">
          <div class="font-semibold mb-2">Exam Limits</div>

          <div class="grid xl:grid-cols-4 gap-4">
            <div class="border bg-gray-100 p-2 dark:border-warm-gray-700 dark:bg-warm-gray-700">
              <span class="flex justify-start items-center">
                <PaperClipIcon class="h-4 w-4  mr-1" />Passer
              </span>
              <input v-model="subjectForm.data.exam.limits.passer" type="number" id="subject-name" class="w-full p-2 mt-2 border rounded text-center focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" />
            </div>
            <div class="border bg-gray-100 p-2 dark:border-warm-gray-700 dark:bg-warm-gray-700">
              <span class="flex justify-start items-center">
                <LightningBoltIcon class="h-4 w-4 mr-1" />Speed Runner
              </span>
              <input v-model="subjectForm.data.exam.limits.speedrunner" type="number" id="subject-name" class="w-full p-2 mt-2 border rounded text-center focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" />
            </div>
            <div class="border bg-gray-100 p-2 dark:border-warm-gray-700 dark:bg-warm-gray-700">
              <span class="flex justify-start items-center">
                <FireIcon class="h-4 w-4 mr-1" />Master
              </span>
              <input v-model="subjectForm.data.exam.limits.master" type="number" id="subject-name" class="w-full p-2 mt-2 border rounded text-center focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" />
            </div>
            <div class="border bg-gray-100 p-2 dark:border-warm-gray-700 dark:bg-warm-gray-700">
              <span class="flex justify-start items-center">
                <ClockIcon class="h-4 w-4 mr-1" /> Time <span class=" text-warm-gray-500 text-xs ml-1">(in seconds)</span>
              </span>
              <input v-model="subjectForm.data.exam.limits.time" type="number" id="subject-name" class="w-full p-2 mt-2 border rounded text-center focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" />
            </div>
          </div>

        </div>
        <!-- exam points -->
        <div class="mb-4">
          <div class="font-semibold mb-2">Exam Points</div>

          <div class="grid xl:grid-cols-4 gap-4">
            <div class="border bg-gray-100 p-2 dark:border-warm-gray-700 dark:bg-warm-gray-700">
              <span class="flex justify-start items-center">
                <PaperClipIcon class="h-4 w-4  mr-1" />Passer
              </span>
              <input v-model="subjectForm.data.exam.points.passer" type="number" id="subject-name" class="w-full p-2 mt-2 border rounded text-center focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" />
            </div>
            <div class="border bg-gray-100 p-2 dark:border-warm-gray-700 dark:bg-warm-gray-700">
              <span class="flex justify-start items-center">
                <LightningBoltIcon class="h-4 w-4 mr-1" />Speed Runner
              </span>
              <input v-model="subjectForm.data.exam.points.speedrunner" type="number" id="subject-name" class="w-full p-2 mt-2 border rounded text-center focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" />
            </div>
            <div class="border bg-gray-100 p-2 dark:border-warm-gray-700 dark:bg-warm-gray-700">
              <span class="flex justify-start items-center">
                <FireIcon class="h-4 w-4 mr-1" />Master
              </span>
              <input v-model="subjectForm.data.exam.points.master" type="number" id="subject-name" class="w-full p-2 mt-2 border rounded text-center focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" />
            </div>
          </div>

        </div>
      </div>
      <!-- courses and subtopics table -->
      <div class="grid xl:grid-cols-2 xl:gap-8">
        <!-- courses table wrapper -->
        <div>
          <!-- header -->
          <div class="flex justify-between items-center mt-12 mb-2">
            <div class="flex justify-start items-center pl-2">
              <span class="text-warm-gray-500 dark:text-warm-gray-300">List of courses that this subject belongs to.</span>
            </div>
          </div>
          <!-- course table -->
          <div class="border rounded-md overflow-hidden dark:border-warm-gray-700">
            <table class="w-full text-left">
              <thead class=" bg-warm-gray-300 dark:bg-warm-gray-700">
                <tr>
                  <th class="p-4">Course ID</th>
                  <th class="w-16"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="p-2">
                    <select v-model="newCourseId" :class="[!newCourseId && ' text-warm-gray-400', 'p-2 w-full rounded border border-warm-gray-500 focus:outline-none focus:border-yellow-400 focus:shadow-md dark:text-warm-gray-700']">
                      <option value="" disabled>Select course id</option>
                      <option v-for="(course,i) in coursesForDropDown" :key="i" :value="course" class=" text-warm-gray-600">{{course.id}}</option>
                    </select>
                  </td>
                  <td class="p-2">
                    <button @click="addCourse" class=" bg-warm-gray-500 rounded border p-2 w-full text-white flex justify-center dark:bg-warm-gray-600 dark:border-warm-gray-500"><PlusIcon class=" h-5 w-5" /></button>
                  </td>
                </tr>
                <tr v-for="(course, i) in subjectForm.data.course" :key="i" class="hover:bg-warm-gray-100 text-white hover:text-warm-gray-600 border-b dark:hover:bg-warm-gray-800 dark:border-warm-gray-700">
                  <td class="p-2 pl-4 text-warm-gray-600 dark:text-warm-gray-200">{{course}}</td>
                  <td>
                    <div class="flex justify-center relative">
                      <span class="has-tooltip">
                        <XIcon @click="removeCourse(i)" class="w-4 h-4 cursor-pointer dark:text-warm-gray-500 dark:hover:text-warm-gray-200" />
                        <span class='tooltip rounded shadow-lg p-1 bg-warm-gray-600 text-xs text-warm-gray-300 -ml-8 mt-4'>Remove</span>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- subtopics table wrapper -->
        <div>
            <!-- header -->
            <div class="flex justify-between items-center mt-12 mb-2">
              <div class="flex justify-start items-center pl-2">
                <span class="text-warm-gray-500 dark:text-warm-gray-300">List of subtopics that are under this subject.</span>
              </div>
            </div>
            <!-- subtopics table -->
            <div class="border rounded-md overflow-hidden dark:border-warm-gray-700">
              <table class="w-full text-left">
                <thead class=" bg-warm-gray-300 dark:bg-warm-gray-700">
                  <tr>
                    <th class="p-4">Subtopic ID</th>
                    <th class="p-4 text-center w-28">items</th>
                    <th class="w-16"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  <td class="p-2">
                    <select v-model="newSubtopic.id" :class="[!newSubtopic.id && ' text-warm-gray-400', 'p-2 h-full w-full rounded border border-warm-gray-500 focus:outline-none focus:border-yellow-400 focus:shadow-md dark:text-warm-gray-700']">
                      <option value="" disabled>Select subtopic id</option>
                      <option v-for="(subtopic,i) in subtopicsForDropDown" :key="i" :value="subtopic.id" class=" text-warm-gray-600">{{subtopic.id}}</option>
                    </select>
                  </td>
                  <td class="p-2">
                    <input v-model="newSubtopic.item" type="number" id="subject-name" class="w-full p-2 border border-warm-gray-500 rounded text-center focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" />
                  </td>
                  <td class="p-2">
                    <button @click="addSubtopic"  class=" bg-warm-gray-500 rounded border p-2 w-full text-white flex justify-center dark:bg-warm-gray-600 dark:border-warm-gray-500"><PlusIcon class=" h-5 w-5" /></button>
                  </td>
                </tr>
                  <tr v-for="(subtopic,i) in subjectForm.data.exam.subtopics" :key="i" class="hover:bg-warm-gray-100 text-white hover:text-warm-gray-600 border-b dark:hover:bg-warm-gray-800 dark:border-warm-gray-700">
                    <td class="p-2 pl-4 text-warm-gray-600 dark:text-warm-gray-200">
                      {{subtopic}} 
                      <small v-if="subtopics.length > 0 && !subtopics.some(s => s.id == subtopic)" class="text-white text-xxs px-1 bg-red-300 rounded">ID not found</small>
                    </td>
                    <td class="p-2 pl-4 text-center text-warm-gray-600 dark:text-warm-gray-200">{{subjectForm.data.exam.items[i]}}</td>
                    <td>
                      <div class="flex justify-center">
                        <span class="has-tooltip relative">
                          <XIcon @click="removeSubtopic(i)" class="w-4 h-4 cursor-pointer dark:text-warm-gray-500 dark:hover:text-warm-gray-200" />
                          <span class='tooltip rounded shadow-lg p-1 bg-warm-gray-600 text-xs text-warm-gray-300 -ml-8 mt-4'>Remove</span>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
      </div>
    </div> 
    <ProcessingModal v-if="isProcessing" />
  </div>
  <div  v-else class="flex justify-center mt-10 ">
    <SpinningIcon class="h-6 w-6 text-yellow-600" />
  </div>

</template>
<script>
import swal from 'sweetalert2';
import {getSubject, getSubtopics, createSubject, updateSubject, getCourses, updateCourses} from '@/firebase/controllers/reviewerController';

import {
  BoltIcon as LightningBoltIcon,
  PaperClipIcon as PaperClipIcon,
  FireIcon as FireIcon,
  ClockIcon as ClockIcon, 
  ExclamationTriangleIcon as ExclamationIcon
} from '@heroicons/vue/24/outline';
import {
  ExclamationCircleIcon as ExclamationCircleIcon,
  XMarkIcon as XIcon,
  PlusIcon as PlusIcon
} from '@heroicons/vue/24/solid';


import SpinningIcon from '@/components/SpinningIcon';
import ProcessingModal from '@/components/ProcessingModal';

export default {
  data() {
    return {
      curRevId: '',
      isProcessing: false,
      isAdd: true,
      isRetrieving: false,
      subjectIdNotFound: false,
      courses: [],
      subtopics: [],
      coursesForDropDown: [],
      subtopicsForDropDown: [],
      subjectForm: {
        id: '',
        data: {
          icon: 'LightningBoltIcon',
          name: '',
          description: '',
          course: [],
          exam: {
              limits: {
                speedrunner: 0,
                passer: 0,
                time: 0,
                master: 0
              },
              points: {
                speedrunner: 0,
                passer: 0,
                master: 0
              },
              subtopics: [],
              items: []
          },
        }
      },
      newCourseId: '',
      customId:{
        isValid: true,
        isSet: false
      },
      newSubtopic: {
        id: '',
        item: 0
      },
      error:{
        for: '',
        errors: []
      },
      currentSubjectName: ''
    }
  },
  components:{
    LightningBoltIcon,
    PaperClipIcon,
    FireIcon,
    ClockIcon,
    XIcon,
    ExclamationCircleIcon,
    PlusIcon,
    ExclamationIcon,
    SpinningIcon,
    ProcessingModal
  },
  async mounted() {

    if (this.$route.query.r) {
      this.curRevId = this.$route.query.r;
    }

    if (this.$route.params.actionType == 'update') {
      this.isAdd = false;
      this.isRetrieving = true;
      await Promise.all([
        this.getSubtopics(),
        this.getCourses(),
        this.getSubject(),
      ]);
      this.isRetrieving = false;
    }else{
      await Promise.all([
        this.getSubtopics(),
        this.getCourses()
      ]);
      this.isAdd = true;
      this.coursesForDropDown = this.courses;
      this.subtopicsForDropDown = this.subtopics;
    }
  },
  watch:{
    'customId.isSet' : function(newVal){
      const format = /[`!@#$%^&*()+\\=\\[\]{};':"\\|,.<>\\/?~]/g;
      if (!newVal) {
        let id = this.subjectForm.data.name.replaceAll(' ', '-').toLowerCase();
        id = id.replaceAll(format, '');
        this.subjectForm.id = id;
      }
    },
    'subjectForm.data.name' : function(newVal){
      // check if auto generate id is active
      if(!this.customId.isSet && this.isAdd){
        // transform the new value to ID format.
        const format = /[`!@#$%^&*()+\\=\\[\]{};':"\\|,.<>\\/?~]/g;
        let id = newVal.replaceAll(' ', '-').toLowerCase();
        id = id.replaceAll(format, '');
        this.subjectForm.id = id;
      }
    },
    'subjectForm.id' : function(newVal){
      const format = /[ `!@#$%^&*()+\\=\\[\]{};':"\\|,.<>\\/?~]/g;
      if (format.test(newVal)) {
        this.customId.isValid = false;
      }else{
        this.customId.isValid = true;
      }
    },
    'subjectForm.data.course': {
      handler(newArr){
        this.coursesForDropDown = this.courses.filter((el)=>!newArr.includes(el.id));
      },
      deep: true
    },
    'subjectForm.data.exam.subtopics': {
      handler(newArr){
        this.subtopicsForDropDown = this.subtopics.filter((el)=>!newArr.includes(el.id));
      },
      deep: true
    },
    // curRevId(newVal, oldVal){
    //   if (this.$route.params.actionType == 'update'){
    //     this.getSubject();
    //   }
    // },
  },
  // updated() {
  //   this.curRevId = this.$route.query.r;
  // },
  methods: {
    async getSubject(){
      this.isRetrieving = true;
      const subjectId = this.$route.params.subject;
      const res = await getSubject(this.curRevId,subjectId);
      this.isRetrieving = false;
      if (res.isError) {
        this.subjectIdNotFound = true;
      }else{
        this.subjectForm = {id: subjectId, data: res.data}
        this.currentSubjectName = this.subjectForm.data.name;
      }
    },
    async getSubtopics(){
      const res = await getSubtopics(this.curRevId);
      this.subtopics = res.subtopics;
    },
    async getCourses(){
      const res = await getCourses(this.curRevId);
      this.courses = res.data;
    },
    addCourse(){
      if(this.newCourseId){
        this.subjectForm.data.course.push(this.newCourseId.id);
        this.newCourseId = '';
      }
    },
    addSubtopic(){
      if(this.newSubtopic.id && this.newSubtopic.item) {
        this.subjectForm.data.exam.subtopics.push(this.newSubtopic.id);
        this.subjectForm.data.exam.items.push(this.newSubtopic.item);
        this.newSubtopic.id = '';
        this.newSubtopic.item = 0;
      }
    },
    removeCourse(i){
      this.subjectForm.data.course.splice(i,1);
    },
    removeSubtopic(i){
      this.subjectForm.data.exam.subtopics.splice(i,1);
      this.subjectForm.data.exam.items.splice(i,1);
    },
    async createSubject(){
      this.error.for = '';
      this.error.errors = [];
      this.isProcessing = true;

      let res = await createSubject(this.curRevId,this.subjectForm);
      if (!res.data.isError) {

        // update reviewers.courses add this subject for each courses
        await this.updateReviewersCourse();

        let subjectName = this.subjectForm.data.name;
        // clear the subject form again
        this.subjectForm = {
          id: '',
          data: {
            icon: 'LightningBoltIcon',
            name: '',
            description: '',
            course: [],
            exam: {
                limits: {
                  speedrunner: 0,
                  passer: 0,
                  time: 0,
                  master: 0
                },
                points: {
                  speedrunner: 0,
                  passer: 0,
                  master: 0
                },
                subtopics: [],
                items: []
            },
          }
        }
        swal.fire(
            'Success!',
            `${subjectName} has been added.`,
            'success'
        );
      }else{
        this.error.for = 'add-update-subject';
        this.error.errors = res.data.error;
      }
      this.isProcessing = false;
    },
    async updateSubject(){
      this.isProcessing = true;
      this.error.for = '';
        this.error.errors = [];
      let res = await updateSubject(this.curRevId,this.subjectForm);
      if (!res.data.isError) {
        await this.updateReviewersCourse();
        this.getSubject();
        swal.fire(
            'Success!',
            `${this.subjectForm.data.name} has been updated.`,
            'success'
        );
      }else{
        this.error.for = 'add-update-subject';
        this.error.errors = res.data.error;
      }
      this.isProcessing = false;
    },
    async updateReviewersCourse(){

      // for adding
      // loop thru all courses
      // if the course id found in the subjectForm.data.course and subject id not found in courses.subject
      // then update the course add the subject id to subjects array
      // else if the course id not found in the subjectForm.data.course and subject id is found in courses.subject
      // then update the course delete the subject id to subjects array
      // then update the reviewer courses array
      this.courses.forEach(course => {
        let courseIsFound = this.subjectForm.data.course.some(c => c == course.id);
        let subjectIsFound = course.subjects.some(s => s == this.subjectForm.id);
         if(courseIsFound && !subjectIsFound){
          course.subjects = [...course.subjects, this.subjectForm.id] 
         }else if(!courseIsFound && subjectIsFound){
          course.subjects = [...course.subjects.filter(s => s != this.subjectForm.id)]
         }
      });

      

      await updateCourses(this.curRevId, this.courses);
      

    }
  },
}
</script>