<template>
  <div class="h-100 bg-white dark:bg-warm-gray-900">
    <main class="mt-4 sm:mt-8 pb-8">
      <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-full lg:px-8">
        <h1 class="sr-only">Profile</h1>
        <!-- <h1 class="text-base font-medium text-warm-gray-600 dark:text-gray-200">
          Hello there!
        </h1> -->
        <div>
            <!-- <table>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Endorser</th>
                    <th>Signup Date</th>
                </tr>
                <tr v-for="user in users" :key="user.id" >
                    <td>{{  user.firstname  }}</td>
                    <td>{{  user.lastname  }}</td>
                    <td>{{  user.email  }}</td>
                    <td>{{  user.phone  }}</td>
                    <td>{{  user.endorser  }}</td>
                    <td>{{moment.unix(user.date_created/1000).format('MM-DD-YY, h:mm:ss A')}}</td>
                </tr>
            </table> -->

            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                    <tr>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">First Name</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last Name</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Phone</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Endorser</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Signup Date</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">CAAP</th>
                        <!-- <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span class="sr-only">Edit</span>
                        </th> -->
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-for="(user, i) in filteredUsers" :key="user.id">
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ i+1 }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.firstname }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.lastname }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.email }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.phone }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.endorser }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ moment.unix(user.date_created/1000).format('MM-DD-YY, h:mm:ss A') }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.reviewers.caap.toUpperCase() }}</td>
                        <!-- <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a href="#" class="text-indigo-600 hover:text-indigo-900">Edit<span class="sr-only">, Lindsay Walton</span></a>
                        </td> -->
                    </tr>

                    </tbody>
                </table>
            </div>
            
        </div>
      </div>
    </main>
  </div>
</template>
  
  <script setup>
  import { ref } from "vue";

import {
  getDocs,
  collection,
  orderBy,
  query,
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
//   import { httpsCallable } from "firebase/functions";
import { auth, db, functions } from "@/firebase/config.js";

//   import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
//   import { DotsVerticalIcon } from '@heroicons/vue/solid'

import moment from "moment";

const NAME = "Dashboard";

console.log("Test");

let user = {};
user.id = auth.currentUser.uid;
user.email = auth.currentUser.email;

console.log({ user });

var users = ref([]);
var filteredUsers = ref([]);

auth.currentUser
  .getIdTokenResult()
  .then((tokenResult) => {
    console.log(
      `Claims retreived for user with id: ${user.id} => `,
      tokenResult.claims
    );

    /**
     * NOTE: Connects To Firebase
     * fetch the current logged in user's data
     */
    // db.collection("users").orderBy("date_created", "desc").get()
    getDocs(query(collection(db, "users"), orderBy("date_created", "desc")))
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          // only check if there are existing practice exams started

          querySnapshot.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;
            users.value.push(data);
          });
        //   users = users;
          //   console.log(users)
        } else {
          console.log("No previous mock exams were found!");
        }
      })
      .catch((error) => {
        console.error("Error getting user document:", error);
        throw `Error getting user document: ${error}`;
      })
      .finally(() => {
        console.log(users);
        filteredUsers.value = users.value.filter( u => (u.reviewers.caap === 'inactive') )
        console.log(filteredUsers.value)
      });
  })
  .catch((error) => {
    console.error("Error getting user id token", error);
    // TODO: Handle Error. Redirect.
  });
</script>