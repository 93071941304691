import { collection, query, where, getDocs, Timestamp, doc, onSnapshot, arrayUnion, updateDoc, addDoc } from "firebase/firestore";

import { db } from '@/firebase/config.js';

import store from '@/store';

let announcementUnsubscribe;
const announcementsRef = collection(db, "schedules", "announcements", "list");

export const announcementSeenByUser = async(uid, announcementId) => {

    const announcementRef = doc(db, "schedules", "announcements", "list", announcementId);

    // Atomically add a new region to the "regions" array field.
    await updateDoc(announcementRef, {
        seen_by: arrayUnion(uid)
    });

}

export const listenForAnnouncement = (subscribe) => {

    // listen for any changes or update in announcement
    if (subscribe) {
        announcementUnsubscribe = onSnapshot(doc(db, "schedules", "announcements"), (doc) => {
            console.log('Announcement change! checking for announcement...');
            setAnnouncements()
        });
    } else {
        if (announcementUnsubscribe) {
            announcementUnsubscribe();
        }
    }

}

const setAnnouncements = async() => {

    // check if user is logged in
    if (!store.state.user.credentials) return
        // get auth user uid
    const userUid = store.state.user.credentials.uid;

    // get all announcements where:
    // 1. status is active
    // 2. AND expires_at is greater than current date and time
    // 3. AND uid is not in seen_by array (!!!not possible in firestore db query!!!)
    const currentDate = Timestamp.now();
    let announcements = [];

    const q = query(announcementsRef,
        where("status", "==", "active"),
        where("expires_at", ">", currentDate)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {

        let announcement = doc.data();
        let millisecondsDifference;

        // check if user uid is not in seen_by field array
        if (!announcement.seen_by.includes(userUid)) {

            // calculate the remaining time to show the announcement
            // Check if announce_at exists and is a Firestore Timestamp
            if (announcement.announce_at instanceof Timestamp) {
                // Calculate the difference in milliseconds
                millisecondsDifference = (announcement.announce_at.toMillis() - currentDate.toMillis()) > 0 ? (announcement.announce_at.toMillis() - currentDate.toMillis()) : 0;
            } else {
                console.error('date_expires is not a valid Firestore Timestamp.');
            }

            announcements.push({ id: doc.id, data: announcement, show_after: millisecondsDifference })
        }
    });


    // create the setTimeout for each announcement
    announcements.forEach(async(announcement) => {

        setTimeout(async() => {

            // save to store announcementPopup if the type is popup
            if (announcement.data.type == 'pop-up') {
                await store.dispatch('setAnnouncementPopup', announcement);
            }

        }, announcement.show_after)

    })

}