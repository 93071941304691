import { collection, getDocs, Timestamp, doc, updateDoc, addDoc, query, orderBy } from "firebase/firestore";
import { db } from '@/firebase/config.js';

import store from '@/store'

const announcementsRef = collection(db, "schedules", "announcements", "list");

export const getAllAnnouncements = async() => {

    let announcements = [];
    const q = query(announcementsRef, orderBy("announce_at", "desc"));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        announcements.push({ id: doc.id, data: doc.data() })
    });

    return announcements;
}

const announcementUpdated = async() => {
    await updateDoc(doc(db, "schedules", "announcements"), {
        "updated_at": Timestamp.now()
    });
}


export const createAnnouncement = async(data) => {

    // check for the required data
    if (!data.title || !data.message || !data.announce_at || !data.expires_at || !data.type || !data.status) {
        return { error: true, message: 'Fill out all required fields.' }
    }

    // convert dates to instance of firebase/firestore Timestamp
    data.announce_at = Timestamp.fromDate(new Date(data.announce_at));
    data.expires_at = Timestamp.fromDate(new Date(data.expires_at));

    if (data.announce_at instanceof Timestamp && data.expires_at instanceof Timestamp) {
        try {
            const res = await addDoc(announcementsRef, data);
            announcementUpdated();
            return { error: false, data: res }
        } catch (error) {
            return { error: true, message: 'API call error...' }
        }
    } else {
        return { error: true, message: 'Invalid datetime!' }
    }


}