// set global variables here

const userColName = 'users';
const questionnaireColName = 'questionnaires';
const reviewersColName = 'reviewers';

export {
    userColName,
    questionnaireColName,
    reviewersColName
};