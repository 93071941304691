<template>
    <div class="h-full p-8 ">
        <p class="my-8">This will become the profile page of the logged in user.</p>
        <div class="flex space-x-4">
            <router-link 
            to="/logout"
            type="button" class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            Logout
        </router-link>

        <router-link 
            to="/dash"
            type="button" class="inline-flex items-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            V1 Dash
        </router-link>
        </div>
        
    </div>
</template>