import Users from '../views/Users.vue';


export const v3UsersRoutes = [{
    path: '/v3/users',
    name: 'v3Users',
    component: Users,
    meta: {
        requiresAuth: true
    }
}, ]