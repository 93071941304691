export const monitorRoutes = [
    {
        path: '/monitor',
        name: 'monitor',
        component: () =>
            import ('../views/Monitor.vue')
    },
    // {
    //     path: '/monitor/:subjectId',
    //     name: 'scorecard-details',
    //     component: () =>
    //         import ('../views/Details.vue')
    // },
]