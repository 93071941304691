<template>
  <div>
    <div class="flex justify-center mt-14 mb-2 text-warm-gray-600 dark:text-warm-gray-200">
      <div class=" w-10/12 lg:w-7/12">

        <!-- header 1 -->
        <div class="flex justify-between items-end">
        <div class="flex justify-start items-center pl-2">
          <h2 class="text-warm-gray-600 font-bold text-lg dark:text-warm-gray-300">Add new course</h2>
        </div>
        </div>  
        <!-- Course Information Form -->
        <div class="border shadow rounded-md p-4 mt-4 dark:border-warm-gray-700">
          <div class="mb-4">
            <div class="flex justify-between items-center">
              <label for="subject-id" class="font-semibold">Course ID</label>
            </div>
            <input v-model="courseForm.id" id="course-id" class="dark:text-warm-gray-700 w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:border-warm-gray-700" disabled />
            <!-- <small v-if="!customId.isValid" class="text-red-600"> <b>Invalid format:</b> Spaces and special characters other than <code class="bg-red-100 px-1">_</code> and <code class="bg-red-100 px-1">–</code> are not allowed.</small> -->
          </div>
          
          <div class="mb-4">
            <div><label for="course-name" class="font-semibold">Course name</label></div>
            <input type="text" id="course-name" class="w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" v-model="courseForm.name" placeholder="Enter course name" />
          </div>
          <div class="mb-4">
            <div><label for="course-description" class="font-semibold">Description</label></div>
            <textarea id="course-description" rows="3" v-model="courseForm.description" placeholder="Enter course description" class="w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700"></textarea>
          </div>
          <div class="flex justify-end items-center mt-6">
            <button @click="$router.go(-1)" class="mr-2 bg-warm-gray-300 font-semibold py-2 px-4 rounded-md hover:bg-warm-gray-400 focus:bg-warm-gray-400 focus:outline-none dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600">Cancel</button>
            <button @click="createCourse()" class="bg-yellow-700 text-white font-semibold py-2 px-4 rounded-md hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none ">Save</button>
          </div>
        </div>
      </div>
    </div>
      <ProcessingModal v-if="isProcessing" />
  </div>
</template>

<script>
import {createCourse} from '../../firebase/controllers/reviewerController'
import ProcessingModal from '@/components/ProcessingModal';
import swal from 'sweetalert2';
export default {
  data(){
    return {
      curRevId: '',
      isProcessing: false,
      courseForm:{
        id:'',
        name: '',
        description: '',
        subjects: []
      }
    }
  },
  watch:{
    'courseForm.name' : function(newVal){
      // transform the new value to ID format.
      const format = /[`!@#$%^&*()+\\=\\[\]{};':"\\|,.<>\\/?~]/g;
      let id = newVal.replaceAll(' ', '-').toLowerCase();
      id = id.replaceAll(format, '');
      this.courseForm.id = id;
    },
  },
  components:{
    ProcessingModal
  },
  mounted() {
    if (this.$route.query.r) {
      this.curRevId = this.$route.query.r;
    }
  },
  methods: {

    async createCourse(){
      this.isProcessing = true;
      let res = await createCourse(this.curRevId,this.courseForm);
      if (!res.data.isError) {

        // clear the course form again
        this.courseForm = {
          id: '',
          name:'',
          description: '',
          subjects: []
        }
        swal.fire(
            'Success!',
            `Course has been added.`,
            'success'
        );
      }
      this.isProcessing = false;
    },
  },

}
</script>