<template>
    <div class="p-8">
        <!-- greeting banner -->
        <div class="relative bg-yellow-300 p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
            <div class="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block" aria-hidden="true">
              <svg width="319" height="198" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="welcome-a" d="M64 0l64 128-64-20-64 20z"></path><path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z"></path><path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z"></path><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="welcome-b"><stop stop-color="#f2d6a5" offset="0%"></stop><stop stop-color="#f59505" offset="100%"></stop></linearGradient><linearGradient x1="50%" y1="24.537%" x2="50%" y2="100%" id="welcome-c"><stop stop-color="#ca9038" offset="0%"></stop><stop stop-color="#f1b863" stop-opacity="0" offset="100%"></stop></linearGradient></defs><g fill="none" fill-rule="evenodd"><g transform="rotate(64 36.592 105.604)"><mask id="welcome-d" fill="#fff"><use xlink:href="#welcome-a"></use></mask><use fill="url(#welcome-b)" xlink:href="#welcome-a"></use><path fill="url(#welcome-c)" mask="url(#welcome-d)" d="M64-24h80v152H64z"></path></g><g transform="rotate(-51 91.324 -105.372)"><mask id="welcome-f" fill="#fff"><use xlink:href="#welcome-e"></use></mask><use fill="url(#welcome-b)" xlink:href="#welcome-e"></use><path fill="url(#welcome-c)" mask="url(#welcome-f)" d="M40.333-15.147h50v95h-50z"></path></g><g transform="rotate(44 61.546 392.623)"><mask id="welcome-h" fill="#fff"><use xlink:href="#welcome-g"></use></mask><use fill="url(#welcome-b)" xlink:href="#welcome-g"></use><path fill="url(#welcome-c)" mask="url(#welcome-h)" d="M40.333-15.147h50v95h-50z"></path></g></g></svg>
            </div>
          <div class="relative"><h1 class="text-2xl md:text-3xl text-slate-800 font-bold mb-1 text-warm-gray-800">{{greeting}} 👋</h1><p class="text-warm-gray-800">Welcome back! Here is the overview of our Skilltech project today:</p></div>
        </div>

        <!-- counters card -->
        <div class="">
          <div class="grid sm:grid-cols-4 gap-5">

            <div class="bg-warm-gray-100 flex flex-col items-center justify-center p-4 rounded-md lg:transform hover:scale-105 hover:shadow-lg transition-transform duration-200 border border-warm-gray-200 text-yellow-600 shadow-md dark:bg-warm-gray-700 dark:border-warm-gray-600">
              <UserGroupIcon class="h-8 w-8"/>
              <h3 class="text-warm-gray-700 tracking-wide mt-2 text-center dark:text-warm-gray-200">Signups</h3>
              <h1 class="text-4xl mt-4 font-bold">{{ totalSignups }}</h1>
            </div>
<!-- 
            <div class="bg-warm-gray-100 flex flex-col items-center justify-center p-4 rounded-md lg:transform hover:scale-105 hover:shadow-lg transition-transform duration-200 border border-warm-gray-200 text-yellow-600 shadow-md dark:bg-warm-gray-700 dark:border-warm-gray-600">
              <AccountCheckOutline  class="h-10 w-10"/>
              <h3 class="text-warm-gray-700 tracking-wide mt-2 text-center dark:text-warm-gray-200">Completed Signups</h3>
              <h1 class="text-4xl mt-4 font-bold">{{ totalCompletedSignups }}</h1>
            </div> -->

            <div class="bg-warm-gray-100 flex flex-col items-center justify-center p-4 rounded-md lg:transform hover:scale-105 hover:shadow-lg transition-transform duration-200 border border-warm-gray-200 text-yellow-600 shadow-md dark:bg-warm-gray-700 dark:border-warm-gray-600">
              <EmojiHappyIcon class="h-8 w-8"/>
              <h3 class="text-warm-gray-700 tracking-wide mt-2 text-center dark:text-warm-gray-200">Current Online</h3>
              <h1 class="text-4xl mt-4 font-bold">{{onlineUsers}}</h1>
            </div>

            <div class="bg-warm-gray-100 flex flex-col items-center justify-center p-4 rounded-md lg:transform hover:scale-105 hover:shadow-lg transition-transform duration-200 border border-warm-gray-200 text-yellow-600 shadow-md dark:bg-warm-gray-700 dark:border-warm-gray-600">
              <ShoppingCartIcon class="h-8 w-8"/>
              <h3 class="text-warm-gray-700 tracking-wide mt-2 text-center dark:text-warm-gray-200">CAAP Purchases</h3>
              <h1 class="text-4xl mt-4 font-bold">{{ totalPurchases }}</h1>
            </div>

            <div class="bg-warm-gray-100 flex flex-col items-center justify-center p-4 rounded-md lg:transform hover:scale-105 hover:shadow-lg transition-transform duration-200 border border-warm-gray-200 text-yellow-600 shadow-md dark:bg-warm-gray-700 dark:border-warm-gray-600">
              <CashIcon class="h-8 w-8"/>
              <h3 class="text-warm-gray-700 tracking-wide mt-2 text-center dark:text-warm-gray-200">Payments</h3>
              <h1 class="text-4xl mt-4 font-bold">{{ totalPaymentForReview }}</h1>
            </div>
              
          </div>
        </div>

        <!-- second row container -->
        <div class="lg:flex lg:space-x-8 mt-8">

          <!-- leaderboards -->
          <div class="lg:w-1/2">
            <h1 class=" font-semibold text-warm-gray-500 mb-2">Leaderboard: Top 10 🎯</h1>
            <div class=" overflow-hidden rounded ">
            <table class="w-full">
              <thead class="bg-warm-gray-400 text-warm-gray-800 dark:bg-warm-gray-800">
                <tr class="dark:text-warm-gray-200">
                  <th class="p-2">Rank</th>
                  <th class="p-2 text-left">Users</th>
                  <th class="p-2">Skillpoints</th>
                </tr>
              </thead>
              <tbody class="text-center text-warm-gray-700 bg-warm-gray-100 dark:bg-warm-gray-700 dark:text-warm-gray-200">
                <tr v-for="(user,i) in leaderboard" :key="i" class="border-b">
                  <td> {{ getOrdinal(user.rank)}} </td>
                  <td class="flex items-center justify-start py-1">
                    <img v-if="user.photo" class="object-cover h-10 w-10 rounded-full mr-2" :src="user.photo" alt="" />
                    <DefaultUser v-else class="h-10 w-10 rounded-full mr-2" />
                    <div class="flex flex-col items-start">
                      <span class=" font-semibold">{{user.firstname}} {{user.lastname}}</span>
                      <span class=" text-xs text-warm-gray-500">{{user.email}}</span>
                    </div> 
                  </td>
                  <td>
                    <span class="ml-2 inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700 bg-yellow-400 hover:bg-yellow-500 dark:text-black">
                        <Logo class="w-3.5 h-3.5 mr-1" />
                        {{ user.skillpoints }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
            
          </div>

          <!-- Top Endorser -->
          <div class="lg:w-1/2">
            <h1 class=" font-semibold text-warm-gray-500 mb-2">Top Endorser 🚀</h1>
            <div class=" overflow-hidden rounded ">
            <table class="w-full">
              <thead class="bg-warm-gray-400 text-warm-gray-800 dark:bg-warm-gray-800">
                <tr class="dark:text-warm-gray-200">
                  <th class="p-2">Rank</th>
                  <th class="p-2 text-left">Users</th>
                  <th class="p-2">Endorsed</th>
                </tr>
              </thead>
              <tbody class="text-center text-warm-gray-700 bg-warm-gray-100 dark:bg-warm-gray-700 dark:text-warm-gray-200">
                <tr v-for="(endorser,i) in topEndorsers" :key="i" class="border-b">
                  <td> {{ getOrdinal(i+1)}} </td>
                  <td v-if="endorser.user" class="flex items-center justify-start py-1">
                    <img v-if="endorser.user.photo" class="object-cover h-10 w-10 rounded-full mr-2" :src="endorser.user.photo" alt="" />
                    <DefaultUser v-else class="h-10 w-10 rounded-full mr-2" />
                    <div class="flex flex-col items-start">
                      <span class=" font-semibold">{{endorser.user.firstname}} {{endorser.user.lastname}} <span class=" text-purple-700">[{{endorser.user.endorser_code}}]</span> </span>
                      <span class=" text-xs text-warm-gray-500">{{endorser.user.email}}</span>
                    </div> 
                  </td>
                  <td>
                    {{ endorser.totalEndorsed }}
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
            
          </div>

        </div>


    </div>
</template>
<script setup>

import { ref as reffb, onValue, off} from "firebase/database";
import { rtdb } from '@/firebase/config.js';

import { ref, onMounted, onBeforeUnmount } from 'vue';
import store from '../store';
// import { UserGroupIcon, EmojiHappyIcon , CashIcon, ShoppingCartIcon } from '@heroicons/vue/outline';
import { 
  UserGroupIcon as UserGroupIcon, 
  FaceSmileIcon as EmojiHappyIcon, 
  BanknotesIcon as CashIcon, 
  ShoppingCartIcon as ShoppingCartIcon 
} from '@heroicons/vue/24/outline';

import {fetchLeaderboard, sortLeaderboard} from '@/firebase/controllers/leaderboardController';
import {fetchUserFromFirebase, fetchUsersLoggedIn, getUsers} from '@/firebase/controllers/userController';
import DefaultUser from '@/components/DefaultUser';
import Logo from '../components/Logo.vue';
import {groupBy, sortBy} from 'lodash';

const greeting = ref('');
const leaderboard = ref([]);
const users = ref([]);
const totalSignups = ref(0);
const totalPurchases = ref(0);
const totalPaymentForReview = ref(0);
const totalUsersLoggedIn = ref(0);
const topEndorsers = ref([]);
const onlineUsers = ref(0);

onMounted(()=>{
  console.log('mounted test home...');
  let userFirstName = store.state.user.otherInfo.firstname;
  let curHour = (new Date()).getHours();
  if (curHour < 12) {
    greeting.value = `Good morning, ${userFirstName}.`;
  }else if (curHour < 17) {
    greeting.value = `Good afternoon, ${userFirstName}.`;
  }else{
    greeting.value = `Good evening, ${userFirstName}.`;
  }

  fetchUsers();
  getUsersPresence()
  loadForLeaderboard();
})

onBeforeUnmount(()=>{
  stopListeningUsersPresence()
})


// call this on mounted
const  getUsersPresence = async() =>{
      const userAdminRef = reffb(rtdb, 'users-app');
      console.log('listening to app user presence change...')
      onValue(userAdminRef, (snapshot) => {
        onlineUsers.value = 0;
        snapshot.forEach(childSnapshot => {
          // onlineUsers.value.push({uid: childSnapshot.key, data: childSnapshot.val()})
          if (childSnapshot.val().status == 'online') {
            onlineUsers.value = onlineUsers.value + 1
          }
        })
        // console.log(onlineUsers.value)
      })
    }


    // call this before unmount
const stopListeningUsersPresence = () => {
      console.log('stop listening to app user presence change...')
      const userAdminRef = reffb(rtdb, 'users-app');
      off(userAdminRef);
    }


const fetchUsers = async() => {
  let res = await fetchUserFromFirebase();

  if (res.success) {
    users.value = res.data;

    // calc total users sign in
    totalSignups.value = users.value.length;

    // calc total users purchase caap
    totalPurchases.value = users.value.filter((user) => user.reviewers.caap == 'active').length;

    // calc total users made payment and need to review
    totalPaymentForReview.value = users.value.filter((user) => user.reviewers.caap == 'paid').length;
    
    calcUsersLoggedIn();

    // get top endorser
    getTopEndorsers();


  }

}

const getTotalUsersAcountCount = async() => {

  let res;
  let pageCount;
  let totalUsersAccountCount = 0;

  res = await getUsers(100);
  pageCount = res.data.data.data.length

  // if page count is greater than 1, calculate the users count
  if (res.data.data.data.length > 1) {
    // get the count of users on the last page
    let lastPageUsersCount = res.data.data.data[pageCount - 1].listUsersResult.users.length;
    let prevPagesUsersCount = 100 * (pageCount - 1)
    totalUsersAccountCount = prevPagesUsersCount + lastPageUsersCount
  }else{
    totalUsersAccountCount = res.data.data.data[0].listUsersResult.users.length
  }
  

  return totalUsersAccountCount;
  

}

const calcUsersLoggedIn = async() => {

  // let unboardedUsersCount;
  // let usersAccountCount = await getTotalUsersAcountCount();

  let res = await fetchUsersLoggedIn();
  if (res.success) {
    // console.log(usersAccountCount, totalSignups.value)
    // unboardedUsersCount = usersAccountCount - totalSignups.value;
    // totalUsersLoggedIn.value = res.data.length - unboardedUsersCount;
    totalUsersLoggedIn.value = res.data.length;
  }
}

const loadForLeaderboard = async() => {

  var all_leaderboard
    all_leaderboard = await fetchLeaderboard();
    all_leaderboard = sortLeaderboard(all_leaderboard) // sort the leaderboard  
    all_leaderboard.splice(10) // splice the all_leaderboard array to only top 20
    leaderboard.value = all_leaderboard;
    // console.log(all_leaderboard);

}


const getOrdinal = (i) => { 
  var j = i % 10, k = i % 100;
  if (j == 1 && k != 11) { return i + "st"; }
  if (j == 2 && k != 12) { return i + "nd"; }
  if (j == 3 && k != 13) { return i + "rd"; }
  return i + "th";
}

const getTopEndorsers = () => {
  const endorserCodes = [];
  const endorserCodesGrouped = groupBy(users.value, user=>user.endorser);
  for (const [key,val] of Object.entries(endorserCodesGrouped)) {
    if(key && key != 'undefined'){
      let user = users.value.find((u)=>u.endorser_code==key)
      if(user){
        endorserCodes.push({user: user, totalEndorsed: val.length})
      }
    }
  }
  topEndorsers.value = sortBy(endorserCodes,['totalEndorsed']).reverse().slice(0,10);
}

</script>