<template>

    <!-- <header v-if="!isInitializing">
      <div class="h-header border-b border-gray-300 ">
          <ul class="flex w-full h-header justify-between">
              <li 
                v-for="view, i in VIEWS"
                @click="activeView = view"
                :key="view" 
                :class="[
                    (activeView == view) ? 'border-b-4 border-gray-500' : '',
                    
                    'w-full flex items-center justify-center'
                ]">
                <a href="#"   
                class="cursor-pointer px-4 py-1 text-xs sm:text-sm font-base leading-4 text-gray-700 h-full w-full flex items-center justify-center"
                :class="(i == 0) ? '' : 'border-l border-gray-300 border-dashed'"
                >
                  <span>
                      {{ view }}
                  </span>
                </a>
              </li>
          </ul>
      </div>
    </header> -->

    <main v-if="!isInitializing" class="overflow-auto h-full p-4 scrollbar-hide">
      <ul class="flex flex-col space-y-4">
        <li 
        :class="[
          (isWaiting) ? 'pointer-events-none cursor-wait opacity-25' : '',
          'border border-gray-300 rounded-md px-8 py-4 text-xs flex flex-col divide-y divide-gray-300 ']">

          
            
          <div class="py-4">
            <h1 class="font-bold text-sm">Personal Info</h1>
            <img v-if="user.photo" class="object-cover h-10 w-10 rounded-full mr-2 my-2" :src="user.photo" alt="" />
            <div v-else class="bg-gray-100 rounded-full overflow-hidden h-10 w-10 mr-2 my-2">
              <svg
                class="w-full h-full text-gray-300"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                ></path>
              </svg>
            </div>
            <p><strong class="font-semibold">Full Name:</strong> {{ user.firstname }} {{ user.lastname }}</p>
            <p><strong class="font-semibold">Age:</strong> {{ user.age }}</p>
            <p><strong class="font-semibold">Gender:</strong> {{ user.gender }}</p>
          </div>
          <div class="py-4">
            <h1 class="font-bold text-sm">Contact Info</h1>
            <p><strong class="font-semibold">Email:</strong> {{ user.email }}</p>
            <p><strong class="font-semibold">Phone:</strong> {{ user.phone }}</p>
            <p><strong class="font-semibold">Street:</strong> {{ user.street }}</p>
            <p><strong class="font-semibold">City:</strong> {{ user.city }}</p>
            <p><strong class="font-semibold">Region:</strong> {{ user.region }}</p>
            <p><strong class="font-semibold">Postal:</strong> {{ user.postal }}</p>
          </div>

          <div class="py-4">
            <h1 class="font-bold text-sm">Academic Info</h1>
            <p><strong class="font-semibold">School:</strong> {{ (schools.filter( e => e.id == user.school).length > 0) ? schools.filter( e => e.id == user.school)[0].name : 'No School Found'}}</p>
            <p><strong class="font-semibold">Course:</strong> {{ user.course }}</p>
            <p><strong class="font-semibold">Level:</strong> {{ user.level }}</p>
            <p><strong class="font-semibold">Batch:</strong> {{ user.batch }}</p>
          </div>

          <div v-if="schools.filter( e => e.id == user.school).length > 0" class="py-4">
            <h1 class="font-bold text-sm">School Info</h1>
            <img v-if="schools.filter( e => e.id == user.school).length > 0" class="object-cover h-10 w-10 rounded-full mr-2 my-2" :src="schools.filter( e => e.id == user.school)[0].logoUrl" alt="" />
            <div v-else class="bg-gray-100 rounded-full overflow-hidden h-10 w-10 mr-2 my-2">
              <svg
                class="w-full h-full text-gray-300"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                ></path>
              </svg>
            </div>
            <p><strong class="font-semibold">School ID:</strong> {{ schools.filter( e => e.id == user.school)[0].id }}</p>
            <p><strong class="font-semibold">School Name:</strong> {{ schools.filter( e => e.id == user.school)[0].name }}</p>
            <p><strong class="font-semibold">School Shorthand:</strong> {{ schools.filter( e => e.id == user.school)[0].shorthand }}</p>
          </div>

          <div class="py-4">
            <h1 class="font-bold text-sm">Account Info</h1>
            <p><strong class="font-semibold">User ID:</strong> {{ user.id }}</p>
            <p><strong class="font-semibold">Skillpoints:</strong> {{ user.skillpoints }}</p>
            <p><strong class="font-semibold">Date Signed Up:</strong> {{ moment.unix(user.date_created/1000).format('LLLL') }}</p>
            <p><strong class="font-semibold">Endorsed By:</strong> {{ user.endorser }}</p>
            <p><strong class="font-semibold">Account Type:</strong> {{ user.type }}</p>
            <p v-if="user.type == 'endorser'"><strong class="font-bold">Endorser Code:</strong> {{ user.endorser_code }}</p>
            <div class="bg-gray-100 py-3 mt-4 text-xs text-black px-3">
              <code>
                {{ user.reviewers }}
              </code>
            </div>
            <br/>
            <button class="underline" @click="fetchUserClaims(user.email)">Fetch user custom claims</button>
          </div>

          <!-- CAAP Info -->
          <div class="py-4">
            <h1 class="font-bold text-sm">CAAP Info</h1>
            <p><strong class="font-semibold">CAAP Status:</strong> {{ user.reviewers.caap }}</p>
            <p><strong class="font-semibold">Purchase Date:</strong> {{ (user.caap_data && user.caap_data.purchase_date) ? moment.unix(user.caap_data.purchase_date/1000).format('LLLL') : '-'}}</p>
            <p><strong class="font-semibold">GCash Ref. No.:</strong> {{ (user.caap_data && user.caap_data.gcash_refno) ? user.caap_data.gcash_refno : '-' }}</p>
            <p><strong class="font-semibold">Activate Later?</strong> {{ (user.caap_data && user.caap_data.activateLater) ? user.caap_data.activateLater : '-'}}</p>
            <p><strong class="font-semibold">Activation Date:</strong> {{ (user.caap_data && user.caap_data.activation_date) ? moment.unix(user.caap_data.activation_date.seconds).format('LLLL') : '-'}}</p>
            <p><strong class="font-semibold">Expiration Date:</strong> {{ (user.caap_data && user.caap_data.expiration_date) ? moment.unix(user.caap_data.expiration_date.seconds).format('LLLL') : '-'}}</p>
            <div v-if="user.caap_data && user.caap_data.isBundled" class="pt-2">
              <span class="py-0.5 px-2 rounded-full border bg-warm-gray-200">PURCHASED IN BUNDLE </span>
            </div>

            <button v-if="user.reviewers.caap == 'active' && user.caap_data && user.caap_data.expiration_date" class="underline mt-2 text-purple-600 flex justify-center items-center" @click="openExpirationDateModal(user.caap_data.expiration_date, 'caap')">
              <CalendarDaysIcon class="w-4 h-4"/> UPDATE EXPIRATION DATE
            </button>

            <div class="bg-gray-100 py-3 mt-4 text-xs text-black px-3">
              <code>
                {{ user.caap_data }}
              </code>
            </div>

            <div class="flex justify-start items-center gap-2">

              <button v-if="user.reviewers.caap == 'inactive'" class="underline mt-4 " @click="purchaseForUser(user,'caap')">PURCHASE FOR USER (3 Months)</button>
              <button v-if="user.reviewers.caap == 'inactive'" class="underline mt-4 " @click="purchaseForUser(user,'caap', 6)">PURCHASE FOR USER (6 Months)</button>

              <button v-if="user.reviewers.caap == 'active' || user.reviewers.caap == 'expired'" class="underline mt-4 text-red-600 flex justify-center items-center" @click="revokePurchase(user,'caap')">
                <HandThumbDownIcon class="w-4 h-4"/> REVOKE
              </button>
              <div v-if="user.isCaapBlocked" class=" w-min">
                <router-link :to="{path: '/soft-blocks'}" class="underline mt-4 text-green-600 flex justify-start items-center">
                  <LockOpenIcon class="w-4 h-4"/> UNBLOCKED
                </router-link>
              </div>
            </div>
            

            <h2 class="font-semibold mt-2">Mock Exam Statistics:</h2>
            <ul class="ml-6">
              <li v-for="subject in user.subjects" :key="subject.id" class="list-disc">
                <span class="font-semibold">Subject Id:</span> {{ subject.id }}
                <ul class="ml-6">
                    <li class="list-disc">
                      <span class="font-semibold">Best Time:</span> {{ moment.utc(subject.time*1000).format('HH:mm:ss') }} or {{ subject.time }}s
                    </li>
                    <li class="list-disc">
                      <span class="font-semibold">Best Score:</span> {{ subject.score }}%
                    </li>
                    <li class="list-disc">
                      <span class="font-semibold">Milestones:</span> <span v-for="milestone in subject.milestones" :key="milestone">{{ milestone }}, </span>
                    </li>
                  </ul>
              </li>
            </ul>
            <h2 class="font-semibold">Achievements:</h2>
            <ul class="ml-6">
              <li v-for="achievement in user.achievements" :key="achievement" class="list-disc">
                {{ achievement }}
              </li>
            </ul>

            <h2 class="font-semibold">Last Practices (mm/dd/yyyy):</h2>
            <ul class="ml-6">
              <li v-for="practice in lastPractices" :key="practice.id" class="list-disc">
                {{ practice.data.time.start }}
              </li>
            </ul>
            <h2 class="font-semibold">Last Mock Exams (mm/dd/yyyy):</h2>
            <ul class="ml-6">
              <li v-for="exam in lastExams" :key="exam.id" class="list-disc">
                {{ exam.data.time.start }}
              </li>
            </ul>
          </div>

          <!-- CAAP Lite Info -->
          <div class="py-4">
            <h1 class="font-bold text-sm">CAAP Lite Info</h1>
            <p><strong class="font-semibold">CAAP Lite Status:</strong> {{ user.reviewers.caap_lite ? user.reviewers.caap_lite : '-' }}</p>
            <p><strong class="font-semibold">Purchase Date:</strong> {{ (user.caap_lite_data && user.caap_lite_data.purchase_date) ? moment.unix(user.caap_lite_data.purchase_date/1000).format('LLLL') : '-'}}</p>
            <p><strong class="font-semibold">GCash Ref. No.:</strong> {{ (user.caap_lite_data && user.caap_lite_data.gcash_refno) ? user.caap_lite_data.gcash_refno : '-' }}</p>

            <button v-if="!user.reviewers.caap_lite || user.reviewers.caap_lite == 'inactive'" class="underline mt-4 " @click="purchaseForUser(user, 'caap_lite')">PURCHASE FOR USER</button>
            <button v-if="user.reviewers.caap_lite == 'active'" class="underline mt-4 text-red-600 flex justify-center items-center" @click="revokePurchase(user,'caap_lite')">
              <HandThumbDownIcon class="w-4 h-4"/> REVOKE
            </button>
          </div>

          <!-- CAAP Plus Info -->
          <div class="py-4">
            <h1 class="font-bold text-sm">CAAP Plus Info</h1>
            <p><strong class="font-semibold">CAAP Plus Status:</strong> {{ user.reviewers.caap_plus ? user.reviewers.caap_plus : '-' }}</p>
            <p><strong class="font-semibold">Purchase Date:</strong> {{ (user.caap_plus_data && user.caap_plus_data.purchase_date) ? moment.unix(user.caap_plus_data.purchase_date/1000).format('LLLL') : '-'}}</p>
            <p><strong class="font-semibold">GCash Ref. No.:</strong> {{ (user.caap_plus_data && user.caap_plus_data.gcash_refno) ? user.caap_plus_data.gcash_refno : '-' }}</p>

            <p><strong class="font-semibold">Activate Later?</strong> {{ (user.caap_plus_data && user.caap_plus_data.activateLater) ? user.caap_plus_data.activateLater : '-'}}</p>
            <p><strong class="font-semibold">Activation Date:</strong> {{ (user.caap_plus_data && user.caap_plus_data.activation_date) ? moment.unix(user.caap_plus_data.activation_date.seconds).format('LLLL') : '-'}}</p>
            <p><strong class="font-semibold">Expiration Date:</strong> {{ (user.caap_plus_data && user.caap_plus_data.expiration_date) ? moment.unix(user.caap_plus_data.expiration_date.seconds).format('LLLL') : '-'}}</p>

            <div v-if="user.caap_plus_data && user.caap_plus_data.isBundled" class="pt-2">
              <span class="py-0.5 px-2 rounded-full border bg-warm-gray-200">PURCHASED IN BUNDLE </span>
            </div>

            <button v-if="user.reviewers.caap_plus == 'active' && user.caap_plus_data && user.caap_plus_data.expiration_date" class="underline mt-2 text-purple-600 flex justify-center items-center" @click="openExpirationDateModal(user.caap_plus_data.expiration_date, 'caap_plus')">
              <CalendarDaysIcon class="w-4 h-4"/> UPDATE EXPIRATION DATE
            </button>

            <div class="bg-gray-100 py-3 mt-4 text-xs text-black px-3">
              <code>
                {{ user.caap_plus_data }}
              </code>
            </div>

            <button v-if="(!user.reviewers.caap_plus || user.reviewers.caap_plus == 'inactive')" class="underline mt-4 " @click="purchaseForUser(user, 'caap_plus')">PURCHASE FOR USER</button>
            <button v-if="user.reviewers.caap_plus == 'active'" class="underline mt-4 text-red-600 flex justify-center items-center" @click="revokePurchase(user,'caap_plus')">
              <HandThumbDownIcon class="w-4 h-4"/> REVOKE
            </button>
            <button v-if="user.isPlusBlocked" class="underline mt-4 text-green-600 flex justify-center items-center" @click="unblockedPlus(user)">
              <LockOpenIcon class="w-4 h-4"/> UNBLOCKED and Ext. 3 Months
            </button>
          </div>

          <!-- MTP Info -->
          <div class="py-4">
            <h1 class="font-bold text-sm">MTP Info</h1>
            <p><strong class="font-semibold">MTP Status:</strong> {{ user.reviewers.mtp ? user.reviewers.mtp : '-' }}</p>
            <p><strong class="font-semibold">Purchase Date:</strong> {{ (user.mtp_data && user.mtp_data.purchase_date) ? moment.unix(user.mtp_data.purchase_date/1000).format('LLLL') : '-'}}</p>
            <p><strong class="font-semibold">GCash Ref. No.:</strong> {{ (user.mtp_data && user.mtp_data.gcash_refno) ? user.mtp_data.gcash_refno : '-' }}</p>

            <p><strong class="font-semibold">Activate Later?</strong> {{ (user.mtp_data && user.mtp_data.activateLater) ? user.mtp_data.activateLater : '-'}}</p>
            <p><strong class="font-semibold">Activation Date:</strong> {{ (user.mtp_data && user.mtp_data.activation_date) ? moment.unix(user.mtp_data.activation_date.seconds).format('LLLL') : '-'}}</p>
            <p><strong class="font-semibold">Expiration Date:</strong> {{ (user.mtp_data && user.mtp_data.expiration_date) ? moment.unix(user.mtp_data.expiration_date.seconds).format('LLLL') : '-'}}</p>

            <button v-if="user.reviewers.mtp == 'active' && user.mtp_data && user.mtp_data.expiration_date" class="underline mt-2 text-purple-600 flex justify-center items-center" @click="openExpirationDateModal(user.mtp_data.expiration_date, 'mtp')">
              <CalendarDaysIcon class="w-4 h-4"/> UPDATE EXPIRATION DATE
            </button>

            <div class="bg-gray-100 py-3 mt-4 text-xs text-black px-3">
              <code>
                {{ user.mtp_data }}
              </code>
            </div>

            <button v-if="(!user.reviewers.mtp || user.reviewers.mtp == 'inactive')" class="underline mt-4 " @click="purchaseForUser(user, 'mtp')">PURCHASE FOR USER</button>
            <button v-if="user.reviewers.mtp == 'active'" class="underline mt-4 text-red-600 flex justify-center items-center" @click="revokePurchase(user,'mtp')">
              <HandThumbDownIcon class="w-4 h-4"/> REVOKE
            </button>
            <button v-if="user.isMtpBlocked" class="underline mt-4 text-green-600 flex justify-center items-center" @click="unblockedMtp(user)">
              <LockOpenIcon class="w-4 h-4"/> UNBLOCKED and Ext. 3 Months
            </button>
          </div>


          <div v-if="user.license" class="py-4">
            <h1 class="font-bold text-sm">License Info</h1>
            <p><strong class="font-semibold">Is Licensed:</strong> {{ user.license.isLicensed }}</p>
            <div v-if="user.license.isLicensed" class="ml-6">
              <ul>
                <li class="list-disc">
                  <p><strong class="font-semibold">Exam Date:</strong> {{ user.license.exam_date }}</p>
                </li>
                <li class="list-disc">
                  <p><strong class="font-semibold">Test Results:</strong> <a :href="user.license.test_results" target="_blank">View Here</a></p>
                </li>
              </ul>
            </div>
            <p><strong class="font-semibold">Is Verified:</strong> {{ user.license.isVerified }}</p>
            <p><strong class="font-semibold">Is Revoked:</strong> {{ user.license.isRevoked }}</p>

            <button v-if="user.license && user.license.isVerified" class="underline mt-4 " @click="revokeLicense(user)">REVOKE LICENSE</button>

          </div>

        
        </li>
      </ul>
    </main>


    <!-- Waiting Screen -->
    <main v-if="isInitializing" class="overflow-auto h-full w-full flex justify-center items-center">
        <Logo class="-mt-24 w-24 animate-pulse text-warm-gray-300" />
    </main>

    <!-- Notification -->
    <div v-if="isWaiting" aria-live="assertive" class="absolute pointer-events-none bottom-14 inset-0 flex items-end px-2 py-4">
      <div class="flex w-full flex-col space-y-4 items-center">
        <!--
          Notification panel, dynamically insert this into the live region when it needs to be displayed

          Entering: "transform ease-out duration-300 transition"
            From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            To: "translate-y-0 opacity-100 sm:translate-x-0"
          Leaving: "transition ease-in duration-100"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="pointer-events-auto w-auto max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="px-6 py-3">
            <div class="flex items-center justify-between">
              <p class="flex-1 text-sm text-center font-medium text-gray-900">{{ notificationMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>


     <!-- Update Expiration Date Modal -->
  <TransitionRoot appear :show="isExpirationDateModalOpen" as="template">
    <Dialog as="div" @close="closeExpirationDateModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">

          <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            >
            <div
              class="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
            >

              <div class="flex-row items-start text-warm-gray-700 p-6">

                <div class="w-full">
                  <label for="email">Expiration date:</label>
                  <input type="date" v-model="expirationDateField" class="w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-gray-500 mt-2">
                  <!-- <input type="email" id="email" class="w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" placeholder="Enter email address" /> -->
                  <!-- <p  v-if="isGettingUid" class="text-yellow-600 flex justify-start items-center">
                    <SpinningIcon class="h-4 w-4 mr-1" />
                    <small>Checking for email... </small>
                  </p> -->
                  <!-- <p v-if="!isGettingUid && addForm.uid" class=" text-sm dark:text-yellow-600 text-yellow-700">User found!</p> -->
                </div>

                <div class="mt-2 flex justify-end w-full gap-2">
                  <button
                    type="button"
                    class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    @click="closeExpirationDateModal"
                    >
                    Cancel
                  </button>

                  <button
                    type="button"
                    class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-purple-300 border border-transparent rounded-md hover:bg-purple-400 focus:bg-purple-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    @click="saveExpirationDate()"
                    >
                    Update
                  </button>

                </div>

              </div>

              <!-- <div class="border-t p-6 pt-3 flex justify-between dark:border-warm-gray-600">
                <div>
                 

                  <button
                    type="button"
                    :class=" [ !addForm.uid || isAdding ? 'disabled:cursor-not-allowed text-warm-gray-300 bg-red-300' : 'text-warm-gray-100 bg-red-600 hover:bg-red-800 focus:bg-red-800 dark:bg-red-700 dark:hover:bg-red-600 dark:focus:bg-red-600 dark:text-warm-gray-200' , 'ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500']"
                    @click="block"
                    :disabled="!addForm.uid || isAdding"
                    >
                    <SpinningIcon class="h-4 w-4 mr-1" v-if="isUpdating" />
                    Update
                  </button>
                </div>
              </div> -->
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  

</template>
<script setup>

import ROUTER from "@/router";

import { ref, computed } from "vue";
import moment from "moment";
import { getDocs, getDoc, collection, orderBy, query, doc, updateDoc, setDoc, deleteDoc, where, limit } from "firebase/firestore";
import { auth, db, functions } from '@/firebase/config.js';
import { httpsCallable } from "firebase/functions";
import { getAuth, getIdTokenResult } from 'firebase/auth';


import {
  // modal
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue';

import Logo from "@/_for_dash/icons/Logo";
import { HandThumbDownIcon, CalendarDaysIcon, LockOpenIcon } from "@heroicons/vue/24/outline";

const lastPractices = ref([]);
const lastExams = ref([]);

const isInitializing = ref(true)
const user = ref(null);
const schools = ref([]);
const isWaiting = ref(false);
const notificationMessage = ref('');

const isExpirationDateModalOpen = ref(false);
const expirationDateField = ref(null);
const revTypeToUpdateForDateExp = ref(null);

const openExpirationDateModal = (expDate, rev)=>{
  const dateFromSeconds = new Date(expDate.seconds * 1000);
  const dateString = dateFromSeconds.toISOString().slice(0, 10);
  expirationDateField.value = dateString;
  isExpirationDateModalOpen.value = true;
  revTypeToUpdateForDateExp.value = rev;
}

const saveExpirationDate = async () => { 

  isExpirationDateModalOpen.value = false;

  const expDate = new Date(expirationDateField.value);

  isWaiting.value = true;
  notificationMessage.value = `Updating User\'s ${revTypeToUpdateForDateExp.value.toUpperCase()} expiration date...`;

  let _newExpDate = null;
  let _newExpDateDB = null;
  switch (revTypeToUpdateForDateExp.value) {
    case 'caap':
        _newExpDate = {caapNewExpAt: expDate}
        _newExpDateDB = {['caap_data.expiration_date']: expDate}
      break;
    case 'caap_plus':
        _newExpDate = {plusNewExpAt: expDate}
        _newExpDateDB = {['caap_plus_data.expiration_date']: expDate}
      break;
    case 'mtp':
        _newExpDate = {mtpNewExpAt: expDate}
        _newExpDateDB = {['mtp_data.expiration_date']: expDate}
      break;
  }

  const updateExpirationDateFbFunc = httpsCallable(functions, "updateReviewerExpirationDate");
  const updateExpirationDate = await updateExpirationDateFbFunc({
    email: user.value.email,
    ..._newExpDate
  })

  
  if(updateExpirationDate.data.success){

    console.log('User claims has been updated successfully.')

    updateDoc( doc(db, "users", user.value.id), _newExpDateDB)
      .then( async () => { 
       
        notificationMessage.value = '✅ Expiration date has been updated.';

        setTimeout(() => {
          isWaiting.value = false;
          initialize();
        }, 2000);


      })
      .catch((error) => { 
        console.error(`Error updating user ${revTypeToUpdateForDateExp.value.toUpperCase()} expiration date: `, error);
      });


  }else{
    console.error(`An error happened during updating ${revTypeToUpdateForDateExp.value.toUpperCase()} expiration date.`)
  }

}

const closeExpirationDateModal = ()=>{
  isExpirationDateModalOpen.value = false;
}

const fetchUser = async (uid) => {
  return new Promise(async (resolve, reject) => {

    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      let data = docSnap.data()
      data.id = docSnap.id;
      console.log({data})
      resolve(data)
    } else {
      // doc.data() will be undefined in this case
      console.error("No such document!")
    }
  })
}

const fetchAllSchools = async () => {
  return new Promise(async (resolve, reject) => {
    getDocs( query(collection(db, "schools")))
      .then((querySnapshot) => { 
        if(querySnapshot.size > 0){
          var _schools = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data()
            data.id = doc.id
            _schools.push(data)
          });
          resolve(_schools)
        }else{
          console.log("Query returned no results!")
        }
      })
      .catch((error) => {
        console.error("Error retreiving schools list from DB");
        throw `Error getting user document: ${error}`;
      });
  })
}


/**
 * Purchase CAAP reviewer for the user
 */
 const purchaseForUser = (user, reviewer, monthDuration = 3) => {


  // ask current price
  let currentPrice;
  currentPrice = prompt(`Enter current price for ${monthDuration} Months duration:`, monthDuration == 3 ? 3999 : 5999);
  
  if (!currentPrice || isNaN(currentPrice)) {
    // The input is not a number
    console.log('Input is not a number or is not a valid number:', currentPrice);
    return;
  }

  isWaiting.value = true;
  notificationMessage.value = `Purchasing ${reviewer.toUpperCase()} for user...`;

  /**
   * Initialize default values for the data
   * @todo @junrey refactor this so that it will be define by user
   */

  let _data = {
    purchase_date: moment().valueOf().toString(),
    gcash_refno: 'PURCHASED_VIA_ADMIN',
    duration: monthDuration == 3 ? 90 : 180,
    finalPrice: currentPrice,
    durationInWords: monthDuration == 3 ? '3 Months' : '6 Months',
    activateLater: true,
  }

  let _reviewers;
  let dataToUpdate;

  switch (reviewer) {
    case 'caap':
      _reviewers = {
        'reviewers.caap': 'paid'
      }  
      dataToUpdate = {caap_data: _data}
      break;
    case 'caap_lite':
      _reviewers = {
        'reviewers.caap_lite': 'paid'
      }  
      dataToUpdate = {caap_lite_data: _data}
      break;
    case 'caap_plus':
      _reviewers = {
        'reviewers.caap_plus': 'paid'
      }  
      dataToUpdate = {caap_plus_data: _data}
      break;
    case 'mtp':
      _reviewers = {
        'reviewers.mtp': 'paid'
      }  
      dataToUpdate = {mtp_data: _data}
      break;
  }


  updateDoc( doc(db, "users", user.id), { 
      ..._reviewers,
      ...dataToUpdate
    })
    .then(async () => { 
      notificationMessage.value = `✅ User has now purchased ${reviewer.toUpperCase()}.`;
        
      setTimeout(() => {
        isWaiting.value = false;
        initialize();
      }, 2000);

    })
    .catch((error) => { console.error("Error updating user data: ", error)  });

}

const revokePurchase = async (user,rev) => {
  
  isWaiting.value = true;
  notificationMessage.value = `Revoking User\'s ${rev.toUpperCase()} access...`;

  //identify which reviewer to revoke and then initialize the payload to send to firebase function
  let funcPayload = {};
  let keysToremove = [];
  switch (rev) {
    case 'caap':
        funcPayload = {caap: false};
        keysToremove.push('caapExpAt');
      break;
    case 'caap_lite':
        funcPayload = {caap_lite: false};
        break;
    case 'caap_plus':
          funcPayload = {caap_plus: false};
          keysToremove.push('plusExpAt');
      break;
    case 'mtp':
        funcPayload = {mtp: false};
        keysToremove.push('mtpExpAt');
      break;
  }

  const updateUserClaimsFbFunc = httpsCallable(functions, "updateUserClaims");
  const updateUserClaimsResponse = await updateUserClaimsFbFunc({
    email: user.email,
    payload: funcPayload,
    toRemoveKeys: keysToremove,
  })

  if(updateUserClaimsResponse.data.success){

    let _data;
    let _reviewers;

    switch (rev) {
      case 'caap':
          _data = {caap_data : {
            purchase_date: '',
            gcash_refno: ''
          }}
    
          _reviewers = {
            'reviewers.caap': 'inactive'
          }  
        break;
      case 'caap_lite':
          _data = {caap_lite_data : {
            purchase_date: '',
            gcash_refno: ''
          }}
      
          _reviewers = {
            'reviewers.caap_lite': 'inactive'
          } 
        break;
      case 'caap_plus':
          _data = {caap_plus_data : {
            purchase_date: '',
            gcash_refno: ''
          }}
    
          _reviewers = {
            'reviewers.caap_plus': 'inactive'
          } 
        break;
      case 'mtp':
          _data = {mtp_data : {
            purchase_date: '',
            gcash_refno: ''
          }}

          _reviewers = {
            'reviewers.mtp': 'inactive'
          } 

        break;
    }

    notificationMessage.value = `Updating User ${rev.toUpperCase()} data...`;
    updateDoc( doc(db, "users", user.id), { ..._reviewers, ..._data })
      .then(() => { 
        console.log(`User's ${rev.toUpperCase()} information has been successfully updated.`) 
        
        notificationMessage.value = '✅ Purchase has been revoke.';
        
        setTimeout(() => {
          isWaiting.value = false;
          initialize();
        }, 2000);

      })
      .catch((error) => { 
        console.error(`Error updating user ${rev.toUpperCase()} information: `, error);
      });


    }else{
      console.error('An error happened during CAAP reviewer revoke.')
    }

}

const revokeLicense = async (user) => {

isWaiting.value = true;
notificationMessage.value = 'Confirming license information...';

console.log("User's license information will now be updated.") 

let _license = user.license;
_license.isVerified = false;
_license.isRevoked = true;

let _skillpoints = user.skillpoints - 2000;

/**
 * Update User
 */
updateDoc( doc(db, "users", user.id), { 
      license: _license,
      skillpoints: _skillpoints
    })
    .then(async () => { 
      console.log("User's license information has been successfully updated.") 
      
      notificationMessage.value = 'Upating leaderboard points...';
      
      /**
       * 
       * Global Leaderboard +2000
       * 
       */

      let user_global_leaderboard = await fetchUserInGlobalLeaderboard(user.id)
      let _global_skillpoints = user_global_leaderboard.skillpoints - 2000;
      updateDoc( doc(db, "leaderboard", user.id), { 
            skillpoints: _global_skillpoints,
            isVerified: false
          })
          .then(async () => { 
            console.log("User's global skillpoints has been successfully updated.") 
            
            notificationMessage.value = 'Upating season2 leaderboard points...';
            
            /**
            * 
            * S2 Leaderboard +2000
            * 
            */

            let user_s2_leaderboard = await fetchUserInS2Leaderboard(user.id)
            let _s2_skillpoints = user_s2_leaderboard.skillpoints - 2000;
            updateDoc( doc(db, "s2leaderboard", user.id), { 
                  skillpoints: _s2_skillpoints,
                  isVerified: false
                })
                .then(() => { 
                  console.log("User's s2 skillpoints has been successfully updated.") 
                  
                  notificationMessage.value = '✅ License is now revoked.';
                  
                  setTimeout(() => {
                    isWaiting.value = false;
                    initialize();
                  }, 2000);

                })
                .catch((error) => { 
                  console.error("Error updating user license information: ", error);
                });

          })
          .catch((error) => { 
            console.error("Error updating user license information: ", error);
          });

    })
    .catch((error) => { 
      console.error("Error updating user license information: ", error);
    });

}

const fetchUserInS2Leaderboard = async (uid) => {
  return new Promise(async (resolve, reject) => {

    const docRef = doc(db, "s2leaderboard", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let data = docSnap.data()
      data.id = docSnap.id;
      resolve(data)
    } else {
      // doc.data() will be undefined in this case
      console.error("No such document!")
    }
  })
}

const fetchUserInGlobalLeaderboard = async (uid) => {
  return new Promise(async (resolve, reject) => {

    const docRef = doc(db, "leaderboard", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let data = docSnap.data()
      data.id = docSnap.id;
      resolve(data)
    } else {
      // doc.data() will be undefined in this case
      console.error("No such document!")
    }
  })
}

const fetchUserClaims = async(email) => {
  const fetchUserCustomClaimsFbFunc = httpsCallable(functions, "fetchUserCustomClaims");
  const userCustomClaims = await fetchUserCustomClaimsFbFunc({email})
  console.log(userCustomClaims.data)
  alert('Check console log!')
}

const initialize = async () => {

  isInitializing.value = true;
  if(ROUTER.currentRoute.value.params.id){
    let uid = ROUTER.currentRoute.value.params.id;
    user.value = await fetchUser(uid);
    schools.value = await fetchAllSchools();  

    fetchLastCaapPractice();
    fetchLastCaapExams();

    isInitializing.value = false;
  }else{
    console.log('No ID is passed.')
  }


}

const fetchLastCaapPractice = async () => {

  lastPractices.value = [];

  const caapPracticeRef = collection(db, "practice");
  // const q = query(caapPracticeRef, where("user", "==", user.value.id));
  const q = query(caapPracticeRef, where("user", "==", user.value.id), orderBy("time.start", 'desc'), limit(3));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {

    let _id = doc.id;
    let _data = doc.data();

    _data.time.start = new Date(_data.time.start).toLocaleString();

    lastPractices.value.push({
      id: _id,
      data: _data
    })
  });

}

const fetchLastCaapExams = async () => {

  lastExams.value = [];

const caapPracticeRef = collection(db, "mock");
// const q = query(caapPracticeRef, where("user", "==", user.value.id));
const q = query(caapPracticeRef, where("user", "==", user.value.id), orderBy("time.start", 'desc'), limit(3));
const querySnapshot = await getDocs(q);

querySnapshot.forEach((doc) => {

  let _id = doc.id;
  let _data = doc.data();

  _data.time.start = new Date(_data.time.start).toLocaleString();

  lastExams.value.push({
    id: _id,
    data: _data
  })
});

}

const unblockedMtp = async (user) => {
  
  isWaiting.value = true;
  notificationMessage.value = `Unblocking Mtp reviewer. Extending to 3 months...`;

  const getReviewerExpiry = (days = 90) => {
      let durationInDays = days;
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + durationInDays);
      return currentDate
  }

  const expDate = getReviewerExpiry();

  // update user claims
  notificationMessage.value = `Updating User Claims...`;
  const softBlockUserRemoveFunc = httpsCallable(functions, "softBlockUserRemoveMtp");
  const softBlockUserRemoveFuncRes = await softBlockUserRemoveFunc({ email: user.email, mtpExpAt: expDate })

  if (softBlockUserRemoveFuncRes.data.success) {

    notificationMessage.value = `Updating User MTP data...`;
    updateDoc( doc(db, "users", user.id), {
            'reviewers.mtp': 'active',
            'isMtpBlocked': false,
            'mtp_data.expiration_date': expDate
        })
      .then( async () => { 

        console.log(`User's MTP information has been successfully updated.`) 
        notificationMessage.value = '✅ MTP has been unblocked and extended to 3 months.';

        setTimeout(() => {
          isWaiting.value = false;
          initialize();
        }, 2000);


      })
      .catch((error) => { 
        console.error(`Error updating user MTP information: `, error);
        isWaiting.value = false;
        initialize();
      });
      
  } else {
        console.error(`Error updating user claims information`);
        isWaiting.value = false;
        initialize();
  }

  

}

const unblockedPlus = async (user) => {
  
  isWaiting.value = true;
  notificationMessage.value = `Unblocking CAAP Plus reviewer. Extending to 3 months...`;

  const getReviewerExpiry = (days = 90) => {
      let durationInDays = days;
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + durationInDays);
      return currentDate
  }

  const expDate = getReviewerExpiry();

  // update user claims
  notificationMessage.value = `Updating User Claims...`;
  const softBlockUserRemoveFunc = httpsCallable(functions, "softBlockUserRemovePlus");
  const softBlockUserRemoveFuncRes = await softBlockUserRemoveFunc({ email: user.email, plusExpAt: expDate })

  if (softBlockUserRemoveFuncRes.data.success) {

    notificationMessage.value = `Updating User CAAP Plus data...`;
    updateDoc( doc(db, "users", user.id), {
            'reviewers.caap_plus': 'active',
            'isPlusBlocked': false,
            'caap_plus_data.expiration_date': expDate
        })
      .then( async () => { 

        console.log(`User's CAAP Plus information has been successfully updated.`) 
        notificationMessage.value = '✅ CAAP Plus has been unblocked and extended to 3 months.';

        setTimeout(() => {
          isWaiting.value = false;
          initialize();
        }, 2000);


      })
      .catch((error) => { 
        console.error(`Error updating user CAAP Plus information: `, error);
        isWaiting.value = false;
        initialize();
      });
      
  } else {
        console.error(`Error updating user claims information`);
        isWaiting.value = false;
        initialize();
  }

  

}

initialize();

</script>
<style>
.h-header{
  min-height: 45pt;
}
</style>