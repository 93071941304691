<template>

    <header v-if="!isInitializing">
      <div class="h-header border-b border-gray-300 ">
          <ul class="flex w-full h-header justify-between">
              <li 
                v-for="view, i in VIEWS"
                @click="activeView = view"
                :key="view" 
                :class="[
                    (activeView == view) ? 'border-b-4 border-gray-600' : '',
                    
                    'w-full flex items-center justify-center'
                ]">
                <a href="#"   
                class="cursor-pointer px-4 py-1 text-xs sm:text-sm font-base leading-4 text-gray-700 h-full w-full flex items-center justify-center"
                :class="(i == 0) ? '' : 'border-l border-gray-300 border-dashed'"
                >
                  <span>
                      {{ view }}
                  </span>
                </a>
              </li>
          </ul>
      </div>
    </header>

    <div v-if="!isInitializing && activeView == VIEWS[0]" class="h-full overflow-none flex flex-col">
        <div class="sticky absolute p-4 border-b border-gray-300">
            <select v-model="filterSelection" class="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option v-for="filter in filters" :key="filter"> {{ filter }}</option>
            </select>
        </div>
        <div class="overflow-scroll px-4 pb-4">
            <ul class="space-y-4 mt-4 pb-40">
                <li v-for="(record, index) in filteredLeaderboard" :key="record.id" class="text-xs border border-gray-300 px-5 py-4">
                    <div class="flex items-center space-x-1 justify-between">
                        <div class="flex items-center space-x-1 ">
                            <h1 class="font-semibold text-sm">{{ record.firstname }} {{  record.lastname }}</h1>
                            <div v-if="record.isVerified">
                                <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                                    <path fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
                                </svg>
                            </div>
                        </div>
                        <div class="space-x-4">
                            <span v-if="filterSelection == 'All Schools'">Overall Rank: {{ record.rank }}</span>
                            <span v-else>School Rank: {{ index+1 }}</span>
                        </div>
                        
                    </div>
                    <p>Email: {{  record.email }}</p>
                    <p>School: {{ getSchool(record.school).name }}</p>
                    <p>Course: {{ record.course }}</p>
                    <p>Skillpoints: {{ record.skillpoints.toLocaleString() }}</p>
                    <p v-if="filterSelection != 'All Schools'">Overall Rank: {{ record.rank }}</p>
                    
                    <!-- {{ record}} -->
                </li>
            </ul>
        </div>

        
    </div>

    <div v-else-if="!isInitializing && activeView == VIEWS[1]" class="h-full overflow-scroll">
        <div class="overflow-scroll px-4 pb-4">
            <ul class="space-y-4 mt-4 pb-8">
                <li v-for="(record, index) in schoolLeaderboard" :key="record.id" class="text-xs border border-gray-300 px-5 py-4 flex flex-col">
                    <h1 class="text-sm font-semibold">Rank {{ index+1 }}</h1>
                    <span>{{ record.name}}</span>
                    <span>Total Skillpoints: {{ record.skillpoints.toLocaleString() }}</span>
                </li>
            </ul>
        </div>
    </div>

    <div v-else-if="!isInitializing && activeView == VIEWS[2]" class="h-full overflow-scroll">
        <div class="sticky absolute p-4 border-b border-gray-300">
            <select v-model="filterS2Selection" class="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option v-for="filter in filters" :key="filter"> {{ filter }}</option>
            </select>
        </div>
        <div class="overflow-scroll px-4 pb-4">
            <ul class="space-y-4 mt-4 pb-40">
                <li v-for="(record, index) in filteredS2Leaderboard" :key="record.id" class="text-xs border border-gray-300 px-5 py-4">
                    <div class="flex items-center space-x-1 justify-between">
                        <div class="flex items-center space-x-1 ">
                            <h1 class="font-semibold text-sm">{{ record.firstname }} {{  record.lastname }}</h1>
                            <div v-if="record.isVerified">
                                <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                                    <path fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
                                </svg>
                            </div>
                        </div>
                        <div class="space-x-4">
                            <span v-if="filterSelection == 'All Schools'">Overall Rank: {{ record.rank }}</span>
                            <span v-else>School Rank: {{ index+1 }}</span>
                        </div>
                        
                    </div>
                    <p>Email: {{  record.email }}</p>
                    <p>Course: {{ record.course }}</p>
                    <p>Skillpoints: {{ record.skillpoints.toLocaleString() }}</p>
                    <p v-if="filterSelection != 'All Schools'">Overall Rank: {{ record.rank }}</p>
                    
                    <!-- {{ record}} -->
                </li>
            </ul>
        </div>
    </div>

    <!-- Waiting Screen -->
    <main v-if="isInitializing" class="overflow-auto h-full w-full flex justify-center items-center">
      <Logo class="w-24 animate-pulse text-warm-gray-300" />
    </main>
  

</template>
<script setup>

import { ref, computed } from "vue";
import moment from "moment";
import { getDocs,collection, orderBy, query, doc, updateDoc, setDoc, deleteDoc } from "firebase/firestore";
import { auth, db, functions } from '@/firebase/config.js';
import Logo from "@/_for_dash/icons/Logo";

const VIEWS = [
    'Overall',
    'Schools',
    'Season 2',
]
const activeView = ref(VIEWS[0])

const isInitializing = ref(true)
const leaderboard = ref([]);
const s2leaderboard = ref([]);
const schools = ref([]);
const schoolLeaderboard = ref([]);

const filterSelection = ref('All Schools')
const filterS2Selection = ref('All Schools')
const filters = ref(['All Schools'])

const fetchLeaderboard = async () => {
  return new Promise(async (resolve, reject) => {
    getDocs( query(collection(db, "leaderboard"), orderBy("date_created", "desc")))
      .then((querySnapshot) => { 
        if(querySnapshot.size > 0){
          var _users = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data()
            data.id = doc.id
            _users.push(data)
          });
          resolve(_users)
        }else{
          console.log("Query returned no results!")
        }
      })
      .catch((error) => {
        console.error("Error retreiving users list from DB");
        throw `Error getting user document: ${error}`;
      });
  })
}

const fetchS2Leaderboard = async () => {
  return new Promise(async (resolve, reject) => {
    getDocs( query(collection(db, "s2leaderboard"), orderBy("date_created", "desc")))
      .then((querySnapshot) => { 
        if(querySnapshot.size > 0){
          var _users = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data()
            data.id = doc.id
            _users.push(data)
          });
          resolve(_users)
        }else{
          console.log("Query returned no results!")
        }
      })
      .catch((error) => {
        console.error("Error retreiving users list from DB");
        throw `Error getting user document: ${error}`;
      });
  })
}

const fetchAllSchools = async () => {
    return new Promise(async (resolve, reject) => {
        getDocs( query(collection(db, "schools")))
            .then((querySnapshot) => { 
                if(querySnapshot.size > 0){
                    var _schools = [];
                    querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    _schools.push(data)
                    });
                    resolve(_schools)
                }else{
                    console.log("Query returned no results!")
                }
            })
            .catch((error) => {
                console.error("Error retreiving schools list from DB");
                throw `Error getting user document: ${error}`;
            });
    })
}

function sortLeaderboard(leaderboard){
    let sorted_leaderboard = leaderboard.sort(function (a, b) {
        if( a.skillpoints === b.skillpoints){ return a.date_started - b.date_started } // if they have equal points, give priority to people who signed up earlier
        else{ return b.skillpoints - a.skillpoints; } // sort via skillpoints
    });
    sorted_leaderboard.forEach( (user, i) => { user.rank = i+1; });
    return sorted_leaderboard
}

const getSchool = (schoolId) => {
    let _filtered = schools.value.filter( school => school.id == schoolId)
    return _filtered[0]
}

const filteredLeaderboard = computed(() => {
    if(filterSelection.value == 'All Schools'){
        return leaderboard.value
    }else{
        return leaderboard.value.filter( record => getSchool(record.school).name == filterSelection.value)
    }
})


const filteredS2Leaderboard = computed(() => {
    if(filterS2Selection.value == 'All Schools'){
        return s2leaderboard.value
    }else{
        
        return s2leaderboard.value.filter( record => {    
            let x = getSchool(record.school);
            console.log(filterS2Selection.value);
            console.log(x.name)
            return (x.name.toString() == filterS2Selection.value);
            // getSchool(record.school).name == filterSelection.value
        })
    }
})


/**
 * sort by skillpoints
 */
 function sortBySkillpoints(data){
  return data.sort(function (a, b) {
    if (a.skillpoints > b.skillpoints) return -1
    if (b.skillpoints > a.skillpoints) return 1
    return 0;
  });
}

/**
 * sort the schools by users
 */
 function sortSchoolsByUsers(schools){
  return schools.sort(function (a, b) {
    if (a.users.length > b.users.length) return -1
    if (b.users.length > a.users.length) return 1
    return 0;
  });
}

const sortSchoolLeaderboard = (_schools, leaderboard) => {

    let schools = _schools;

    schools.forEach( (school, index) => {
    var usersInSchool = leaderboard.filter( user => { return (school.id == user.school) })
    
    // Sort users according to skillpoints
    schools[index].users = usersInSchool;
    schools[index].users = sortBySkillpoints(schools[index].users)

    // compute the total score
    let skillpoints = 0;
    usersInSchool.forEach( user => { skillpoints += user.skillpoints });
    schools[index].skillpoints = skillpoints;

    });

    schools = sortSchoolsByUsers(schools)  // sort first by number of users. so schools will be sorted with more users at the top
    schools = sortBySkillpoints(schools) // then sort by skillpoints

    return schools

}

const initialize = async () => {
  isInitializing.value = true;
  leaderboard.value = await fetchLeaderboard();
  leaderboard.value = sortLeaderboard(leaderboard.value);

  s2leaderboard.value = await fetchS2Leaderboard();
  s2leaderboard.value = sortLeaderboard(s2leaderboard.value);

  schools.value = await fetchAllSchools();

  let _schoolNames = new Array();
  schools.value.forEach( school => {
    _schoolNames.push(school.name);
  });
  _schoolNames.sort();
    filters.value = [...filters.value, ..._schoolNames ];


    let _schools = schools.value;
    let _leaderboard = leaderboard.value;
    schoolLeaderboard.value = await sortSchoolLeaderboard(_schools, _leaderboard)
  
  isInitializing.value = false;
}
initialize();

</script>
<style>
</style>