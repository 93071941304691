<template>
  <main>
    
    <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto flex justify-between text-gray-900 dark:text-gray-300">
          <div class="flex justify-start items-center">
            <label for="reviewer" class="text-base font-semibold leading-6 ">Select Reviewer :</label>
            <select v-model="selectedRev" name="Reviewer" id="reviewer" class=" ml-2 text-gray-900">
              <option value="caap" selected>CAAP Knowledge</option>
              <option value="plus" selected>CAAP Practical (Plus)</option>
              <option value="mtp" selected>Entrance Exam (MTP)</option>
            </select>
          </div>
          <div>
            <h1 class="text-base font-semibold leading-6 ">Purchases: {{ usersData.length }}</h1>
            <h1 class="text-base font-semibold leading-6">Revenue: {{ calculateTotal.toLocaleString() }} PHP</h1>
          </div>
        </div>
      </div>
      <div class="mt-4 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div class="ring-1 ring-black ring-opacity-5 ">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr>
                    <!-- <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Index</th> -->
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date Purchased</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">First Name</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last Name</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">GCash Ref Code</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Amount</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Coupon</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Endorser Code</th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="(user, index) in usersData" :key="user.id">
                    <!-- <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ index+1 }} 
                    </td> -->
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ getCaapIcon(user.revStatus) }} 
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ convertTimestampToDate(user.purchase_date) }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.email }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.firstname }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.lastname }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.ref_code }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.final_price.toLocaleString() }} <span v-if="user.isBundled" class="rounded-full text-xs border py-0.5 px-1 bg-warm-gray-300">bundle</span></td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.code }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.endorser_code ? user.endorser_code : 'NA' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

  </main>

</template>
<script setup>

import { ref, computed, watch, onMounted } from 'vue'
import { db } from '@/firebase/config.js'
import { getDocs, collection, orderBy, query, doc, updateDoc, setDoc, deleteDoc, limit, where, startAfter, documentId } from "firebase/firestore"

let lastDoc = ref(null)  // this is used for lazy loading
let waiting = ref(false) // this is a UI flag
const usersData = ref([]) // all users will be stored here
const selectedRev = ref(''); 


function getCurrentMonthDateRange() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth(); // Note: January is 0, February is 1, and so on.

    // Start date is the first day of the current month
    const startDatePrep = new Date(year, month, 1);

    // End date is the first day of the next month
    const endDatePrep = new Date(year, month + 1, 1);

    // Convert dates to milliseconds
    const startDate = startDatePrep.getTime();
    const endDate = endDatePrep.getTime();

    return { startDate, endDate };
}


const fetchForCaapRev = async () => {

  waiting.value = true
  lastDoc.value = null;
  usersData.value = [];

  let userQuery = null

  // const startDate = new Date('2024-01-01T00:00:00Z').getTime();
  // const endDate = new Date('2024-02-01T00:00:00Z').getTime();

  const { startDate, endDate } = getCurrentMonthDateRange();

  console.log(startDate)
  console.log(endDate)

  if (lastDoc.value) {
    userQuery = query(
      collection(db, "users"), 
      // where("caap_data", "!=", null), 
      orderBy("caap_data.purchase_date", "desc"),
      // where("caap_data.purchase_date", ">=", startDate),
      // where("caap_data.purchase_date", "<", endDate),
      startAfter(lastDoc.value), 
      limit(200));
  } else {
    userQuery = query(
      collection(db, "users"), 
      // where("reviewers.caap", "==", 'active'), 
      // where("caap_data", "!=", null), 
      orderBy("caap_data.purchase_date", "desc"),
      where("caap_data.purchase_date", ">=", startDate.toString()),
      where("caap_data.purchase_date", "<", endDate.toString()),
      limit(200));
  }

  const userQuerySnapshot = await getDocs(userQuery);
  // const users = userQuerySnapshot.docs.map(doc => { 

  //   let user = { id: doc.id, data: doc.data() }


  // });

  lastDoc.value = userQuerySnapshot.docs[userQuerySnapshot.docs.length - 1];

  const usersDataArray = await Promise.all(

    userQuerySnapshot.docs.map(async doc => { 

      let user = { id: doc.id, data: doc.data() }

      const endorser_code = await queryCouponCodeEndorser(user.data.caap_data.couponCode);

      return {
        id: user.id,
        email: user.data.email,
        firstname: user.data.firstname,
        lastname: user.data.lastname,
        school: user.data.school,
        revStatus: user.data.reviewers.caap,
        purchase_date: user.data.caap_data.purchase_date,
        ref_code: user.data.caap_data.gcash_refno,
        code: user.data.caap_data.couponCode ? user.data.caap_data.couponCode : 'NA',
        discount: user.data.caap_data.couponDiscount ? user.data.caap_data.couponDiscount : 0,
        final_price: user.data.caap_data.finalPrice,
        endorser_code: endorser_code,
        isBundled: user.data.caap_data?.isBundled,
      };

    })

  );

  console.log('users data array: ', usersDataArray);

  usersData.value = usersDataArray;

}

const fetchForPlusRev = async () => {

  waiting.value = true
  lastDoc.value = null;
  usersData.value = [];

  let userQuery = null

  // const startDate = new Date('2024-01-01T00:00:00Z').getTime();
  // const endDate = new Date('2024-02-01T00:00:00Z').getTime();

  const { startDate, endDate } = getCurrentMonthDateRange();

  console.log(startDate)
  console.log(endDate)

  if (lastDoc.value) {
    userQuery = query(
      collection(db, "users"), 
      orderBy("caap_plus_data.purchase_date", "desc"),
      startAfter(lastDoc.value), 
      limit(200));
  } else {
    userQuery = query(
      collection(db, "users"), 
      orderBy("caap_plus_data.purchase_date", "desc"),
      where("caap_plus_data.purchase_date", ">=", startDate.toString()),
      where("caap_plus_data.purchase_date", "<", endDate.toString()),
      limit(200));
  }

  const userQuerySnapshot = await getDocs(userQuery);
  const users = userQuerySnapshot.docs.map(doc => { return { id: doc.id, data: doc.data() } });

  lastDoc.value = userQuerySnapshot.docs[userQuerySnapshot.docs.length - 1];

  // populate users data
  users.forEach(async (user) => {

    // no need to query coupons for since we are only using the code for CAAP reviewer
    // const coupon = await queryCouponCode(user.data.email)

    let userData = {
      id: user.id,
      email: user.data.email,
      firstname: user.data.firstname,
      lastname: user.data.lastname,
      school: user.data.school, 
      revStatus: user.data.reviewers.caap_plus,
      purchase_date: user.data.caap_plus_data.purchase_date,
      ref_code: user.data.caap_plus_data.gcash_refno,
      code: 'NA',
      discount: 'NA',
      final_price: user.data.caap_plus_data.finalPrice,
      endorser_code: 'NA',
      isBundled: user.data.caap_plus_data?.isBundled,
    }
    usersData.value.push(userData)
  });

}

const fetchForMtpRev = async () => {

  waiting.value = true;
  lastDoc.value = null;
  usersData.value = [];

  let userQuery = null

  // const startDate = new Date('2024-01-01T00:00:00Z').getTime();
  // const endDate = new Date('2024-02-01T00:00:00Z').getTime();

  const { startDate, endDate } = getCurrentMonthDateRange();

  console.log(startDate)
  console.log(endDate)

  if (lastDoc.value) {
    userQuery = query(
      collection(db, "users"), 
      orderBy("mtp_data.purchase_date", "desc"),
      startAfter(lastDoc.value), 
      limit(200));
  } else {
    userQuery = query(
      collection(db, "users"), 
      orderBy("mtp_data.purchase_date", "desc"),
      where("mtp_data.purchase_date", ">=", startDate.toString()),
      where("mtp_data.purchase_date", "<", endDate.toString()),
      limit(200));
  }

  const userQuerySnapshot = await getDocs(userQuery);
  const users = userQuerySnapshot.docs.map(doc => { return { id: doc.id, data: doc.data() } });

  lastDoc.value = userQuerySnapshot.docs[userQuerySnapshot.docs.length - 1];

  // populate users data
  users.forEach(async (user) => {

    // no need to query coupons for since we are only using the code for CAAP reviewer
    // const coupon = await queryCouponCode(user.data.email)

    let userData = {
      id: user.id,
      email: user.data.email,
      firstname: user.data.firstname,
      lastname: user.data.lastname,
      school: user.data.school, 
      revStatus: user.data.reviewers.mtp,
      purchase_date: user.data.mtp_data.purchase_date,
      ref_code: user.data.mtp_data.gcash_refno,
      code: 'NA',
      discount: 'NA',
      final_price: user.data.mtp_data.finalPrice,
      endorser_code: 'NA',
      isBundled: user.data.mtp_data?.isBundled,
    }
    usersData.value.push(userData)
  });

}

watch(selectedRev, async ()=>{
  console.log('Reviewer changed to :', selectedRev.value);

  if (selectedRev.value == 'caap') {
    await fetchForCaapRev();
  }else if (selectedRev.value == 'plus') {
    await fetchForPlusRev();
  }else if (selectedRev.value == 'mtp') {
    await fetchForMtpRev();
  }
})

// const initialize = async () => {

//   console.log("🪹 Initializing Finance.")
//   selectedRev.value = 'caap'
// }

// initialize();

onMounted(()=>{
  console.log("🪹 Initializing Finance.")
  selectedRev.value = 'caap'
})

const queryCouponCodeEndorser = async (couponCode) => {

   try {
    const q = query(
      collection(db, "coupons"),
      where("code", "==", couponCode),
      limit(1)
    );

    const qS = await getDocs(q);
    const mapped = qS.docs.map(doc => { return { id: doc.id, data: doc.data() } });
    if (mapped.length > 0) {
      return mapped[0].data.assignedToEndorserCode;
    }else{
      return null;
    }
   } catch (error) {
    return null;
   }

}

const calculateTotal = computed(() => {
  return usersData.value.reduce((accumulator, user) => {
    let finalPrice = parseFloat(user.final_price);
    if (isNaN(finalPrice)) {
      finalPrice = 0; // Or handle this case as needed, e.g., log an error or use a default value
    }
    return accumulator + finalPrice;
  }, 0);
});

const getCaapIcon = (caapStatus) => {
  if(caapStatus == 'active'){
    return '🟢';
  }

  if(caapStatus == 'ready'){
    return '🟣';
  }

}

const convertTimestampToDate = (timestampInMilliseconds) => {
  if (timestampInMilliseconds) {
    const date = new Date(parseInt(timestampInMilliseconds))
    return date.toLocaleDateString() + " at " + date.toLocaleTimeString()
  } else {
    return 'NA' // returns NA if timestamp is null (i.e. for legacy users, date_purchased is null)
  }
}


</script>

<style scoped>
@layer components {
  main {
    @apply h-screen overflow-auto
  }
  
  ul > li {
    @apply my-4
  }

  button {
    @apply rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-25
  }
  
}
</style>