<template>
  <div class="root p-20 h-screen bg-gray-50">
    <div class="overflow-hidden rounded-lg bg-white shadow">
      <div class="px-4 py-5 sm:p-6">
        <button
              type="submit"
              @click="handleSubmit"
              class="
                flex
                w-full
                justify-center
                rounded-md
                border border-transparent
                bg-indigo-600
                py-2
                px-4
                text-sm
                font-medium
                text-white
                shadow-sm
                hover:bg-indigo-700
                focus:outline-none
                focus:ring-2
                focus:ring-indigo-500
                focus:ring-offset-2
              "
            >
              Override as Admin
            </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { auth, functions } from '@/firebase/config.js';
import { httpsCallable } from "firebase/functions";

const user = {
  id: null,
  email: null,
}
user.id = auth.currentUser.uid;
user.email = auth.currentUser.email;

console.log({user})

auth.currentUser.getIdTokenResult().then((tokenResult) => {
  console.log(`Claims retreived for user with id: ${user.id} => `, tokenResult.claims)
})


// FORM

const email = ref("");
const password = ref("");

const handleSubmit = async function () {
  
  const setAsAdminOverride = httpsCallable(functions, 'setAsAdminOverride');
  setAsAdminOverride({ email: user.email })
      .then((result) => {
          console.log(result)
      })
      .catch((err) => {
        console.log(err)
      });

};

</script>