import { httpsCallable } from "firebase/functions";
import { functions } from '@/firebase/config.js';
import { collection, getDocs, doc, getDoc, query, where, orderBy, deleteDoc, limit, startAfter, updateDoc } from "firebase/firestore";
import { db } from '@/firebase/config.js';
import { reviewersColName } from '@/firebase/vars';

// temp file
// import { updateDoc } from "firebase/firestore";
// export const updateItemsCount = async() => {
//     const querySnapshot = await getDocs(collection(db, reviewersColName, 'caap', "subtopics"));
//     let allSubtopics = [];
//     // loop to all subtopics
//     querySnapshot.forEach(async(subtopic) => {
//         let items = 0;
//         allSubtopics.push({ id: subtopic.id, data: subtopic.data() })

//         // check if subtopic id exist at questionnaires collections
//         const docRef = doc(db, "questionnaires", subtopic.id);
//         const docSnap = await getDoc(docRef);
//         if (docSnap.exists()) {
//             // get subtopics question count
//             items = docSnap.data().questions.length;
//             console.log(`id:${subtopic.id} ===> items: ${items} `)
//         }

//         // update subtopic items
//         const subtopicRef = doc(db, reviewersColName, 'caap', "subtopics", subtopic.id);
//         await updateDoc(subtopicRef, {
//             items: items
//         });
//     });
// }

export const getSubtopics = async(reviewer) => {
    const querySnapshot = await getDocs(collection(db, reviewersColName, reviewer, "subtopics"));
    let allSubtopics = [];
    querySnapshot.forEach(doc => {
        allSubtopics.push({ id: doc.id, data: doc.data() })
    });
    return { isError: false, subtopics: allSubtopics }
}

export const getSubjects = async(reviewer) => {
    const querySnapshot = await getDocs(collection(db, reviewersColName, reviewer, "subjects"));
    let allSubjects = [];
    querySnapshot.forEach(doc => {
        allSubjects.push({ id: doc.id, data: doc.data() })
    });
    return { isError: false, subjects: allSubjects }
}

export const getSubject = async(reviewer, subjectId) => {
    const docSnap = await getDoc(doc(db, reviewersColName, reviewer, "subjects", subjectId));
    if (docSnap.exists()) {
        return { isError: false, data: docSnap.data() }
    } else {
        return { isError: true, data: null }
    }
}

export const createSubtopic = async(revId, form) => {

    // Add an additional initial validation here to ensure no duplicate subtopic IDs.
    // This will prevent overriding the questionnaire data bank.
    let subjectIds = [];
    let querySnapshot = await getDocs(collection(db, "reviewers"));
    querySnapshot.forEach((doc) => {
        subjectIds.push(doc.id)
    });

    const promises = subjectIds.map(async docId => {
        const subtopicCollectionRef = collection(db, "reviewers", docId, "subtopics");
        return getDocs(subtopicCollectionRef).then(querySnapshot => {
            return querySnapshot.docs.map(doc => doc.id);
        });
    });

    // Wait for all promises to resolve
    const results = await Promise.all(promises);
    // Flatten the results array
    const allSubtopicIds = results.flat();

    if (allSubtopicIds.includes(form.id)) {
        return { data: { isError: true, error: ['Subtopic ID already exist.'] } };
    }

    const createSubtopic = httpsCallable(functions, 'createSubtopic');
    return await createSubtopic({ revId, form })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const updateSubtopic = async(revId, form) => {
    const updateSubtopic = httpsCallable(functions, 'updateSubtopic');
    return await updateSubtopic({ revId, form })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const deleteSubtopic = async(revId, subtopicId) => {
    const deleteSubtopic = httpsCallable(functions, 'deleteSubtopic');
    return await deleteSubtopic({ revId, subtopicId })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const createSubject = async(revId, form) => {
    const createSubject = httpsCallable(functions, 'createSubject');
    return await createSubject({ revId, form })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const updateSubject = async(revId, form) => {
    const updateSubject = httpsCallable(functions, 'updateSubject');
    return await updateSubject({ revId, form })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const deleteSubject = async(revId, subjectId) => {
    const deleteSubject = httpsCallable(functions, 'deleteSubject');
    return await deleteSubject({ revId, subjectId })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const getCourses = async(reviewer) => {
    const docSnap = await getDoc(doc(db, reviewersColName, reviewer));
    if (docSnap.exists()) {
        return { isError: false, data: docSnap.data().courses }
    } else {
        return { isError: true, data: null }
    }
}

export const createCourse = async(revId, form) => {
    const createCourse = httpsCallable(functions, 'createCourse');
    return await createCourse({ revId, form })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const updateCourse = async(revId, form) => {
    const updateCourse = httpsCallable(functions, 'updateCourse');
    return await updateCourse({ revId, form })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const deleteCourse = async(revId, id) => {
    const deleteCourse = httpsCallable(functions, 'deleteCourse');
    return await deleteCourse({ revId, id })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const getCourse = async(reviewer, courseId) => {
    const docSnap = await getDoc(doc(db, reviewersColName, reviewer));
    if (docSnap.exists()) {
        let courses = docSnap.data().courses;
        return { isError: false, data: courses.find(c => c.id == courseId) }
    } else {
        return { isError: true, data: null }
    }
}

export const updateCourses = async(revId, courses) => {
    const updateCourses = httpsCallable(functions, 'updateCourses');
    return await updateCourses({ revId, courses })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}


export const createTag = async(revId, form) => {
    const createTag = httpsCallable(functions, 'createTag');
    return await createTag({ revId, form })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const getTags = async(reviewer) => {

    const q = query(collection(db, 'tags'), where("reviewerId", "==", reviewer), orderBy('name', 'asc'));

    const querySnapshot = await getDocs(q);
    let allTags = [];
    querySnapshot.forEach(doc => {
        allTags.push({ id: doc.id, data: doc.data() })
    });
    return { isError: false, tags: allTags }
}

export const updateTag = async(revId, tagId, form) => {
    const updateTag = httpsCallable(functions, 'updateTag');
    return await updateTag({ revId, tagId, form })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const deleteTag = async(revId, tagId) => {
    const deleteTag = httpsCallable(functions, 'deleteTag');
    return await deleteTag({ revId, tagId })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const getTagsQuestions = async(revId) => {

    const q = query(collection(db, 'tags_questions'), where("revId", "==", revId));

    const querySnapshot = await getDocs(q);
    let allTagsQuestions = [];
    querySnapshot.forEach(doc => {
        allTagsQuestions.push({ id: doc.id, data: doc.data() })
    });
    return { isError: false, tagsQuestions: allTagsQuestions }

}

export const getSubtopicQuestions = async(subtopicId) => {

    const docSnap = await getDoc(doc(db, 'questionnaires', subtopicId));
    if (docSnap.exists()) {
        return docSnap.data().questions;
    } else {
        return null
    }

}

export const fetchExplanations = async(pageSize,lastDoc = null) => {

    // const q = query(collection(db, 'explanations'));

    // const querySnapshot = await getDocs(q);
    // let explanations = [];
    // querySnapshot.forEach(doc => {
    //     explanations.push({ id: doc.id, data: doc.data() })
    // });

    // return { isError: false, explanations }

    const explanationsRef = collection(db, 'explanations');
  
    let q = query(explanationsRef, where('downvotes','>', 0), orderBy('downvotes', 'desc'), limit(pageSize));
    // If lastDoc is provided, start after that document
    if (lastDoc) {
        q = query(explanationsRef, where('downvotes','>', 0), orderBy('downvotes', 'desc'), startAfter(lastDoc), limit(pageSize));
    }

    // Execute the query
    const querySnapshot = await getDocs(q);
    
    // Extract the documents from the query snapshot
    const explanations = [];
    querySnapshot.forEach((doc) => {
        explanations.push({ id: doc.id, data: doc.data() });
    });

    const isLastPage = querySnapshot.size < pageSize;

    // Return the fetched explanations and the last document in the current page
    return {isError: false, explanations, lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1], isLastPage };

}

export const deleteExplanation = async (explanationId) => {

    return await deleteDoc(doc(db, 'explanations', explanationId))
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('Error removing explanation:', error.message);
      return error;
    });

}

export const updateExplanation = async (form) => {

    await updateDoc(doc(db, 'explanations', form.id), form.data)
        .then(() => {
          console.log('Explanation updated successfully.');
        })
        .catch((error) => {
          console.error('Error updating explanation:', error.message);
        });

}