<template>
  <div class="flex w-full">
    <div class="flex w-full flex-col">
      <div class="flex min-h-0 flex-1 flex-col border-r border-gray-200">
        <div
          class="
            flex flex-shrink-0
            border-b border-gray-300
            h-20
            items-center
            px-4
            bg-white
          "
        >
          <div class="group block w-full flex-shrink-0">
            <div class="flex items-center justify-between">
              <div>
                <p
                  class="
                    text-sm
                    font-medium
                    text-gray-700
                    group-hover:text-gray-900
                  "
                >
                  Context Number 2
                </p>
                <p
                  class="
                    text-xs
                    font-medium
                    text-gray-500
                    group-hover:text-gray-700
                  "
                >
                  Tags: Hard
                </p>
              </div>
              <div class="flex space-x-2">
                <nav aria-label="Pagination">
                  <span class="isolate inline-flex rounded-md shadow-sm">
                    <a
                      href="#"
                      class="
                        relative
                        inline-flex
                        items-center
                        rounded-l-md
                        border border-gray-300
                        bg-white
                        px-4
                        py-2
                        text-sm
                        font-medium
                        text-gray-500
                        hover:bg-gray-50
                        focus:z-10
                        focus:border-blue-600
                        focus:outline-none
                        focus:ring-1
                        focus:ring-blue-600
                      "
                    >
                      <span class="sr-only">Next</span>
                      <ChevronUpIcon class="h-5 w-5" aria-hidden="true" />
                    </a>
                    <a
                      href="#"
                      class="
                        relative
                        -ml-px
                        inline-flex
                        items-center
                        rounded-r-md
                        border border-gray-300
                        bg-white
                        px-4
                        py-2
                        text-sm
                        font-medium
                        text-gray-500
                        hover:bg-gray-50
                        focus:z-10
                        focus:border-blue-600
                        focus:outline-none
                        focus:ring-1
                        focus:ring-blue-600
                      "
                    >
                      <span class="sr-only">Previous</span>
                      <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                    </a>
                  </span>
                </nav>
                <button
                  @click="handleClick()"
                  class="
                    inline-flex
                    justify-center
                    rounded-md
                    border border-gray-300
                    bg-white
                    px-3.5
                    py-2
                    text-sm
                    font-medium
                    text-gray-700
                    shadow-sm
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring-2
                    focus:ring-indigo-500
                    focus:ring-offset-2
                  "
                >
                  <FilterIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-1 flex-col overflow-y-auto pb-4 bg-gray-50">
          <div class="p-5 space-y-4">
            <div
              class="
                bg-blue-50
                border border-blue-500
                text-blue-500
                px-6
                py-4
                rounded-md
                flex
                justify-between
                items-start
                space-x-4
              "
            >
              <!-- <p> Which word does not have a similar meaning to - <strong>attitude</strong></p> -->
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Expedita dolorem ipsa vero, fugiat delectus dolorum unde
                dolores? Cumque voluptate temporibus, modi laborum alias velit,
                dignissimos sed nulla culpa eaque optio. Lorem ipsum dolor sit
                amet, consectetur adipisicing elit. Expedita dolorem ipsa vero,
                fugiat delectus dolorum unde dolores? Cumque voluptate
                temporibus, modi laborum alias velit, dignissimos sed nulla
                culpa eaque optio.
              </p>
              <PencilAltIcon
                class="
                  flex-shrink-0
                  w-4
                  h-4
                  text-blue-400
                  hover:opacity-75
                  cursor-pointer
                "
              />
            </div>

            <div
              class="
                border border-gray-100
                rounded-lg
                bg-white
                shadow-sm
                px-4
                py-5
                sm:p-6
                space-y-5
              "
            >
              <!-- <p>Quasi architecto quia enim non mollitia. Quae praesentium temporibus animi quia, ex voluptas. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor ab culpa doloremque reiciendis molestias rem modi eligendi? </p> -->
              <div class="flex justify-between items-start space-x-4">
                <!-- <p> Which word does not have a similar meaning to - <strong>attitude</strong></p> -->
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Expedita dolorem ipsa vero, fugiat delectus dolorum unde
                  dolores? Cumque voluptate temporibus, modi laborum alias
                  velit, dignissimos sed nulla culpa eaque optio.
                </p>
                <PencilAltIcon
                  class="
                    flex-shrink-0
                    w-4
                    h-4
                    text-blue-400
                    hover:opacity-75
                    cursor-pointer
                  "
                />
              </div>
              <div>
                <a
                  href="#"
                  class="
                    flex
                    w-full
                    items-center
                    justify-center
                    rounded-md
                    border border-gray-300
                    bg-white
                    px-4
                    py-2
                    text-sm
                    font-medium
                    text-gray-700
                    shadow-sm
                    hover:bg-gray-50
                  "
                >
                  Add Image
                </a>
              </div>

              <div class="border border-gray-200 rounded-md my-4">
                <div class="flow-root">
                  <ul role="list" class="divide-y divide-gray-200">
                    <li v-for="choice in choices" :key="choice.id">
                      <div
                        class="
                          relative
                          focus-within:ring-2 focus-within:ring-indigo-500
                          flex
                          items-center
                          justify-between
                        "
                      >
                        <div
                          class="
                            hover:underline
                            focus:outline-none
                            p-6
                            flex flex-row
                            space-x-4
                            items-center
                          "
                        >
                          <input
                            id="comments"
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            class="
                              flex-shrink-0
                              h-4
                              w-4
                              rounded
                              border-gray-300
                              text-indigo-600
                              focus:ring-indigo-500
                            "
                          />
                          <div class="" aria-hidden="true">
                            {{ choice.data }}
                          </div>
                        </div>
                        <div class="mr-6 flex space-x-2">
                          <PencilAltIcon
                            class="
                              w-4
                              h-4
                              text-blue-400
                              hover:opacity-75
                              cursor-pointer
                            "
                          />
                          <!-- <TrashIcon
                            class="
                              w-4
                              h-4
                              text-red-400
                              hover:opacity-75
                              cursor-pointer
                            "
                          /> -->
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <a
                  href="#"
                  class="
                    flex
                    w-full
                    items-center
                    justify-center
                    rounded-md
                    border border-gray-300
                    bg-white
                    px-4
                    py-2
                    text-sm
                    font-medium
                    text-gray-700
                    shadow-sm
                    hover:bg-gray-50
                  "
                >
                  Add Choice
                </a>
              </div>
            </div>
            <!-- <div class="flex justify-end">
                  <button type="button" class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      Save Changes
                  </button>
              </div> -->
          </div>
        </div>
        <div
          class="
            flex flex-shrink-0
            border-t border-gray-300
            px-4
            h-12
            items-center
          "
        >
          <p
            class="text-xs font-normal text-gray-600 group-hover:text-gray-900"
          >
            Created On: June 23, 2022 by admin@skilltech.ph
            
            <span v-if="store.getters.modal">TRUE</span>
            <span v-else>DALAE</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import store from '@/store';
import {
  FilterIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/vue/solid";
import { PencilAltIcon, TrashIcon } from "@heroicons/vue/outline";

const choices = [
  {
    id: 1,
    data: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut dolorum itaque iure assumenda minus laborum, nemo excepturi nostrum deleniti est quia ea tempore tempora pariatur voluptatum exercitationem, architecto enim. Dignissimos.",
  },
  {
    id: 1,
    data: "aptitude",
  },
  {
    id: 1,
    data: "approach",
  },
  {
    id: 1,
    data: "manner",
  },
];

const handleClick = () => {
  store.dispatch('setSlideOver', true)
}

</script>