<template>
  <main>
    <div class="px-4 sm:px-6 lg:px-8 mt-10">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto flex justify-between">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Today is {{ convertTimestampToDate(todaysDate) }}</h1>
          <h1 class="text-base font-semibold leading-6 text-gray-900">Six Months ago was {{ convertTimestampToDate(getTimestampSomeMonthAgo(6)) }}</h1>
          <!-- <p class="mt-2 text-sm text-gray-700">A list of all the users in your account including their name, title, email
            and role.</p> -->
        </div>
      </div>
      <div class="mt-4 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Email</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Purchased</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Practice</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Mock</th>
                    <!-- <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Diff</th> -->
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Skillpoints</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Risk</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-24"></th>
                    <!-- <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span class="sr-only">Edit</span>
                    </th> -->
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="user in usersData" :key="user.id">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {{ user.email}} {{ user.isLegacyUser ? '💎' : '' }}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ convertTimestampToDate(user.date_created) }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ convertTimestampToDate(user.date_purchased) }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ convertTimestampToDate(user.date_last_practice) }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ convertTimestampToDate(user.date_last_mock_exam) }}</td>
                    <!-- <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ monthDiff((user.date_purchased ? user.date_purchased : user.date_created), user.date_last_practice) }}</td> -->
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.skillpoints }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.freshStatus }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.riskLevel }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <a :href="`/v2/user/${user.id}`" target="_blank" class="flex items-center space-x-1">
                        <span>View Profile</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                        </svg>
                      </a>
                    </td>
                    <!-- <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <a href="#" class="text-indigo-600 hover:text-indigo-900">Edit<span class="sr-only">, {{ user.id}}</span></a>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!waiting" class="text-center justify-center mt-4">
        <div v-if="usersData.length > 0" class="my-3 text-xs">Showing {{ usersData.length }} Users</div>
        <button @click="initialize()" :disabled="waiting" type="button" class="mx-auto block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          Load more
        </button>
      </div>
      <div v-else class="flex justify-center mt-4">
        <svg
          class="animate-spin -ml-1 w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    </div>
    <div class="h-40">

    </div>

  </main>
</template>

<script setup>
import { ref } from 'vue'
import { auth, db, functions } from '@/firebase/config.js'
import { getDocs, collection, orderBy, query, doc, updateDoc, setDoc, deleteDoc, limit, where, startAfter } from "firebase/firestore"

let lastDoc = ref(null)  // this is used for lazy loading
let waiting = ref(false) // this is a UI flag
const usersData = ref([]) // all users will be stored here

// This is the UserData data structure
// const userData = {
//   id: null, 
//   email: null, 
//   date_created: null, // timestamp in milli of when the account was created
//   date_purchased: null, // timestamp in milli of when the user purchased a caap reviewer
//   date_last_practice: null, // timestamp in milli of the user's most recent practice session
// }

// today's date in milliseconds
const todaysDate = new Date().getTime();

/**
 * a function that would get the timestamp of a previous date
 * measured in months ago (i.e. `months = 6` means 6 months ago)
 */
const getTimestampSomeMonthAgo = (months) => {
  const date = new Date()
  date.setMonth(date.getMonth() - months)
  return date.getTime()
}

const convertTimestampToDate = (timestampInMilliseconds) => {
  if (timestampInMilliseconds) {

    // this will just return a default date string format
    // const date = new Date(parseInt(timestampInMilliseconds))
    // return date.toDateString()

    // this will return a specific YYYY-MM-DD format
    const date = new Date(parseInt(timestampInMilliseconds));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;

  } else {
    return 'NA' // returns NA if timestamp is null (i.e. for legacy users, date_purchased is null)
  }
}

const attachUserFlags = (user) => {

  // 1. Is user a legacy user?
  user.isLegacyUser = (!user.date_purchased) ? true : false;

  /**
   * 2. How fresh is the account?
   * undefined = if they had no recent practice 🟦
   * stale = if their recent practice happened more than a month ago ⬜️
   * active = if their recent practice happened less than a month ago 🟨
   * online = if their recent practice happened today 🟩
   */

  if(user.date_last_practice){
    if (user.date_last_practice > getTimestampSomeMonthAgo(1)) {
      if (convertTimestampToDate(user.date_last_practice) == convertTimestampToDate(todaysDate)) {
        user.freshStatus = '🟩 online'
      } else {
        user.freshStatus = '🟨 active'
      }
    } else {
      user.freshStatus = '⬜️ stale'
    }
  }else{
    user.freshStatus = '🟦 undefined'
  }  

  /**
   * 3. How Risky is the User?
   * 
   * A legacy user, by default, had their accounts created more than 6 months ago (2021-2022).
   * If a legacy user doesn't have a recent practice, then the risk is undefined. 🔵
   * If a legacy user had a recent practice more than 12 months ago, then there are no risk. 🟢
   * If a legacy user had a recent practice less than 12 months ago, then they are low risk. 🟡
   * If a legacy user had a recent practice less than 6 months ago, then they are medium risk. 🟠
   * If a legacy user had a recent practice less than 1 month ago, then they are high risk. 🔴
   * If a legacy user had a recent practice TODAY, then they are critical risk (absolute certainty). 🟣
   * 
   * If a non-legacy user purchased less than 6 months ago and their recent practice happened less than 6 month ago, then they are low risk. 🟡 
   * If a non-legacy user purchased less than 6 months ago and their recent practice happened less than 1 month ago, then there are no risk. 🟢
   * If a non-legacy user purchased more than 6 months ago and their recent practice happened more than 1 month ago, then they are medium risk. 🟠
   * If a non-legacy user purchased more than 6 months ago and their recent practice happened less than 1 month ago, then they are high risk. 🔴
   * If a non-legacy user purchased more than 6 months ago and their recent practice happeed TODAY, then they are critical risk (absolute certainty). 🟣
   * 
   * Catch all: Risk is null. ⚪️
   */

  user.riskLevel = '⚪️ null' // default

  if (!user.date_last_practice) {
    user.riskLevel = '🔵 undefined' // no practice found. definitely undefined
  }

  // user is legacy user
  if (user.isLegacyUser) {

    if (user.date_last_practice < getTimestampSomeMonthAgo(12)) {
      user.riskLevel = '🟢 none' // no risk. safe
    }

    if (user.date_last_practice > getTimestampSomeMonthAgo(12)) {
      user.riskLevel = '🟡 low'
    }

    if (user.date_last_practice > getTimestampSomeMonthAgo(6)) {
      user.riskLevel = '🟠 medium'
    }

    if (user.date_last_practice > getTimestampSomeMonthAgo(1)) {
      user.riskLevel = '🔴 high' // within the month
    }

    if (convertTimestampToDate(user.date_last_practice) == convertTimestampToDate(todaysDate)) {
      user.riskLevel = '🟣 critical' // absolutely risky
    }
  }

  // user is not legacy user
  else {

    if (user.date_purchased > getTimestampSomeMonthAgo(6) && user.date_last_practice > getTimestampSomeMonthAgo(6)) {
      user.riskLevel = '🟡 low'
    }

    if (user.date_purchased > getTimestampSomeMonthAgo(6) && user.date_last_practice > getTimestampSomeMonthAgo(1)) {
      user.riskLevel = '🟢 none' // no risk. safe
    }

    if (user.date_purchased < getTimestampSomeMonthAgo(6) && user.date_last_practice < getTimestampSomeMonthAgo(1)) {
      user.riskLevel = '🟠 medium'
    }

    if (user.date_purchased < getTimestampSomeMonthAgo(6) && user.date_last_practice > getTimestampSomeMonthAgo(1)) {
      user.riskLevel = '🔴 high'
    }

    if (user.date_purchased < getTimestampSomeMonthAgo(6) && convertTimestampToDate(user.date_last_practice) == convertTimestampToDate(todaysDate)) {
      user.riskLevel = '🟣 critical'
    }
  }

  return user
}

// calculates the month difference between 2 dates in YYYY-MM-DD format
function monthDiff(date1, date2) {

  if(date1 == null || date2 == null ){
    return 'NA'
  }

  date1 = convertTimestampToDate(parseInt(date1))
  date2 = convertTimestampToDate(parseInt(date2))

  // Create Date objects
  const parts1 = date1.split('-');
  const parts2 = date2.split('-');
  const d1 = new Date(parts1[0], parts1[1] - 1, parts1[2]);
  const d2 = new Date(parts2[0], parts2[1] - 1, parts2[2]);

  // Calculate difference
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

const initialize = async () => {
  console.log("🪹 Initializing Nest.")

  waiting.value = true

  let userQuery;
  if (lastDoc.value) {
    userQuery = query(collection(db, "users"), where("reviewers.caap", "==", 'active'), startAfter(lastDoc.value), limit(10));
  } else {
    userQuery = query(collection(db, "users"), where("reviewers.caap", "==", 'active'), limit(10));
  }

  const userQuerySnapshot = await getDocs(userQuery);
  const users = userQuerySnapshot.docs.map(doc => { return { id: doc.id, data: doc.data() } });

  lastDoc.value = userQuerySnapshot.docs[userQuerySnapshot.docs.length - 1];

  // populate users data
  users.forEach(async (user) => {
    const practice_time = await queryLatestPractice(user.id)
    const mock_exam_time = await queryLatestMockExam(user.id)
    let userData = {
      id: user.id,
      email: user.data.email,
      // timestamp in milli of when the account was created
      date_created: parseInt(user.data.date_created),
      // timestamp in milli of when the user purchased a caap reviewer
      date_purchased: (user.data.reviewers.caap == 'active' && user.data.caap_data) ? parseInt(user.data.caap_data.purchase_date) : null,
      // timestamp in milli of the user's most recent practice session
      date_last_practice: parseInt(practice_time),
      date_last_mock_exam: parseInt(mock_exam_time),
      // user skillpoints
      skillpoints: parseInt(user.data.skillpoints).toLocaleString()
    }

    userData = attachUserFlags(userData)
    usersData.value.push(userData)
  });

  waiting.value = false
}

const queryLatestPractice = async (userId) => {

  // Build the query
  const q = query(
    collection(db, "practice"),
    where("user", "==", userId),
    orderBy("time.start", "desc"),
    limit(1)
  );

  const qS = await getDocs(q);
  const mapped = qS.docs.map(doc => { return { id: doc.id, data: doc.data() } });
  if (mapped.length > 0) {
    return mapped[0].data.time.start
  } else {
    return null
  }
}

const queryLatestMockExam = async (userId) => {

// Build the query
const q = query(
  collection(db, "mock"),
  where("user", "==", userId),
  orderBy("time.start", "desc"),
  limit(1)
);

const qS = await getDocs(q);
const mapped = qS.docs.map(doc => { return { id: doc.id, data: doc.data() } });
if (mapped.length > 0) {
  return mapped[0].data.time.start
} else {
  return null
}
}

initialize();

</script>

<style scoped>
@layer components {
  main {
    @apply h-screen overflow-auto
  }

  ul {
    @apply space-y-3 text-xs p-1
  }

  /* li {
    @apply flex flex-col
  } */

  .badge {
    @apply inline-flex items-center rounded-full bg-gray-50 px-2 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500
  }

  button {
    @apply rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-25
  }
}</style>