<template>
  <div class="flex-row border-b dark:border-warm-gray-700">
    <div class="flex justify-start items-center mb-8">
      <h2 class="text-xl font-extrabold text-warm-gray-700 dark:text-warm-gray-100 tracking-wider">Scheduler</h2>
    </div>

    <ul v-if="!retrieving" class="flex justify-start">
      <li v-for="(tab,i) in tabs" :key="i" :class="[view == tab.viewId && 'border-b-4 border-yellow-600']">
        <router-link :to="tab.to">
          <button class="py-2 px-4 focus:outline-none">
            {{tab.name}}
          </button>
        </router-link>
      </li>
    </ul>

  </div>
  <router-view v-if="!retrieving"/>
  <div v-if="retrieving" class="flex justify-center items-center mt-10">
    <SpinningIcon class="h-6 w-6 text-yellow-600" />
  </div>
</template>

<script>
import SpinningIcon from '@/components/SpinningIcon';
export default {
  data(){
    return {
      retrieving: false,
      view: '',
      tabs: [
        {
          viewId: 'announcements',
          name: 'Announcements',
          to: '/scheduler/announcements',
        },
        {
          viewId: 'tasks',
          name: 'Tasks',
          to: '/scheduler/tasks',
        }
      ],
    }
  },
  components:{
    SpinningIcon,
  },
  updated() {
    const curPath = this.$route.path;
    this.view = curPath.split('/')[2];
  },
  mounted() {
    const curPath = this.$route.path;
    this.view = curPath.split('/')[2];
  },
}
</script>