import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { doc, getDoc, getDocs, query, collection, orderBy, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { ref, onValue, push, onDisconnect, set, serverTimestamp } from "firebase/database";

import { auth, db, functions, rtdb } from '@/firebase/config.js';
import { userColName } from '@/firebase/vars';


/**
 * Login
 */
export const logIn = async(email, password) => {

    return signInWithEmailAndPassword(auth, email, password)
        .then(async(cred) => {



            // check if user is admin@skilltech.ph  or an admin
            const isAdmin = await cred.user.getIdTokenResult()
                .then(tokenResult => tokenResult.claims.admin);
            if (isAdmin || cred.user.email == 'root@skilltech.team') {
                return { error: false, data: cred.user }
            } else {
                logOut();
                return { error: true, data: 'unauthorized' }
            }

        })
        .catch((err) => {

            let errRes = null;

            switch (err.code) {
                case 'auth/user-not-found':
                    errRes = {
                        title: 'User not found',
                        message: 'This account could not be found.'
                    };
                    break;
                case 'auth/user-disabled':
                    errRes = {
                        title: 'Disabled',
                        message: 'Your account has been disabled.'
                    };
                    break;
                case 'auth/wrong-password':
                    errRes = {
                        title: 'Incorrect Password',
                        message: 'The password you entered is incorrect.'
                    };
                    break;
                default:
                    errRes = {
                        title: 'Error',
                        message: 'Unable to login. Please try again later.'
                    };
                    break;
            }

            return { error: true, data: errRes };
        });

}


/**
 * Logout
 */
export const logOut = async() => {
    console.log('(process logout...)');
    return await signOut(auth).then(() => {
        return { error: false, message: 'success' };
    }).catch((error) => {
        return { error: true, data: error };
    });
}


/**
 * Get user info
 */
export const getUserOtherInfo = async(id) => {
    const user = await getDoc(doc(db, userColName, id));
    return user.exists ? user.data() : null;
}


/**
 * Get user info from email
 */
export const getUserOtherInfoFromEmail = async(email) => {

    let user = null;
    const q = query(collection(db, userColName), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        user = {
            uid: doc.id,
            data: doc.data()
        }
    });

    return user;

}


/**
 * Get list of users
 * @param {integer} userPerPage 
 * @returns {object}
 */
export const getUsers = async(userPerPage) => {
    const getUsers = httpsCallable(functions, 'getUsers');
    return await getUsers({ userPerPage })
        .then((result) => {
            return { error: false, data: result };
        })
        .catch((err) => {
            return { error: true, data: err };
        });
}

/**
 * Update the purchase reviewers
 * @param {object} purchaseForm 
 * @returns {object}
 */
export const purchaseReviewers = async(purchaseForm) => {
    const purchaseReviewers = httpsCallable(functions, 'purchaseReviewers');
    return await purchaseReviewers({ email: purchaseForm.email, reviewers: purchaseForm.reviewers })
        .then((result) => {
            return { error: false, data: result };
        })
        .catch((err) => {
            return { error: true, data: err };
        });
}


/**
 * Set or remove user as admin
 * @param {email} email 
 * @param {boolean} isSetAdmin 
 * @returns {object}
 */
export const setAdmin = async(email, isSetAdmin) => {
    const setAdmin = httpsCallable(functions, 'setAdmin');
    return await setAdmin({ email, isSetAdmin })
        .then((result) => {
            return { error: false, data: result };
        })
        .catch((err) => {
            return { error: true, data: err };
        });
}


/**
 * Delete user from the firestore database
 * @param {string} uid 
 * @returns object
 */
export const deleteUser = async(uid) => {
    const deleteUser = httpsCallable(functions, 'deleteUser');
    return await deleteUser({ uid })
        .then((result) => {
            return { success: true, data: result };
        })
        .catch((err) => {
            return { success: false, error: err };
        });
}

/**
 * Disable user from the firestore database
 * @param {stirng} uid
 * @param {boolean} disabled
 * @returns {object}
 */
export const disableUser = async(uid, disabled) => {
    const disableUser = httpsCallable(functions, 'disableUser');
    return await disableUser({ uid, disabled })
        .then((result) => {
            return { success: true, data: result };
        })
        .catch((err) => {
            return { success: false, error: err };
        });
}

/**
 * Add new user
 * @param {object} newUserForm 
 * @returns 
 */
export const addUser = async(newUserForm) => {
    const addUser = httpsCallable(functions, 'addUser');
    return await addUser(newUserForm)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

/**
 * Updare user authData like the email
 * @param {string} uid 
 * @param {object} dataToUpdate 
 * @returns {object}
 */
export const updateUser = async(uid, dataToUpdate) => {
    const updateUser = httpsCallable(functions, 'updateUser');
    return await updateUser({ uid, dataToUpdate })
        .then((result) => {
            return { success: true, data: result };
        })
        .catch((err) => {
            return { success: false, error: err };
        });
}

/**
 * Send SMS to users
 * @param {string} to 
 * @param {string} body 
 * @returns {object}
 */
export const sendSMS = async(to, body) => {

    const sendSMS = httpsCallable(functions, 'sendSMS');
    return await sendSMS({ message: { to, body } })
        .then((result) => {
            return { success: true, data: result };
        })
        .catch((err) => {
            return { success: false, error: err };
        });
}

/**
 * Fetch all the users from users collection
 * @returns {object}
 */
export const fetchUserFromFirebase = async() => {

    return await getDocs(query(collection(db, "users"), orderBy("date_created", "desc")))
        .then((querySnapshot) => {
            var users = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                users.push(data)
            });
            return { success: true, data: users };
        })
        .catch((error) => {
            console.error("Error getting user document:", error);
            return { success: false, error: error };
        });
}


/**
 * Fetch all users that logged in to our app
 * @returns {object}
 */
export const fetchUsersLoggedIn = async() => {


    return await getDocs(collection(db, "usersLoggedIn"))
        .then((querySnapshot) => {
            var users = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                users.push(data)
            });
            return { success: true, data: users };
        })
        .catch((error) => {
            console.error("Error getting user logged in list:", error);
            return { success: false, error: error };
        });
}


export const setUserPresence = (uid) => {
    /**
     * this is for user presence (online, offline, away) 
     */

    const myConnectionsRef = ref(rtdb, `users/${uid}/connections`);
    const lastOnlineRef = ref(rtdb, `users/${uid}/lastOnline`);

    const connectedRef = ref(rtdb, '.info/connected');
    onValue(connectedRef, (snap) => {

        console.log('=====> (00) value changed!!!')

        if (snap.val() === true) {

            // const con = push(myConnectionsRef);

            // // When I disconnect, remove this device
            // onDisconnect(con).remove();

            // // Add this device to my connections list
            // // this value could contain info about the device or a timestamp too
            // set(con, true);

            // // When I disconnect, update the last time I was seen online
            // onDisconnect(lastOnlineRef).set(serverTimestamp());
            console.log('=====> (01) value is true!!! ser connected!!!')
        } else {
            console.log('=====>(02) value is false!!! user not connected!!!')
        }
    });
}