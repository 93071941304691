import { collection, query, where, getDocs, getDoc, setDoc, doc, writeBatch, deleteDoc, serverTimestamp, addDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { db, functions } from '@/firebase/config.js';
import { deleteUser } from "@/firebase/controllers/userController";

import * as _ from "lodash";

import store from '@/store';

/**
 * Copy all firestore data from old email account to new email account 
 * @param {emal} from 
 * @param {email} to 
 */
export const transferUserData = async(data) => {

    if (!data.fromUid) return { error: true, message: 'From email address not found.' }
    if (!data.toUid) return { error: true, message: 'To email address not found.' }
    if (data.fromUid == data.toUid) return { error: true, message: 'You have entered the same email address.' }

    const from = data.fromEmail;
    const to = data.toEmail;
    const fromUid = data.fromUid;
    const toUid = data.toUid;
    const fromInfo = {
        uid: fromUid,
        email: from
    }
    const toInfo = {
        uid: toUid,
        email: to
    }

    await store.dispatch('setTransferUserProgress', { type: 'separator', message: `--------------------` });


    const promiseRes = await Promise.all([
        // 1) _endorser_codes - uid is the doc ID
        copyAndDeleteDataWhereDocidIsUid('_endorser_codes', fromInfo, toInfo),
        // 2) leaderboard - uid is the doc ID (update field: email, id)
        copyAndDeleteDataWhereDocidIsUid('leaderboard', fromInfo, toInfo, { email: to, id: toUid }),
        // 3) s2leaderboard - uid is the doc ID (update field: email, id)
        copyAndDeleteDataWhereDocidIsUid('s2leaderboard', fromInfo, toInfo, { email: to, id: toUid }),
        // 4) users - uid is the doc ID (update field: email)
        copyAndDeleteDataWhereDocidIsUid('users', fromInfo, toInfo, { email: to }),

        // 5) lite_mock - uid is in the field (update field: user)
        copyAndDeleteDataWhereDocidIsUnique('lite_mock', fromInfo, toInfo, 'user', { user: toUid }),
        // 6) lite_practice - uid is in the field (update field: user)
        copyAndDeleteDataWhereDocidIsUnique('lite_practice', fromInfo, toInfo, 'user', { user: toUid }),
        // 7) milestones  - uid is in the field (update field: user)
        copyAndDeleteDataWhereDocidIsUnique('milestones', fromInfo, toInfo, 'user', { user: toUid }),
        // 8) mock  - uid is in the field (update field: user)
        copyAndDeleteDataWhereDocidIsUnique('mock', fromInfo, toInfo, 'user', { user: toUid }),
        // 9) practice  - uid is in the field (update field: user)
        copyAndDeleteDataWhereDocidIsUnique('practice', fromInfo, toInfo, 'user', { user: toUid }),

        // 10) usersLoggedIn - uid is the doc ID (remove only)
        DeleteData('usersLoggedIn', fromUid)
    ]);

    await store.dispatch('setTransferUserProgress', { type: 'separator', message: `--------------------` });

    // transfer user Claims
    try {
        const transferUserCredentials = httpsCallable(functions, 'transferUserCredentials');
        const result = await transferUserCredentials({ emailFrom: from, uidTo: toUid });
        await store.dispatch('setTransferUserProgress', { type: 'success', message: `Transferring of user claims completed!` });
    } catch (error) {
        await store.dispatch('setTransferUserProgress', { type: 'error', message: `Transferring of user claims completed!` });
    }

    // delete old user account
    await deleteUser(fromUid);
    await store.dispatch('setTransferUserProgress', { type: 'success', message: ` Deleting old user account completed!` });

    // save to history/transfer_user_data/logs
    const logData = {
        datePerform: serverTimestamp(),
        from,
        to,
        collectionAffected: ['_endorser_codes', 'leaderboard', 's2leaderboard', 'users', 'lite_mock', 'lite_practice', 'milestones', 'mock', 'practice', 'usersLoggedIn']
    }

    await Promise.all([
        // Add to history logs then update
        addDoc(collection(db, "history", "transfer_user_data", "logs"), logData),
        // update backup doc metadata
        setDoc(doc(db, 'backup', fromInfo.uid), { date: serverTimestamp(), email: fromInfo.email })
    ]);

    await store.dispatch('setTransferUserProgress', { type: 'success', message: `Updating of logs and backup information completed!` });

    return { error: false, message: 'Success!' }

}

// const getUserUid = async(email) => {
//     const usersRef = collection(db, "users");
//     const q = await getDocs(query(usersRef, where("email", "==", email)));
//     let docId;
//     q.forEach(doc => docId = doc.id);
//     return docId;
// }


const copyAndDeleteDataWhereDocidIsUid = async(colName, from, to, dataToUpdate = {}) => {

    let message;
    // get the doc data from old account uid
    const user = await getDoc(doc(db, colName, from.uid));

    if (user.exists()) {

        await store.dispatch('addTransferUserTotalDocs', 1);

        const docData = user.data();
        const docUpdatedData = {...docData }

        // modify the data if needed before writing it
        for (const key in dataToUpdate) {
            docUpdatedData[key] = dataToUpdate[key];
        }

        await Promise.all([
            // write the old UPDATED data to new account uid
            setDoc(doc(db, colName, to.uid), docUpdatedData),
            // write the old data to backup collection (backup/{uidFrom}/{colName}/{uidFrom})
            setDoc(doc(db, 'backup', from.uid, colName, from.uid), docData),
            // delete old user data
            deleteDoc(doc(db, colName, from.uid))
        ]);

        await store.dispatch('addTransferUserTransferredDocs', 1);

        message = { type: 'success', message: `[ ${colName} ] : completed! ` };
    } else {
        message = { type: 'warning', message: `[ ${colName} ] : no records found! ` };
    }

    await store.dispatch('setTransferUserProgress', message);
    return true;

}


const copyAndDeleteDataWhereDocidIsUnique = async(colName, from, to, conditionKey, dataToUpdate = {}, ) => {

    // const docs = [];
    // const docsToDelete = [];
    // let updatedDocs = [];

    let message;

    // get all documents belongs to uidFrom user
    const docRef = collection(db, colName);

    const querySnapshot = await getDocs(query(docRef, where(conditionKey, "==", from.uid)));

    await store.dispatch('addTransferUserTotalDocs', querySnapshot.size);

    let batchCounter = 1;
    let batch = [];
    let counter = 0;
    batch[counter] = writeBatch(db);
    let remainingDocsCount = querySnapshot.size;
    const querySnapshotDocs = querySnapshot.docs;

    if (querySnapshot.size > 0) {

        let batches = [];

        for (const snapDoc of querySnapshotDocs) {

            // counter++;
            // remainingDocsCount--;
            let docID = snapDoc.id;
            let docData = snapDoc.data();
            let updatedDocData = snapDoc.data();

            // update the doc data first
            // modify the data if needed before writing it
            //loop thru each dataToUpdate
            for (const key in dataToUpdate) {
                updatedDocData[key] = dataToUpdate[key];
            }

            // for copying the updated docs
            batch[counter].set(doc(collection(db, colName)), updatedDocData);
            // for saving the old data to backup
            batch[counter].set(doc(collection(db, 'backup', from.uid, colName)), docData);
            // delete original docs
            batch[counter].delete(doc(db, colName, docID));
            // push for later commit
            batches.push(commitBatch(batch[counter], colName));
            counter++;
            batch[counter] = writeBatch(db);


            // if counter reached 150
            // commit batch then reset
            // reset counter
            // if (counter == 2 || remainingDocsCount == 0) {
            //     batches.push(commitBatch(batch[batchCounter], counter, colName));
            //     // await batch[batchCounter].commit();
            //     // await store.dispatch('addTransferUserTransferredDocs', counter);
            //     batchCounter++;
            //     batch[batchCounter] = writeBatch(db);
            //     counter = 0
            // }

        }

        await Promise.all([...batches]);

        message = { type: 'success', message: `[ ${colName} ] : completed! ${querySnapshot.size} docs transferred.` };

    } else {

        message = { type: 'warning', message: `[ ${colName} ] : no records found! ` };
    }

    await store.dispatch('setTransferUserProgress', message);
    return true;


    /*
    querySnapshot.forEach(async(snapDoc) => {
        





        // copy the udpated doc data


        // backup the doc

        // delete the doc

        // docs.push(doc.data());
        // docsToDelete.push(doc.id);
        // updatedDocs.push(doc.data());

    });
    */

    /*

    if (docs.length > 0) {

        console.log(`====> [ ${colName} ] : ${docs.length} documents...`)

        // modify the data if needed before writing it
        // loop thru all docs
        updatedDocs = updatedDocs.map((doc) => {
            //loop thru each dataToUpdate
            for (const key in dataToUpdate) {
                doc[key] = dataToUpdate[key];
            }
            return doc;
        })

        // Get a new write batch for duplicating the data for new account
        // loop thru all updated docs
        // let batchCount = 0;
        // const copyBatches = _.chunk(updatedDocs, 300).map(upDocs => {
        //     const batch = writeBatch(db);
        //     upDocs.forEach(newDoc => {
        //         const newRef = doc(collection(db, colName));
        //         batch.set(newRef, newDoc);
        //     })
        //     batchCount++
        //     console.log('====>', `[ ${colName} ] [ ${batchCount} ] : copy batch...`)
        //     return batch.commit()
        // })

        // console.log(`====> [ ${colName} ] : copy batches...`)
        // const promiseAllRes = await Promise.all(copyBatches)
        // console.log(`====> [ ${colName} ] : copy batches completed...`, promiseAllRes)

        // let batch = writeBatch(db);
        // let counter = 0;
        // let batchCount = 0;
        // try {
        //     updatedDocs.forEach(async newDoc => {
        //         const newRef = doc(collection(db, colName));
        //         batch.set(newRef, newDoc);
        //         counter++;
        //         if (counter > 450) {
        //             console.log(`====> [ ${colName} ] [${batchCount}]: copy batch commiting...`)
        //             await batch.commit();
        //             console.log(`====> [ ${colName} ] [${batchCount}]: copy batch commited...`)
        //             counter = 0;
        //             batchCount++;
        //             batch = writeBatch(db);
        //         }
        //     })
        // } catch (err) {
        //     console.error(`[ ${colName} ] : Error... ${error.stack}`);
        //     reject(error);
        // }


        // Get a new write batch for saving the old data to backup
        // loop thru all old docs
        // const buBatches = _.chunk(docs, 300).map(orgDocs => {
        //     const batch = writeBatch(db);
        //     orgDocs.forEach(docData => {
        //         const newRef = doc(collection(db, 'backup', from.uid, colName));
        //         batch.set(newRef, docData);
        //     })
        //     console.log('====>', `[ ${colName} ] : Backup batch...`)
        //     return batch.commit()
        // })
        // await Promise.all(buBatches)

        // // Get a new write batch for deleting the old data
        // // loop thru all old document ID
        // const delBatches = _.chunk(docsToDelete, 300).map(delDocs => {
        //     const batch = writeBatch(db);
        //     delDocs.forEach(docID => {
        //         const delRef = doc(db, colName, docID);
        //         batch.delete(delRef);
        //     })
        //     console.log('====>', `[ ${colName} ] : delete batch...`)
        //     return batch.commit()
        // })
        // await Promise.all(delBatches)

        // await Promise.all([...delBatches, ...buBatches, ...copyBatches])



        message = { type: 'success', message: `[ ${colName} ] : completed! ${docs.length} docs transferred.` };
    } else {
        message = { type: 'warning', message: `[ ${colName} ] : no records found! ` };
    }

    await store.dispatch('setTransferUserProgress', message);

    */


}

const commitBatch = async(batch, colName) => {
    try {
        await batch.commit();
        await store.dispatch('addTransferUserTransferredDocs', 1);
        return true;
    } catch (error) {
        console.error(`[ ${colName} ] : Error while committing bacth.`, error);
        return false;
    }
}

const DeleteData = async(colName, docId) => {
    await deleteDoc(doc(db, colName, docId));
    await store.dispatch('setTransferUserProgress', { type: 'success', message: `[ ${colName} ] : record has been deleted! ` });
    return true;
}