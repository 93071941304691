<template>
  <div class="flex">
    <div class="flex w-64 flex-col">
      <div
        class="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white"
      >
        <div
          class="
            flex flex-shrink-0
            border-b border-gray-300
            h-20
            items-center
            px-4
            bg-white
          "
        >
          <div class="group block w-full flex-shrink-0">
            <div class="flex items-center justify-between">
              <div>
                <p
                  class="
                    text-sm
                    font-medium
                    text-gray-700
                    group-hover:text-gray-900
                  "
                >
                  Topics
                </p>
                <p
                  class="
                    text-xs
                    font-medium
                    text-gray-500
                    group-hover:text-gray-700
                  "
                >
                  under Categories
                </p>
              </div>
              <button
                class="
                  inline-flex
                  justify-center
                  rounded-full
                  border border-gray-300
                  bg-white
                  p-1
                  text-sm
                  font-medium
                  text-gray-400
                  shadow-sm
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-indigo-500
                  focus:ring-offset-2
                "
              >
                <PlusIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <div class="flex flex-1 flex-col overflow-y-auto pb-4">
          <nav
            class="min-h-0 flex-1 overflow-y-auto -mt-0.5"
            aria-label="Directory"
          >
            <div
              v-for="category in categories"
              :key="category.id"
              class="relative"
            >
              <div
                class="
                  sticky
                  top-0
                  z-10
                  border-t border-b border-gray-200
                  bg-gray-50
                  px-4
                  py-1
                  text-sm
                  font-medium
                  text-gray-500
                  flex
                  justify-between
                  items-center
                "
              >
                <h3>
                  {{ category.name }}
                  <!-- <span class="inline-flex items-center rounded-full bg-indigo-100 px-1.5 ml-1 text-xs font-normal text-gray-800">4</span> -->
                </h3>
                <div
                  class="
                    text-xs
                    font-normal
                    cursor-pointer
                    text-gray-400
                    hover:opacity-75
                  "
                >
                  <button @click="handleClickCategory">
                    <PencilAltIcon class="h-4 w-4" aria-hidden="true" />
                  </button>
                  
                </div>
              </div>
              <ul role="list" class="relative z-0 space-y-1 px-2 py-4">
                <div
                  v-if="topics.filter((e) => e.catId == category.id).length == 0"
                  class="px-4 py-2 text-sm text-gray-200 font-medium"
                >
                  Empty
                </div>
                <li
                  v-for="topic in topics.filter((e) => e.catId == category.id)"
                  :key="topic.id"
                  :class="[
                    topic.id == store.state.current.topicId
                      ? 'bg-gray-200 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group flex justify-between items-center px-4 py-2 text-sm font-medium rounded-md  cursor-pointer',
                  ]"
                  @click="store.dispatch('setCurrentTopic', topic.id)"
                >
                  <span>{{ topic.name }}</span>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div
          class="
            flex flex-shrink-0
            border-t border-gray-300
            px-4
            h-12
            items-center
          "
        >
          <p
            class="text-xs font-normal text-gray-600 group-hover:text-gray-900"
          >
            Categories: 5
            <span class="mx-2 text-sm font-light text-gray-300">|</span>
            Topics: 15
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

import store from '@/store';
import { PencilAltIcon, PlusIcon } from "@heroicons/vue/solid";

const categories = [
  { id: "cat-a", name: "Verbal Reasoning" },
  { id: "cat-b", name: "Numerical Ability" },
  { id: "cat-c", name: "Abstract Reasoning" },
  { id: "cat-d", name: "Category D" },
  { id: "cat-e", name: "Mechanical Aptitude" },
];

const topics = [
  { id: "item-1", catId: "cat-a", name: "Spelling", current: true },
  { id: "item-2", catId: "cat-a", name: "Word Meaning", current: false },
  { id: "item-3", catId: "cat-a", name: "Word Relationship", current: false },
  { id: "item-4", catId: "cat-a", name: "Comprehension", current: false },
  { id: "item-5", catId: "cat-a", name: "Critical Thinking", current: false },
  { id: "item-6", catId: "cat-b", name: "Computation", current: false },
  { id: "item-7", catId: "cat-b", name: "Estimation", current: false },
  { id: "item-8", catId: "cat-b", name: "Numerical Reasoning", current: false },
  { id: "item-9", catId: "cat-b", name: "Data Interpretation", current: false },
  { id: "item-10", catId: "cat-c", name: "Abstract Ability", current: false },
  {
    id: "item-11",
    catId: "cat-c",
    name: "Diagrammatic Ability",
    current: false,
  },
  { id: "item-12", catId: "cat-c", name: "Concentration", current: false },
  { id: "item-13", catId: "cat-c", name: "Spatial Ability", current: false },
  {
    id: "item-14",
    catId: "cat-e",
    name: "Mechanical Reasoning",
    current: false,
  },
  { id: "item-15", catId: "cat-e", name: "Fault Diagnosis", current: false },
  { id: "item-16", catId: "cat-e", name: "Data Checking", current: false },
];

const handleClickCategory = () => {
  console.log("LA")
  store.dispatch("setModal", true)
}
</script>