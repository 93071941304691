<template>
  <div class="mt-5">
    
    <div v-for="user in users" class="border rounded-md border-warm-gray-600 p-2 flex justify-between mb-2">

      <div class="flex justify-start items-center">
        <div class="bg-gray-100 rounded-full overflow-hidden h-10 w-10 mr-2">
          <svg
            class="w-full h-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
            ></path>
          </svg>
        </div>
        <div>
          <h3 class=" font-semibold">{{user.data.firstname}} {{user.data.lastname}}</h3>
          <span class=" text-warm-gray-600 dark:text-warm-gray-400 italic"> {{user.data.email}} </span> <br/>
          <span class=" text-warm-gray-600 dark:text-warm-gray-400 italic"> Purchase date: {{ (user.data.caap_data && user.data.caap_data.purchase_date) ? moment.unix(user.data.caap_data.purchase_date/1000).format('MMM DD YYYY') : ''}} </span>
        </div>
      </div>
      <div>
        <a :href="'/v2/user/' + user.id" target="_blank">
          <ArrowTopRightOnSquareIcon class="w-5 h-5 text-warm-gray-600"/>
        </a>
      </div>

    </div>


    <div v-if="!waiting" class="text-center justify-center mt-4">
      <div class="my-3 text-xs opacity-60">Showing {{users.length}} Users</div>
      <button v-if="!isLastSet" @click="loadMore()" :disabled="waiting" type="button" class="mx-auto block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        Load more
      </button>
    </div>
    <div v-else class="flex justify-center items-center mt-4">
      <svg
        class="animate-spin -ml-1 w-4 h-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <span class="italic ml-2 text-warm-gray-400 text-sm">Checking {{ queryLimit }} users...</span>
    </div>

  </div>
</template>
<script setup>

import { ref, onMounted } from 'vue';

import { auth, db, functions } from '@/firebase/config.js'
import { query, collection, orderBy, startAfter, limit, getDocs, where } from 'firebase/firestore';

import moment from "moment";

import {ArrowTopRightOnSquareIcon} from "@heroicons/vue/24/outline";


const waiting = ref(false)

const users = ref([]);
const lastDocument = ref(null);
const isLastSet = ref(false);
const filteredUsers = ref([]);
const queryLimit = 10

const loadMore = async () => {
  waiting.value = true
  try {
    // do {
      // Query 10 users starting from the lastDocument (exclusive) with a condition
      let q = query(
        collection(db, 'users'),
        where('reviewers.caap', '==', "active"),
        orderBy('firstname'),
        startAfter(lastDocument.value),
        limit(queryLimit)
      );
      let querySnapshot = await getDocs(q);
      let queriedUsers = querySnapshot.docs
                            .filter((doc)=> {
                              let user = doc.data();
                              // check if users has purchase date data
                              if (user.caap_data && user.caap_data.purchase_date) {
                                // check if user purchase date is between Jul 1, 2023 and Jan 1, 2024
                                let extUserStartDate = moment('2023-06-30').valueOf()
                                let extUserEndDate = moment('2024-01-01').valueOf()
                                if (extUserStartDate < parseInt(user.caap_data.purchase_date) && extUserEndDate > parseInt(user.caap_data.purchase_date)) {
                                  // check if the user has the activateLater data
                                  return typeof user.caap_data.activation_date === 'undefined' || user.caap_data.activation_date != null;
                                }
                              }
                              return false;
                            })
                            .map((doc)=> {
                              // transform data
                              return { id: doc.id, data: doc.data() }
                            })

      // Update lastDocument for the next query
      lastDocument.value = querySnapshot.docs[querySnapshot.docs.length - 1];
      // Check if it's the last set of data
      isLastSet.value = querySnapshot.size < queryLimit;
      filteredUsers.value = [...filteredUsers.value, ...queriedUsers]
      
      // rerun query if filtered users count is less than 10 and it is not the last set
    // } while (filteredUsers.value.length < queryLimit && !isLastSet.value);

    users.value = [...users.value, ...filteredUsers.value];
    filteredUsers.value = [];

    console.log('===>', users.value)
  } catch (error) {
    console.error('Error loading more data:', error);
  }

  waiting.value = false
};

onMounted(() => {
  // Initial load of 10 users
  loadMore();
  console.log('legacy component loaded...')
});


</script>