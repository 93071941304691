<template>

    <div class=" flex-row border-b dark:border-warm-gray-700 pb-4">
      <h2 class="text-xl font-extrabold text-warm-gray-700 tracking-wider mb-4 dark:text-warm-gray-100">V3 Users</h2>
      
      <!-- desktop view -->
      <ul class=" hidden md:flex justify-start gap-x-2">
        <li v-for="tab in tabs" @click="activeTab = tab.id" :class="[activeTab == tab.id ? 'border-yellow-600 font-semibold' : 'border-warm-gray-400 dark:border-warm-gray-700 text-warm-gray-600 dark:text-warm-gray-400','border-b-4 py-2 px-2 cursor-pointer']">{{tab.name}}</li>
      </ul>
      <!-- mobile view -->
      <div class=" block md:hidden w-full text-right border border-warm-gray-600 rounded-md bg-warm-gray-700">
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton
              class="inline-flex w-full justify-center rounded-md bg-black/20 px-4 py-2 text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
            >
              <h3 class="font-bold">
                <template v-for="tab in tabs">
                  <span v-if="activeTab == tab.id">{{tab.name}}</span>
                </template>
              </h3>

              <ChevronDownIcon
                class="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                aria-hidden="true"
              />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <MenuItems
              class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-warm-gray-600 shadow-lg ring-1 ring-black/5 focus:outline-none border-none z-50"
            >
              <div class="px-1 py-1">
                <MenuItem v-for="tab in tabs" v-slot="{ active }">
                  <button
                    @click="activeTab = tab.id"
                    :class="[
                      active ? ' text-purple-800' : 'text-warm-gray-100',
                      'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                    ]"
                  >
                    {{ tab.name }}
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>

    </div>

    <div class="">
      <LegacySignup v-show="activeTab == 'legacy-signup'" />
      <LegacyOnboarded v-show="activeTab == 'legacy-onboarded'" />
      <LegacyPurchased v-show="activeTab == 'legacy-purchased'" />
      <LegacyExtActivated v-show="activeTab == 'legacy-ext-activated'" />
      <LegacyExtDeactivated v-show="activeTab == 'legacy-ext-deactivated'" />
    </div>
</template>

<script setup>

import { ref, onMounted } from 'vue';
import LegacySignup from './components/LegacySignup.vue'
import LegacyOnboarded from './components/LegacyOnboarded.vue'
import LegacyPurchased from './components/LegacyPurchased.vue'
import LegacyExtActivated from './components/LegacyExtActivated.vue'
import LegacyExtDeactivated from './components/LegacyExtDeactivated.vue'



import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

const tabs = [
  {id: 'legacy-signup',name: 'Sign up'},
  {id: 'legacy-onboarded',name: 'Onboarded'},
  {id: 'legacy-purchased',name: 'Legacy - Purchased'},
  {id: 'legacy-ext-activated',name: 'Legacy Ext. - Activated'},
  {id: 'legacy-ext-deactivated',name: 'Legacy Ext. - Deactivated'},
];


const activeTab = ref('legacy-signup')

</script>

<style>
  
</style>