export const reviewerRoutes = [{
        path: '/reviewers',
        name: 'reviewerList',
        component: () =>
            import ('../views/reviewerList.vue')
    },
    {
        path: '/reviewer/create',
        name: 'reviewerCreate',
        component: () =>
            import ('../views/reviewerCreate.vue')
    },
    {
        path: '/reviewer/edit/:reviewerId',
        name: 'reviewerEdit',
        props: true,
        component: () =>
            import ('../views/reviewerEdit.vue')
    },
    {
        path: '/reviewer/view/:reviewerId',
        name: 'reviewerView',
        props: true,
        component: () =>
            import ('../views/reviewerView.vue')
    },
]